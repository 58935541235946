import { SupportedProducts } from "../constants";
import { AmethystStrategy, CraftumStrategy, FreeStrategy, GrassStrategy, ProductStrategy } from "./ProductStrategies";

const useProductStrategySelector = (productName: string): ProductStrategy | null => {
    switch (productName) {
        case SupportedProducts.AMETHYST:
            return new AmethystStrategy();
        case SupportedProducts.GRASS:
            return new GrassStrategy();
        case SupportedProducts.FREE:
            return new FreeStrategy();
        case SupportedProducts.BASIC_CRAFTUM:
            return new CraftumStrategy();
        default:
            return null;
    }
};

export default useProductStrategySelector;
