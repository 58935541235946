import { useState } from "react";
import { VisualLinkMode } from "../types";

export default function () {
    const [sidebarMode, setSidebarMode] = useState<VisualLinkMode>(VisualLinkMode.HIDE);
    const [subsidebarMode, setSubsidebarMode] = useState<string | null>(null);

    const setSubsidebar = (subsidebar: string | null) => {
        setSubsidebarMode(subsidebar);
    };

    const toggleSidebar = () => {
        setSidebarMode((prev) => {
            if (prev === VisualLinkMode.SHOW) {
                return VisualLinkMode.HIDE;
            }
            return VisualLinkMode.SHOW;
        });
        // setSidebarMode((prev) => {
        //     if (prev === VisualLinkMode.SHOW) {
        //         setMenuMode(VisualLinkMode.HIDE);
        //         return VisualLinkMode.HIDE;
        //     } else {
        //         setMenuMode(VisualLinkMode.SHOW);
        //         return VisualLinkMode.SHOW;
        //     }
        // });
    };
    const hideSidebar = () => {
        // setSidebarMode(VisualLinkMode.HIDE);
    };

    return { sidebarMode, toggleSidebar, hideSidebar, subsidebarMode, setSubsidebar };
}
