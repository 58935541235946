import { posthog } from "posthog-js";

const FEATURE_FLAGS_KEY = "csrv-feature-flags";

interface FeatureFlags {
    [key: string]: boolean | string | undefined;
}

export const getFeatureFlags = (): FeatureFlags => {
    const flags = sessionStorage.getItem(FEATURE_FLAGS_KEY);
    return flags ? JSON.parse(flags) : {};
};

export const setFeatureFlag = (flag: string, value: boolean | string | undefined): void => {
    const flags = getFeatureFlags();
    flags[flag] = value;
    sessionStorage.setItem(FEATURE_FLAGS_KEY, JSON.stringify(flags));
};

export const getFeatureFlag = (flag: string): boolean | string | undefined => {
    const flags = getFeatureFlags();
    if (flags[flag] === undefined) {
        const value = posthog.getFeatureFlag(flag);
        if (value) {
            // check if value is "true" or "false"
            if (sessionStorage) {
                setFeatureFlag(flag, value);
            }
            return value;
        }

        return undefined;
    }

    return flags[flag] ?? null;
};

export const removeFeatureFlag = (flag: string): void => {
    const flags = getFeatureFlags();
    delete flags[flag];
    sessionStorage.setItem(FEATURE_FLAGS_KEY, JSON.stringify(flags));
};

// Warning: This function is only for testing purposes, you can test your experiments by overriding the flag value https://eu.posthog.com/project/32409/experiments
export const overrideFlag = (flag: string, value: string): void => {
    posthog.featureFlags.override({ [flag]: value });
};
