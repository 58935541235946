import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ServerStats } from "../types";

const MAX_LOGS = 60;

interface ConsoleState {
    liveDataBuffor: string;
    stats: ServerStats[];
    bytes: number;
    historyBytes: number;
    totalBytes: number;
}

const slice = createSlice({
    name: "consoleLogs",
    initialState: {
        stats: [] as ServerStats[],
    } as ConsoleState,
    reducers: {
        addStats: (state, { payload }: PayloadAction<{ data: ServerStats }>) => {
            if (payload) {
                state.stats.push(payload.data);
                if (state.stats.length > MAX_LOGS) {
                    state.stats.shift();
                }
            }
        },
        clearStats: (state) => {
            return {
                ...state,
                stats: [],
            };
        },
    },
});

export const { addStats, clearStats } = slice.actions;

export default slice.reducer;
