import { Struct, validate } from "superstruct";
import CustomError from "../CustomError";
import transformData from "./transformData";

export async function transformToStruct<T>(data: T, structure: Struct<T>, url?: string) {
    const transformedData = transformData(data, structure);
    const [err] = validate(transformedData, structure);
    if (err) {
        // eslint-disable-next-line no-console
        console.debug({
            message: `${url} validation error`,
            structure,
            data,
            err,
        });
    }
    return transformedData;
}

export async function customQuery({
    url,
    method,
    body,
    expectedStatus = 200,
}: {
    url: string;
    method: string;
    body?: object | string | null;
    expectedStatus?: number;
}) {
    const response = await fetch(url, {
        method,
        credentials: "include",
        body: body ? JSON.stringify(body) : undefined,
        headers: {
            "Content-Type": "application/json",
        },
    });

    if (response.status !== expectedStatus) {
        const result = await response.json();
        return {
            error: new CustomError(result.data.message, result.data.event_id),
        };
    }

    return {
        data: undefined,
    };
}
