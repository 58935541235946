import styled from "styled-components";

const Container = styled.div`
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1);
`;

const Spacer: React.FC<{ style?: React.CSSProperties }> = ({ style }) => {
    return <Container style={style} />;
};

export default Spacer;
