import { FaFireAlt } from "@react-icons/all-files/fa/FaFireAlt";
import { Space } from "csrvui";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { BillingPeriods } from "../../../constants";
import { BillingProduct } from "../../../modules/Billings/types";
import { hoursToPackage } from "../../../modules/Server/constants";
import useClusters from "../../../modules/Server/hooks/useClusters";
import useServerCost from "../../../modules/Server/hooks/useServerCost";
import useProductStrategySelector from "../../../modules/Server/strategies/useProductStrategySelector";
import Chip from "../Chip";
import ResponsiveModal from "../ResponsiveModal";
import { BillingPeriodButton, ButtonsWrapper } from "./style";

interface InfoModalProps {
    isOpen: boolean;
    onClose: () => void;
    product: BillingProduct;
}

const MoreInfoModal: React.FC<InfoModalProps> = ({ isOpen, onClose, product }) => {
    const { t } = useTranslation();
    const strategy = useProductStrategySelector(product.id);
    const { calculateProductCost } = useServerCost();
    const { getProductClusters } = useClusters();

    const periods = product.prices ? product.prices.map((p) => p.period as BillingPeriods) : [];

    const uniquePeriods = Array.from(new Set(periods));

    const availableClusters = getProductClusters(product);

    const totalCost = useCallback(
        (billingPeriod: string) => {
            return calculateProductCost([product], availableClusters[0].id || "", billingPeriod || "");
        },
        [calculateProductCost, product, availableClusters]
    );

    const getDiscountPercentage = (period: string) => {
        const totalCostValue = totalCost(period);
        const billingPeriodMultiplier = Math.round(parseInt(period || "720", 10) / 720);

        const totalRegularCost = totalCost(BillingPeriods.MONTH);

        if (!totalCostValue || !totalRegularCost) {
            return null;
        }

        const multipliedTotalRegularPrice = totalRegularCost.amount * billingPeriodMultiplier;

        const discountPercentage = Math.round(
            ((multipliedTotalRegularPrice - totalCostValue.amount) / multipliedTotalRegularPrice) * 100
        );

        return discountPercentage;
    };

    if (!strategy) {
        return null;
    }

    const { renderModalContent, getCardThumb, getProductScheme } = strategy;

    const buttonBackground = getCardThumb();

    return (
        <ResponsiveModal
            styles={{
                modal: {
                    width: "500px",
                    maxWidth: "750px",
                },
            }}
            open={isOpen}
            onClose={onClose}
            animationDuration={0}
            center
        >
            <Space $gap={15}>
                {renderModalContent()}

                <Space $padding={0} $direction="horizontal" $align="space-between" $oppositeAlign="end">
                    <ButtonsWrapper>
                        {uniquePeriods.map((period) => {
                            const discount = getDiscountPercentage(period);

                            return (
                                <BillingPeriodButton
                                    data-ph-capture-attribute-id="more_info_modal:billing_period_switch"
                                    data-ph-capture-attribute-period={period}
                                    data-ph-capture-attribute-product={product.id}
                                    image={buttonBackground}
                                    key={period}
                                    to={`/offers/create?products=${product.id}&billing=${period}`}
                                >
                                    {discount && discount > 0 && (
                                        <Chip
                                            bgColor={getProductScheme()}
                                            style={{
                                                position: "absolute",
                                                top: -15,
                                                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                            }}
                                        >
                                            <Space $padding={0} $direction="horizontal" $gap={10}>
                                                <FaFireAlt size={15} />
                                                {discount}% {t("offers.discount")}
                                            </Space>
                                        </Chip>
                                    )}

                                    {hoursToPackage[period]}
                                </BillingPeriodButton>
                            );
                        })}
                    </ButtonsWrapper>
                </Space>
            </Space>
        </ResponsiveModal>
    );
};

export default MoreInfoModal;
