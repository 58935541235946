import styled from "styled-components";

export const SwitchWrapper = styled.div`
    backgroundcolor: ${({ theme }) => theme.colors.primaryBackground};
    padding: 5px;
    border-radius: 8px;
    position: relative;
    display: flex;

    flex-wrap: wrap;
    height: fit-content;
`;

export const SwitchTabBackground = styled.div<{
    left: number;
    width: number;
    top: number;
    variant?: "small" | "default";
}>`
    position: absolute;
    padding: 10px;
    border-radius: 8px;
    width: ${({ width }) => `${width}px`};
    height: ${({ variant }) => (variant === "small" ? "30px" : "40px")};
    top: ${({ top }) => `${top}px`};
    left: ${({ left }) => `${left}px`};
    box-shadow: 0px 2px 2px ${({ theme }) => theme.colors.dark}4A;
    background-color: ${({ theme }) => theme.colors.secondaryBackground};

    transition: 0.2s ease-in-out;
`;
