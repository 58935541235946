import { useCallback, useEffect, useState } from "react";

const usePackageCreationListener = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isFailed, setIsFailed] = useState(false);
    const [currentProcessId, setCurrentProcessId] = useState<string | null>(null);

    const handleClose = useCallback(() => {
        if (currentProcessId) {
            sessionStorage.setItem(`popup-ignore-${currentProcessId}`, "true");
        }
        if (isFailed && currentProcessId) {
            sessionStorage.setItem(`alert-ignore-${currentProcessId}`, "true");
        }
        setIsModalOpen(false);
    }, [currentProcessId, isFailed]);

    const handleShowModal = (e: CustomEvent<{ id: string }>, prefix: string, isFailed?: boolean) => {
        const processId = e.detail.id;
        const isIgnored = sessionStorage.getItem(`${prefix}-${processId}`);

        if (isIgnored !== null) return;

        setCurrentProcessId(processId);
        setIsModalOpen(true);
        setIsFailed(!!isFailed);
    };

    useEffect(() => {
        const handleEvent = (e: CustomEvent<{ id: string }>) =>
            handleShowModal(e as CustomEvent<{ id: string }>, "popup-ignore");

        window.addEventListener("process-package-in-progress", handleEvent as EventListener);

        return () => {
            window.removeEventListener("process-package-in-progress", handleEvent as EventListener);
        };
    }, []);

    useEffect(() => {
        const handleEvent = (e: CustomEvent<{ id: string }>) =>
            handleShowModal(e as CustomEvent<{ id: string }>, "alert-ignore");

        window.addEventListener("process-package-failed", handleEvent as EventListener);

        return () => {
            window.removeEventListener("process-package-failed", handleEvent as EventListener);
        };
    }, []);

    useEffect(() => {
        const handleEvent = () => {
            if (isModalOpen) {
                handleClose();
            }
        };

        window.addEventListener("process-package-success", handleEvent as EventListener);

        return () => {
            window.removeEventListener("process-package-success", handleEvent as EventListener);
        };
    }, [handleClose, isModalOpen]);

    return { isModalOpen, isFailed, handleClose };
};

export default usePackageCreationListener;
