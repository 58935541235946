import { createContext, useContext } from "react";

type GRPCProcessesContextType = {
    isConnected: boolean;
    connect: () => void;
    disconnect: () => void;
};

export const GRPCProcessesContext = createContext<GRPCProcessesContextType>({
    isConnected: false,
    connect: () => {},
    disconnect: () => {},
});

export default () => useContext(GRPCProcessesContext);
