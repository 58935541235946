import { BillingService } from "../../modules/Billings/types";
import { Transaction } from "../../modules/Payments/types";

interface GAEventParams {
    value?: number;
    currency?: string;
    transaction_id?: string;
    shipping?: number;
    items?: unknown[];
    tax: number;
}

type SignUpEventMethod = "basic" | "from-code-login-form" | "from_redeem_test_server_form";
type SignInEventMethod = "password" | "code";

interface GASignUpEventParams {
    method: SignUpEventMethod;
}

interface GaSignInEventParams {
    method: SignInEventMethod;
}

interface GaCreateServerEventParams {
    product_id: string;
    billing_period: string;
    currency: string;
}

interface GaUpgradeServerEventParams {
    from_product: string;
    to_product: string;
}

interface GaRechargeWalletEventParams {
    amount: number;
    currency: string;
}

interface GaRedeemTestServerParams {
    product_id: string;
    package: string;
}

type EventParams =
    | GAEventParams
    | GASignUpEventParams
    | GaSignInEventParams
    | GaCreateServerEventParams
    | GaUpgradeServerEventParams
    | GaRechargeWalletEventParams
    | GaRedeemTestServerParams;

export const trackGAEvent = (eventName: string, eventParams: EventParams) => {
    if (typeof window.gtag === "function") {
        window.gtag("event", eventName, {
            ...eventParams,
        });
    } else {
        // eslint-disable-next-line no-console
        console.warn("gtag function is not available on window");
    }
};

interface GAConsent {
    ad_storage: "granted" | "denied";
    ad_user_data: "granted" | "denied";
    ad_personalization: "granted" | "denied";
    analytics_storage: "granted" | "denied";
}

export const updateGAConsent = (data: GAConsent) => {
    if (typeof window.gtag === "function") {
        window.gtag("consent", "update", {
            ad_storage: data.ad_storage,
            analytics_storage: data.analytics_storage,
            ad_personalization: data.ad_personalization,
            ad_user_data: data.ad_user_data,
        });
    } else {
        // eslint-disable-next-line no-console
        console.warn("gtag function is not available on window");
    }
};

function initCCConvertion(val: number) {
    window.ccConVal = val || 0;
    const script = document.createElement("script");
    const target = "https://www.clickcease.com/monitor/cccontrack.js";
    const elem = document.head;
    script.type = "text/javascript";
    script.src = target;
    elem.appendChild(script);
}

export const emitPurchaseEvent = (data: Transaction, service: BillingService) => {
    const { id: transactionId, amount: value, currency, positions } = data;

    const items = positions.map((position) => {
        let item_name = position.name;
        if (item_name.startsWith("Doładowanie")) {
            item_name = `Doładowanie ${service.product}`;
        }

        return {
            item_name,
            price: position.total_price_gross,
            quantity: position.quantity,
            item_category: service.billing_period,
        };
    });

    const brutto = value / 100;
    const netto = brutto / (1 + data.country_tax_rate.vat_rate);
    const tax = brutto - netto;

    trackGAEvent("purchase", {
        transaction_id: transactionId,
        value: brutto,
        currency,
        items,
        tax,
    });

    initCCConvertion(value);
};

export const emitSignUpEvent = (method: SignUpEventMethod) => {
    trackGAEvent("sign_up", {
        method,
    });
};

export const emitSignInEvent = (method: SignInEventMethod) => {
    trackGAEvent("sign_in", {
        method,
    });
};

export const emitCreateServerEvent = (data: GaCreateServerEventParams) => {
    trackGAEvent("create_server", {
        ...data,
    });
};

export const emitUpgradeServerEvent = (data: GaUpgradeServerEventParams) => {
    trackGAEvent("upgrade_server", {
        ...data,
    });
};

export const emitRechargeWalletEvent = (data: GaRechargeWalletEventParams) => {
    trackGAEvent("recharge_wallet", {
        ...data,
    });
};

export const emitRedeemTestServerEvent = (data: GaRedeemTestServerParams) => {
    trackGAEvent("redeem_test_server", {
        ...data,
    });
};
