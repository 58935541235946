import CsrvFS from "csrvfs";
import CsrvWebFTP from "csrvfs/dist/CsrvRemoteFS";
import { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useGetProductsQuery, useGetServiceByIdQuery, useGetServiceCostQuery } from "../../../modules/Billings/api";
import { BillingService } from "../../../modules/Billings/types";
import { MarketProject } from "../../../modules/Marketplace/types";
import {
    useGenerateRemotefsTokenMutation,
    useServerByIdQuery,
    useServerStatusQuery,
} from "../../../modules/Server/api";
import useServerPackages from "../../../modules/Server/hooks/useServerPackages";
import { useWalletByIdQuery } from "../../../modules/Server/modules/Wallets/api";
import {
    clearSlice,
    setCurrentServer,
    setCurrentService,
    setCurrentServiceCost,
    setCurrentWallet,
} from "../../../modules/Server/slices/currentServerSlice";
import {
    getCurrentServer,
    getCurrentService,
    getCurrentWallet,
    getInstalledApps,
    getInstalledPackages,
    getServerStatus,
    isInstalledPackagesLoading,
} from "../../../modules/Server/slices/selectors";
import { ServerStatusSlice, setServerStatus } from "../../../modules/Server/slices/serverStatusSlice";
import { ServerWithParameters } from "../../../modules/Server/types";
import getRawPriceForProduct from "../../../modules/User/helpers/getRawPriceForProduct";
import { hasUserCapability } from "../../../modules/User/helpers/hasCapability";
import useUserData from "../../../modules/User/hooks/useUserData";
import * as UserTypes from "../../../modules/User/types";
import { HostUrl } from "../../../rootTypes";
import { showApiError } from "../../helpers/showToast";
import usePingServer from "../../helpers/usePingServer";
import { ProcessesContext } from "../../hooks/ProcessesContext";
import useCurrentServerIdentitites from "../../hooks/useCurrentServerIdentitites";
import { UserPermissions } from "../../types";
import GlobalTransitionLoader from "../GlobalTransitionLoader";

interface Props {
    children: JSX.Element;
    rewriteServerId?: string;
    disableTransition?: boolean;
}

export const CurrentServerContext = createContext({
    server: {} as ServerWithParameters,
    service: {} as BillingService,
    webFTP: null as CsrvWebFTP | null,
    wallet: {} as UserTypes.Wallet,
    refetchWallet: async () => {},
    csrvfs: null as CsrvFS | null,
    installedPackages: {} as Record<
        string,
        {
            version: string;
            hash: string;
            provides: string[];
            labels: Record<string, string>;
        }
    > | null,
    installedPackagesLoading: true,
    primaryEngine: null as string | null,
    installedApps: [] as MarketProject[],
    canBeUpgraded: false,
    serverStatus: {
        pod_name: null,
        game_status: null,
        pod_image: null,
        hypnos_version: null,
    } as ServerStatusSlice,
    refreshCurrentServerData: async () => {},
    refreshInstalledPackages: async () => {},
    clearServerData: () => {},
});

const CurrentServerProvider: React.FC<Props> = ({ children, rewriteServerId, disableTransition }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [generateRemotefsToken] = useGenerateRemotefsTokenMutation();
    const { resubscribe } = useContext(ProcessesContext);

    // data
    const { serverId: currentServerId } = useCurrentServerIdentitites();
    const { user, updateUserData, isLoading: isUserLoading } = useUserData();
    const { data: products } = useGetProductsQuery();
    const serverId = useMemo(() => rewriteServerId ?? currentServerId, [rewriteServerId, currentServerId]);

    // queries
    const {
        data: serverData,
        isLoading,
        isError,
        error: serverError,
    } = useServerByIdQuery(serverId ?? "", {
        skip: !serverId,
    });

    const { data: serverStatusData, refetch: refetchServerStatus } = useServerStatusQuery(serverId ?? "", {
        skip: !serverId || !serverData,
    });

    const { data: serviceData, refetch: refetchService } = useGetServiceByIdQuery(serverId ?? "", {
        skip: !serverId || !serverData,
    });

    const { data: serviceCost, refetch: refetchServiceCost } = useGetServiceCostQuery(
        { id: serverId ?? "" },
        {
            skip: !serverId || !serverData,
        }
    );
    const { data: walletData, refetch: refetchWallet } = useWalletByIdQuery(serviceData?.wallet_id || "", {
        skip: !serviceData?.wallet_id,
    });

    // selectors
    const dispatch = useAppDispatch();
    const server = useAppSelector(getCurrentServer);
    const service = useAppSelector(getCurrentService);
    const wallet = useAppSelector(getCurrentWallet);
    const serverStatus = useAppSelector(getServerStatus);
    const installedPackages = useAppSelector(getInstalledPackages);
    const installedPackagesLoading = useAppSelector(isInstalledPackagesLoading);
    const installedApps = useAppSelector(getInstalledApps);
    const primaryEngine = useMemo(() => {
        return serverData?.parameters.game?.engine || null;
    }, [serverData]);

    usePingServer(serverId);

    // useStates
    const [inProp, setInProp] = useState(!server || !service || !serverStatus);

    const url = window.location.origin.includes("localhost") ? HostUrl : window.location.origin;

    const remotefsUrl = useMemo(() => {
        if (!serverData || !serverData.last_node || !serverStatus || !serverStatus.pod_name) return null;
        return `https://${serverData.last_node}/${serverData.id}/remotefs`;
    }, [serverData, serverStatus]);

    const canBeUpgraded = useMemo(() => {
        const currentProduct =
            products && serviceData && products.find((product) => product.id === serviceData.product);

        if (!currentProduct) return false;

        return products.some(
            (product) => getRawPriceForProduct(product, "PLN") > getRawPriceForProduct(currentProduct, "PLN")
        );
    }, [serviceData, products]);
    const csrvfsInstance = useRef<CsrvFS | null>(null as CsrvFS | null);
    const [webFTPInstance, setWebFTPInstance] = useState<CsrvWebFTP | null>(null);

    useEffect(() => {
        resubscribe(currentServerId);
    }, [currentServerId, resubscribe]);

    useEffect(() => {
        return () => {
            resubscribe(undefined);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const initCsrvfs = async () => {
            if (!serviceData || !remotefsUrl || !serverStatus.pod_name) return;

            try {
                const tokenResponse = await generateRemotefsToken(serviceData.service_id).unwrap();
                csrvfsInstance.current = new CsrvFS(url, remotefsUrl, serviceData.service_id, {
                    token: tokenResponse.token,
                });
                const webFTPInstance = new CsrvWebFTP(url, remotefsUrl, serviceData.service_id, {
                    token: tokenResponse.token,
                });

                setWebFTPInstance(webFTPInstance);
            } catch (e) {
                showApiError(e);
            }
        };

        initCsrvfs();
    }, [serviceData, url, remotefsUrl, generateRemotefsToken, serverStatus.pod_name]);

    const { refresh } = useServerPackages(webFTPInstance || null, server);

    const providerValue = useMemo(() => {
        return {
            server,
            service,
            wallet,
            refetchWallet: async () => {
                await refetchWallet();
            },
            installedPackages,
            installedPackagesLoading,
            primaryEngine,
            canBeUpgraded,
            installedApps,
            refreshInstalledPackages: refresh,
            refreshCurrentServerData: async () => {
                refetchWallet();
                refetchService();
                updateUserData();
                refetchServerStatus();
                // refetch();
                refetchServiceCost();
            },
            clearServerData: () => {
                dispatch(clearSlice());
            },
            csrvfs: csrvfsInstance.current,
            webFTP: webFTPInstance,
            serverStatus,
        };
    }, [
        server,
        service,
        wallet,
        installedPackages,
        installedPackagesLoading,
        primaryEngine,
        installedApps,
        refresh,
        webFTPInstance,
        serverStatus,
        refetchWallet,
        refetchService,
        updateUserData,
        canBeUpgraded,
        refetchServerStatus,
        refetchServiceCost,
        dispatch,
    ]);

    useEffect(() => {
        return () => {
            dispatch(clearSlice());
        };
    }, [dispatch]);

    useEffect(() => {
        if (serverData) {
            dispatch(setCurrentServer(serverData));
        }
    }, [dispatch, serverData]);

    useEffect(() => {
        if (serviceData) {
            dispatch(setCurrentService(serviceData));
        }
    }, [dispatch, serviceData]);

    useEffect(() => {
        if (serviceCost) {
            dispatch(setCurrentServiceCost(serviceCost.cost));
        }
    }, [dispatch, serviceCost]);

    useEffect(() => {
        if (walletData) {
            dispatch(setCurrentWallet(walletData));
        }
    }, [dispatch, walletData]);

    useEffect(() => {
        if (serverStatusData) {
            dispatch(setServerStatus(serverStatusData));
        }
    }, [dispatch, serverStatusData]);

    useEffect(() => {
        setInProp(serverStatus.loading || !service || !server);
    }, [isLoading, serverStatus, service, server, wallet]);

    if (!isLoading && !serverData && !isUserLoading && serverId !== undefined) {
        if (isError && hasUserCapability(user, UserPermissions.SERVER)) {
            if (user.services.length > 0) {
                navigate("/unauthorized-server", {
                    replace: true,
                    state: {
                        from: window.location.pathname.replace(serverId as string, ":serverid"),
                    },
                });
            } else {
                navigate("/account");
            }
            return null;
        }
        if (!serverData) {
            showApiError(serverError);
            return <Navigate to="/account" />;
        }
    }

    if (disableTransition) {
        return <CurrentServerContext.Provider value={providerValue}>{children}</CurrentServerContext.Provider>;
    }

    return (
        <CurrentServerContext.Provider value={providerValue}>
            <GlobalTransitionLoader text={t("loading.data")} inProp={inProp} />
            {!isLoading && !serverStatus.loading && !!server && !!service && children}
        </CurrentServerContext.Provider>
    );
};

export default CurrentServerProvider;
