import styled from "styled-components";

export const Background = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
`;

export const Container = styled.div`
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 1000px;
    max-height: 90%;
    overflow: auto;
`;

export const Section = styled.div`
    margin-bottom: 15px;
    padding: 16px 10px;
    background: #67a35727;
    border-radius: 10px;
`;

export const Title = styled.div`
    font-size: 18px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
`;

export const TitleHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
    padding-left: 32px;
    cursor: pointer;
`;

export const Content = styled.div<{ visible: boolean }>`
    margin-top: 10px;
    font-weight: 400;
    display: ${(props) => (props.visible ? "block" : "none")};
`;

export const Arrow = styled.div`
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
`;

export const Buttons = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    margin-top: 24px;
    margin-bottom: 16px;

    @media (max-width: 600px) {
        button {
            width: 100%;
        }
    }

    @media (min-width: 600px) {
        flex-direction: row;
    }
`;

export const Toggle = styled.button<{ active: boolean; disabled?: boolean }>`
    width: 50px;
    min-width: 50px;
    height: 25px;
    border-radius: 15px;
    border: none;
    background: ${(props) => (props.active ? "#67a357a5" : "#67a35745")};
    ${({ disabled }) => disabled && "filter: grayscale(1);"}
    position: relative;
    cursor: pointer;

    &:before {
        content: "";
        width: 20px;
        height: 20px;
        background: white;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: ${(props) => (props.active ? "25px" : "5px")};
        transform: translateY(-50%);
        transition: left 0.3s;
    }
`;

export const Button = styled.button`
    cursor: pointer;
    background: none;
    box-shadow: none;
    border: none;
    padding: 12px 24px;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    border-radius: 8px;
    transition: all 0.45s ease;

    &:hover,
    &:focus {
        opacity: 0.7;
    }
`;

export const PrimaryButton = styled(Button)`
    background-color: #234d20;
`;

export const SecondaryButton = styled(Button)`
    background-color: #67a35774;
    color: #234d20;
`;

export const Details = styled.div`
    padding: 12px 0 24px 0;
`;
