import { Important, Paragraph, Space, Title } from "csrvui";

import { useTranslation } from "react-i18next";
import ResponsiveModal from "../../../../shared/components/ResponsiveModal";

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

const EventModal = ({ isOpen, onClose }: Props) => {
    const { t } = useTranslation();
    return (
        <ResponsiveModal open={isOpen} onClose={onClose}>
            <Space $padding="0 40px 0 20px">
                <Title>{t("marketplace.event_details")}</Title>
                <Important>🏆 Konkurs na najczęściej instalowany dodatek! 🏆</Important>
                <Paragraph>
                    Marzysz, by Twój dodatek zdobył sławę i uznanie wśród społeczności? Teraz masz szansę się wykazać!
                </Paragraph>
                <Important
                    style={{
                        marginTop: "10px",
                    }}
                >
                    Co zrobić, aby wygrać?
                </Important>
                <ul>
                    <li>Dodaj swój dodatek do naszej Galerii Dodatków (jeśli jeszcze go tam nie ma).</li>
                    <li>
                        Promuj swój dodatek wśród znajomych, na forach i w social media – im więcej instalacji, tym
                        większe szanse na wygraną!
                    </li>
                    <li>Śledź swoją pozycję w rankingu i zachęcaj innych do instalacji Twojej paczki!</li>
                </ul>
                <Important>Nagrody</Important>
                <ul>
                    <li>💎 I miejsce: 500 zł + 300 zł doładowania portfela Craftserve</li>
                    <li>💎 II miejsce: 250 zł + 200 zł doładowania portfela Craftserve</li>
                    <li>💎 III miejsce: 100 zł + 150 zł doładowania portfela Craftserve</li>
                    <li>🎉 Miejsca 4-10: 100 zł doładowania portfela Craftserve</li>
                </ul>
                <Paragraph>
                    Konkurs trwa od 29 listopada do 31 grudnia. Wyniki ogłosimy w ciągu 72 godzin na naszym Discordzie.
                </Paragraph>
                <Paragraph>Nie czekaj! Dołącz do konkursu i pokaż, co potrafisz!</Paragraph>
            </Space>
        </ResponsiveModal>
    );
};

export default EventModal;
