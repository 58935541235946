import { Button } from "csrvui";
import styled from "styled-components";

export const OfferCard = styled.div<{ borderColor: string }>`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
    min-width: 320px;
    min-height: 340px;
    border-radius: 12px;
    overflow: hidden;
    background-color: ${({ theme }) => theme.colors.white};
    border: 4px solid ${({ borderColor }) => borderColor};
    @media (max-width: 535px) {
        width: 100%;
    }
`;

export const UnavailableWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-100%);
    height: 90px;
    background-color: ${({ theme }) => theme.colors.danger};
`;

export const TitleWrapper = styled.div<{ bgColor: string }>`
    padding: 40px;
    padding-bottom: 12px;
    margin-bottom: -26px;
    ${({ bgColor }) => `background: ${bgColor};`}
    text-align: center;
    z-index: 1;
    border-radius: 12px;

    @media (max-width: 535px) {
        width: 100%;
    }
`;

export const Details = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    list-style-type: none;
    margin: 4px 0;
    padding: 0;

    & span,
    & p {
        font-size: 18px;
        color: ${({ theme }) => theme.colors.secondaryText};
    }
`;

export const Detail = styled.li`
    background-color: ${({ theme }) => theme.colors.secondaryBackground};
    padding: 2px;
    text-align: center;
`;

export const PrimaryButton = styled(Button)<{ bgColor: string }>`
    background-color: ${({ bgColor }) => bgColor};
    width: 100%;
    color: white;
`;

export const OutlinedButton = styled(Button)<{ bgColor: string }>`
    color: ${({ bgColor }) => bgColor};
    border: 2px solid ${({ bgColor }) => bgColor};
    background-color: transparent;
    width: 100%;
`;
