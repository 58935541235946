import { Button, Checkbox, Paragraph, Space, TextInput, Title } from "csrvui";
import { isAfter, isBefore, sub } from "date-fns";
import { Field, Form, Formik } from "formik";
import { usePostHog } from "posthog-js/react";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";
import * as Yup from "yup";
import { npsModalAvailableIssues } from "../../../constants";
import { getCurrentServer } from "../../../modules/Server/slices/selectors";
import { useSendReviewMutation } from "../../../modules/Support/api";
import { useEventsQuery } from "../../../modules/User/api";
import useUserData from "../../../modules/User/hooks/useUserData";
import { getLastNPSModalDate, setLastNPSModalDate } from "../../helpers/localStorage";
import { NPSModalFormValues } from "../../types";
import ResponsiveModal from "../ResponsiveModal";

const ModalButton = styled.button<{ selected: boolean }>`
    font-size: 24px;
    width: 70px;
    height: 70px;
    background-color: rgba(35, 77, 32, 0.1);
    color: #234d20;
    border: 2px solid #234d20;
    padding: 20px;
    cursor: pointer;

    ${({ selected }) => selected && "background-color: #9cce4c;"}

    &:hover {
        background-color: rgba(156, 202, 76, 0.7);
    }

    @media (max-width: 1000px) {
        width: 36px;
        height: 36px;
        padding: 8px;
    }
`;

const StyledSpace = styled(Space)`
    @media (max-width: 1000px) {
        * {
            font-size: 1em !important;
        }
    }
`;

export default function NPSModal() {
    const lastNPSModalDate = getLastNPSModalDate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [sendReview] = useSendReviewMutation();
    const { user } = useUserData();
    const { t } = useTranslation();
    const currentServer = useSelector(getCurrentServer);
    const posthog = usePostHog();
    const serverSpentEnoughtTimeToReview = useMemo(() => {
        if (!user) {
            return null;
        }

        const acceptedServices = user.services.filter(
            (service) =>
                isBefore(new Date(service.created_at), new Date(Date.now() - 3 * 24 * 60 * 60 * 1000)) &&
                service.expires &&
                isAfter(new Date(service.expires), new Date(Date.now()))
        );

        if (acceptedServices.length === 0) {
            return null;
        }

        const oldestActveService = acceptedServices.reduce((prev, current) =>
            isBefore(new Date(prev.created_at), new Date(current.created_at)) ? prev : current
        );

        return oldestActveService;
    }, [user]);
    const [modalOpen, setModalOpen] = useState(true);
    const eventsQueryParams = useMemo(
        () => ({
            id: user.id,
            after: "",
            action: "USER_RATED_APP",
            fromDate: sub(new Date(), { months: 12 }).toISOString(),
            toDate: new Date().toISOString(),
        }),
        [user]
    );

    const { data: userEvents, isLoading } = useEventsQuery(eventsQueryParams, {
        skip: !user,
    });

    const validationSchema = Yup.object().shape({
        rate: Yup.number().required(t("validation.required")),
        comment: Yup.string().max(3000, t("validation.max_length", { max: 3000 })),
        causes: Yup.array().when("rate", {
            is: (rate: number) => rate < 7,
            then: Yup.array()
                .min(1, t("nps_modal.validation.required_issues"))
                .required(t("nps_modal.validation.required_issues")),
            otherwise: Yup.array().nullable().required(t("nps_modal.validation.required_issues")),
        }),
    });

    const handleSubmit = useCallback(
        async (values: NPSModalFormValues) => {
            if (values.rate === null || isSubmitting || !serverSpentEnoughtTimeToReview) {
                return;
            }

            setIsSubmitting(true);

            const transformedIssues = values.causes?.map((issue) => issue.label) || [];

            await sendReview({
                ...values,
                rate: values.rate,
                causes: transformedIssues,
                server_id: serverSpentEnoughtTimeToReview.service_id,
                engine: currentServer.parameters.game?.engine || null,
            });

            posthog.capture("nps_review", {
                ...values,
                serverid: serverSpentEnoughtTimeToReview.service_id,
                engine: currentServer.parameters.game?.engine || null,
            });

            setLastNPSModalDate(new Date());
            setModalOpen(false);
        },
        [isSubmitting, serverSpentEnoughtTimeToReview, sendReview, currentServer, posthog]
    );

    return (
        <ResponsiveModal
            open={
                serverSpentEnoughtTimeToReview !== null &&
                !lastNPSModalDate &&
                modalOpen &&
                !isLoading &&
                !!userEvents &&
                userEvents.count === 0
            }
            center
            onClose={() => {
                setLastNPSModalDate(new Date());
                setModalOpen(false);
            }}
            animationDuration={0}
        >
            <Formik<NPSModalFormValues>
                initialValues={{ rate: null, comment: "", causes: [] }}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
            >
                {({ values, setFieldValue, touched, errors, setTouched }) => (
                    <Form>
                        <StyledSpace $width="100%">
                            <Title $level={2}>{t("nps_modal.title")}</Title>
                            <Paragraph style={{ fontSize: 24 }}>{t("nps_modal.subtitle")}</Paragraph>
                            <Space $direction="horizontal" $padding="20px 0" $wrap={false} $forceWrapOn={1000}>
                                {new Array(11).fill(0).map((_, index) => (
                                    <ModalButton
                                        type="button"
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={index}
                                        onClick={() => {
                                            setFieldValue("rate", index);
                                            setFieldValue("causes", []);
                                            setTouched({ causes: undefined });
                                        }}
                                        selected={values.rate !== null && values.rate === index}
                                    >
                                        {index}
                                    </ModalButton>
                                ))}
                            </Space>
                            {values.rate && values.rate < 7 && (
                                <Space $padding="10px 0 20px 0">
                                    <Paragraph style={{ marginBottom: "8px", fontSize: 24 }}>
                                        {t("nps_modal.issues_tite")}
                                    </Paragraph>
                                    {npsModalAvailableIssues.map((option) => (
                                        <Space
                                            $fullWidth
                                            key={option.value}
                                            $direction="horizontal"
                                            $gap={10}
                                            $padding={0}
                                        >
                                            <Checkbox
                                                onChange={() => {
                                                    if (values.causes?.includes(option)) {
                                                        const newIssues = values.causes.filter(
                                                            (checked) => checked.value !== option.value
                                                        );

                                                        if (option.value === "other") {
                                                            const updatedIssues = newIssues.filter(
                                                                (checked) => checked.value !== "custom-option"
                                                            );
                                                            setFieldValue("causes", updatedIssues);
                                                        } else {
                                                            setFieldValue("causes", newIssues);
                                                        }
                                                    } else {
                                                        setFieldValue("causes", [...(values.causes || []), option]);
                                                    }
                                                }}
                                                $error={!!(touched.causes && errors.causes)}
                                                $sizeVariant="big"
                                                checked={values.causes.some(
                                                    (checkedIssue) => checkedIssue.value === option.value
                                                )}
                                                id={option.value}
                                                label={option.label}
                                            />

                                            {values.causes.some((issue) => issue.value === "other") &&
                                                option.value === "other" && (
                                                    <TextInput
                                                        placeholder={t("nps_modal.issues.other_option_placeholder")}
                                                        onChange={(e) => {
                                                            const otherIssueIndex = values.causes.findIndex(
                                                                (issue) => issue.value === "custom-option"
                                                            );
                                                            const newIssues = [...values.causes];
                                                            if (otherIssueIndex !== -1) {
                                                                newIssues[otherIssueIndex] = {
                                                                    label: e.target.value,
                                                                    value: "custom-option",
                                                                };
                                                            } else {
                                                                newIssues.push({
                                                                    label: e.target.value,
                                                                    value: "custom-option",
                                                                });
                                                            }
                                                            setFieldValue("causes", newIssues);
                                                        }}
                                                        type="text"
                                                    />
                                                )}
                                        </Space>
                                    ))}
                                </Space>
                            )}

                            {touched.causes && errors.causes && (
                                <div style={{ color: "red", marginBottom: 20 }}>{errors.causes as string}</div>
                            )}

                            <Paragraph
                                style={{
                                    padding: 0,
                                    margin: 0,
                                }}
                            >
                                {t("nps_modal.comment_label")}
                            </Paragraph>
                            <Field
                                as="textarea"
                                name="comment"
                                style={{
                                    width: "100%",
                                    height: 100,
                                    padding: 10,
                                    fontSize: 24,
                                    margin: "0 0 20px 0",
                                    border: "1px solid  #234d20",
                                    borderRadius: 5,
                                }}
                            />
                            {touched.comment && errors.comment && (
                                <div style={{ color: "red", marginBottom: 20 }}>{errors.comment}</div>
                            )}

                            <Button
                                $sizeVariant="big"
                                style={{
                                    fontSize: 24,
                                }}
                                type="submit"
                                $loading={isSubmitting}
                                $loadingText={t("common.sending")}
                                disabled={values.rate === null || isSubmitting}
                            >
                                {t("common.send")}
                            </Button>
                        </StyledSpace>
                    </Form>
                )}
            </Formik>
        </ResponsiveModal>
    );
}
