import styled from "styled-components";

export const AccordionHeader = styled.button<{ variant: "primary" | "secondary" }>`
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    padding: ${({ variant }) => (variant === "primary" ? "10px 15px" : "20px")};
    width: 100%;
    background-color: ${({ theme, variant }) =>
        variant === "secondary" ? theme.colors.secondaryBackground : theme.colors.primaryBackground};
    border: none;
    border-bottom: ${({ theme, variant }) => (variant === "primary" ? `1px solid ${theme.colors.border}` : "none")};
    cursor: pointer;
    text-align: left;
    font-size: ${({ variant }) => (variant === "primary" ? "16px" : "20px")};
    font-weight: ${({ variant }) => (variant === "primary" ? 500 : 600)};

    &::before {
        content: "";
        display: ${({ variant }) => (variant === "secondary" ? "block" : "none")};
        width: 4px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        background-color: ${({ theme }) => theme.colors.secondary};
    }
`;

export const AccordionBody = styled.div<{ isOpen: boolean; variant: "primary" | "secondary" }>`
    padding: 0px 15px;
    position: relative;
    margin: 0;
    background-color: ${({ theme, variant }) =>
        variant === "secondary" ? theme.colors.secondaryBackground : theme.colors.primaryBackground};
    border-bottom: ${({ theme, variant }) => (variant === "primary" ? `1px solid ${theme.colors.border}` : "none")};
    font-size: ${({ variant }) => (variant === "primary" ? "16px" : "20px")};
    font-weight: 400;
    max-height: ${({ isOpen }) => (isOpen ? "1000px" : "0px")};
    transition: 0.3s max-height;

    &::before {
        content: "";
        display: ${({ variant }) => (variant === "secondary" ? "block" : "none")};
        width: 4px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        background-color: ${({ theme }) => theme.colors.secondary};
    }
`;

export const AccordionWrapper = styled.div<{ variant: "primary" | "secondary"; animate: boolean }>`
    width: 100%;
    margin: 0 0 10px 0;
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.border}`};
    border-radius: ${({ variant }) => (variant === "primary" ? "5px" : "8px")};
    overflow: hidden;
    box-shadow: 0px 2px 10px #e0e0e0;
    position: relative;

    ${({ animate, theme }) =>
        animate &&
        `
        position: relative;
        overflow: hidden;

        &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 300%;
            height: 300%;
            z-index: 1;
            background: ${theme.colors.warning};
            
            background-size: 10px 10px;
            transform: translate(-50%, -50%);
            animation: ripple .8s linear forwards;
        }

        @keyframes ripple {
            0% {
                transform: translate(-50%, -50%) scale(0);
                opacity: 1;
            }
            100% {
                transform: translate(-50%, -50%) scale(1);
                opacity: 0;
                display: none;
            }
        }
    `}
`;
