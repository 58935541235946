import { Paragraph, Space } from "csrvui";
import { SpaceProps } from "csrvui/dist/esm/components/Space";
import useTheme from "../../hooks/useCustomTheme";
import { StyledLoaderSmall } from "../PanelLoader/style";

interface Props extends SpaceProps {
    vatRate?: number;
}

const VatChip = ({ vatRate, ...props }: Props) => {
    const theme = useTheme();

    return (
        <Space
            $padding="0 0 0 15px"
            $align="center"
            $oppositeAlign="center"
            {...props}
            style={{
                borderRadius: "8px 0 0 8px",
                height: "100%",
                minHeight: "100%",
                border: `1px solid ${theme.colors.white}`,
                borderRight: "none",
                ...props.style,
            }}
        >
            {vatRate ? (
                <Paragraph
                    style={{
                        textWrap: "nowrap",
                        margin: "2px 15px 0 0",
                        color: theme.colors.white,
                        fontSize: "18px",
                        height: "21px",
                    }}
                >
                    VAT {vatRate}%
                </Paragraph>
            ) : (
                <StyledLoaderSmall
                    variant="white"
                    style={{
                        padding: "0 10px 0 0 ",
                    }}
                />
            )}
        </Space>
    );
};

export default VatChip;
