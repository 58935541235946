import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CurrentServerContext } from "../../../shared/components/CurrentServerProvider";
import { generateMinecraftDomain } from "../../../shared/helpers/domainGenerator";
import { showApiError } from "../../../shared/helpers/showToast";
import { useCheckDomainAvailabilityMutation } from "../api";

export default function () {
    const { server } = useContext(CurrentServerContext);
    const navigate = useNavigate();
    const [checkDomainAvailability] = useCheckDomainAvailabilityMutation();
    const { t } = useTranslation();

    const checkIfReserved = useCallback(
        async (domain: string, zone: string) => {
            const result = await checkDomainAvailability({
                payload: {
                    subdomain: domain,
                    zone_name: zone,
                },
            });

            if ("error" in result) {
                return true;
            }

            return false;
        },
        [checkDomainAvailability]
    );

    const validateDomain = async (
        domain: string,
        prevDomain: string,
        zoneName: string,
        onLoading: (loading: boolean) => void
    ) => {
        if (domain.length < 3) {
            onLoading(false);
            return t("domain_settings.subdomain_too_short");
        }
        if (domain.length > 63) {
            onLoading(false);
            return t("domain_settings.subdomain_too_long");
        }
        if (!/^[a-z0-9-]+$/.test(domain)) {
            onLoading(false);
            return t("domain_settings.subdomain_invalid");
        }

        if (domain === prevDomain) {
            onLoading(false);
            return null;
        }

        const reserved = await checkIfReserved(domain, zoneName);

        onLoading(false);
        return reserved ? t("domain_settings.subdomain_reserved") : null;
    };

    const generateDomain = useCallback(
        async (zone_name: string) => {
            let domain = generateMinecraftDomain(zone_name);
            let tries = 0;

            try {
                // eslint-disable-next-line no-await-in-loop
                while (await checkIfReserved(domain.subdomain, domain.zone_name)) {
                    domain = generateMinecraftDomain(zone_name, tries > 5);
                    tries++;
                    if (tries > 10) {
                        throw new Error("Cannot generate domain");
                    }
                }
            } catch (e) {
                showApiError(e);
                navigate("/");
            }
            return domain;
        },
        [checkIfReserved, navigate]
    );

    const suggestDomain = useCallback(
        async (zone_name: string) => {
            const domain =
                server.domains && server.domains.length > 0 && server.domains[0].sub_domain
                    ? server.domains[0].sub_domain.toLowerCase().replace(/[^a-z0-9]/g, "")
                    : "";
            const reserved = await checkIfReserved(domain, zone_name);

            if (!reserved) {
                return domain;
            }

            for (let i = 0; i < 10; i++) {
                const suggestedDomain = `${domain}${i}`;
                // eslint-disable-next-line no-await-in-loop
                const reserved = await checkIfReserved(suggestedDomain, zone_name);
                if (!reserved) {
                    return suggestedDomain;
                }
            }

            return null;
        },
        [checkIfReserved, server.domains]
    );

    return { suggestDomain, checkIfReserved, generateDomain, validateDomain };
}
