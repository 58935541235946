import { Money } from "ts-money";
import { RootState } from "../../../rootReducer";
import { BillingService } from "../../Billings/types";
import { Wallet } from "../../User/types";
import { ServerWithParameters } from "../types";

export const getServerStatus = (state: RootState) => state.serverStatus;
export const isServerStatusLoading = (state: RootState) => state.serverStatus.loading;
export const routeAlertSelector = (state: RootState) => state.routeAlert.showAlertOnRouteChange;
export const getPointerToasts = (state: RootState) => state.pointerToasts.toasts;
export const getCurrentServer = (state: RootState) => state.currentData.server.data as ServerWithParameters;
export const getCurrentService = (state: RootState) => state.currentData.service.data as BillingService;
export const getCurrentServiceCost = (state: RootState) => state.currentData.serviceCost.data as Money;
export const getCurrentWallet = (state: RootState) => state.currentData.wallet.data as Wallet;
export const getPrimaryEngine = (state: RootState) => state.currentData.primaryEngine;
export const getInstalledPackages = (state: RootState) => state.currentData.installedPackages.data;
export const getInstalledApps = (state: RootState) => state.currentData.installedApps.data;
export const isInstalledPackagesLoading = (state: RootState) => state.currentData.installedPackages.loading;
export const isInstalledAppsLoading = (state: RootState) => state.currentData.installedApps.loading;
export const getStatsLogs = (state: RootState) => state.consoleLogs.stats;
export const getTotalBytes = (state: RootState) => state.consoleLogs.totalBytes;
export const getHistoryBytes = (state: RootState) => state.consoleLogs.historyBytes;
export const getOpenedFiles = (state: RootState) => state.fileManagerData.openedFiles;
export const getModifiedPaths = (state: RootState) => state.fileManagerData.modifiedPaths;
export const getFilesProgress = (state: RootState) => state.fileManagerData.currentFilesProgress;
export const getTree = (state: RootState) => state.fileManagerData.tree;
