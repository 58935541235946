import { Paragraph, Space, Title } from "csrvui";
import { useTranslation } from "react-i18next";
import img4 from "../../../assets/images/landingPanelGrid/backupsList.webp";
import img2 from "../../../assets/images/landingPanelGrid/panelImg2.webp";
import img3 from "../../../assets/images/landingPanelGrid/panelImg3.webp";
import img5 from "../../../assets/images/landingPanelGrid/webFTP.webp";
import { ContentWrapper } from "../../../screens/HomeScreen/style";
import { themes } from "../../hooks/useDarkMode";
import LikeButton from "../LikeButton";
import { GridContainer, StyledCard, StyledImage } from "./styles";

interface Card {
    id: string;
    imageSrc?: string;
    title: string;
    description: string;
}

const LandingPanelGrid: React.FC = () => {
    const { t } = useTranslation();

    const cards: Card[] = [
        {
            id: "0",
            imageSrc: img4,
            description: t("landing.backupDescription"),
            title: t("landing.backupTitle"),
        },
        {
            id: "1",
            imageSrc: img2,
            description: t("landing.marketplaceDescription"),
            title: t("landing.marketplaceTitle"),
        },
        {
            id: "2",
            imageSrc: img5,
            description: t("landing.webFtpDescription"),
            title: t("landing.webFtpTitle"),
        },
        {
            id: "3",
            imageSrc: img3,
            description: t("landing.panelDescription"),
            title: t("landing.panelTitle"),
        },
    ];

    return (
        <GridContainer>
            {cards.map((card) => (
                <Space
                    $wrap={false}
                    $fullWidth
                    $align="center"
                    $oppositeAlign="space-between"
                    key={card.id}
                    $padding={0}
                >
                    {card.imageSrc && (
                        <Space
                            $padding={0}
                            style={{
                                maxWidth: "100%",
                                width: "100%",
                                height: "100%",
                                position: "relative",
                            }}
                        >
                            <picture>
                                <source srcSet={card.imageSrc} type="image/webp" />
                                <StyledImage src={card.imageSrc} alt={card.title} loading="lazy" />
                            </picture>
                            <LikeButton
                                label={`discover-our-panel-${card.title}`}
                                style={{ position: "absolute", right: "3%", bottom: "3%" }}
                            />
                        </Space>
                    )}

                    <StyledCard>
                        <ContentWrapper style={{ flex: "1" }}>
                            <Title
                                $level={3}
                                $color={themes.light.colors.primaryBackground}
                                style={{ fontWeight: 700, marginBottom: 20 }}
                            >
                                {card.title}
                            </Title>
                            <Paragraph
                                $level={3}
                                $color={themes.light.colors.primaryBackground}
                                style={{
                                    display: "-webkit-box",

                                    WebkitBoxOrient: "vertical",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    maxWidth: "100%",
                                }}
                            >
                                {card.description}
                            </Paragraph>
                        </ContentWrapper>
                    </StyledCard>
                </Space>
            ))}
        </GridContainer>
    );
};

export default LandingPanelGrid;
