import { Button } from "csrvui";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const ModalContent = styled.div`
    width: 50vw;
    max-width: 700px;
    height: 100%;

    @media (max-width: 768px) {
        width: 100vw;
    }
`;

export const StyledButton = styled(Button)`
    position: absolute;
    top: 16px;
    right: 50%;
    transform: translate(50%, -50%);
    font-size: 13px;
    padding: 5px 24px;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.success} !important;
    z-index: 1;

    &:hover,
    &:active,
    &:focus {
        opacity: 1;
        cursor: auto;
    }
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 15px;
    margin-top: 20px;
    width: 100%;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 20px;
    }
`;

export const BillingPeriodButton = styled(Link)<{ image: string }>`
    margin: 0;
    width: 100%;
    text-align: center;
    padding: 15px;
    border-radius: 8px;
    color: ${({ theme }) => theme.colors.white};
    text-decoration: none;
    background-image: url("${({ image }) => image}");
    background-size: cover;
    background-position: center;
    position: relative;
    display: flex;
    justify-content: center;
    transition: background-position 0.3s;
    align-items: center;

    &:hover {
        background-position: 0 -50px;
    }
`;
