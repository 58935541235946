import { MdExpandMore } from "@react-icons/all-files/md/MdExpandMore";
import { Paragraph } from "csrvui";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useTheme from "../../hooks/useCustomTheme";
import { AccordionBody, AccordionHeader, AccordionWrapper } from "./styles";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    $title: React.ReactNode;
    children: React.ReactNode;
    variant?: "primary" | "secondary";
}

const Accordion = ({ children, $title, variant = "primary", ...props }: Props) => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [playSharedAnimation, setPlaySharedAnimation] = useState(false);
    const [hasBeenOpened, setHasBeenOpened] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const isPrimary = variant === "primary";
    const theme = useTheme();

    const handleOpen = useCallback(() => {
        setIsOpen(!isOpen);

        if (!isOpen && props.id) navigate(`#${props.id}`);
    }, [isOpen, navigate, props]);

    useEffect(() => {
        const { hash } = window.location;

        if (props.id && hash === `#${props.id}` && !hasBeenOpened) {
            if (ref.current) {
                ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
                setPlaySharedAnimation(true);
                setIsOpen(true);
                setHasBeenOpened(true);

                setTimeout(() => {
                    setPlaySharedAnimation(false);
                }, 800);
            }
        }
    }, [props.id, hasBeenOpened]);

    return (
        <AccordionWrapper
            ref={ref}
            animate={playSharedAnimation}
            as={isPrimary ? "div" : "dl"}
            variant={variant}
            {...props}
        >
            <AccordionHeader variant={variant} type="button" onClick={handleOpen}>
                {isPrimary ? (
                    <Paragraph>{$title}</Paragraph>
                ) : (
                    <dt
                        style={{
                            fontWeight: 550,
                            fontSize: "20px",
                            color: theme.colors.secondary,
                        }}
                        itemProp="name"
                    >
                        {$title}
                    </dt>
                )}
                <MdExpandMore
                    size={isPrimary ? 20 : 30}
                    style={{
                        color: isPrimary ? theme.colors.dark : theme.colors.primary,
                        transform: `rotate(${isOpen ? "180deg" : "0deg"})`,
                        transition: "transform 0.3s",
                    }}
                />
            </AccordionHeader>

            {isPrimary ? (
                <AccordionBody variant={variant} isOpen={isOpen}>
                    {children}
                </AccordionBody>
            ) : (
                <AccordionBody variant={variant} isOpen={isOpen}>
                    <dd
                        style={{
                            padding: "5px 20px 20px 6px",
                            margin: "0",
                        }}
                    >
                        {children}
                    </dd>
                </AccordionBody>
            )}
        </AccordionWrapper>
    );
};

export default Accordion;
