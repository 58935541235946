import { t } from "i18next";
import { Money } from "ts-money";
import { getLocalCurrency } from "../../../shared/helpers/localStorage";
import useMoney from "../../../shared/hooks/useMoney";
import { BillingProduct, BillingProductPrice } from "../../Billings/types";

const getPriceForProduct = (product: BillingProduct) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { printLocalPrice } = useMoney();
    const currency = getLocalCurrency();

    const { prices } = product;
    const hasPrices = prices && prices.length > 0;

    const getPeriodInHours = (period: string) => parseInt(period.replace("h", ""), 10);

    const getFilteredPrices = (prices: BillingProductPrice[], currency: string) =>
        prices.filter((price) => price.gross_price.currency === currency);

    if (!hasPrices) return null;

    const filteredPrices = getFilteredPrices(prices, currency);

    const longestBillingPeriodPrice = filteredPrices.sort(
        (a, b) => getPeriodInHours(b.period) - getPeriodInHours(a.period)
    )[0];
    const shortestBillingPeriodPrice = filteredPrices.sort(
        (a, b) => getPeriodInHours(a.period) - getPeriodInHours(b.period)
    )[0];

    const shortestBillingPeriod = shortestBillingPeriodPrice
        ? getPeriodInHours(shortestBillingPeriodPrice.period)
        : 720;

    const longestBillingPeriod = longestBillingPeriodPrice
        ? Math.floor(getPeriodInHours(longestBillingPeriodPrice.period))
        : 720;

    const cheapestPrice = longestBillingPeriodPrice
        ? Math.floor(
              longestBillingPeriodPrice.gross_price.amount / Math.floor(longestBillingPeriod / shortestBillingPeriod)
          )
        : 0;

    const isFree = cheapestPrice === 0;
    const priceDisplay = isFree ? t("common.for_free") : printLocalPrice(new Money(cheapestPrice, currency));

    return isFree ? priceDisplay : `${t("common.from")} ${priceDisplay} / ${t("offers.month")}`;
};

export default getPriceForProduct;

// const prices = product.prices;
// const hasPrices = prices && prices.length > 0;
// const cheapestPrice = hasPrices
//     ? prices
//           .filter((price) => price.gross_price.currency === currency)
//           .sort((a, b) => a.gross_price.amount - b.gross_price.amount)[0]
//     : null;

// const isFree = !cheapestPrice || cheapestPrice.gross_price.amount === 0;
// const priceDisplay = isFree
//     ? t("common.for_free")
//     : `${printLocalPrice(new Money(cheapestPrice.gross_price.amount, cheapestPrice.gross_price.currency))}`;

// (${t(
//     "common.incl_vat"
// )})
