import { useMemo } from "react";
import { Category } from "../../../constants";
import { QueryParamsObject } from "../../../shared/types";
import usePreviewState from "../../Server/hooks/usePreviewState";
import { useSearchProjectQuery } from "../api";

export default function useMarketplace(filters: QueryParamsObject) {
    const [isPreview] = usePreviewState();

    const queryFilters = {
        ...filters,
        ...(isPreview && { project_type: "public" }),
    };

    const {
        data: projects,
        isLoading: projectsLoading,
        refetch,
        isFetching: isFetchingProjects,
    } = useSearchProjectQuery(queryFilters);

    const {
        data: packages,
        isLoading: packagesLoading,
        refetch: refetchPackages,
        isFetching: isFetchingPackages,
    } = useSearchProjectQuery({
        ...filters,
        labels: Category.SERVERPACK + (filters.labels ? `,${filters.labels}` : ""),
    });

    const finalProjects = useMemo(() => {
        return isPreview && projects ? projects.filter((project) => project.project_type === "public") : projects;
    }, [projects, isPreview]);

    const finalPackages = useMemo(() => {
        return isPreview && packages ? packages.filter((project) => project.project_type === "public") : packages;
    }, [packages, isPreview]);

    return {
        projects: finalProjects,
        projectsLoading,
        refetch,
        packages: finalPackages,
        isFetchingPackages,
        isFetchingProjects,
        packagesLoading,
        refetchPackages,
    };
}
