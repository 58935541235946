import { useCallback, useContext } from "react";
import { Currency, Money } from "ts-money";
import { CurrentServerContext } from "../../../shared/components/CurrentServerProvider";
import CustomError from "../../../shared/CustomError";
import { showApiError, showRequestErrorToast } from "../../../shared/helpers/showToast";
import { WalletID } from "../../../shared/types";
import { useCreateTransactionMutation } from "../../Payments/api";
import { TransactionAction } from "../../Payments/types";
import { PaymentType } from "../../Server/types";
import { useCreateWalletMutation, useWalletsQuery } from "../api";
import useUserData from "./useUserData";

export default function () {
    const { server } = useContext(CurrentServerContext);
    const { user } = useUserData();
    const {
        data: wallets,
        isLoading,
        refetch,
    } = useWalletsQuery(user ? user.id : "", {
        skip: !user,
    });
    const [createTransaction] = useCreateTransactionMutation();
    const [createWalletRequest] = useCreateWalletMutation();

    const refreshWallets = useCallback(async () => {
        try {
            if (wallets) {
                await refetch().unwrap();
            }
        } catch (err) {
            const { error, event_id } = err as CustomError;
            showRequestErrorToast(error, event_id);
        }
    }, [refetch, wallets]);

    const recharge = useCallback(
        async (id: WalletID, paymentType: PaymentType, details: Money) => {
            try {
                switch (paymentType) {
                    case PaymentType.Blik: {
                        const response = await createTransaction({
                            action: TransactionAction.DEPOSIT,
                            data: {},
                            payment_details: details,
                            payment_type: PaymentType.Payu,
                            wallet_id: id,
                            blik: true,
                            service_id: server.id,
                        }).unwrap();

                        if (response.redirect_uri) {
                            window.location.href = response.redirect_uri;
                        }

                        break;
                    }
                    default: {
                        const response = await createTransaction({
                            action: TransactionAction.DEPOSIT,
                            data: {},
                            payment_details: details,
                            payment_type: paymentType,
                            wallet_id: id,
                            service_id: server.id,
                        }).unwrap();

                        if (response.redirect_uri) {
                            window.location.href = response.redirect_uri;
                        }

                        break;
                    }
                }
            } catch (err) {
                const { error, event_id } = err as CustomError;
                showRequestErrorToast(error, event_id);
            }
        },
        [createTransaction, server]
    );

    const createWallet = useCallback(
        async (currency: Currency) => {
            try {
                if (!user) {
                    throw new CustomError("User is not logged in");
                }

                await createWalletRequest({
                    userId: user.id,
                    currency: currency.code,
                }).unwrap();
            } catch (e) {
                showApiError(e);
            }
        },
        [user, createWalletRequest]
    );

    return { wallets, loading: isLoading, recharge, createWallet, refreshWallets };
}
