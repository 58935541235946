import { usePostHog } from "posthog-js/react";
import { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import useUserData from "../../../modules/User/hooks/useUserData";
import { useTrackEvent } from "../../../tracking/useTrackEvent";
import { TrackedRoute } from "../../types";

interface Props {
    children: React.ReactNode;
    trackedRoutes: TrackedRoute[];
}

const RouteChangeListener = ({ children, trackedRoutes }: Props) => {
    const location = useLocation();
    const { trackEvent } = useTrackEvent();
    const { user } = useUserData();
    const currentRoute = trackedRoutes.find(({ route }) => location.pathname.includes(route));
    const posthog = usePostHog();
    const { pathname } = location;
    const formattedPathname = pathname.replace(
        /\/(s|events|releases|notifications|wallets)\/[a-zA-Z0-9-]+/g,
        (_, p1) => `/${p1}/{id}`
    );

    const handleVisit = useCallback(() => {
        trackEvent("routes", "route", "change", {
            pathname: formattedPathname,
        });
    }, [formattedPathname, trackEvent]);

    const track = useCallback(() => {
        const isTrackingDisabled = localStorage.getItem("disableTracking");
        if (!currentRoute || isTrackingDisabled) return;

        if (!isTrackingDisabled) {
            trackEvent("routes", "route", "interrupted", {
                pathname: currentRoute.event,
            });
        }
    }, [currentRoute, trackEvent]);

    useEffect(() => {
        if (user) {
            posthog.identify(user.id);
        }
    }, [posthog, user]);

    useEffect(() => {
        handleVisit();

        if (!currentRoute) return undefined;
        localStorage.removeItem("disableTracking");

        const handleBeforeUnload = () => track();

        const addListener = () => {
            window.addEventListener("beforeunload", handleBeforeUnload);
        };

        const removeListener = () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };

        addListener();

        return () => {
            track();
            removeListener();
        };
    }, [currentRoute, handleVisit, location.pathname, track]);

    return children;
};

export default RouteChangeListener;
