import { captureException } from "@sentry/browser";
import { Component, ErrorInfo, ReactNode } from "react";

interface Props {
    children?: ReactNode;
}

interface State {
    hasError: boolean;
    eventId: string;
}

class ErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            hasError: false,
            eventId: "",
        };
    }

    public static getDerivedStateFromError(): State {
        return { hasError: true, eventId: "" };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // eslint-disable-next-line no-console
        console.error("Uncaught error:", error, errorInfo);

        if (error.message.startsWith("Failed to fetch dynamically imported module")) {
            return;
        }

        const eventId = captureException({
            message: "Uncaught error",
            error,
            errorInfo,
        });
        this.setState({ eventId });
    }

    public render() {
        const { hasError, eventId } = this.state;
        const { children } = this.props;
        if (hasError) {
            return (
                <div
                    style={{
                        height: "100vh",
                        width: "100vw",
                        overflow: "hidden",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        fontFamily: "Rajdhani, sans-serif",
                        fontSize: "1.3rem",
                    }}
                >
                    <h1>Coś poszło nie tak</h1>
                    <p
                        style={{
                            margin: "0",
                        }}
                    >
                        Skontaktuj się z biurem obsługi klienta lub spróbuj ponownie później.
                    </p>
                    <p>Kod błędu: {eventId}</p>
                    <a href="/">Wróć na stronę główną</a>
                </div>
            );
        }

        return children;
    }
}

export default ErrorBoundary;
