import { Paragraph, Space } from "csrvui";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ContentWrapper } from "../../../screens/HomeScreen/style";
import { themes } from "../../hooks/useDarkMode";
import LikeButton from "../LikeButton";
import { StyledCard } from "./styles";

interface CardData {
    id: string;
    title: string;
    posthog_key: string;
    description: string[];
}

const LandingWhyUsCard: React.FC = () => {
    const { t } = useTranslation();
    const isMobile = window.innerWidth < 541;

    const cardsData: CardData[] = useMemo(
        () => [
            {
                id: "01",
                posthog_key: "panel",
                title: t("landing.cards.0.title"),
                description: [t("landing.cards.0.desc")],
            },
            {
                id: "02",
                posthog_key: "marketplace",
                title: t("landing.cards.1.title"),
                description: [t("landing.cards.1.desc")],
            },
            {
                id: "03",

                posthog_key: "performance",
                title: t("landing.cards.2.title"),
                description: [t("landing.cards.2.desc")],
            },
            {
                id: "04",
                title: t("landing.cards.3.title"),
                description: [t("landing.cards.3.desc")],
                posthog_key: "heritage",
            },
            {
                id: "05",
                title: t("landing.cards.4.title"),
                description: [t("landing.cards.4.desc")],
                posthog_key: "payments",
            },
            {
                id: "06",
                posthog_key: "features",
                title: t("landing.cards.5.title"),
                description: [t("landing.cards.5.desc")],
            },
        ],
        [t]
    );

    return (
        <>
            {cardsData.map((card) => (
                <StyledCard style={{ width: "100%" }} key={card.id}>
                    <ContentWrapper
                        style={{
                            justifyContent: "space-between",
                            height: "100%",
                        }}
                    >
                        <Space $padding={0} $gap={0} $direction="horizontal">
                            <Paragraph
                                $level={isMobile ? 3 : 2}
                                $color={themes.light.colors.primaryBackground}
                                style={{ fontWeight: 600, alignSelf: "center" }}
                            >
                                {card.title}
                            </Paragraph>
                            <Paragraph
                                $level={1}
                                $color={themes.light.colors.primaryBackground}
                                style={{ fontWeight: 700, fontSize: isMobile ? 48 : 64, lineHeight: "55px" }}
                            >
                                {card.id}
                            </Paragraph>
                            <div>
                                {card.description.map((desc, index) => (
                                    <Paragraph
                                        $level={isMobile ? 4 : 3}
                                        key={desc}
                                        style={{
                                            marginBottom: index < card.description.length - 1 ? "1em" : "0",
                                            color: themes.light.colors.primaryBackground,
                                            fontWeight: 400,
                                            fontSize: "22px",
                                        }}
                                    >
                                        {desc}
                                    </Paragraph>
                                ))}
                            </div>
                        </Space>

                        <Space $fullWidth $align="end" $padding="8px 0 0 0">
                            {" "}
                            <LikeButton label={`why-us-${card.posthog_key}`} />
                        </Space>
                    </ContentWrapper>
                </StyledCard>
            ))}
        </>
    );
};

export default LandingWhyUsCard;
