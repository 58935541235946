import i18next, { t } from "i18next";
import { BillingPeriods } from "../../constants";

export const MinecraftSettingsKeys = {
    "spawn-npcs": t("minecraft.spawn-npcs"),
    "allow-nether": t("minecraft.nether"),
    "spawn-animals": t("minecraft.spawn-animals"),
    "enable-command-block": t("minecraft.enable-command-block"),
    pvp: t("minecraft.pvp"),
    "allow-flight": t("minecraft.allow-flight"),
    gamemode: t("server_settings.gamemode"),
    difficulty: t("server_settings.difficulty"),
    "online-mode": t("minecraft.online-mode"),
    "level-name": t("minecraft.level-name"),
    "max-players": t("minecraft.max-players"),
};

i18next.on("languageChanged", () => {
    MinecraftSettingsKeys["spawn-npcs"] = t("minecraft.spawn-npcs");
    MinecraftSettingsKeys["allow-nether"] = t("minecraft.nether");
    MinecraftSettingsKeys["spawn-animals"] = t("minecraft.spawn-animals");
    MinecraftSettingsKeys["enable-command-block"] = t("minecraft.enable-command-block");
    MinecraftSettingsKeys.pvp = t("minecraft.pvp");
    MinecraftSettingsKeys["allow-flight"] = t("minecraft.allow-flight");
    MinecraftSettingsKeys["online-mode"] = t("minecraft.online-mode");
    MinecraftSettingsKeys["level-name"] = t("minecraft.level-name");
    MinecraftSettingsKeys["max-players"] = t("minecraft.max-players");
});

export const hoursToPackage = {
    [BillingPeriods.MONTH]: t("offers.billing_periods.month"),
    [BillingPeriods.THREE_MONTHS]: t("offers.billing_periods.3-month"),
    [BillingPeriods.YEAR]: t("offers.billing_periods.year"),
};

export enum GameType {
    MINECRAFT_JAVA_EDITION = "minecraft-java",
    MINECRAFT_BEDROCK_EDITION = "minecraft_bedrock",
}

export enum SupportedProducts {
    AMETHYST = "AMETHYST",
    GRASS = "GRASS",
    FREE = "FREE",
    BASIC_CRAFTUM = "BASIC_CRAFTUM",
}

export const GameKey = {
    GAME: "game",
};

export enum GameTypeSummary {
    MINECRAFT = "minecraft-java",
    MINECRAFT_BEDROCK = "minecraft_bedrock",
}

export const translateGames = {
    [GameType.MINECRAFT_JAVA_EDITION]: {
        title: t("games.minecraft.java_edition"),
        description: t("games.minecraft.java_edition_desc"),
    },
    [GameType.MINECRAFT_BEDROCK_EDITION]: {
        title: t("games.minecraft.bedrock_edition"),
        description: t("games.minecraft.java_edition_desc"),
    },
};

export const supportedCompressionTypes = ["zip", "tgz", "tar.gz"];
