import posthog from "posthog-js";
import { useCallback, useRef } from "react";

export type EventCategory =
    | "console"
    | "panel_screen"
    | "global"
    | "panel_shortcuts"
    | "upgrade_server_card"
    | "events"
    | "server_settings"
    | "payments"
    | "marketplace"
    | "notifications"
    | "registration"
    | "support"
    | "account_settings"
    | "routes"
    | "vouchers"
    | "file_manager_screen"
    | "files"
    | "cookies";
export type EventObject =
    | "logs_list"
    | "scrollbar"
    | "upgrade_server_modal"
    | "autostart_toggler"
    | "unscheduled_modal"
    | "migration_modal"
    | "billing_period"
    | "format_modal"
    | "support_form"
    | "billing_period_switch"
    | "full_drag_area"
    | "dropzone"
    | "tab"
    | "editor_dropzone"
    | "ftp_card"
    | "filters"
    | "payments_vouchers"
    | "event_details"
    | "project_reviews"
    | "antibot_toggler"
    | "email"
    | "game_select"
    | "version_select"
    | "filter_engines_select"
    | "engine"
    | "admin_recharge_wallet_modal"
    | "payments_voucher"
    | "payment_method"
    | "video"
    | "redeem_free_server_modal"
    | "details_tab"
    | "releases"
    | "item"
    | "editor"
    | "notification"
    | "invoices"
    | "project_icon"
    | "ticket"
    | "route"
    | "game"
    | "project_type"
    | "project_category"
    | "project_video"
    | "project_thumbnail"
    | "project_gallery"
    | "project_visibility"
    | "project_release_dependency"
    | "project_release_type"
    | "project_release_require_empty_workspace"
    | "project_server"
    | "release_files"
    | "release_advanced_options"
    | "release_description"
    | "file_item"
    | "cookie_toggler"
    | "cookies"
    | "cookie_expander";
export type EventAction =
    | "scroll"
    | "touch_scroll"
    | "move"
    | "open"
    | "close"
    | "change"
    | "drag"
    | "drop"
    | "click"
    | "view"
    | "submit"
    | "delete"
    | "begin"
    | "finish"
    | "toggle"
    | "load"
    | "interrupted"
    | "block_binary_file"
    | "sort"
    | "add"
    | "upload"
    | "confirm"
    | "accept"
    | "select";

type EventPayload = Record<string, string | boolean | number | Date | object | null>;

type TrackEvent = (category: EventCategory, object: EventObject, action: EventAction, payload?: EventPayload) => void;

export const useTrackEvent = (): {
    trackEvent: TrackEvent;
    trackEventOnce: TrackEvent;
} => {
    const sentEvents = useRef<Set<string>>(new Set());
    const { umami } = window;

    const trackEvent = useCallback(
        (category: EventCategory, object: EventObject, action: EventAction, payload?: EventPayload) => {
            const eventName = `${category}:${object}_${action}`;

            posthog.capture(eventName, payload);

            if (umami && payload) {
                umami.track(eventName, payload);
            }
        },
        [umami]
    );

    const trackEventOnce = useCallback(
        (category: EventCategory, object: EventObject, action: EventAction, payload?: EventPayload) => {
            const eventName = `${category}:${object}_${action}`;

            if (!sentEvents.current.has(eventName)) {
                posthog.capture(eventName, payload);
                if (umami && payload) {
                    umami.track(eventName, payload);
                }
                sentEvents.current.add(eventName);
            }
        },
        [umami]
    );

    return { trackEvent, trackEventOnce };
};
