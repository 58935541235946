import { Paragraph, Space } from "csrvui";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useTheme from "../../../../shared/hooks/useCustomTheme";
import { MarketEventMetric } from "../../types";

interface Props {
    data: MarketEventMetric;
    place: number;
}

const RankingTableRow = ({ data, place }: Props) => {
    const theme = useTheme();
    const { t } = useTranslation();
    return (
        <Link
            style={{
                textDecoration: "none",
            }}
            to={{
                pathname: `/m/${data.project_slug}`,
            }}
        >
            <Space
                style={{
                    backgroundColor: theme.colors.secondaryBackground,
                    width: "100%",
                    borderRadius: "8px",
                    padding: "20px",
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.08)",
                    cursor: "pointer",
                }}
                $direction="horizontal"
                $padding={0}
                $align="space-between"
                $oppositeAlign="center"
            >
                <Space $direction="horizontal" $padding={0}>
                    <Paragraph $level={3}>#{place}</Paragraph>
                    <Paragraph $level={3}>{data.project_name}</Paragraph>
                </Space>
                <Space $direction="horizontal" $padding={0}>
                    <Paragraph
                        style={{
                            backgroundColor: theme.colors.border,
                            borderRadius: "8px",
                            padding: "5px 20px",

                            fontWeight: 550,
                        }}
                        $level={3}
                    >
                        {t("marketplace.event_prize_row")}
                    </Paragraph>
                    <Paragraph
                        style={{
                            backgroundColor: theme.colors.border,
                            borderRadius: "8px",
                            padding: "5px 20px",

                            fontWeight: 550,
                        }}
                        $level={3}
                    >
                        {data.points}p
                    </Paragraph>
                </Space>
            </Space>
        </Link>
    );
};

export default RankingTableRow;
