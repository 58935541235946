import { ComponentPropsWithoutRef, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { MaxWidthContainer } from "../../../../shared/components/MaxWidthContainer/style";
import ProductCard from "../../../Billings/components/ProductCard";
import * as BillingsTypes from "../../../Billings/types";
import { ProductsWrapper } from "./style";

interface Props extends ComponentPropsWithoutRef<"div"> {
    products: BillingsTypes.BillingProduct[];
    onSelectProduct: (isOpen: boolean, product: BillingsTypes.BillingProduct) => void;
}

const hidden = ["BASIC_CRAFTUM", "EXTRA_STORAGE"];

const LandingProductsList: React.FC<Props> = ({ products, onSelectProduct }) => {
    const location = useLocation();

    const filteredProducts = useMemo(
        () => products.filter((p) => p.depends_on_products.length === 0 && !hidden.includes(p.id)),
        [products]
    );

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                setTimeout(() => {
                    element.scrollIntoView({ behavior: "smooth", block: "center" });
                }, 200);
            }
        }
    }, [location]);

    return (
        <MaxWidthContainer maxWidth="1920px" style={{ minHeight: "500px" }}>
            <ProductsWrapper
                id="offers"
                style={{
                    margin: "0px 0px 40px 0px",
                    minHeight: "800px",
                }}
            >
                {filteredProducts.length > 0 &&
                    filteredProducts.map((product) => (
                        <ProductCard
                            showOutline
                            onShowDetails={() => onSelectProduct(true, product)}
                            showBadge={product.id === "AMETHYST"}
                            key={product.id}
                            data={product}
                        />
                    ))}
            </ProductsWrapper>
        </MaxWidthContainer>
    );
};
export default LandingProductsList;
