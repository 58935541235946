import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { setLocalLanguage } from "../../helpers/localStorage";
import GB from "./en.svg";
import PL from "./pl.svg";
import { Options, SelectButton } from "./style";

const LanguageSelect: React.FC<{ light?: boolean }> = ({ light }) => {
    const {
        i18n: { changeLanguage, language: currentLanguage },
    } = useTranslation();

    const [lang, setLang] = useState(currentLanguage === "pl-PL" ? "pl" : "en");
    const [optionsVisible, setOptionsVisible] = useState(false);

    const handleChange = useCallback(
        (code: string) => {
            switch (code) {
                case "GB":
                    setLang("en");
                    changeLanguage("en");
                    setLocalLanguage("en-GB");

                    break;
                case "PL":
                default:
                    setLang("pl");
                    changeLanguage("pl");
                    setLocalLanguage("pl-PL");

                    break;
            }
        },
        [changeLanguage]
    );

    const toggleOptions = useCallback(() => {
        setOptionsVisible((prev) => !prev);
    }, []);

    const flags = useMemo(() => {
        return {
            pl: <img width={20} height={20} src={PL} alt="PL" />,
            en: <img width={20} height={20} src={GB} alt="EN" />,
        };
    }, []);

    return (
        <div style={{ position: "relative", height: "100%" }}>
            <SelectButton type="button" onClick={toggleOptions} light={light} opened={optionsVisible}>
                {flags[lang as keyof typeof flags]}
                {optionsVisible && (
                    <Options>
                        <li>
                            <button
                                data-ph-capture-attribute-id="language:pl"
                                type="button"
                                onClick={() => handleChange("PL")}
                            >
                                {flags.pl}
                            </button>
                        </li>
                        <li>
                            <button
                                data-ph-capture-attribute-id="language:en"
                                type="button"
                                onClick={() => handleChange("GB")}
                            >
                                {flags.en}
                            </button>
                        </li>
                    </Options>
                )}
            </SelectButton>
        </div>
    );
};

export default LanguageSelect;
