import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getCurrentScope } from "@sentry/browser";
import { HttpStatus } from "../../constants";
import { HostUrl } from "../../rootTypes";
import { transformError } from "../../shared/helpers/errors";
import { CreateCraftumPayload, GetCraftumParametersData, UpdateHostPayload } from "./types";

export const craftumsApi = createApi({
    reducerPath: "craftums",
    baseQuery: fetchBaseQuery({
        baseUrl: `${HostUrl}/api/craftums`,
        prepareHeaders: (headers) => {
            const transactionId = Math.random().toString(36).substr(2, 9);
            const scope = getCurrentScope();
            if (scope) {
                scope.setTag("transaction_id", transactionId);
            }
            headers.set("X-transation-ID", transactionId);

            return headers;
        },
    }),
    endpoints: (builder) => ({
        createCraftum: builder.mutation<void, CreateCraftumPayload>({
            query: (payload) => ({
                url: "",
                method: "POST",
                body: payload,
                credentials: "include",
                validateStatus: (response) => response.status === HttpStatus.Created,
            }),
            transformErrorResponse: transformError,
        }),
        updateCraftum: builder.mutation<
            void,
            {
                id: string;
                data: UpdateHostPayload;
            }
        >({
            query: ({ id, data }) => ({
                url: `/${id}`,
                method: "PUT",
                body: data,
                credentials: "include",
                validateStatus: (response) => response.status === HttpStatus.NoContent,
            }),
            transformErrorResponse: transformError,
        }),
        getCraftumParameters: builder.query<GetCraftumParametersData, string>({
            query: (id) => ({
                url: `/${id}/parameters`,
                method: "GET",
                credentials: "include",
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
            transformErrorResponse: transformError,
        }),
    }),
});

export const { useCreateCraftumMutation, useUpdateCraftumMutation, useLazyGetCraftumParametersQuery } = craftumsApi;
