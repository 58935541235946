import { Button, Paragraph, Space } from "csrvui";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ResponsiveModal from "../../../../shared/components/ResponsiveModal";
import useLocalPreferences from "../../../../shared/hooks/useLocalPreferences";
import { useTrackEvent } from "../../../../tracking/useTrackEvent";
import { BillingProduct } from "../../../Billings/types";
import { GameType } from "../../../Server/constants";
import useClusters from "../../../Server/hooks/useClusters";
import useCreateServer from "../../../Server/hooks/useCreateServer";
import RawFastRegisterForm from "../../../User/components/RawFastRegisterForm";
import useAuth from "../../../User/hooks/useAuth";
import useUserData from "../../../User/hooks/useUserData";
import { RedeemServerFormValues } from "../../types";

interface Props {
    open: boolean;
    onClose: () => void;
    product: BillingProduct | null;
    preinstallProject?: string;
}

const RedeemFreeServerModal = ({ onClose, open, product, preinstallProject }: Props) => {
    const { fastEmailAuthBegin, fastEmailAuthComplete } = useAuth();
    const { t } = useTranslation();
    const { user } = useUserData();
    const [step, setStep] = useState<0 | 1>(user ? 1 : 0);
    const { redeemFreeServer } = useCreateServer();
    const { availableClusters } = useClusters();
    const { browserLanguage, currency } = useLocalPreferences();
    const { trackEvent } = useTrackEvent();

    if (!product || !availableClusters) {
        return null;
    }

    const onEmailSubmit = async (values: Omit<RedeemServerFormValues, "verificationCode">) => {
        trackEvent("registration", "redeem_free_server_modal", "begin");

        await fastEmailAuthBegin({
            email: values.email,
            language: browserLanguage,
            server: {
                cluster_id: availableClusters[0].id,
                game: GameType.MINECRAFT_JAVA_EDITION,
                requested_billing_services: [
                    {
                        name: product.id,
                        user_parameters: {
                            engine: preinstallProject || product.parameters.engine,
                        },
                    },
                ],
                billing_period: "720h",
                currency,
            },
        });

        setStep(1);
    };

    const onVerificationSubmit = async (values: RedeemServerFormValues) => {
        let username = values.email.split("@")[0];
        if (username.length > 128) {
            username = username.slice(0, 128);
        }

        trackEvent("registration", "redeem_free_server_modal", "finish");

        const fastRegisterResponse = await fastEmailAuthComplete({
            language: browserLanguage,
            newsletter: values.agreement2,
            currency,
            code: values.verificationCode,
            terms: values.agreement,
        });

        if (!fastRegisterResponse) {
            throw new Error("No response");
        }

        const engine = preinstallProject || product.parameters.engine;

        redeemFreeServer(product, values.agreement && values.agreement2, {
            engine,
        });
    };

    return (
        <ResponsiveModal
            center
            styles={{
                modal: {
                    maxWidth: "800px",
                    borderRadius: "8px",
                    padding: "30px",
                    minHeight: user ? "auto" : "335px",
                },
            }}
            open={open}
            onClose={() => {
                onClose();
            }}
        >
            {user ? (
                <Space $gap={15} $padding={0}>
                    <Paragraph style={{ fontWeight: 550 }} $level={2}>
                        {t("redeem_server_modal.title_confirm", {
                            product: product.id,
                        })}
                    </Paragraph>
                    <Paragraph style={{ fontWeight: 450 }}>{t("redeem_server_modal.subtitle_confirm")}</Paragraph>

                    <Button
                        data-ph-capture-attribute-id="redeem_server_modal:redeem_confirm"
                        data-ph-capture-attribute-product={product.id}
                        onClick={() => {
                            if (preinstallProject) {
                                redeemFreeServer(product, true, {
                                    engine: preinstallProject,
                                });
                            }
                        }}
                    >
                        {t("redeem_server_modal.redeem_confirm")}
                    </Button>
                </Space>
            ) : (
                <Space $gap={15} $padding={0}>
                    <Paragraph style={{ fontWeight: 550 }} $level={2}>
                        {t("redeem_server_modal.title", {
                            product: product.id,
                        })}
                    </Paragraph>
                    <Paragraph style={{ fontWeight: 450 }}>{t("redeem_server_modal.subtitle")}</Paragraph>

                    <RawFastRegisterForm
                        step={step}
                        setStep={setStep}
                        onEmailSubmit={onEmailSubmit}
                        onVerificationCodeSubmit={onVerificationSubmit}
                    />
                </Space>
            )}
        </ResponsiveModal>
    );
};

export default RedeemFreeServerModal;
