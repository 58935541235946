import styled, { css } from "styled-components";

export const Options = styled.div`
    position: absolute;
    top: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
    padding: 0;
    list-style-type: none;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.white};

    button {
        width: 100%;
        display: block;
        background: none;
        border: none;
        padding: 10px;
        cursor: pointer;

        &:hover {
            background-color: ${({ theme }) => theme.colors.gray};
        }
    }
`;

export const SelectButton = styled.button<{ light?: boolean; opened: boolean }>`
    color: ${({ theme }) => theme.colors.primaryText};
    padding: 5px 30px 5px 20px;
    border: 1px solid ${({ theme }) => theme.colors.gray};
    border-left: 0;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.secondary};
    border-radius: 0 8px 8px 0;
    appearance: none;
    padding-left: -5px;
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 10px;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid ${({ theme }) => theme.colors.primaryText};
        transform: translateY(-50%);
        pointer-events: none;

        ${({ light }) =>
            light &&
            css`
                border-top-color: #fff;
            `}

        ${({ opened }) =>
            opened &&
            css`
                transform: translateY(-50%) rotate(180deg);
            `}
    }
`;

// export const StyledSelect = styled.select<{ light?: boolean }>`
//     color: ${({ theme }) => theme.colors.primaryText};
//     padding: 7px 10px;
//     border: 1px solid ${({ theme }) => theme.colors.gray};
//     background-color: ${({ theme }) => theme.colors.secondary};
//     border-radius: 8px;
//     appearance: none;
//     -webkit-appearance: none;
//     -moz-appearance: none;
//     position: relative;

//     &::after {
//         content: "";
//         position: absolute;
//         top: 50%;
//         right: 10px;
//         width: 0;
//         height: 0;
//         border-left: 5px solid transparent;
//         border-right: 5px solid transparent;
//         border-top: 5px solid ${({ theme }) => theme.colors.primaryText};
//         transform: translateY(-50%);
//         pointer-events: none;

//         ${({ light }) =>
//             light &&
//             css`
//                 border-top-color: #fff;
//             `}
//     }

//     option {
//         background-color: ${({ theme }) => theme.colors.secondaryBackground};
//         color: ${({ theme }) => theme.colors.primaryText};
//         display: flex;
//         align-items: center;
//     }

//     &:hover {
//         background: ${({ theme }) => theme.colors.gray};
//     }
// `;

// export const Select = styled.select<{ light?: boolean }>`
//     color: ${({ theme }) => theme.colors.primaryText};
//     padding: 7px 10px;
//     border: 1px solid ${({ theme }) => theme.colors.gray};
//     background-color: ${({ theme }) => theme.colors.secondary};
//     border-radius: 8px;
//     appearance: none;
//     -webkit-appearance: none;
//     -moz-appearance: none;
//     position: relative;

//     &:hover {
//         background: ${({ theme }) => theme.colors.gray};
//     }
// `;

// export const OptionContainer = styled.div`
//     display: flex;
//     align-items: center;
// `;

// export const Flag = styled.img`
//     width: 20px;
//     height: 20px;
//     margin-right: 8px;
// `;

// export const Label = styled.span`
//     color: ${({ theme }) => theme.colors.primaryText};
// `;
