import { Button } from "csrvui";
import styled from "styled-components";

export const UpgradeNowButton = styled(Button)<{ product_color: string; product_background: string }>`
    padding: 10px 55px;
    background-color: ${(props) => props.product_color};
    color: ${(props) => props.theme.colors.primaryText};
    position: relative;
    width: 100%;
    background-image: url("${(props) => props.product_background}");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &:hover {
        background-position: 66px;
        opacity: 1;
    }
`;
