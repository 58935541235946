import {
    differenceInDays,
    differenceInHours,
    differenceInMinutes,
    differenceInSeconds,
    formatDuration,
} from "date-fns";
import { pl } from "date-fns/locale";
import { t } from "i18next";

export const hoursToDuration = (hours: number) => {
    // i want to get days, hours
    const days = Math.floor(hours / 24);
    const remainingHours = hours % 24;

    return { days, hours: remainingHours };
};

export const daysToDuration = (days: number) => {
    return { days };
};

export const getHoursDiff = (startDate: Date, endDate: Date) => {
    const msInHour = 1000 * 60 * 60;

    return Math.round(Math.abs(endDate.getTime() - startDate.getTime()) / msInHour);
};

export const billingPeriodToHours = (billingPeriod: string) => {
    return Number(billingPeriod.slice(0, -1));
};

export const getDaysDiff = (startDate: Date, endDate: Date) => {
    const msInDay = 1000 * 60 * 60 * 24;

    return Math.round(Math.abs(endDate.getTime() - startDate.getTime()) / msInDay);
};

export const formatServerDuration = (expirationDate: Date) => {
    const now = new Date();
    const totalHours = differenceInHours(expirationDate, now);
    const totalDays = differenceInDays(expirationDate, now);
    const totalMinutes = differenceInMinutes(expirationDate, now);
    const totalSeconds = differenceInSeconds(expirationDate, now);

    if (totalDays !== 0) {
        if (totalDays > 3) {
            return formatDuration({ days: totalDays }, { format: ["days"], locale: pl });
        }
        return formatDuration({ hours: totalHours }, { format: ["hours"], locale: pl });
    }

    if (totalHours !== 0) {
        return formatDuration(
            {
                hours: totalHours,
            },
            { format: ["hours"], locale: pl }
        );
    }

    if (totalMinutes !== 0) {
        return formatDuration(
            {
                minutes: totalMinutes,
            },
            { format: ["minutes"], locale: pl }
        );
    }

    if (totalSeconds !== 0) {
        return formatDuration(
            {
                seconds: totalSeconds,
            },
            { format: ["seconds"], locale: pl }
        );
    }

    return t("common.expired");
};
