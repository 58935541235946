import { IoArrowBackOutline } from "@react-icons/all-files/io5/IoArrowBackOutline";
import { VscChromeClose } from "@react-icons/all-files/vsc/VscChromeClose";
import { Button, Paragraph, Space } from "csrvui";
import { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CurrentServerContext } from "../../../../shared/components/CurrentServerProvider";
import ResponsiveModal from "../../../../shared/components/ResponsiveModal";
import { formatServerDuration } from "../../../../shared/helpers/dates";
import showToast from "../../../../shared/helpers/showToast";
import useTheme from "../../../../shared/hooks/useCustomTheme";
import { useGetProductsQuery } from "../../../Billings/api";
import { Divider } from "../../../Billings/components/ProductCard/style";
import { BillingProduct } from "../../../Billings/types";
import { TransactionAction } from "../../../Payments/types";
import {
    useDeleteBillingUpgradablesMutation,
    useGetBillingUpgradablesQuery,
    useGetByIdQuery,
} from "../../../Services/api";
import CreateTransactionForm from "../CreateTransactionForm";
import { ProductsWrapper } from "../LandingProductsList/style";
import UpgradeActionAlert from "../UpgradeActionAlert";
import UpgradeServerCard from "../UpgradeServerCard";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    title?: string;
    desc?: string;
}

const UpgradeServerModal = ({ onClose, isOpen, desc, title }: Props) => {
    const { t } = useTranslation();
    const { service, refreshCurrentServerData } = useContext(CurrentServerContext);
    const currentProduct = service.product;
    const { data: products } = useGetProductsQuery();
    const [mutate] = useDeleteBillingUpgradablesMutation();
    const theme = useTheme();
    const { data: billingServices, refetch } = useGetByIdQuery(service.service_id);
    const scheduledActions = billingServices ? billingServices.scheduled_actions : [];
    const isSmallScreen = window.innerWidth < 1500;
    const isMobile = window.innerWidth < 570;

    const [createTransactionOptions, setCreateTransactionOptions] = useState<{
        product: string | null;
    }>({
        product: null,
    });

    const deleteScheduledUpgrade = async () => {
        await mutate({
            serviceId: service.service_id,
        });

        showToast({
            text: t("upgrade_server.success_cancel_scheduled_upgrade"),
            variant: "success",
        });

        refetch();
    };

    const { data } = useGetBillingUpgradablesQuery({
        serviceId: service.service_id,
    });

    const filteredProducts = useMemo(() => {
        const hidden = ["BASIC_CRAFTUM", "EXTRA_STORAGE"];

        if (!products) return [];

        return products.filter((p) => p.depends_on_products.length === 0 && !hidden.includes(p.id));
    }, [products]);

    const serviceProduct = useMemo(
        () => filteredProducts.find((product) => product.id === currentProduct),
        [filteredProducts, currentProduct]
    );

    const handleUpgrade = useCallback(
        (createTransaction: boolean, product: BillingProduct, nextPeriod: boolean) => {
            if (createTransaction) {
                setCreateTransactionOptions({
                    product: product.id,
                });
            } else {
                onClose();
                showToast({
                    text: nextPeriod
                        ? t("upgrade_server.success_upgrade_next_period")
                        : t("upgrade_server.success_upgrade_now"),
                    variant: "success",
                });
                refetch();
                refreshCurrentServerData();
            }
        },
        [onClose, refetch, refreshCurrentServerData, t]
    );

    const handleModalClose = () => {
        onClose();

        setCreateTransactionOptions({
            product: null,
        });
    };

    return (
        <ResponsiveModal
            classNames={{
                modal: "upgrade_server_modal",
            }}
            styles={{
                modal: {
                    width: isMobile ? "100vw !important" : "90vw",

                    maxWidth: isSmallScreen ? "100vw" : "1950px",
                },
                root: {
                    padding: "0",
                    margin: "0",
                },
            }}
            showCloseIcon={false}
            onClose={handleModalClose}
            open={isOpen}
        >
            <Space $fullWidth $gap={0} $padding="0 20px">
                <Space
                    $direction="horizontal"
                    style={{ position: "relative" }}
                    $align="space-between"
                    $oppositeAlign="center"
                    $wrap={false}
                    $padding={0}
                    $fullWidth
                >
                    <Space
                        style={{
                            width: "fit-content",
                            alignItems: "flex-start",
                        }}
                        $gap={0}
                        $padding={0}
                    >
                        <Paragraph $level={3} style={{ fontWeight: 550, textTransform: "uppercase" }}>
                            {title || t("upgrade_server.title")}
                        </Paragraph>
                        {!createTransactionOptions.product && (
                            <Paragraph>
                                {desc ||
                                    t("upgrade_server.pricing_info_desc", {
                                        server_expires: service.expires && formatServerDuration(service.expires),
                                    })}
                            </Paragraph>
                        )}
                    </Space>

                    <Button
                        data-ph-capture-attribute-id="upgrade_server_modal:close"
                        onClick={handleModalClose}
                        $variant="transparent"
                        $rounded
                    >
                        <VscChromeClose />
                    </Button>
                </Space>

                <Divider
                    style={{
                        backgroundColor: theme.colors.border,
                    }}
                />
            </Space>

            {!createTransactionOptions.product &&
                scheduledActions
                    .filter((action) => "planned_upgrade" in action.details)
                    .map((action) => (
                        <UpgradeActionAlert onCancel={deleteScheduledUpgrade} key={action.id} action={action} />
                    ))}

            {createTransactionOptions.product ? (
                <Space style={{ maxWidth: "500px", margin: "0 auto" }}>
                    <Space $padding="0 20px">
                        <Button
                            data-ph-capture-attribute-id="upgrade_server_modal:back"
                            onClick={() => setCreateTransactionOptions({ product: null })}
                            $fullWidth
                            style={{
                                display: "flex",
                                alignItems: "center",
                                position: "relative",
                            }}
                            $variant="dark"
                        >
                            <IoArrowBackOutline
                                style={{
                                    position: "absolute",
                                    left: "10px",
                                    top: "25%",
                                }}
                                size={20}
                            />
                            Powrót
                        </Button>
                    </Space>
                    <CreateTransactionForm
                        action={TransactionAction.UPGRADE_SERVICE}
                        data={{
                            upgrade_to: createTransactionOptions.product,
                        }}
                    />
                </Space>
            ) : (
                <ProductsWrapper
                    style={{
                        gridTemplateColumns: `repeat(auto-fit, minmax(${isMobile ? "380px" : "430px"}, 1fr))`,
                        width: "100%",
                        maxWidth: "1550px",
                        margin: "0 auto",
                        padding: 0,
                    }}
                >
                    {serviceProduct &&
                        service.billing_period &&
                        filteredProducts.map((product) => {
                            const upgradeData = data && data.find((d) => d.product_id === product.id);

                            return (
                                <UpgradeServerCard
                                    remainingTime={service.expires ? formatServerDuration(service.expires) : ""}
                                    onUpgrade={(create, _, nextPeriod) => handleUpgrade(create, product, nextPeriod)}
                                    data={upgradeData}
                                    currentBillingPeriod={service.billing_period}
                                    currentProduct={serviceProduct}
                                    key={product.id}
                                    product={product}
                                />
                            );
                        })}
                </ProductsWrapper>
            )}
        </ResponsiveModal>
    );
};

export default UpgradeServerModal;
