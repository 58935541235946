import Icon2 from "../../../assets/icons/Instagram.svg";
import Icon4 from "../../../assets/icons/X.svg";
import Icon3 from "../../../assets/icons/Youtube.svg";
import { SocialIconsWrapper, StyledIcon } from "./style";

export const SocialIcons = () => {
    return (
        <SocialIconsWrapper>
            <a
                data-ph-capture-attribute-id="social_icons:instagram"
                href="https://www.instagram.com/craftserve"
                target="_blank"
                rel="noopener noreferrer"
            >
                <StyledIcon src={Icon2} alt="Instagram" />
            </a>
            <a
                data-ph-capture-attribute-id="social_icons:youtube"
                href="https://www.youtube.com/@CraftServe"
                target="_blank"
                rel="noopener noreferrer"
            >
                <StyledIcon src={Icon3} alt="Youtube" />
            </a>
            <a
                data-ph-capture-attribute-id="social_icons:x"
                href="https://twitter.com/CraftservePL"
                target="_blank"
                rel="noopener noreferrer"
            >
                <StyledIcon src={Icon4} alt="X" />
            </a>
        </SocialIconsWrapper>
    );
};
