import { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ProtectedRoute from "./modules/User/components/ProtectedRoute";
import useVerify from "./modules/User/hooks/useVerify";
import { HostUrl, ScreenTitle } from "./rootTypes";

import HomeScreen from "./screens/HomeScreen";
import { CookieConsentProvider } from "./shared/components/CookieConsentProvider";
import CookiesBanner from "./shared/components/CookiesBanner";
import CurrentServerProvider from "./shared/components/CurrentServerProvider";
import Layout from "./shared/components/Layout";
import PanelLayout from "./shared/components/PanelLayout";
import PanelLoader from "./shared/components/PanelLoader";
import { ProcessesProvider } from "./shared/components/ProcessesProvider";
import RouteChangeListener from "./shared/components/RouteChangeListener";
import RoutingTracker from "./shared/components/RoutingTracker";
import SEO from "./shared/components/SEO";
import SuspenseLoadingSwitcher from "./shared/components/SuspenseLoadingSwitcher";
import lazyWithRetry from "./shared/helpers/lazyWithRetry";
import { EventAction } from "./shared/types";

const LoginScreen = lazyWithRetry(() => import("./screens/LoginScreen"), "LoginScreen");
const RegisterScreen = lazyWithRetry(() => import("./screens/RegisterScreen"), "RegisterScreen");
const ChangePasswordScreen = lazyWithRetry(() => import("./screens/ChangePasswordScreen"), "ChangePasswordScreen");
const ResetPasswordScreen = lazyWithRetry(() => import("./screens/ResetPasswordScreen"), "ResetPasswordScreen");
const ConfirmChangePasswordScreen = lazyWithRetry(
    () => import("./screens/ConfirmChangePasswordScreen"),
    "ConfirmChangePasswordScreen"
);
const ChangeEmailScreen = lazyWithRetry(() => import("./screens/ChangeEmailScreen"), "ChangeEmailScreen");
const OffersScreen = lazyWithRetry(() => import("./screens/OffersScreen"), "OffersScreen");
const SelectServerScreen = lazyWithRetry(() => import("./screens/SelectServerScreen"), "SelectServerScreen");
const RechargeWalletScreen = lazyWithRetry(() => import("./screens/RechargeWalletScreen"), "RechargeWalletScreen");
const NotFoundScreen = lazyWithRetry(() => import("./screens/NotFoundScreen"), "NotFoundScreen");
const UnauthorizedScreen = lazyWithRetry(() => import("./screens/UnauthorizedScreen"), "UnauthorizedScreen");
const MessagesScreen = lazyWithRetry(() => import("./screens/MessagesScreen"), "MessagesScreen");
const AccountScreen = lazyWithRetry(() => import("./screens/AccountScreen"), "AccountScreen");
const AccountSettingsScreen = lazyWithRetry(() => import("./screens/AccountSettingsScreen"), "AccountSettingsScreen");
const AccountEventsScreen = lazyWithRetry(() => import("./screens/AccountEventsScreen"), "AccountEventsScreen");
const PayForServerScreen = lazyWithRetry(() => import("./screens/PayForServerScreen"), "PayForServerScreen");
const CreateServerFromOfferScreen = lazyWithRetry(
    () => import("./screens/CreateServerFromOfferScreen"),
    "CreateServerFromOfferScreen"
);
const PanelScreen = lazyWithRetry(() => import("./screens/PanelScreen"), "PanelScreen");
const FileManagerScreen = lazyWithRetry(() => import("./screens/FileManagerScreen"), "FileManagerScreen");
const ServerLogsScreen = lazyWithRetry(() => import("./screens/ServerLogsScreen"), "ServerLogsScreen");
const EventDetailsScreen = lazyWithRetry(() => import("./screens/EventDetailsScreen"), "EventDetailsScreen");
const NotificationDetailsScreen = lazyWithRetry(
    () => import("./screens/NotificationDetailsScreen"),
    "NotificationDetailsScreen"
);
const NotificationsScreen = lazyWithRetry(() => import("./screens/NotificationsScreen"), "NotificationsScreen");
const SettingsScreen = lazyWithRetry(() => import("./screens/SettingsScreen"), "SettingsScreen");
const AppScreen = lazyWithRetry(() => import("./screens/AppScreen"), "AppScreen");
const DomainSettingsScreen = lazyWithRetry(() => import("./screens/DomainSettingsScreen"), "DomainSettingsScreen");
const EngineSettingsScreen = lazyWithRetry(() => import("./screens/EngineSettingsScreen"), "EngineSettingsScreen");
const BasicSettingsScreen = lazyWithRetry(() => import("./screens/BasicSettingsScreen"), "BasicSettingsScreen");
const PaymentsScreen = lazyWithRetry(() => import("./screens/PaymentsScreen"), "PaymentsScreen");
const ParentsScreen = lazyWithRetry(() => import("./screens/ParentsScreen"), "ParentsScreen");
const ContactScreen = lazyWithRetry(() => import("./screens/ContactScreen"), "ContactScreen");
const BackupsScreen = lazyWithRetry(() => import("./screens/BackupsScreen"), "BackupsScreen");
const PayuSuccessScreen = lazyWithRetry(() => import("./screens/PayuSuccessScreen"), "PayuSuccessScreen");
const PayuServerSuccessScreen = lazyWithRetry(
    () => import("./screens/PayuServerSuccessScreen"),
    "PayuServerSuccessScreen"
);
const ConfirmEmailScreen = lazyWithRetry(() => import("./screens/ConfirmEmailScreen"), "ConfirmEmailScreen");
const MarketplaceScreen = lazyWithRetry(() => import("./screens/MarketplaceScreen"), "MarketplaceScreen");
const CreateMarketProjectScreen = lazyWithRetry(
    () => import("./screens/CreateMarketProjectScreen"),
    "CreateMarketProjectScreen"
);
const EditMarketProjectScreen = lazyWithRetry(
    () => import("./screens/EditMarketProjectScreen"),
    "EditMarketProjectScreen"
);
const ProjectReleaseUploaderScreen = lazyWithRetry(
    () => import("./screens/ProjectReleaseUploaderScreen"),
    "ProjectReleaseUploaderScreen"
);
const ProjectDetailsScreen = lazyWithRetry(() => import("./screens/ProjectDetailsScreen"), "ProjectDetailsScreen");
const ServerMarketplaceScreen = lazyWithRetry(
    () => import("./screens/ServerMarketplaceScreen"),
    "ServerMarketplaceScreen"
);
const UseCouponScreen = lazyWithRetry(() => import("./screens/UseCouponScreen"), "UseCouponScreen");
const UnauthorizedServerScreen = lazyWithRetry(
    () => import("./screens/UnauthorizedServerScreen"),
    "UnauthorizedServerScreen"
);
const PrivacyPolicyScreen = lazyWithRetry(() => import("./screens/PrivacyPolicyScreen"), "PrivacyPolicyScreen");
const PaypalCancelScreen = lazyWithRetry(() => import("./screens/PaypalCancelScreen"), "PaypalCancelScreen");
const PaypalContinueScreen = lazyWithRetry(() => import("./screens/PaypalContinueScreen"), "PaypalContinueScreen");
const PaypalSuccessScreen = lazyWithRetry(() => import("./screens/PaypalSuccessScreen"), "PaypalSuccessScreen");
const CreateCraftumScreen = lazyWithRetry(() => import("./screens/CreateCraftumScreen"), "CreateCraftumScreen");
const CraftumScreen = lazyWithRetry(() => import("./screens/CraftumScreen"), "CraftumScreen");
const AdminScreen = lazyWithRetry(() => import("./screens/AdminScreen"), "AdminScreen");
const UserAdminViewScreen = lazyWithRetry(() => import("./screens/UserAdminViewScreen"), "UserAdminViewScreen");
const UserProjectsScreen = lazyWithRetry(() => import("./screens/UserProjectsScreen"), "UserProjectsScreen");

interface RoutesItem {
    path: string;
    Element: JSX.Element;
    protect: boolean;
    title: ScreenTitle;
    forPaidServerOnly?: boolean;
    keywords?: string[];
}

export const panelRoutes: RoutesItem[] = [
    {
        path: "/s/:serverIdentity",
        protect: true,
        Element: <PanelScreen />,
        title: ScreenTitle.PANEL,
        forPaidServerOnly: true,
    },
    {
        path: "/s/:serverIdentity/files/*",
        protect: true,
        Element: <FileManagerScreen />,
        title: ScreenTitle.FILES,
        forPaidServerOnly: true,
    },
    {
        path: "/s/:serverIdentity/files/trash/",
        protect: true,
        Element: <FileManagerScreen />,
        title: ScreenTitle.FILES,
        forPaidServerOnly: true,
    },
    {
        path: "/s/:serverIdentity/events",
        protect: true,
        Element: <ServerLogsScreen />,
        title: ScreenTitle.SERVER_EVENTS,
        forPaidServerOnly: true,
    },
    {
        path: "/s/:serverIdentity/events/:eventid",
        protect: true,
        Element: <EventDetailsScreen />,
        title: ScreenTitle.EVENT_DETAILS,
        forPaidServerOnly: true,
    },
    {
        path: "/s/:serverIdentity/settings",
        protect: true,
        Element: <SettingsScreen />,
        title: ScreenTitle.SETTINGS,
        forPaidServerOnly: true,
    },
    {
        path: "/s/:serverIdentity/settings/domain",
        protect: true,
        Element: <DomainSettingsScreen />,
        title: ScreenTitle.DOMAIN_SETTINGS,
        forPaidServerOnly: true,
    },
    {
        path: "/s/:serverIdentity/settings/engines",
        protect: true,
        Element: <EngineSettingsScreen />,
        title: ScreenTitle.DOMAIN_SETTINGS,
        forPaidServerOnly: true,
    },
    {
        path: "/s/:serverIdentity/settings/basic",
        protect: true,
        Element: <BasicSettingsScreen />,
        title: ScreenTitle.BASIC_SETTINGS,
        forPaidServerOnly: true,
    },
    {
        path: "/s/:serverIdentity/app/:name",
        protect: true,
        Element: <AppScreen />,
        title: ScreenTitle.MC_WORLD_MANAGER,
        forPaidServerOnly: true,
    },

    {
        path: "/s/:serverIdentity/payments",
        protect: true,
        Element: <PaymentsScreen />,
        title: ScreenTitle.PAYMENTS,
    },

    {
        path: "/s/:serverIdentity/paypal/success",
        protect: true,
        Element: <PaypalSuccessScreen />,
        title: ScreenTitle.PAYPAL_SUCCESS,
    },
    {
        path: "/s/:serverIdentity/backups",
        protect: true,
        Element: <BackupsScreen />,
        title: ScreenTitle.BACKUPS,
    },
    {
        path: "s/:serverIdentity/renew",
        protect: true,
        Element: <PayForServerScreen />,
        title: ScreenTitle.RENEW_SERVER,
    },
    {
        path: "s/:serverIdentity/m",
        protect: true,
        Element: <ServerMarketplaceScreen />,
        title: ScreenTitle.MARKETPLACE,
    },
    {
        path: "s/:serverIdentity/m/:projectid",
        protect: true,
        Element: <ProjectDetailsScreen />,
        title: ScreenTitle.MARKETPLACE,
    },
    {
        path: "s/:serverIdentity/wallets/:walletid/recharge",
        protect: true,
        Element: <RechargeWalletScreen />,
        title: ScreenTitle.RECHARGE_WALLET,
    },
];

export const routes: RoutesItem[] = [
    {
        path: "*",
        protect: false,
        Element: <NotFoundScreen />,
        title: ScreenTitle.NOT_FOUND,
    },
    {
        path: "/notifications",
        protect: true,
        Element: <NotificationsScreen />,
        title: ScreenTitle.NOTIFICATIONS,
    },
    {
        path: "/notifications/:notificationid",
        protect: true,
        Element: <NotificationDetailsScreen />,
        title: ScreenTitle.NOTIFICATION_DETAILS,
    },
    {
        path: "/",
        protect: false,
        Element: <HomeScreen />,
        title: ScreenTitle.HOME,
    },
    {
        path: "/parents",
        protect: false,
        Element: <ParentsScreen />,
        title: ScreenTitle.PARENTS,
    },
    {
        path: "/contact",
        protect: false,
        Element: <ContactScreen />,
        title: ScreenTitle.CONTACT,
    },
    {
        path: "/change-password",
        protect: false,
        Element: <ChangePasswordScreen />,
        title: ScreenTitle.FORGOT_PASSWORD,
    },
    {
        path: "/change-email",
        protect: true,
        Element: <ChangeEmailScreen />,
        title: ScreenTitle.CHANGE_EMAIL,
    },
    {
        path: "/account",
        protect: true,
        Element: <AccountScreen />,
        title: ScreenTitle.ACCOUNT,
    },
    {
        path: "/account/settings",
        protect: true,
        Element: <AccountSettingsScreen />,
        title: ScreenTitle.ACCOUNT_SETTINGS,
    },
    {
        path: "/account/events",
        protect: true,
        Element: <AccountEventsScreen />,
        title: ScreenTitle.ACCOUNT_EVENTS,
    },
    {
        path: "/account/events/:eventid",
        protect: true,
        Element: <EventDetailsScreen />,
        title: ScreenTitle.EVENT_DETAILS,
    },

    // {
    //     path: "/payments/:paymentid/success",
    //     protect: true,
    //     Element: <PaymentsSuccessScreen />,
    //     title: ScreenTitle.PAYMENTS_SUCCESS,
    // },
    {
        path: "/login",
        protect: false,
        Element: <LoginScreen />,
        title: ScreenTitle.LOGIN,
    },
    {
        path: "/register",
        protect: false,
        Element: <RegisterScreen />,
        title: ScreenTitle.REGISTER,
    },
    {
        path: "/forgot-password",
        protect: false,
        Element: <ResetPasswordScreen />,
        title: ScreenTitle.FORGOT_PASSWORD,
    },
    {
        path: "/change-password/confirm",
        protect: false,
        Element: <ConfirmChangePasswordScreen />,
        title: ScreenTitle.CONFIRM_CHANGE_PASSWORD,
    },
    {
        path: "/email/confirm",
        protect: true,
        Element: <ConfirmEmailScreen />,
        title: ScreenTitle.CONFIRM_EMAIL,
    },
    {
        path: "/unauthorized",
        protect: false,
        Element: <UnauthorizedScreen />,
        title: ScreenTitle.UNAUTHORIZED,
    },
    {
        path: "/messages/:code",
        protect: false,
        Element: <MessagesScreen />,
        title: ScreenTitle.MESSAGES,
    },
    {
        path: "/offers",
        protect: false,
        Element: <OffersScreen />,
        title: ScreenTitle.OFFERS,
    },
    {
        path: "/offers/create",
        protect: false,
        Element: <CreateServerFromOfferScreen />,
        title: ScreenTitle.CREATE_OFFER,
    },
    {
        path: "/offers/craftum/create",
        protect: true,
        Element: <CreateCraftumScreen />,
        title: ScreenTitle.CREATE_CRAFTUM,
    },
    {
        path: "/create",
        protect: true,
        Element: <CreateServerFromOfferScreen />,
        title: ScreenTitle.CREATE_SERVER,
    },
    {
        path: "/s",
        protect: true,
        Element: <SelectServerScreen />,
        title: ScreenTitle.SERVERS_LIST,
    },
    {
        path: "/payu/continue",
        protect: true,
        Element: <PayuSuccessScreen />,
        title: ScreenTitle.PAYU_SUCCESS,
    },
    {
        path: "/s/:serverIdentity/payu/continue",
        protect: true,
        Element: <PayuServerSuccessScreen />,
        title: ScreenTitle.PAYU_SUCCESS,
    },

    {
        path: "/m/projects/create",
        protect: true,
        Element: <CreateMarketProjectScreen />,
        title: ScreenTitle.CREATE_PROJECT,
    },
    {
        path: "/m/projects/edit/:projectid",
        protect: true,
        Element: <EditMarketProjectScreen />,
        title: ScreenTitle.EDIT_PROJECT,
    },

    {
        path: "/m/:userid/projects",
        protect: true,
        Element: <UserProjectsScreen />,
        title: ScreenTitle.USER_PROJECTS,
    },
    {
        path: "/m/:projectid",
        protect: false,
        Element: (
            <Layout>
                <ProjectDetailsScreen />
            </Layout>
        ),
        title: ScreenTitle.PROJECT_DETAILS,
    },
    {
        path: "/m/",
        protect: false,
        Element: <MarketplaceScreen />,
        title: ScreenTitle.MARKETPLACE,
    },
    {
        path: "/m/:projectid/releases/new",
        protect: true,
        Element: <ProjectReleaseUploaderScreen />,
        title: ScreenTitle.PROEJCT_RELEASE_UPLOADER,
    },
    {
        path: "/voucher/use",
        protect: true,
        Element: <UseCouponScreen />,
        title: ScreenTitle.COUPON_USE,
    },
    {
        path: "/unauthorized-server",
        protect: false,
        Element: <UnauthorizedServerScreen />,
        title: ScreenTitle.UNAUTHORIZED_SERVER,
    },
    {
        path: "/user/:userId",
        protect: true,
        Element: <UserAdminViewScreen />,
        title: ScreenTitle.USER_ADMIN_VIEW,
    },
    {
        path: "/admin",
        protect: true,
        Element: <AdminScreen />,
        title: ScreenTitle.USER_ADMIN_VIEW,
    },
    {
        path: "/privacy-policy",
        protect: false,
        Element: <PrivacyPolicyScreen />,
        title: ScreenTitle.PRIVACY_POLICY,
    },

    {
        path: "/c/:craftumId",
        protect: true,
        Element: <CraftumScreen />,
        title: ScreenTitle.CRAFTUM,
    },
    {
        path: "/s/:serverIdentity/paypal/cancel",
        protect: true,
        Element: <PaypalCancelScreen />,
        title: ScreenTitle.PAYPAL_CANCEL,
    },
    {
        path: "/s/:serverIdentity/paypal/continue",
        protect: true,
        Element: <PaypalContinueScreen />,
        title: ScreenTitle.PAYPAL_SUCCESS,
    },
];

const RootRouter: React.FC = () => {
    const [inProp, setInProp] = useState(false);

    const { verifyUser, getTaxInfo } = useVerify();

    useEffect(() => {
        getTaxInfo();
        verifyUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <CookieConsentProvider>
            <BrowserRouter>
                <RouteChangeListener
                    trackedRoutes={[
                        {
                            event: EventAction.SERVER_CREATION_INTERRUPTED,
                            route: "offers/create",
                        },
                    ]}
                >
                    <RoutingTracker />
                    <ProcessesProvider>
                        <Routes>
                            {routes.map(({ path, protect, Element, title }) => {
                                return protect ? (
                                    <Route
                                        key={path}
                                        path={path}
                                        element={
                                            <>
                                                <SEO title={title} />
                                                <PanelLoader inProp={inProp} />
                                                <Suspense fallback={<SuspenseLoadingSwitcher setInProp={setInProp} />}>
                                                    <ProtectedRoute>{Element}</ProtectedRoute>
                                                </Suspense>
                                            </>
                                        }
                                    />
                                ) : (
                                    <Route
                                        key={path}
                                        path={path}
                                        element={
                                            <>
                                                <SEO title={title} />
                                                <PanelLoader inProp={inProp} />
                                                <Suspense fallback={<SuspenseLoadingSwitcher setInProp={setInProp} />}>
                                                    {Element}
                                                </Suspense>
                                            </>
                                        }
                                    />
                                );
                            })}
                            {panelRoutes.map(({ path, Element, title, forPaidServerOnly }) => {
                                return (
                                    <Route
                                        key={path}
                                        path={path}
                                        element={
                                            <>
                                                <SEO title={title} url={`${HostUrl}/${path}`} />
                                                <PanelLoader inProp={inProp} />
                                                <ProtectedRoute>
                                                    <CurrentServerProvider>
                                                        <PanelLayout forPaidServerOnly={!!forPaidServerOnly}>
                                                            <Suspense
                                                                fallback={
                                                                    <SuspenseLoadingSwitcher setInProp={setInProp} />
                                                                }
                                                            >
                                                                {Element}
                                                            </Suspense>
                                                        </PanelLayout>
                                                    </CurrentServerProvider>
                                                </ProtectedRoute>
                                            </>
                                        }
                                    />
                                );
                            })}
                        </Routes>
                    </ProcessesProvider>
                </RouteChangeListener>

                <CookiesBanner />
            </BrowserRouter>
        </CookieConsentProvider>
    );
};

export default RootRouter;
