import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import CardDetailsBackground from "../../../../assets/images/card_bg.webp";

export const ProductLink = styled(Link)`
    width: 100%;
    text-decoration: none;
    width: 100%;
    max-width: 550px;
    color: ${({ theme }) => theme.colors.primaryText};
`;

export const StyledProductCard = styled.article<{ showOutline?: boolean; disabled?: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    padding: ${({ showOutline }) => (showOutline ? "3px" : "0")};
    z-index: 3;
    border-radius: 8px;
    filter: ${({ disabled }) => (disabled ? "grayscale(100%)" : "none")};
    opacity: ${({ disabled }) => (disabled ? 0.8 : 1)};
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;

export const ProductCardHeading = styled.section<{ bgImage: string }>`
    width: 100%;
    // height: 245px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 280px;
    background-image: url(${({ bgImage }) => bgImage});
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 3;
    border-radius: 8px 8px 0 0;
    // boxshadow only bottom
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const ProductCardBody = styled.section`
    width: 100%;
    height: fit-content;
    background-image: url(${CardDetailsBackground});
    background-size: cover;
    background-position: center;
    border-radius: 0 0 8px 8px;
    z-index: 1;
`;

const floatingAnimation = keyframes`
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0);
    }
`;

export const Floating = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    height: 100%;
    z-index: 1;
    animation: ${floatingAnimation} 3s ease-in-out infinite;

    & img {
        width: auto;
    }

    @media (max-width: 470px) {
        display: none;
    }
`;

export const PriceChip = styled.p`
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    text-transform: uppercase;
    padding: 2px 16px;
    font-weight: 550;
    font-size: 20px;
    flex: 0 1 auto;
    background-color: ${({ theme }) => theme.colors.secondaryBackground};
    border-radius: 4px;
    box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.15);
    text-wrap: nowrap;
`;

export const Divider = styled.div`
    width: 100%;
    flex-grow: 1;
    margin: 0 16px;
    align-self: center;
    height: 3px;
    opacity: 0.5;
    background-color: ${({ theme }) => theme.colors.secondaryText};
    margin: 20px 0;
`;

export const FeatureTile = styled.span<{ bg: string }>`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    background-image: ${({ bg }) => `url(${bg})`};
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center;
    height: 50px;
    width: 50px;
    font-weight: 550;
    background-color: #ac2ec0;
    border-radius: 8px;
    font-size: 24px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.secondaryText};
`;

export const FeatureTileWrapper = styled.p`
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 20px;
    font-weight: 550;
    justify-content: center;
    align-items: center;
    margin: 0;

    @media (max-width: 470px) {
        min-width: 90px;
    }
`;

export const Table = styled.table`
    display: flex;
    flex-direction: column;
    color: ${({ theme }) => theme.colors.dark};
    gap: 2px;
    width: 100%;
    margin: 8px 0 20px 0;
    padding: 0 20px;
`;

export const DividerTitle = styled.h3`
    font-size: 40px;
    font-weight: 550;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.dark};
    margin: 10px 0 0 0;
    display: flex;
    text-wrap: nowrap;

    &::before,
    &::after {
        content: "";
        display: block;
        width: 100%;
        height: 3px;
        background-color: ${({ theme }) => theme.colors.dark};
        margin: auto 20px;
    }
`;

export const TableRow = styled.tr`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 4px 0;
    background-color: ${({ theme }) => theme.colors.border}4D;

    & td {
        font-size: 20px;
        font-weight: 800;
        padding: 0 9px;
    }
`;

export const Badge = styled.div`
    display: flex;
    gap: 8px;
    position: absolute;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 4px 20px 23px 20px;
    overflow: hidden;
    margin-bottom: -20px;
    background: linear-gradient(10deg, #fac203, #fbfb00);
    border-radius: 8px;
    position: relative;
    z-index: 0 !important;
    color: ${({ theme }) => theme.colors.primaryText};
`;
