import { Card, Space } from "csrvui";
import { forwardRef, useContext, useEffect, useState } from "react";
import { getUILocalOptions } from "../../helpers/localStorage";
import { ProcessesContext } from "../../hooks/ProcessesContext";
import ProcessItem from "./components/ProcessItem";
import { Container, List, Scrolled } from "./style";

const ProcessesContainer = forwardRef<HTMLDivElement>((props, ref) => {
    const { processes, messages } = useContext(ProcessesContext);
    const { processesExpanded } = getUILocalOptions();
    const [mode, setMode] = useState<"show" | "hide">(processesExpanded ? "show" : "hide");

    useEffect(() => {
        const compareProcessWithStorage = () => {
            const { processesExpanded: newValue } = getUILocalOptions();
            setMode(newValue ? "show" : "hide");
        };
        window.addEventListener("storage", compareProcessWithStorage);

        return () => window.removeEventListener("storage", compareProcessWithStorage);
    }, []);

    if (!messages.length && !processes.length) {
        return null;
    }

    return (
        <Container mode={mode} ref={ref}>
            <Card $fullHeight>
                <Space
                    $padding={5}
                    style={{
                        overflowX: "scroll",
                    }}
                >
                    <Scrolled>
                        <List>
                            {[...messages, ...processes].slice(0, 20).map((process) => (
                                <ProcessItem key={process.id} process={process} />
                            ))}
                        </List>
                    </Scrolled>
                </Space>
            </Card>
        </Container>
    );
});

ProcessesContainer.displayName = "ProcessesContainer";

export default ProcessesContainer;
