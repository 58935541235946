import { FaArrowAltCircleUp } from "@react-icons/all-files/fa/FaArrowAltCircleUp";
import { IoCheckmarkCircle } from "@react-icons/all-files/io5/IoCheckmarkCircle";
import { Button, Paragraph, Space } from "csrvui";
import { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Money } from "ts-money";
import { BillingPeriods, translateBillingPeriods } from "../../../../constants";
import BillingPeriodSwitch from "../../../../shared/components/BillingPeriodSwitch";
import { CurrentServerContext } from "../../../../shared/components/CurrentServerProvider";
import { getLocalCurrency } from "../../../../shared/helpers/localStorage";
import { showApiError } from "../../../../shared/helpers/showToast";
import useTheme from "../../../../shared/hooks/useCustomTheme";
import useMoney from "../../../../shared/hooks/useMoney";
import { useTrackEvent } from "../../../../tracking/useTrackEvent";
import ProductCard from "../../../Billings/components/ProductCard";
import { BillingProduct } from "../../../Billings/types";
import { useUpgradeBillingServiceMutation } from "../../../Services/api";
import getRawPriceForProduct from "../../../User/helpers/getRawPriceForProduct";
import useServerCost from "../../hooks/useServerCost";
import useProductStrategySelector from "../../strategies/useProductStrategySelector";
import { BillingServiceUpgradablesResponse } from "../../types";
import { UpgradeNowButton } from "./style";

interface Props {
    product: BillingProduct;
    currentBillingPeriod: string;
    currentProduct: BillingProduct;
    data?: BillingServiceUpgradablesResponse;
    remainingTime: string;
    onUpgrade: (createTransaction: boolean, billingPeriod: BillingPeriods, nextPeriod: boolean) => void;
}

const UpgradeServerCard = ({
    product,
    currentProduct,
    data,
    remainingTime,
    currentBillingPeriod,
    onUpgrade,
}: Props) => {
    const strategy = useProductStrategySelector(product.id);
    const [billingPeriod, setBillingPeriod] = useState(currentBillingPeriod as BillingPeriods);
    const { trackEvent } = useTrackEvent();
    const [mutate] = useUpgradeBillingServiceMutation();
    const { calculateProductCost } = useServerCost();
    const { service } = useContext(CurrentServerContext);

    const { printLocalPrice } = useMoney();
    const currency = getLocalCurrency();

    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = window.innerWidth < 570;
    const cleanCurrentBillingPeriod = parseInt(currentBillingPeriod.replace(/[^\d]/g, ""), 10);
    const cleanProductBillingPeriod = parseInt(billingPeriod.replace(/[^\d]/g, ""), 10);

    const getCardVariant = useCallback(() => {
        if (product.id === currentProduct.id && billingPeriod === currentBillingPeriod) return "same";

        if (cleanProductBillingPeriod > cleanCurrentBillingPeriod && product.id === currentProduct.id) {
            return "higher";
        }

        if (getRawPriceForProduct(currentProduct, currency).amount < getRawPriceForProduct(product, currency).amount) {
            return "higher";
        }

        return "lower";
    }, [
        currentProduct,
        product,
        billingPeriod,
        currency,
        currentBillingPeriod,
        cleanCurrentBillingPeriod,
        cleanProductBillingPeriod,
    ]);

    const canUpgradeNow = useMemo(() => {
        if (product.id !== currentProduct.id && !!data && data.upgrade_price === null) {
            return false;
        }

        return (
            billingPeriod === currentBillingPeriod ||
            (billingPeriod !== currentBillingPeriod && product.id === currentProduct.id)
        );
    }, [billingPeriod, currentBillingPeriod, product.id, currentProduct.id, data]);

    const canUpgradeLater = useMemo(() => {
        return product.id !== currentProduct.id;
    }, [product.id, currentProduct.id]);

    const variant = getCardVariant();

    const handleUpgrade = useCallback(
        async (nextPeriod?: boolean) => {
            // const upgradeAtNextPeriod = nextPeriod || billingPeriod !== currentBillingPeriod || variant === "same";

            const upgradeAtNextPeriod = () => {
                switch (true) {
                    case nextPeriod:
                        return true;
                    case product.id === currentProduct.id:
                        return false;
                    case cleanCurrentBillingPeriod < cleanProductBillingPeriod ||
                        cleanCurrentBillingPeriod === cleanProductBillingPeriod:
                        return false;
                    default:
                        return true;
                }
            };

            try {
                const res = await mutate({
                    serviceId: service.service_id,
                    payload: {
                        next_period: upgradeAtNextPeriod(),
                        upgrade_to: product.id === currentProduct.id ? null : product.id,
                        upgrade_billing_period: billingPeriod === currentBillingPeriod ? null : billingPeriod,
                    },
                }).unwrap();

                onUpgrade(res.create_transaction, billingPeriod, upgradeAtNextPeriod());
            } catch (err) {
                showApiError(err);
            }
        },
        [
            billingPeriod,
            product,
            currentProduct,
            mutate,
            service,
            onUpgrade,
            cleanCurrentBillingPeriod,
            cleanProductBillingPeriod,
            currentBillingPeriod,
        ]
    );

    const cost = useMemo(() => {
        const periodExists = product.prices?.some((price) => price.period === billingPeriod);

        const effectiveBillingPeriod = periodExists ? billingPeriod : product.prices?.[0]?.period || billingPeriod;

        return calculateProductCost(
            [product],
            service.parameters.user_parameters.cluster_id || "",
            effectiveBillingPeriod || ""
        );
    }, [product, service, billingPeriod, calculateProductCost]);

    const availableBillingPeriods = data
        ? data.billing_periods.map((period) => period.period)
        : product.prices
              ?.filter((price) => price.cluster_id === service.parameters.user_parameters.cluster_id)
              .map((product) => product.period);

    const upgradeNowButtonText = useMemo(() => {
        const formattedPriceForUpgrade = data && data.upgrade_price ? data.upgrade_price : new Money(0, currency);

        return {
            title: (() => {
                switch (true) {
                    case !product.available:
                        return t("upgrade_server.unavailable");
                    case variant === "higher":
                        return t("upgrade_server.upgrade_now", {
                            upgrade_price: printLocalPrice(formattedPriceForUpgrade),
                            time: remainingTime,
                        });

                    case variant === "lower":
                        return t("upgrade_server.downgrade_now");

                    case variant === "same":
                        return t("upgrade_server.same_tier");
                    default:
                        return "";
                }
            })(),

            subtitle: (() => {
                switch (true) {
                    case !product.available:
                        return t("upgrade_server.unavailable_desc");
                    case variant === "higher" ||
                        (currentProduct.id === product.id && billingPeriod !== currentBillingPeriod):
                        return t("upgrade_server.upgrade_now_desc", {
                            price: cost
                                ? `${printLocalPrice(cost)}  ${translateBillingPeriods[billingPeriod]}`
                                : t("errors.price_for_billing_period_not_found"),
                        });
                    case variant === "same":
                        return t("upgrade_server.same_tier_desc");

                    case variant === "lower":
                        return t("upgrade_server.lower_tier_desc");
                    default:
                        return "";
                }
            })(),
        };
    }, [
        variant,
        t,
        printLocalPrice,
        remainingTime,
        cost,
        billingPeriod,
        currentProduct,
        currentBillingPeriod,
        product,
        currency,
        data,
    ]);

    const upgradeLaterButtonText = {
        title: (() => {
            switch (true) {
                case !product.available:
                    return t("upgrade_server.unavailable");
                case variant === "higher":
                    return t("upgrade_server.upgrade_later");

                case variant === "lower":
                    return t("upgrade_server.downgrade_later");

                case variant === "same":
                    return t("upgrade_server.same_tier");
                default:
                    return "";
            }
        })(),

        subtitle: (() => {
            switch (true) {
                case !product.available:
                    return t("upgrade_server.unavailable_desc");
                case variant === "higher":
                    return t("upgrade_server.upgrade_later_desc");

                case variant === "lower":
                    return t("upgrade_server.downgrade_later_desc");

                case variant === "same":
                    return t("upgrade_server.same_tier_desc");
                default:
                    return "";
            }
        })(),
    };

    if (!strategy) return null;

    const { getProductScheme, getCardThumb } = strategy;

    const product_thumb = getCardThumb();
    const product_color = getProductScheme();

    const isProductFeatured = product.id === "AMETHYST";

    return (
        <Space
            $fullWidth
            style={{
                maxWidth: "480px",
                position: "relative",
                height: "100%",
                opacity: product.available ? 1 : 0.5,
            }}
            $padding={0}
        >
            <Space
                $padding={0}
                style={{
                    minHeight: isProductFeatured ? "0" : "80px",
                }}
            >
                {availableBillingPeriods && availableBillingPeriods.length > 1 && (
                    <BillingPeriodSwitch
                        periods={availableBillingPeriods as BillingPeriods[]}
                        value={billingPeriod}
                        onChange={(period) => {
                            trackEvent("upgrade_server_card", "billing_period_switch", "change", {
                                period,
                            });

                            setBillingPeriod(period);
                        }}
                    />
                )}
            </Space>
            <ProductCard
                options={{
                    price: cost
                        ? `${printLocalPrice(cost)} / ${translateBillingPeriods[billingPeriod]}`
                        : t("errors.price_for_billing_period_not_found"),
                }}
                disabled
                variant="upgrade"
                showOutline
                showBadge={isProductFeatured}
                data={product}
            />
            <Space
                $fullWidth
                style={{
                    minHeight: "146px",
                }}
                $padding={0}
            >
                {canUpgradeNow && (
                    <UpgradeNowButton
                        data-ph-capture-attribute-id="upgrade_server_card:upgrade_now_button"
                        product_background={product_thumb}
                        product_color={product_color}
                        onClick={() => handleUpgrade()}
                        $fullWidth
                        disabled={!product.available || variant === "same" || variant === "lower"}
                    >
                        {!isMobile && (
                            <Space
                                style={{
                                    position: "absolute",
                                    left: "18px",
                                    top: "50%",
                                    transform: "translate(0, -50%)",
                                }}
                                $padding={0}
                            >
                                {variant === "same" ? (
                                    <IoCheckmarkCircle
                                        style={{
                                            color: theme.colors.white,
                                        }}
                                        size={38}
                                    />
                                ) : (
                                    <FaArrowAltCircleUp
                                        style={{
                                            color: theme.colors.white,
                                            transform: variant === "higher" ? "rotate(0deg)" : "rotate(180deg)",
                                        }}
                                        size={38}
                                    />
                                )}
                            </Space>
                        )}
                        <Space
                            style={{
                                color: theme.colors.white,
                                position: "relative",
                            }}
                            $align="center"
                            $oppositeAlign="center"
                            $fullWidth
                            $padding={0}
                            $direction="horizontal"
                        >
                            <Space $gap={0} $padding={0}>
                                <Paragraph
                                    style={{
                                        fontWeight: 550,
                                        fontSize: "24px",
                                        textTransform: "uppercase",
                                        color: theme.colors.white,
                                    }}
                                >
                                    {upgradeNowButtonText.title}
                                </Paragraph>
                                <Paragraph
                                    style={{
                                        fontWeight: 550,
                                        fontSize: "16px",
                                        textTransform: "uppercase",
                                        color: theme.colors.white,
                                    }}
                                >
                                    {upgradeNowButtonText.subtitle}
                                </Paragraph>
                            </Space>
                        </Space>
                    </UpgradeNowButton>
                )}

                {canUpgradeLater && (
                    <Button
                        id="upgrade_server_card:upgrade_later_button"
                        style={{
                            padding: "8px 55px",
                            border: `3px solid ${product_color}`,
                            position: "relative",
                        }}
                        $fullWidth
                        onClick={() => handleUpgrade(true)}
                        $outlined
                        disabled={!product.available}
                    >
                        {!isMobile && (
                            <Space
                                style={{
                                    position: "absolute",
                                    left: "15px",
                                    top: "50%",
                                    transform: "translate(0, -50%)",
                                }}
                                $padding={0}
                            >
                                {variant === "same" ? (
                                    <IoCheckmarkCircle
                                        style={{
                                            color: product_color,
                                        }}
                                        size={38}
                                    />
                                ) : (
                                    <FaArrowAltCircleUp
                                        style={{
                                            color: product_color,
                                            transform: variant === "higher" ? "rotate(0deg)" : "rotate(180deg)",
                                        }}
                                        size={38}
                                    />
                                )}
                            </Space>
                        )}
                        <Space
                            style={{
                                position: "relative",
                            }}
                            $align="center"
                            $oppositeAlign="center"
                            $fullWidth
                            $padding={0}
                            $direction="horizontal"
                        >
                            <Space $padding={0} $gap={0}>
                                <Paragraph
                                    style={{
                                        fontWeight: 550,
                                        fontSize: "24px",
                                        textTransform: "uppercase",
                                        color: product_color,
                                    }}
                                >
                                    {upgradeLaterButtonText.title}
                                </Paragraph>
                                <Paragraph
                                    style={{
                                        fontWeight: 550,
                                        fontSize: "16px",
                                        textTransform: "uppercase",
                                        color: product_color,
                                    }}
                                >
                                    {upgradeLaterButtonText.subtitle}
                                </Paragraph>
                            </Space>
                        </Space>
                    </Button>
                )}
            </Space>
        </Space>
    );
};

export default UpgradeServerCard;
