import { Paragraph, Space } from "csrvui";
import { ParagraphProps } from "csrvui/dist/esm/components/Paragraph";
import { SpaceProps } from "csrvui/dist/esm/components/Space";
import { ReactNode } from "react";
import useTheme from "../../hooks/useCustomTheme";

interface ChipProps extends SpaceProps {
    children: string | ReactNode;
    textProps?: ParagraphProps;
    textColor?: string;
    fontSize?: string;
    bgColor?: string;
}

const Chip = ({ children, textColor, fontSize, bgColor, textProps = {}, ...props }: ChipProps) => {
    const theme = useTheme();

    return (
        <Space
            {...props}
            $padding={0}
            style={{ borderRadius: "12px", backgroundColor: bgColor || theme.colors.danger, ...props.style }}
            $align="center"
            $oppositeAlign="center"
        >
            <Paragraph
                {...textProps}
                $color={textColor || theme.colors.white}
                style={{
                    fontWeight: "bold",
                    padding: "3px 8px",
                    fontSize: fontSize || "inherit",
                    ...textProps.style,
                }}
            >
                {children}
            </Paragraph>
        </Space>
    );
};

export default Chip;
