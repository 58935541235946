import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../rootReducer";

export const selectCurrentUser = (state: RootState) => state.auth.user;
export const wasUserVerified = (state: RootState) => state.auth.verified;
export const isUserLoggedIn = (state: RootState) => state.auth.verified && !!state.auth.user;
export const isUserAdmin = (state: RootState) => state.auth.isAdmin;
export const getTaxInfo = (state: RootState) => state.taxInfo;

export const userPermissions = createSelector(
    (state: RootState) => state.auth.user,
    (user) => (user ? user.user_capabilities : [])
);
