import { ExternalLink, InternalLink, Paragraph, Space } from "csrvui";
import styled from "styled-components";

interface StyledIntroductionProps {
    backgroundMobile: string;
    backgroundDesktop: string;
}

export const StyledIntroduction = styled.section<StyledIntroductionProps>`
    position: relative;

    box-shadow: 0px 23px 6px 0px #00000080 inset;
    background-color: #333;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 100px;
    overflow: hidden;
    height: 700px;
    z-index: 1;

    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100vh; /* lub inna odpowiednia wysokość */

    @media (max-width: 649px) {
        background-image: url(${(props) => props.backgroundMobile});
    }

    @media (min-width: 650px) {
        background-image: url(${(props) => props.backgroundDesktop});
    }

    &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 55%;
        background: linear-gradient(360deg, #193b17 13.64%, rgba(20, 53, 18, 0.55) 45.68%, rgba(15, 47, 13, 0) 76.43%);
        z-index: 2;
    }

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
            109.86deg,
            #234d20 5.5%,
            rgba(16, 74, 22, 0.5) 75.58%,
            rgba(40, 167, 69, 0.54) 97.11%
        );
        z-index: -1;
    }

    @media (max-width: 1190px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    @media (min-width: ${({ theme }) => theme.mobileBreakpoint}) {
        height: 1000px;
        padding-bottom: 100px;
        padding-top: 50px;
    }

    @media (max-width: 768px) {
        padding-top: 40px;
    }
`;

// export const StyledHeroImageWrapper = styled.div`
//     position: absolute;
//     z-index: 0;
//     width: 100%;
//     overflow: hidden;
//     height: 100%;
//     left: 0;

//     &::before {
//         content: "";
//         position: absolute;
//         top: 0;
//         left: 0;
//         width: 100%;
//         height: 100%;
//         background: linear-gradient(
//             109.86deg,
//             #234d20 5.5%,
//             rgba(16, 74, 22, 0.5) 75.58%,
//             rgba(40, 167, 69, 0.54) 97.11%
//         );
//         z-index: 1;
//     }
// `;

export const StyledBackgroundImage = styled.img`
    width: auto;
    height: 100%;
    position: relative;
    z-index: 0;

    @media (min-width: 1720px) {
        width: 100% !important;
    }
`;

export const Description = styled.div`
    max-width: 1000px;
    margin: 0;
    width: 50%;

    @media (max-width: 1190px) {
        order: -1;
        align-items: center;
        text-align: center;
        width: 100%;
        max-width: 100%;
    }
`;

export const StyledTitle = styled.h1`
    font-weight: 700;
    font-size: 96px;
    line-height: 100px;
    min-height: 380px;
    font-family: ${({ theme }) => theme.fontFamilies.rajdhani}, "Title fallback";
    color: #fff;
    margin: 0;
    text-align: left;
    text-transform: capitalize;
    z-index: 2;

    @media (min-width: ${({ theme }) => theme.mobileBreakpoint}) {
        margin: 0 auto;
        margin-bottom: 40px;
        max-width: 700px;
    }

    @media (max-width: 1190px) {
        font-size: 64px;
        order: -1;
        align-items: center;
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
    }

    @media (max-width: 540px) {
        font-size: 66px;
        line-height: 60.5px;
    }
`;

export const StyledSpan = styled.span`
    color: ${({ theme }) => theme.colors.success};
`;

export const StyledInternalLink = styled(InternalLink)`
    padding: 15px 30px;
    width: 292px;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    text-decoration: none;
    background-color: #28a745;
    box-shadow: 0px 0px 15px 9px #28a74540;
    margin-right: 20px;
    z-index: 3;
    text-align: center;

    & p {
        height: 37px;
    }

    @media (max-width: 540px) {
        padding: 6px 12px;
        width: auto;
        height: 62px;

        & p {
            height: auto;
        }
    }
`;

export const StyledParagraph = styled(Paragraph)`
    color: ${({ theme }) => theme.colors.primaryBackground};
    font-weight: 700;
    font-size: 32px;

    @media (max-width: 540px) {
        font-size: 18px;
    }
`;

export const DiscordLink = styled(ExternalLink)`
    padding: 12px 12px;
    width: 81px;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    background-color: #5865f2;
    box-shadow: 0px 0px 15px 9px #5865f240;
    z-index: 3;

    @media (max-width: 540px) {
        padding: 6px 6px;
        width: 62px;
        height: 62px;
    }
`;

export const DiscordIconImg = styled.img`
    width: 50px;
    height: 38px;

    @media (max-width: 540px) {
        width: 42px;
        height: 42px;
    }
`;

export const HeroContainer = styled.div`
    margin-left: 12vw;
    width: 50%;

    @media (max-width: 1190px) {
        display: none;
    }
`;

export const StyledSpace = styled(Space)`
    flex-direction: row;
    justify-content: center;
    padding: 0;

    @media (min-width: 1190px) {
        justify-content: flex-start;
    }
`;
