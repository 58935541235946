import { Paragraph, Space } from "csrvui";
import { Link } from "react-router-dom";
import bronze_podium_image from "../../../../assets/images/bronze_platform.svg";
import gold_podium_image from "../../../../assets/images/gold_platform.svg";
import silver_podium_image from "../../../../assets/images/silver_platform.svg";
import useTheme from "../../../../shared/hooks/useCustomTheme";
import { useGetProjectQuery } from "../../api";
import standarizeUrl from "../../helpers/standarizeUrl";
import useProjectAssets from "../../hooks/useProjectAssets";
import { MarketEventMetric } from "../../types";
import { ImageWrapper, Platform } from "./styles";

interface Props {
    place: number;
    data?: MarketEventMetric;
}

const RankingPlatform = ({ data, place }: Props) => {
    const { data: project } = useGetProjectQuery(data ? data.project_slug : "", {
        skip: !data || !data.project_slug,
    });
    const theme = useTheme();
    const { thumbnail, icon } = useProjectAssets(project);

    const getPlatformData = (place: number) => {
        switch (place) {
            case 1:
                return {
                    image: gold_podium_image,
                    height: 219,
                    left: "50%",
                    color: "#E2C008",
                    prizeText: "800zł",
                };
            case 2:
                return {
                    image: silver_podium_image,
                    height: 162,
                    left: "56%",
                    color: "#A7B1CA",
                    prizeText: "450zł",
                };
            default:
                return {
                    image: bronze_podium_image,
                    height: 128,
                    left: "45%",
                    color: "#D89142",
                    prizeText: "250zł",
                };
        }
    };

    const { height, image, left, color, prizeText } = getPlatformData(place);

    return (
        <Link
            to={data ? `/m/${data.project_slug}` : ""}
            style={{
                textDecoration: "none",
            }}
        >
            <Platform height={height} image={image}>
                <img
                    src={image}
                    alt=""
                    aria-disabled
                    style={{
                        position: "absolute",
                        top: "0px",
                        width: "100%",
                        height: "100%",
                    }}
                />
                <Space
                    $padding="15px 20px"
                    style={{
                        position: "absolute",
                        top: "-165px",
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
                        backgroundColor: color,
                        borderRadius: "8px",
                    }}
                >
                    <Paragraph
                        style={{
                            color: theme.colors.secondaryText,
                            fontWeight: 550,
                            fontSize: "20px",
                        }}
                    >
                        {prizeText}
                    </Paragraph>
                </Space>

                {project && (
                    <ImageWrapper pos={left}>
                        <img
                            src={standarizeUrl(thumbnail || icon, {
                                width: 120,
                            })}
                            alt="project"
                        />
                    </ImageWrapper>
                )}
                <Space $padding={0}>
                    <Space
                        style={{
                            backgroundColor: "rgba(0, 0, 0, 0.2)",
                            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
                            borderRadius: "8px",
                            width: "fit-content",
                            zIndex: 1,
                            height: "fit-content",
                        }}
                        $padding="10px 20px 8px 20px"
                    >
                        <Paragraph
                            $level={3}
                            $color={theme.colors.secondaryText}
                            style={{
                                fontWeight: 500,
                                lineHeight: "20px",
                            }}
                        >
                            {data ? `${data.points}p` : "-"}
                        </Paragraph>
                    </Space>
                    <Paragraph
                        $level={3}
                        $color={theme.colors.secondaryText}
                        style={{
                            fontWeight: 500,
                            maxWidth: "100%",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            padding: "0 14px",
                            zIndex: 1,
                            // max 2 lines
                            display: "-webkit-box",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                            textAlign: "center",
                            wordBreak: "break-all",
                        }}
                    >
                        {data ? data.project_name : "-"}
                    </Paragraph>
                </Space>
            </Platform>
        </Link>
    );
};

export default RankingPlatform;
