import { FaPlay } from "@react-icons/all-files/fa/FaPlay";
import { Button, Paragraph, Space } from "csrvui";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { StyledLoaderSmall } from "../../../../shared/components/PanelLoader/style";
import useTheme from "../../../../shared/hooks/useCustomTheme";
import { MarketplaceProjectCard, MarketplaceProjectContent } from "../Marketplace/MarketplaceCard/style";

interface Props {
    useShortDescription?: boolean;
}

const MarketplaceSkeletonCard = ({ useShortDescription }: Props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <MarketplaceProjectCard
            style={{
                position: "relative",
                backgroundColor: theme.colors.secondaryBackground,
                opacity: 0.7,
                cursor: "progress",
            }}
        >
            <StyledLoaderSmall
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                }}
            />
            <Space style={{ zIndex: 1 }} $direction="horizontal" $fullWidth $align="end">
                <Button
                    $variant="success"
                    style={{
                        padding: "10px 10px",
                        boxShadow: ` 0px 2px 10px ${theme.colors.success}`,
                    }}
                    disabled
                >
                    <Space $direction="horizontal" $align="center" $oppositeAlign="center" $gap={15} $padding={0}>
                        <FaPlay size={19} />
                        <Paragraph
                            $level={4}
                            style={{
                                fontWeight: 550,
                                color: theme.colors.secondaryText,
                                textTransform: "uppercase",
                            }}
                        >
                            {t("marketplace.run")}
                        </Paragraph>
                    </Space>
                </Button>
            </Space>
            <MarketplaceProjectContent>
                {/* name */}
                <Skeleton height={27} width={170} />
                {useShortDescription && <Skeleton height={54} width="100%" />}
            </MarketplaceProjectContent>
        </MarketplaceProjectCard>
    );
};

export default MarketplaceSkeletonCard;
