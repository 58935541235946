import styled, { keyframes } from "styled-components";

export const Platform = styled.div<{ image: string; height: number }>`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 20px 0 0 0;
    margin-bottom: -1px;
    height: ${(props) => props.height}px;
    width: 147px;
    cursor: pointer;
    background-repeat: no-repeat;

    background-size: contain;
    transition: 0.2s ease-in-out;

    &:hover {
        transform: scaleY(1.05);
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
    }
`;

const float = keyframes`
    0% {
        transform: translateY(0) translateX(-50%);
    }
    50% {
        transform: translateY(-5px) translateX(-50%);
    }
    100% {
        transform: translateY(0) translateX(-50%);
    }
`;

export const ImageWrapper = styled.div<{ pos: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -60px;
    left: ${(props) => props.pos};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    overflow: hidden;
    height: 68px;
    width: 120px;
    animation: ${float} 3s ease-in-out infinite;
`;
