import posthog from "posthog-js";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const RoutingTracker = () => {
    const location = useLocation();

    useEffect(() => {
        if (window.location.hostname === "craftserve.com") {
            if (window.gtag) {
                window.gtag("config", "AW-1016555889", {
                    page_path: location.pathname + location.search,
                });
            }

            posthog.capture("$pageview", {
                $current_url: location.pathname + location.search,
            });
        }
    }, [location]);

    return null;
};

export default RoutingTracker;
