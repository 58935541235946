import { FaTrophy } from "@react-icons/all-files/fa/FaTrophy";
import { Button, InternalLink, Paragraph, Space } from "csrvui";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import useTheme from "../../../../shared/hooks/useCustomTheme";
import EventModal from "../EventModal";
import { EventAlertWrapper } from "./style";

const EventAlert = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const isMobile = window.innerWidth < 600;
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <EventAlertWrapper>
            <Space $wrap={isMobile} $padding={0} $gap={30} $direction="horizontal">
                <FaTrophy
                    style={{
                        minWidth: "80px",
                    }}
                    size={80}
                    color={theme.colors.secondaryText}
                />
                <Space $gap={5} $padding={0}>
                    <Paragraph
                        $level={3}
                        style={{
                            fontWeight: 550,
                            color: theme.colors.secondaryText,
                        }}
                    >
                        {t("marketplace.event_title")}
                    </Paragraph>
                    <Paragraph
                        $level={3}
                        style={{
                            color: theme.colors.secondaryText,
                            maxWidth: "1100px",
                        }}
                    >
                        {t("marketplace.event_desc")}
                    </Paragraph>
                </Space>
            </Space>
            <Space $wrap={isMobile} $oppositeAlign="center" $direction="horizontal" $padding={0}>
                <Button
                    data-ph-capture-attribute-id="event_alert:more_details"
                    onClick={() => {
                        setIsModalOpen(true);
                    }}
                    $fullWidth={isMobile}
                    $sizeVariant="big"
                    $outlined
                    $variant="light"
                >
                    {t("marketplace.event_more_details")}
                </Button>
                <InternalLink
                    data-ph-capture-attribute-id="event_alert:join_now"
                    to="/m/projects/create"
                    $sizeVariant="big"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: theme.colors.white,
                        color: theme.colors.primaryText,
                        padding: "18px 40px",
                        minWidth: "200px",
                    }}
                    $fullWidth={isMobile}
                    $underline={false}
                    $variant="primary"
                    $filled
                >
                    {t("marketplace.event_join_now")}
                </InternalLink>
            </Space>
            <EventModal
                isOpen={isModalOpen}
                onClose={() => {
                    setIsModalOpen(false);
                }}
            />
        </EventAlertWrapper>
    );
};

export default EventAlert;
