import { FaHeart } from "@react-icons/all-files/fa/FaHeart";
import { Paragraph, Space } from "csrvui";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import useTheme from "../../hooks/useCustomTheme";
import { StyledLikeButton } from "./style";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    label: string;
}

const LikeButton = ({ label, ...props }: Props) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const [isLiked, setIsLiked] = useState(localStorage.getItem(label) === "true");
    const isMobile = window.innerWidth < 541;

    const handleSendLike = () => {
        if (isLiked) return;

        localStorage.setItem(label, "true");
        setIsLiked(true);
    };

    return (
        <StyledLikeButton
            data-ph-capture-attribute-id="like_button"
            data-ph-capture-attribute-label={label}
            isLiked={isLiked}
            onClick={handleSendLike}
            {...props}
        >
            <Paragraph
                style={{
                    fontSize: isMobile ? "16px" : "20px",
                    color: theme.colors.primaryBackground,
                    fontWeight: "bold",
                    lineHeight: "20px",
                }}
            >
                {isLiked ? t("common.liked") : t("common.like")}
            </Paragraph>
            <Space style={{ marginBottom: "1px" }} $padding={0}>
                <FaHeart />
            </Space>
        </StyledLikeButton>
    );
};

export default LikeButton;
