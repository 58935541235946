import { IoIosArrowDown } from "@react-icons/all-files/io/IoIosArrowDown";
import { IoIosArrowUp } from "@react-icons/all-files/io/IoIosArrowUp";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useTrackEvent } from "../../../tracking/useTrackEvent";
import { updateGAConsent } from "../../helpers/ga";
import { useCookieConsent } from "../../hooks/useCookieConsent";
import {
    Arrow,
    Background,
    Buttons,
    Container,
    Content,
    Details,
    PrimaryButton,
    SecondaryButton,
    Section,
    Title,
    TitleHeader,
    Toggle,
} from "./style";
import { ConsentSettings, ConsentType } from "./types";

const getConsentCookies = (): Partial<ConsentSettings> => {
    const cookie = document.cookie
        .split("; ")
        .find((row) => row.startsWith("csrv_cookie_consent"))
        ?.split("=")[1];

    if (!cookie) {
        return {};
    }

    try {
        return JSON.parse(decodeURIComponent(cookie)) as ConsentSettings;
    } catch {
        return {};
    }
};

const setConsentCookies = (settings: ConsentSettings) => {
    const expires = new Date();
    const allTrue = Object.values(settings).every((value) => value === true);

    if (allTrue) {
        expires.setFullYear(expires.getFullYear() + 1);
    } else {
        expires.setMonth(expires.getMonth() + 1);
    }

    document.cookie = `csrv_cookie_consent=${encodeURIComponent(JSON.stringify(settings))}; expires=${expires.toUTCString()}; path=/`;
};
const CookiesBanner = () => {
    const { t } = useTranslation();
    const { trackEvent } = useTrackEvent();
    const { alreadyAccepted, setAlreadyAccepted } = useCookieConsent();

    const [settings, setSettings] = useState<ConsentSettings>({
        essential: true,
        ad_storage: false,
        analytics_storage: false,
        ad_user_data: false,
        ad_personalization: false,
    });

    const [expanded, setExpanded] = useState<ConsentSettings>({
        essential: false,
        ad_storage: false,
        analytics_storage: false,
        ad_user_data: false,
        ad_personalization: false,
    });

    const [details, setDetails] = useState(false);

    const toggleSetting = (key: ConsentType) => {
        trackEvent("cookies", "cookie_toggler", "toggle", {
            key,
            value: !settings[key],
        });

        setSettings((prev) => ({
            ...prev,
            [key]: !prev[key],
        }));
    };

    const toggleExpanded = (key: ConsentType) => {
        trackEvent("cookies", "cookie_expander", "toggle", {
            key,
            value: !settings[key],
        });
        setExpanded({ ...expanded, [key]: !expanded[key] });
    };

    const onAcceptAll = () => {
        trackEvent("cookies", "cookies", "accept", {
            variant: "all",
        });

        updateGAConsent({
            ad_storage: "granted",
            analytics_storage: "granted",
            ad_personalization: "granted",
            ad_user_data: "granted",
        });

        setConsentCookies({
            essential: true,
            ad_storage: true,
            analytics_storage: true,
            ad_personalization: true,
            ad_user_data: true,
        });

        setSettings({
            essential: true,
            ad_storage: true,
            analytics_storage: true,
            ad_personalization: true,
            ad_user_data: true,
        });

        setAlreadyAccepted(true);
    };

    const onAcceptOnlyNecessary = () => {
        trackEvent("cookies", "cookies", "accept", {
            variant: "necessary",
        });

        updateGAConsent({
            ad_storage: "denied",
            analytics_storage: "denied",
            ad_personalization: "denied",
            ad_user_data: "denied",
        });

        setConsentCookies({
            essential: true,
            ad_personalization: false,
            ad_storage: false,
            analytics_storage: false,
            ad_user_data: false,
        });

        setSettings({
            essential: true,
            ad_personalization: false,
            ad_storage: false,
            analytics_storage: false,
            ad_user_data: false,
        });

        setAlreadyAccepted(true);
    };

    useEffect(() => {
        const infoFromCookies = getConsentCookies();
        if (Object.keys(infoFromCookies).length === 0) {
            setAlreadyAccepted(false);
            return;
        }

        const { ad_personalization, ad_storage, ad_user_data, analytics_storage } = infoFromCookies;

        updateGAConsent({
            ad_storage: ad_storage ? "granted" : "denied",
            analytics_storage: analytics_storage ? "granted" : "denied",
            ad_personalization: ad_personalization ? "granted" : "denied",
            ad_user_data: ad_user_data ? "granted" : "denied",
        });

        setSettings({
            essential: true,
            ad_storage: ad_storage || false,
            analytics_storage: analytics_storage || false,
            ad_personalization: ad_personalization || false,
            ad_user_data: ad_user_data || false,
        });
    }, [setAlreadyAccepted]);

    if (
        alreadyAccepted ||
        window.location.pathname === "/privacy-policy" ||
        window.location.pathname === "/static/regulamin.pdf"
    ) {
        return null;
    }

    return (
        <Background>
            <Container>
                <h2>{t("cookies.title")}</h2>
                <p>
                    {t("cookies.description")}{" "}
                    <Link target="_blank" to="/privacy-policy">
                        {t("cookies.privacyPolicy")}
                    </Link>{" "}
                    {t("cookies.and")}{" "}
                    <Link target="_blank" to="/static/regulamin.pdf">
                        {t("cookies.terms")}
                    </Link>
                    .
                </p>
                {details && (
                    <Details>
                        <Section onClick={() => toggleExpanded("essential")}>
                            <Title>
                                <TitleHeader>
                                    <Arrow>
                                        {expanded.essential ? (
                                            <IoIosArrowUp width={24} />
                                        ) : (
                                            <IoIosArrowDown width={24} />
                                        )}
                                    </Arrow>
                                    {t("cookies.essentialCookies")}
                                    <Toggle
                                        disabled
                                        active={settings.essential}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            toggleSetting("essential");
                                        }}
                                    />
                                </TitleHeader>
                                <Content onClick={(e) => e.stopPropagation()} visible={expanded.essential}>
                                    {t("cookies.essentialCookiesDescription")}
                                </Content>
                            </Title>
                        </Section>
                        <Section onClick={() => toggleExpanded("analytics_storage")}>
                            <Title>
                                <TitleHeader>
                                    <Arrow>
                                        {expanded.analytics_storage ? (
                                            <IoIosArrowUp width={24} />
                                        ) : (
                                            <IoIosArrowDown width={24} />
                                        )}
                                    </Arrow>
                                    {t("cookies.analyticsCookies")}
                                    <Toggle
                                        active={settings.analytics_storage}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            toggleSetting("analytics_storage");
                                        }}
                                    />
                                </TitleHeader>
                                <Content onClick={(e) => e.stopPropagation()} visible={expanded.analytics_storage}>
                                    {t("cookies.analyticsCookiesDescription")}
                                </Content>
                            </Title>
                        </Section>
                        <Section onClick={() => toggleExpanded("ad_storage")}>
                            <Title>
                                <TitleHeader>
                                    <Arrow>
                                        {expanded.ad_storage ? (
                                            <IoIosArrowUp width={24} />
                                        ) : (
                                            <IoIosArrowDown width={24} />
                                        )}
                                    </Arrow>
                                    {t("cookies.adCookies")}
                                    <Toggle
                                        active={settings.ad_storage}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            toggleSetting("ad_storage");
                                            toggleSetting("ad_personalization");
                                            toggleSetting("ad_user_data");
                                        }}
                                    />
                                </TitleHeader>
                                <Content onClick={(e) => e.stopPropagation()} visible={expanded.ad_storage}>
                                    {t("cookies.adCookiesDescription")}
                                </Content>
                            </Title>
                        </Section>
                    </Details>
                )}

                <Buttons>
                    <PrimaryButton type="button" onClick={onAcceptAll}>
                        {t("cookies.acceptAll")}
                    </PrimaryButton>
                    {!details && (
                        <SecondaryButton type="button" onClick={() => setDetails(true)}>
                            {t("cookies.letMeChoose")}
                        </SecondaryButton>
                    )}
                    {details && (
                        <SecondaryButton type="button" onClick={() => onAcceptOnlyNecessary()}>
                            {t("cookies.onlyNecessary")}
                        </SecondaryButton>
                    )}
                    {details && (
                        <SecondaryButton
                            type="button"
                            onClick={() => {
                                setDetails(false);
                                setConsentCookies(settings);
                                updateGAConsent({
                                    ad_storage: settings.ad_storage ? "granted" : "denied",
                                    analytics_storage: settings.analytics_storage ? "granted" : "denied",
                                    ad_personalization: settings.ad_personalization ? "granted" : "denied",
                                    ad_user_data: settings.ad_user_data ? "granted" : "denied",
                                });
                                setAlreadyAccepted(true);
                            }}
                            style={{
                                marginLeft: "auto",
                            }}
                        >
                            {t("cookies.saveSettings")}
                        </SecondaryButton>
                    )}
                </Buttons>
            </Container>
        </Background>
    );
};

export default CookiesBanner;
