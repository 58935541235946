import { array, Infer, object } from "superstruct";
import { Transaction } from "../../shared/types";
import { Domain, ServerWithParameters } from "../Server/types";
import { User } from "../User/types";

export interface AdminSearchOptions {
    q: string;
}

export const AdminSearchResponse = object({
    users: array(User),
    servers: array(ServerWithParameters),
    transactions: array(Transaction),
    domains: array(Domain),
});

export type AdminSearchResponse = Infer<typeof AdminSearchResponse>;
