import { useMemo, useState } from "react";
import { CookieConsentContext } from "../../hooks/CookieConsentContext";

interface Props {
    children: JSX.Element;
}

export const CookieConsentProvider: React.FC<Props> = ({ children }) => {
    const [alreadyAccepted, setAlreadyAccepted] = useState(true);

    const value = useMemo(
        () => ({
            alreadyAccepted,
            setAlreadyAccepted,
        }),
        [alreadyAccepted, setAlreadyAccepted]
    );

    return <CookieConsentContext.Provider value={value}>{children}</CookieConsentContext.Provider>;
};
