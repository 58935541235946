import { ServerWithParameters } from "../../Server/types";
import { User } from "../types";

export const hasUserCapability = (user: User, capability: string) => {
    const fields = capability.split(".");
    let value = false;
    fields.forEach((f) => {
        const sliced = fields.slice(0, fields.indexOf(f) + 1).join(".");
        if (user.user_capabilities[sliced]) {
            value = user.user_capabilities[sliced];
        }
    });

    return value;
};

export const hasResourceUserCapability = (server: ServerWithParameters, capability: string) => {
    const fields = capability.split(".");
    let value = false;
    fields.forEach((f) => {
        const sliced = fields.slice(0, fields.indexOf(f) + 1).join(".");
        if (server.user_capabilities[sliced]) {
            value = Boolean(server.user_capabilities[sliced]);
        }
    });

    return value;
};
