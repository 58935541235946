import styled from "styled-components";

export const StyledCard = styled.div`
    width: 440px;
    border-radius: 12px;
    background: linear-gradient(269.7deg, #316c2d 10.81%, #1c5718 74.35%);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 22px;
    padding-right: 22px;
    padding-top: 18px;
    align-items: flex-start;
    z-index: 2;

    @media (max-width: 540px) {
        width: 100%;
        height: auto;
        padding: 0;
        padding-top: 8px;
    }
`;
