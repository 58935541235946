import { FiMenu } from "@react-icons/all-files/fi/FiMenu";
import { IoMdArrowDropdown } from "@react-icons/all-files/io/IoMdArrowDropdown";
import { IoMdArrowDropup } from "@react-icons/all-files/io/IoMdArrowDropup";

import { Button, InternalLink, Space } from "csrvui";
import { posthog } from "posthog-js";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Logo from "../../../assets/images/logo.png";
import { useLogoutMutation } from "../../../modules/User/api";
import useVerify from "../../../modules/User/hooks/useVerify";
import { showApiError } from "../../helpers/showToast";
import useCustomTheme from "../../hooks/useCustomTheme";
import { VisualLinkMode } from "../../types";
import LanguageSelect from "../LanguageSelect";

import { routeAlertSelector } from "../../../modules/Server/slices/selectors";
import { getTaxInfo } from "../../../modules/User/slices/selectors";
import useGRPCProcessesContext from "../../hooks/useGRPCProcessesContext";
import RouteChangeAlertModal from "../RouteChangeAlertModal";
import VatChip from "../VatChip";
import {
    DropdownButton,
    DropdownContainer,
    DropdownContent,
    HeaderButtonsWrapper,
    HeaderLinks,
    MobileRoutes,
    StyledHeader,
    StyledHeaderReference,
    StyledHeaderReferences,
    StyledInternalLink,
    StyledLogo,
    StyledLogoHeading,
} from "./style";

const Header: React.FC = () => {
    const [isMenuOpened, setIsMenuOpened] = useState(false);
    const { t } = useTranslation();
    const theme = useCustomTheme();
    const { isLoggedIn, verifyUser } = useVerify();
    const { vat_rate } = useSelector(getTaxInfo);
    const [logout] = useLogoutMutation();
    const [showChangeRouteAlertModal, setShowChangeRouteAlertModal] = useState(false);
    const [targetLocation, setTargetLocation] = useState<string | null>(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [overflowRight, setOverflowRight] = useState(false);
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const isAlertEnabled = useSelector(routeAlertSelector);
    const { disconnect } = useGRPCProcessesContext();

    const onLogout = async () => {
        disconnect();
        try {
            await logout().unwrap();
            await verifyUser();
            posthog.reset(true);

            window.location.reload();
        } catch (e) {
            showApiError(e);
        }
    };

    const toggleDropdown = () => {
        setIsDropdownOpen((prev) => !prev);
    };

    const preventRouteChange = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (isAlertEnabled) {
            e.preventDefault();
            const pendingLocation = e.currentTarget.getAttribute("href");
            setTargetLocation(pendingLocation);
            setShowChangeRouteAlertModal(true);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node) && isDropdownOpen) {
                setIsDropdownOpen(false);
            }
        };

        if (isDropdownOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isDropdownOpen]);

    useEffect(() => {
        if (isDropdownOpen && dropdownRef.current) {
            const dropdownRect = dropdownRef.current.getBoundingClientRect();
            const viewportWidth = window.innerWidth;

            if (dropdownRect.right > viewportWidth) {
                setOverflowRight(true);
            } else {
                setOverflowRight(false);
            }
        }
    }, [isDropdownOpen]);

    const routes = (
        <>
            <InternalLink
                style={{ height: "41px" }}
                $underline={false}
                onClick={preventRouteChange}
                $variant="white"
                $sizeVariant="big"
                to="/#offers"
            >
                {t("common.offer")}
            </InternalLink>
            <InternalLink
                style={{ height: "41px" }}
                onClick={preventRouteChange}
                $underline={false}
                $variant="white"
                $sizeVariant="big"
                to="/m"
            >
                {t("routes.marketplace")}
            </InternalLink>
            <InternalLink
                onClick={preventRouteChange}
                $underline={false}
                $variant="white"
                style={{ height: "41px" }}
                $sizeVariant="big"
                to="/contact"
            >
                {t("routes.contact")}
            </InternalLink>

            <InternalLink
                onClick={preventRouteChange}
                $underline={false}
                $variant="white"
                style={{ height: "41px" }}
                $sizeVariant="big"
                to="/voucher/use"
            >
                {t("routes.voucher_use")}
            </InternalLink>
        </>
    );

    return (
        <StyledHeader>
            <Space
                $direction="horizontal"
                $align="space-between"
                $oppositeAlign="center"
                $fullHeight
                style={{ width: "100%", maxWidth: 1850, margin: "0 auto" }}
                $wrap={false}
            >
                <Space $direction="horizontal" $align="start" $oppositeAlign="end" $gap={0} $padding={0}>
                    <StyledLogoHeading $level={1}>
                        <InternalLink onClick={preventRouteChange} $inline title={t("home_screen.home")} to="/">
                            <StyledLogo alt="Craftserve logo" src={Logo} />
                        </InternalLink>
                    </StyledLogoHeading>
                    <HeaderLinks>
                        <Space $height="41px" $padding={0} $direction="horizontal" $oppositeAlign="end">
                            {/* routes */}
                            {routes}
                        </Space>
                    </HeaderLinks>
                </Space>
                <StyledHeaderReferences
                    $isMenuOpened={isMenuOpened}
                    $padding={0}
                    $gap={20}
                    $direction="horizontal"
                    $oppositeAlign="center"
                >
                    <MobileRoutes>
                        {/* routes */}
                        {routes}
                    </MobileRoutes>
                    {isLoggedIn ? (
                        <HeaderButtonsWrapper>
                            <StyledInternalLink
                                onClick={preventRouteChange}
                                $inline
                                $underline={false}
                                $sizeVariant="big"
                                $mode={VisualLinkMode.SHOW}
                                $variant="white"
                                $hoverColor={theme.colors.success}
                                to="/account"
                                style={{
                                    borderRadius: "6px",
                                    border: "1px solid white",
                                    padding: "10px 12px",
                                    height: "43px",
                                    textWrap: "nowrap",
                                }}
                            >
                                {t("home_screen.profile")}
                            </StyledInternalLink>
                            <DropdownContainer ref={dropdownRef}>
                                <DropdownButton
                                    style={{
                                        height: "43px",
                                    }}
                                    $inline
                                    $sizeVariant="big"
                                    $variant="white"
                                    onClick={toggleDropdown}
                                >
                                    <Space
                                        $direction="horizontal"
                                        $padding={0}
                                        $gap={2}
                                        $oppositeAlign="center"
                                        $wrap={false}
                                        style={{ maxHeight: "20.69px", marginRight: "-4px" }}
                                    >
                                        {t("header.profile")}

                                        {isDropdownOpen ? (
                                            <IoMdArrowDropup size="20px" />
                                        ) : (
                                            <IoMdArrowDropdown size="20px" />
                                        )}
                                    </Space>
                                </DropdownButton>
                                <DropdownContent isOpen={isDropdownOpen} overflowRight={overflowRight}>
                                    <InternalLink
                                        onClick={preventRouteChange}
                                        $inline
                                        $underline={false}
                                        $sizeVariant="big"
                                        $mode={VisualLinkMode.SHOW}
                                        $variant="primary"
                                        $hoverColor={theme.colors.success}
                                        to="/account/settings"
                                        style={{
                                            borderRadius: "6px",
                                            border: "1px solid white",
                                            padding: "10px 12px",
                                            textWrap: "nowrap",
                                            width: "100%",
                                        }}
                                    >
                                        {t("header.account_settings")}
                                    </InternalLink>
                                    <Button
                                        $sizeVariant="big"
                                        $variant="transparent"
                                        style={{
                                            borderRadius: "6px",
                                            border: "1px solid white",
                                            padding: "10px 10px",
                                            textWrap: "nowrap",
                                            justifyContent: "flex-start",
                                            width: "100%",
                                        }}
                                        onClick={onLogout}
                                    >
                                        {t("header.logout")}
                                    </Button>
                                </DropdownContent>
                            </DropdownContainer>
                            <Space
                                $wrap={false}
                                $align="center"
                                $oppositeAlign="center"
                                $gap={0}
                                $height="100%"
                                $direction="horizontal"
                                $padding={0}
                            >
                                <VatChip style={{ minWidth: "93px" }} vatRate={vat_rate} />
                                <LanguageSelect light />
                            </Space>

                            {/* <CurrencySelect /> */}
                        </HeaderButtonsWrapper>
                    ) : (
                        <HeaderButtonsWrapper>
                            {/* <CurrencySelect /> */}
                            <StyledInternalLink
                                $inline
                                onClick={preventRouteChange}
                                $underline={false}
                                $sizeVariant="big"
                                $variant="white"
                                $hoverColor={theme.colors.success}
                                to="/register"
                                style={{ border: "none", padding: "10px" }}
                            >
                                {t("routes.register")}
                            </StyledInternalLink>
                            <StyledInternalLink
                                $inline
                                onClick={preventRouteChange}
                                $underline={false}
                                $sizeVariant="big"
                                $variant="white"
                                style={{ border: "none", padding: "10px" }}
                                $hoverColor={theme.colors.success}
                                to="/login"
                            >
                                {t("routes.login")}
                            </StyledInternalLink>
                            <Space
                                $align="center"
                                $oppositeAlign="center"
                                $wrap={false}
                                $gap={0}
                                $direction="horizontal"
                                $padding={0}
                            >
                                <VatChip style={{ minWidth: "93px" }} vatRate={vat_rate} />
                                <LanguageSelect light />
                            </Space>
                        </HeaderButtonsWrapper>
                    )}
                </StyledHeaderReferences>
                <StyledHeaderReference className="menu-toggler">
                    <Button
                        $variant="transparent"
                        LeftIcon={<FiMenu color={theme.colors.white} />}
                        onClick={() => setIsMenuOpened(!isMenuOpened)}
                    />
                </StyledHeaderReference>
            </Space>
            {showChangeRouteAlertModal && targetLocation && (
                <RouteChangeAlertModal
                    open={showChangeRouteAlertModal}
                    onClose={() => setShowChangeRouteAlertModal(false)}
                    onConfirm={targetLocation}
                />
            )}
        </StyledHeader>
    );
};

export default Header;
