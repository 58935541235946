/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { UserPermissions } from "../../../shared/types";
import { hasUserCapability } from "../helpers/hasCapability";
import { AuthReducer } from "../types";

const slice = createSlice({
    name: "auth",
    initialState: { user: null, verified: false, error: null, isAdmin: false } as AuthReducer,
    reducers: {
        setAuth: (state, { payload: { user, error } }: PayloadAction<AuthReducer>) => {
            if (!state.verified) {
                state.verified = true;
            }
            if (user !== state.user) {
                if (user) {
                    state.user = user;
                    state.error = null;
                    state.isAdmin = hasUserCapability(user, UserPermissions.SERVERS_MANAGE);
                } else {
                    state.error = error;
                    state.user = null;
                    state.isAdmin = false;
                }
            }
        },
    },
});

export const { setAuth } = slice.actions;

export default slice.reducer;
