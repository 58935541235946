import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getCurrentScope } from "@sentry/browser";
import { array } from "superstruct";
import { HostUrl } from "../../rootTypes";
import { transformToStruct } from "../../shared/helpers/customQuery";
import * as ServerTypes from "./types";

export const clusterApi = createApi({
    reducerPath: "clusters",
    baseQuery: fetchBaseQuery({
        baseUrl: `${HostUrl}/api/clusters`,
        prepareHeaders: (headers) => {
            const transactionId = Math.random().toString(36).substr(2, 9);
            const scope = getCurrentScope();
            if (scope) {
                scope.setTag("transaction_id", transactionId);
            }
            headers.set("X-transation-ID", transactionId);

            return headers;
        },
    }),
    endpoints: (builder) => ({
        availableClusters: builder.query<ServerTypes.Cluster[], void>({
            query: () => ({
                name: "availableClusters",
                url: "",
                method: "GET",
                credentials: "include",
            }),
            transformResponse: (response: ServerTypes.Cluster[]) => {
                return transformToStruct(response, array(ServerTypes.Cluster), "/availableClusters");
            },
        }),
    }),
});

export const { useAvailableClustersQuery } = clusterApi;
