/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

interface RouteAlertSliceState {
    showAlertOnRouteChange: boolean;
}

const initialState: RouteAlertSliceState = {
    showAlertOnRouteChange: false,
};

const routeAlertSlice = createSlice({
    name: "routeAlert",
    initialState,
    reducers: {
        enableRouteAlert(state) {
            state.showAlertOnRouteChange = true;
        },
        disableRouteAlert(state) {
            state.showAlertOnRouteChange = false;
        },
    },
});

export const { enableRouteAlert, disableRouteAlert } = routeAlertSlice.actions;

export default routeAlertSlice.reducer;
