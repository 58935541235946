import { Button, Checkbox, Label, Space, TextInput } from "csrvui";
import { Field, FieldProps, Form, Formik, FormikProps } from "formik";
import { Dispatch, SetStateAction, useRef } from "react";
import { useTranslation } from "react-i18next";
import { bool, object, string } from "yup";
import Accordion from "../../../../shared/components/Accordion";
import { showApiError } from "../../../../shared/helpers/showToast";
import { RedeemServerFormValues } from "../../../Marketplace/types";
import useUserData from "../../hooks/useUserData";

interface Props {
    step?: 0 | 1;
    setStep: Dispatch<SetStateAction<0 | 1>>;
    onEmailSubmit: (values: Omit<RedeemServerFormValues, "verificationCode">) => Promise<void>;
    onVerificationCodeSubmit: (values: RedeemServerFormValues) => Promise<void>;
    column?: boolean;
    children?: React.ReactNode;
}

const RawFastRegisterForm = ({ step, setStep, onEmailSubmit, onVerificationCodeSubmit, column, children }: Props) => {
    const { t } = useTranslation();
    const formikRef = useRef<FormikProps<RedeemServerFormValues>>(null);
    const { user } = useUserData();

    const validationSchema = object().shape({
        email: string().when("step", {
            is: 0,
            then: string()
                .email(t("redeem_server_modal.validation.email"))
                .required(t("redeem_server_modal.validation.email_required")),
        }),
        verificationCode: string().when("step", {
            is: 1,
            then: string().required(t("redeem_server_modal.validation.verification_code")),
        }),
        agreement: bool().oneOf([true], t("redeem_server_modal.validation.tos")),
        eula: bool().oneOf([true], t("redeem_server_modal.validation.tos")),
    });

    const handleCheckAll = async () => {
        if (formikRef.current) {
            const { values, setFieldValue, setFieldTouched } = formikRef.current;
            const isAllChecked = values.agreement && values.eula && values.agreement2;

            const newValue = !isAllChecked;
            await setFieldValue("agreement", newValue);
            await setFieldValue("eula", newValue);
            await setFieldValue("agreement2", newValue);

            setFieldTouched("agreement", true);
            setFieldTouched("eula", true);
            setFieldTouched("agreement2", true);
        }
    };

    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                email: user ? user.email : "",
                verificationCode: "",
                agreement: false,
                agreement2: false,
                eula: false,
                step,
            }}
            validateOnChange
            validationSchema={validationSchema}
            validateOnBlur
            validateOnMount
            onSubmit={async (values) => {
                // TODO handle submit
                switch (step) {
                    case 0:
                        try {
                            await onEmailSubmit(values);
                            if (formikRef.current) {
                                formikRef.current.setFieldValue("step", 1);
                            }
                        } catch (e) {
                            showApiError(e);
                        }
                        break;
                    case 1:
                        onVerificationCodeSubmit(values);
                        break;

                    default:
                        onEmailSubmit(values);
                        break;
                }
            }}
        >
            {({ values, handleSubmit, errors, setFieldValue }) => {
                return (
                    <Form onSubmit={handleSubmit}>
                        {step === 0 && (
                            <Space $gap={15} $padding={0}>
                                <Space $padding={0}>
                                    <Label htmlFor="email">{t("redeem_server_modal.steps.step_1")}</Label>
                                    <Space
                                        $direction="horizontal"
                                        $align="start"
                                        $oppositeAlign="start"
                                        $wrap={false}
                                        $padding={0}
                                    >
                                        <Field id="email" name="email">
                                            {({ field }: FieldProps) => {
                                                return (
                                                    <Space $gap={0} $fullWidth $padding={0}>
                                                        <TextInput
                                                            data-ph-capture-attribute-id="fast_register_form:email_input"
                                                            {...field}
                                                            $fullWidth
                                                            type="text"
                                                            placeholder="Adres email"
                                                            $hasError={!!errors.email}
                                                            error={errors.email}
                                                        />
                                                    </Space>
                                                );
                                            }}
                                        </Field>
                                        {!column && <Button type="submit">{t("common.send")}</Button>}
                                    </Space>
                                </Space>

                                <Space $padding={0} $width="100%">
                                    <Checkbox
                                        id="fast_register_form:check_all_checkbox"
                                        onChange={handleCheckAll}
                                        $error={!!errors.agreement || !!errors.eula}
                                        label={t("redeem_server_modal.check_all")}
                                        checked={values.agreement && values.eula && values.agreement2}
                                    />
                                    <Accordion style={{ marginTop: "10px" }} $title="Wszystkie zgody">
                                        <Space>
                                            <Field id="agreement" name="agreement">
                                                {({ field }: FieldProps) => {
                                                    return (
                                                        <Checkbox
                                                            id="agreement"
                                                            data-ph-capture-attribute-id="fast_register_form:tos_checkbox"
                                                            {...field}
                                                            key={field.value}
                                                            checked={values.agreement}
                                                            $error={!!errors.agreement}
                                                            label={t("redeem_server_modal.tos")}
                                                        />
                                                    );
                                                }}
                                            </Field>
                                            <Field id="eula" name="eula">
                                                {({ field }: FieldProps) => {
                                                    return (
                                                        <Checkbox
                                                            id="eula"
                                                            data-ph-capture-attribute-id="fast_register_form:eula_checkbox"
                                                            {...field}
                                                            key={field.value}
                                                            checked={values.eula}
                                                            label={t("common.eula")}
                                                            $error={!!errors.eula}
                                                        />
                                                    );
                                                }}
                                            </Field>
                                            <Field id="agreement2" name="agreement2">
                                                {({ field }: FieldProps) => {
                                                    return (
                                                        <Checkbox
                                                            id="agreement2"
                                                            data-ph-capture-attribute-id="fast_register_form:newsletter_checkbox"
                                                            {...field}
                                                            key={field.value}
                                                            checked={values.agreement2}
                                                            label={t("redeem_server_modal.newsletter")}
                                                        />
                                                    );
                                                }}
                                            </Field>
                                        </Space>
                                    </Accordion>
                                </Space>
                            </Space>
                        )}
                        {step === 1 && (
                            <Space $padding={0}>
                                <Space $direction="horizontal" $oppositeAlign="center" $padding={0}>
                                    <Label htmlFor="verificationCode">{t("redeem_server_modal.steps.step_2")}</Label>
                                    {!user && (
                                        <Button
                                            data-ph-capture-attribute-id="fast_register_form:change_email_button"
                                            style={{
                                                border: "none",
                                                padding: "0",
                                            }}
                                            type="button"
                                            onClick={() => {
                                                setStep(0);
                                                setFieldValue("step", 0);
                                            }}
                                            $variant="info"
                                            $sizeVariant="small"
                                            $outlined
                                        >
                                            ({t("redeem_server_modal.change_email_button")})
                                        </Button>
                                    )}
                                </Space>
                                <Space $direction="horizontal" $oppositeAlign="start" $wrap={false} $padding={0}>
                                    <Field id="verificationCode" name="verificationCode">
                                        {({ field }: FieldProps) => {
                                            return (
                                                <Space $gap={0} $fullWidth $padding={0}>
                                                    <TextInput
                                                        data-ph-capture-attribute-id="fast_register_form:verification_code_input"
                                                        $fullWidth
                                                        type="text"
                                                        placeholder="Kod weryfikacyjny z wiadomości email"
                                                        $hasError={!!errors.verificationCode}
                                                        error={errors.verificationCode}
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                        }}
                                                    />
                                                </Space>
                                            );
                                        }}
                                    </Field>
                                    {!column && (
                                        <Button
                                            data-ph-capture-attribute-id="fast_register_form:send_code_button"
                                            type="submit"
                                        >
                                            {t("common.send")}
                                        </Button>
                                    )}
                                </Space>
                            </Space>
                        )}
                        <Space
                            style={{ marginTop: "25px" }}
                            $direction="horizontal"
                            $align="center"
                            $oppositeAlign="center"
                            $padding={0}
                        >
                            {column && (
                                <Button
                                    data-ph-capture-attribute-id="fast_register_form:send_button"
                                    style={{
                                        width: "100%",
                                        height: "55px",
                                        fontWeight: 500,
                                        flex: "1",
                                    }}
                                    type="submit"
                                >
                                    {t("common.send")}
                                </Button>
                            )}
                            {children}
                        </Space>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default RawFastRegisterForm;
