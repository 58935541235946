import styled from "styled-components";

export const List = styled.ul<{ center?: boolean }>`
    display: flex;
    gap: 20px;
    width: 100%;
    justify-content: center;
    flex-direction: row-reverse;
    padding: 0;

    @media (max-width: 830px) {
        flex-wrap: wrap;
    }
`;

export const ProductsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, minmax(400px, 1fr));
    justify-items: center;
    gap: 20px;
    align-items: end;
    padding: 30px 30px;
    background-color: ${({ theme }) => theme.colors.primaryBackground}1A;
    border-radius: 8px;

    @media (max-width: 1350px) {
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    }

    @media (max-width: 520px) {
        grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
        padding: 0;
        background-color: transparent;
    }
`;
