import {
    Infer,
    any,
    array,
    boolean,
    date,
    enums,
    integer,
    literal,
    nullable,
    number,
    object,
    optional,
    record,
    string,
    union,
} from "superstruct";

import CustomError from "./CustomError";

export enum VisualLinkMode {
    SHOW = "show",
    HIDE = "hide",
}

export type UserID = string;
export const UserID = string();
export type WalletID = string;
export type Email = string;
export type Language = string;
export type ServerID = string;
export const ServerID = string();
export type ProcessID = string;
export type NotificationID = string;
export type DomainID = string;
export type VoucherUseID = string;
export type EventID = string;
export type ProjectReleaseId = string;
export const ProjectReleaseType = string();
export type ProjectReleaseType = Infer<typeof ProjectReleaseType>;

export type DurationString = string;

export type CreatedAt = Date;
export type DestroyedAt = Date;
export type Newsletter = Date;
export type Expires = Date | null;

export enum StatusCodes {
    SUCCESSFUL_PAYMENT = "SUCCESSFUL_PAYMENT",
    FAILED_PAYMENT = "FAILED_PAYMENT",
}
export enum FetchingStatus {
    INIT = "init",
    LOADING = "loading",
    SUCCESSED = "succeeded",
    FAILED = "failed",
}

export enum Capability {
    CanManageCurrentServer = "server.manage",
    CanCreateMarketplaceProject = "marketplace.project-create",
    IsAdmin = "admin",
    CanManageServer = "server",
    CanManageServersAsAdmin = "admin.server",
    CanManageServerParameters = "admin.server.parameters",
    CanTopupAsAdmin = "admin.payments.topup",
    CanBrowseMarketplaceProjects = "marketplace.project-browse",
    CanEditMarketplaceProject = "marketplace.project-manage.edit",
    CanReadMarketplaceProject = "marketplace.project-manage.read",
    CanCreateProjectWithoutSuffix = "marketplace.project-create-without-suffix",
}

export interface TrackedRoute {
    route: string;
    event: EventAction;
}
interface ApiErrorData {
    error: string;
    event_id?: string;
}

export interface ApiError {
    data: ApiErrorData;
    status: number;
}

export type ApiResponse<DataType> = DataType | ApiErrorData;

export interface FetchDataStore<DataType> {
    data: DataType;
    status: FetchingStatus;
    error: string | null;
}

export interface FiltersType {
    [key: string]: {
        value: string;
        format: "string" | "date";
    };
}

export const EventLog = object({
    id: string(),
    level: number(),
    resource_id: string(),
    resource_type: string(),
    created_at: date(),
    action: string(),
    success: boolean(),
});

export type EventLog = Infer<typeof EventLog>;

export const ResourceType = enums(["user", "server", "SERVER"]);

export const EventLogLevel = enums([10, 20, 30, 40, 50]);

export const EventLogAction = enums([
    "USER_LOGIN",
    "USER_CHANGE_EMAIL",
    "USER_CHANGE_USERNAME",
    "USER_CONFIRM_EMAIL",
    "USER_RESEND_EMAIL",
    "USER_CHANGE_PASSWORD",
    "USER_SOCIAL_CONNECT",
    "USER_RATED_APP",
    "USER_LOGIN_EMAIL",
    "USER_REGISTER_EMAIL",
    "GAME_CRASHED",
    "GAME_STARTED",
    "GAME_STOPPED",
    "GAME_CRASHED_TOO_MANY_TIMES",
    "UPLOADED_FILES",
    "UPLOADED_FILES_FROM_URL",
    "EXTRACTED_ARCHIVE",
    "SERVER_EXPIRED",
    "SERVER_RENEWED",
    "SERVER_PVC_CREATED",
    "SERVER_POD_CREATED",
    "SERVER_POD_DELETED",
    "SERVER_POD_CRASHED",
    "SERVER_POD_UNSCHEDULED",
    "SERVER_DISC_QUOTA_WARNING",
    "SERVER_INVALID_GAME_CONFIGURATION",
    "SERVER_PREPARE",
    "SERVER_MIGRATION",
    "SERVER_NAME_CHANGED",
    "SERVER_OVERVIEWER_GENERATED_MAP",
    "SERVER_OVERVIEWER_FAILED_DURING_GENERATION",
    "SERVER_FORMAT",
    "SERVER_BACKUP",
    "SERVER_RESTORE_BACKUP",
    "SERVER_CHANGE_ENGINE",
    "SERVER_INSTALL_PACKAGE",
    "SERVER_UNINSTALL_PACKAGE",
    "SERVER_BACKUP_DELETION",
    "SERVER_PACKAGE_CREATE",
    "SERVICE_UPGRADE",
]);

export const EventLogWithoutData = object({
    id: string(), // values.EventLogId
    level: EventLogLevel, // values.EventLogLevel
    resource_id: string(), // idiota.Id
    resource_type: ResourceType, // values.ResourceType
    created_at: date(), // time.Time
    action: EventLogAction, // values.EventLogAction
    success: boolean(), // bool
});

export const GetEventLogsResponse = object({
    data: array(EventLogWithoutData),
    count: number(),
});

export type GetEventLogsResponse = Infer<typeof GetEventLogsResponse>;

export type WalletLogId = string;

export const WalletLogAdminDetails = object({
    admin_id: string(),
    cause: string(),
    server_expires: nullable(date()),
    duration: nullable(string()),
});

export type WalletLogAdminDetails = Infer<typeof WalletLogAdminDetails>;

export const WalletLogUserDetails = object({
    transaction_id: string(),
    server_expires: optional(date()),
});

export type WalletLogUserDetails = Infer<typeof WalletLogUserDetails>;

export const WalletLogVoucherDetails = object({
    user_id: string(),
    voucher_id: string(),
});

export type WalletLogVoucherDetails = Infer<typeof WalletLogVoucherDetails>;

export const WalletLogCause = enums(["DEPOSIT", "PAYMENT", "ITEMSHOP_SELL", "RENEW_SERVICE", "VOUCHER_USE"]);

export const WalletLog = object({
    id: string(), // values.WalletLogId
    wallet_id: string(), // values.WalletId
    pair_id: string(), // values.WalletLogPairId
    ts: date(), // time.Time
    amount: number(), // int64
    currency: string(), // monies.CurrencyCode
    cause: WalletLogCause, // values.WalletLogCause
    details: record(string(), any()), // json.RawMessage
    completed: nullable(date()), // *time.Time
});

export type WalletLog = Infer<typeof WalletLog>;

export const PaymentHistory = object({
    wallet_log: WalletLog,
    provider: string(),
    transaction_id: nullable(string()),
    status: nullable(string()),
});

export type PaymentHistory = Infer<typeof PaymentHistory>;

export const EventDetails = object({
    id: string(),
    level: EventLogLevel,
    resource_id: string(),
    resource_type: ResourceType,
    created_at: date(),
    action: EventLogAction,
    data: union([
        object({
            last_logs: string(),
            error: optional(string()),
        }),
        object({
            file: string(),
            error: optional(string()),
        }),
        object({
            cause: string(),
            stdout: string(),
            charge: boolean(),
            duration: string(),
            is_admin: boolean(),
            user_id: string(),
            wallet_log_id: string(),
            error: optional(string()),
        }),
        object({
            crash_count: string(),
            error: optional(string()),
        }),
        object({
            transaction_id: string(),
            error: optional(string()),
        }),
        object({
            traceId: string(),
            error: optional(string()),
        }),
        object({
            package: string(),
            error: optional(string()),
        }),
        object({
            files: array(string()),
        }),
    ]),
    success: boolean(),
});

export type EventDetails = Infer<typeof EventDetails>;

export enum EventAction {
    CREATE = "create",
    UPDATE = "update",
    DELETE = "delete",
    CONFIRM_EMAIL = "confirm_email",
    NAVIGATE_TO_MARKET_PROJECT_REVIEWS = "navigate_to_market_project_reviews",
    NAVIGATE_TO_EDIT_MARKET_PROJECT = "navigate_to_edit_market_project",
    CREATE_NEW_PORT = "create_new_port",
    CLOSE_FTP_DETAILS = "close_ftp_details",
    CLOSE_CONFIRMATION_MODAL = "close_confirmation_modal",
    SHOW_OUT_OF_RESOURCES_MODAL = "show_out_of_resources_modal",
    HELP_CENTER_QUICK_ACCESS_BUTTON_CLICK = "help_center_quick_access_button_click",
    NEW_FILE = "new_file",
    INSTALL_BUTTON_CLICK = "install_button_click",
    TOGGLE_ACCORDION = "toggle_accordion",
    SELECT_PORT_PROTOCOL = "select_port_protocol",
    NEW_FOLDER = "new_folder",
    CLOSE_STOP_SERVER_MODAL = "close_stop_server_modal",
    DRAG_FILES_ENTER = "drag_files_enter",
    SORT_FILES = "sort_files",
    DELETE_FILE = "delete_file",
    RESTORE_DRAFT = "restore_draft",
    OPEN_SEARCH_IN_FILE = "open_search_in_file",
    SAVE_FILE = "save_file",
    SAVE_DRAFT = "save_draft",
    SELECT_FILE = "select_file",
    REJECT_DRAFT = "reject_draft",
    RENAME_FILE = "rename_file",
    UNZIP_FILE = "unzip_file",
    EMPTY_TRASH = "empty_trash",
    REVERT_FILE = "revert_file",
    DOWNLOAD_FOLDER = "download_folder",
    DOWNLOAD_FILE = "download_file",
    UPLOAD_FROM_URL = "upload_from_url",
    OPEN_FOLDER = "open_folder",
    DROP_FILE = "drop_file",
    SUBMIT_EMAIL = "submit_email",
    UPLOAD_FILE = "upload_files",
    UPLOAD_FOLDER = "upload_folder",
    COLLAPSE_ALL = "collapse_all",
    CANCEL_REGISTRATION = "cancel_registration",
    SELECT_PAYMENT_METHOD = "select_payment_method",
    GET_FTP_DETAILS = "get_ftp_details",
    REMOVE_PORT = "remove_port",
    OPEN_FILE = "open_file",
    CLOSE_FILE = "close_file",
    RELEASE_SERVER_PORT = "release_server_port",
    UPDATE_SERVER_PORTS = "update_server_ports",
    SELECT_FILES_TO_KEEP = "select_files_to_keep",
    DELETE_VOUCHER = "delete_voucher",
    SUBMIT_VERIFICATION_CODE = "submit_verification_code",
    CLOSE_INSTALL_PROJECT_MODAL = "close_install_project_modal",
    SELECT_FILES_TO_UPLOAD = "select_files_to_upload",
    TOGGLE_ADVANCED_OPTIONS = "toggle_advanced_options",
    OPEN_NEW_DEPENDENCY_MODAL = "open_new_dependency_modal",
    ADD_DEPENDENCY = "add_dependency",
    MARKET_REDEEM_FREE_SERVER_MODAL_CLOSE = "market_redeem_free_server_modal_close",
    INSTALL_BUTTON_NAVIGATE_TO_CREATE_SERVER = "install_button_navigate_to_create_server",
    UPLOAD_RELEASE_FILES_CHANGE_SERVER = "upload_release_files_change_server",
    SELECT_REQUIRE_EMPTY_WORKSPACE = "select_require_empty_workspace",
    CREATE_NEW_RELEASE_SUCCESS = "create_new_release_success",
    SELECT_RELEASE_TYPE = "select_release_type",
    CREATE_NEW_RELEASE_FAILURE = "create_new_release_failure",
    SHOW_INSTALLER = "show_installer",
    SORT_RELEASES = "sort_releases",
    SHOW_MARKET_PROJECT_DESCRIPTION = "show_market_project_description",
    NAVIGATE_TO_CREATE_NEW_RELEASE = "navigate_to_create_new_release",
    DELETE_RELEASE = "delete_release",
    INSTALL_RELEASE_SUCCESS = "install_release_success",
    NAVIGATE_BACK_FROM_RELEASE_DETAILS = "navigate_back_from_release_details",
    OPEN_STOP_SERVER_MODAL = "open_stop_server_modal",
    MARKET_PROJECT_GALLERY_DRAG_END = "market_project_gallery_drag_end",
    MARKET_PROJECT_GALLERY_DRAG_START = "market_project_gallery_drag_start",
    INSTALL_PROJECT = "install_project",
    CLICK_PROJECT_GALLERY_IMAGE = "click_project_gallery_image",
    STOP_SERVER_AND_INSTALL_RELEASE = "stop_server_and_install_release",
    FORMAT = "format",
    MARKET_PROJECT_TAB_CHANGE = "market_project_tab_change",
    CREATE_REVIEW = "create_review",
    MARKET_PROJECT_LOAD_MORE_REVIEWS = "market_project_load_more_reviews",
    ACCORDION_TOGGLE = "accordion_toggle",
    OPEN_ARTIFACT_MODAL = "open_artifact_modal",
    UPDATE_ICON = "update_icon",
    SELECT_SERVER_TO_INSTALL_PROJECT = "select_server_to_install_project",
    CREATE_ICON = "create_icon",
    COPY_ARTIFACT_JSON = "copy_artifact_json",
    DOWNLOAD_ARTIFACT = "download_artifact",
    OPEN_REDEEM_FREE_SERVER_MODAL = "open_redeem_free_server_modal",
    SUBMIT_CREATE_PROJECT_FORM = "submit_create_project_form",
    TOGGLE_JOIN_EVENT = "toggle_join_event",
    FAST_AUTH_BEGIN = "fast_auth_begin",
    TOGGLE_DISABLE_MARKET_PROJECT_PREFIX = "toggle_disable_market_project_prefix",
    ENTER_PROJECT_NAME = "enter_project_name",
    FAST_AUTH_COMPLETE = "fast_auth_complete",
    SELECT_PROJECT_VISIBILITY = "select_project_visibility",
    SELECT_PROJECT_CATEGORY = "select_project_category",
    SUBMIT_EDIT_PROJECT_FORM = "submit_edit_project_form",
    TOGGLE_ARCHIVED = "toggle_archived",
    OPEN_EVENT_MODAL = "open_event_modal",
    SELECT_PROJECT_GAME_TYPE = "select_project_game_type",
    UPDATE_GALLERY_IMAGE = "update_gallery_image",
    DELETE_GALLERY_IMAGE = "delete_gallery_image",
    CLOSE_EVENT_MODAL = "close_event_modal",
    UPLOAD_PROJECT_ASSETS = "upload_project_assets",
    FOLLOW_PROJECT = "follow_project",
    UNFOLLOW_PROJECT = "unfollow_project",
    RAW_LOGIN_FORM_SUBMIT = "raw_login_form_submit",
    VERIFY_VOUCHER = "verify_voucher",
    RENEW_SERVER = "renew_server",
    CHECK_ALL = "check_all",
    LOGIN = "login",
    SUBMIT_VOUCHER = "submit_voucher",
    CREATE_MARKET_PROJECT = "create_market_project",
    VIEW_MORE_INFO = "view_more_info",
    LOGOUT = "logout",
    NAVIGATE_TO_RENEW_SERVER = "navigate_to_renew_server",
    CLOSE_RENEW_SERVER_MODAL = "close_renew_server_modal",
    OPEN_RENEW_SERVER_MODAL = "open_renew_server_modal",
    TOGGLE_ANTIBOT = "toggle_antibot",
    CLOSE_RECHARGE_WALLET_MODAL = "close_recharge_wallet_modal",
    NAVIGATE_TO_RECHARGE_WALLET = "navigate_to_recharge_wallet",
    OPEN_FORMAT_MODAL = "open_format_modal",
    CLOSE_FORMAT_MODAL = "close_format_modal",
    OPEN_RECHARGE_WALLET_MODAL = "open_recharge_wallet_modal",
    NAVIGATE_TO_MARKET_PROJECT_FORM = "navigate_to_market_project_form",
    MARKETPLACE_SEARCH = "marketplace_search",
    LIKE = "like",
    NAVIGATE_TO_CRAFTUM_FORM = "navigate_to_craftum_form",
    UPGRADE_SERVER = "upgrade_server",
    DOWNGRADE_SERVER = "downgrade_server",
    MARKETPLACE_CARD_CLICK = "marketplace_card_click",
    REGISTER = "register",
    FAST_REGISTER_BEGIN = "fast_register_begin",
    FAST_REGISTER_COMPLETE = "fast_register_complete",
    UPGRADE_SERVER_CLOSE_MODAL = "upgrade_server_close_modal",
    FORGOT_PASSWORD = "forgot_password",
    RESET_PASSWORD_BEGIN = "reset_password_begin",
    RESET_PASSWORD_COMPLETE = "reset_password_COMPLETE",
    CHANGE_PASSWORD = "change_password",
    UPGRADE_SERVER_CHANGE_BILLING_PERIOD = "upgrade_server_change_billing_period",
    UNINSTALL_PACKAGE = "uninstall_package",
    OPEN_UPGRADE_SERVER_MODAL = "open_upgrade_server_modal",
    CHANGE_EMAIL = "change_email",
    CHANGE_LANGUAGE = "change_language",
    CREATE_NEW_RELEASE = "create_new_release",
    CHANGE_CURRENCY = "change_currency",
    CHANGE_THEME = "change_theme",
    CREATE_SERVER_PREVIOUS_STEP = "create_server_previous_step",
    PAYU_PAYMENT = "payu_payment",
    BLIK_PAYMENT = "blik_payment",
    SELECT_ENGINE_FROM_ENGINES_LIST = "select_engine_from_engines_list",
    SUBMIT_PRIMARY_DOMAIN = "submit_primary_domain",
    PAYPAL_PAYMENT = "paypal_payment",
    CHECK_DOMAIN = "check_domain",
    INSTALL_PROJECT_INSTALLER = "install_project_installer",
    MANUAL_STOP_SERVER = "manual_stop_server",

    MANUAL_START_SERVER = "manual_start_server",
    RECHARGE_WALLET = "recharge_wallet",
    CRAFTUM_PAYMENT = "craftum_payment",
    PAY_FOR_SERVER = "pay_for_server",
    CHANGE_DOMAIN = "change_domain",
    ADD_VOUCHER_FROM_PAY_FOR_SERVER = "add_voucher_from_pay_for_server",
    ADD_VOUCHER_FROM_PAYMENTS_SCREEN = "add_voucher_from_payments_screen",
    ADD_VOUCHER_IN_SERVER_CREATOR = "add_voucher_in_server_creator",
    BAN_PLAYER = "ban_player",
    OPEN_UNSCHEDULED_MODAL = "open_unscheduled_modal",
    CLOSE_UNSCHEDULED_MODAL = "close_unscheduled_modal",
    MIGRATE_SERVER = "migrate_server",
    NAVIGATE_TO_CONTACT = "navigate_to_contact",
    ADD_TO_WHITELIST = "add_to_whitelist",
    CLOSE_SUPPORT_FORM = "close_support_form",
    NAVIGATE_TO_SHORTCUT = "navigate_to_shortcut",
    ADD_TO_OP = "add_to_op",
    CLICK_REDEEM_FREE_SERVER_BUTTON = "click_redeem_free_server_button",
    RESTORE_BACKUP = "restore_backup",
    SELECT_SERVER = "select_server",
    RESET_PASSWORD_CONFIRMED = "reset_password_confirmed",
    DELETE_BACKUP = "delete_backup",
    RUN_MANUAL_BACKUP = "run_manual_backup",
    OPEN_SUPPORT_FORM = "open_support_form",
    CHOOSE_PLAN = "choose_plan",
    COPY_SNAPSHOT_ID = "copy_snapshot_id",
    REDEEM_FREE_SERVER = "redeem_free_server",
    CHANGE_BILLING_PERIOD = "change_billing_period",
    PAY_BY_WALLET_PAYMENT = "pay_by_wallet_payment",
    UPDATE_INVOICE_DATA = "update_invoice_data",
    UPDATE_TRANSACTION = "update_transaction",
    GET_TRANSACTION = "get_transaction",
    CREATE_MANUAL_BACKUP = "create_manual_backup",
    DELETE_MANUAL_BACKUP = "delete_manual_backup",
    DOWNLOAD_MANUAL_BACKUP = "download_manual_backup",
    RESTORE_MANUAL_BACKUP = "restore_manual_backup",
    NAVIGATE_TO_PRODUCT_FORM = "navigate_to_product_form",
    SERVER_CREATION_INTERRUPTED = "server_creation_interrupted",
    CREATE_SERVER = "create_server",
    VISIT_ROUTE = "visit_route",
    INSTALL_RELEASE = "install_release",
    INSTALL_NEWEST_VERSION_CLICKED = "install_newest_version_clicked",
}

export enum UserPermissions {
    ADMIN = "admin",
    SERVER = "server",
    SERVERS_MANAGE = "server.manage",
    SERVER_READ = "server.manage.read",
    MARKETPLACE_SERVER_PACKAGES = "server.packages",
    CRAFTUM = "user.craftum",
    PAYPAL = "user.payments.paypal",
    PAYU = "user.payments.payu",
    BLIK = "user.payments.blik",
    OFFERS = "user.offers",
    USERS_READ = "users.read",
}

type ExpressionType =
    | "Compound"
    | "Identifier"
    | "MemberExpression"
    | "Literal"
    | "ThisExpression"
    | "CallExpression"
    | "UnaryExpression"
    | "BinaryExpression"
    | "LogicalExpression"
    | "ConditionalExpression"
    | "ArrayExpression";

export interface Expression {
    type: ExpressionType;
}

export interface Literal extends Expression {
    type: "Literal";
    value: boolean | number | string;
    raw: string;
}
export interface BinaryExpression extends Expression {
    type: "BinaryExpression";
    operator: string;
    left: Literal | BinaryExpression;
    right: Literal | BinaryExpression;
}

export interface BaseQueryError {
    status: number;
    data: CustomError;
}

export const DomainTarget = object({
    app: string(),
    record_name: string(),
    record_type: string(),
    dns_content: string(),
    mode: nullable(string()),
});

export const Domain = object({
    id: number(), // values.DomainId
    server_id: string(), // values.BillingServiceId
    user_id: string(), // values.UserID
    kind: string(), // values.DomainKind
    fqdn: string(), // string
    sub_domain: nullable(string()), // *string
    zone_name: string(), // string
    external_ns: nullable(string()), // *string
    targets: array(DomainTarget), // []values.DomainTarget
    updated_at: string(), // time.Time
    created_at: string(), // time.Time
    expires: nullable(string()), // *time.Time
});
export type Domain = Infer<typeof Domain>;

export const CountryTaxRate = object({
    vat_rate: number(),
    country_code: string(),
});

export type CountryTaxRate = Infer<typeof CountryTaxRate>;

export enum TransactionStatus {
    DRAFT = "DRAFT",
    NEW = "NEW",
    PROCESSING = "PROCESSING",
    READY_TO_CAPTURE = "READY_TO_CAPTURE",
    COMPLETED = "COMPLETED",
    FAILED = "FAILED",
    CANCELLED = "CANCELLED",
    ADMIN_ACTION = "ADMIN_ACTION",
}

export const StructTransactionStatus = union([
    literal(TransactionStatus.DRAFT),
    literal(TransactionStatus.NEW),
    literal(TransactionStatus.PROCESSING),
    literal(TransactionStatus.READY_TO_CAPTURE),
    literal(TransactionStatus.COMPLETED),
    literal(TransactionStatus.FAILED),
    literal(TransactionStatus.CANCELLED),
]);

export enum TransactionAction {
    RENEW_SERVICE = "RENEW_SERVICE",
    UPGRADE_SERVICE = "UPGRADE_SERVICE",
    DEPOSIT = "DEPOSIT",
    CRAFTUM = "CRAFTUM",
    RENEW_SERVER = "RENEW_SERVER",
    VOUCHER_USE = "VOUCHER_USE",
}

export const StructTransactionAction = union([
    literal(TransactionAction.RENEW_SERVICE),
    literal(TransactionAction.DEPOSIT),
    literal(TransactionAction.CRAFTUM),
]);

export const TransactionPosition = object({
    name: optional(string()),
    quantity: optional(number()),
    tax: optional(number()),
    total_price_gross: optional(number()),
});

export const Transaction = object({
    id: string(),
    status: StructTransactionStatus,
    action: StructTransactionAction,
    positions: optional(array(TransactionPosition)),
    invoiceid: nullable(number()),
    ts: date(),
    amount: number(),
    currency: string(),
    country_tax_rate: CountryTaxRate,
    wallet_id: string(),
});

export type Transaction = Infer<typeof Transaction>;

export const TransactionDetails = object({
    status: StructTransactionStatus,
});

export type TransactionDetails = Infer<typeof TransactionDetails>;

export const Coupon = object({
    name: string(),
    expression: optional(string()),
    add_hours_on_renewal: optional(nullable(string())),
    end_time: nullable(date()),
    uses_left: nullable(integer()),
});

export type Coupon = Infer<typeof Coupon>;

/*
type AppliedCoupon struct {
	Coupon
	UsesLeft *int        `json:"uses_left"`
	Details  interface{} `json:"details"`
}
*/

export const AppliedCoupon = object({
    name: string(),
    expression: optional(string()),
    add_hours_on_renewal: optional(nullable(string())),
    end_time: nullable(date()),
    uses_left: nullable(integer()),
    details: object(),
});

export type AppliedCoupon = Infer<typeof AppliedCoupon>;

// filters as object
export type QueryParamsObject = Record<string, string | number | boolean | undefined>;

export interface NPSModalFormValues {
    rate: number | null;
    comment: string;
    causes: { label: string; value: string }[];
}
