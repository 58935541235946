import { Space } from "csrvui";
import Skeleton from "react-loading-skeleton";
import useTheme from "../../../../shared/hooks/useCustomTheme";
import InstallButton from "../InstallButton";

const MarketplaceProjectRowSkeleton = () => {
    const isSmallScreen = window.innerWidth < 900;
    const isMobile = window.innerWidth < 450;
    const theme = useTheme();

    return (
        <Space
            $background={theme.colors.secondaryBackground}
            $fullWidth
            $direction="horizontal"
            $padding="8px 20px"
            $align="space-between"
            $oppositeAlign="center"
            style={{
                borderRadius: "8px",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
            }}
            $wrap={isSmallScreen}
        >
            <Space $wrap={false} $direction="horizontal" $padding={0}>
                {!isSmallScreen && (
                    // image
                    <Skeleton height={100} width={100} />
                )}
                <Space $align="start">
                    <Space $direction="horizontal" $oppositeAlign="center" style={{ textAlign: "start" }} $padding={0}>
                        {/* name */}
                        <Skeleton height={25} width={200} />
                    </Space>
                    {/* desc */}
                    <Skeleton height={18} width={280} />
                    <Space
                        style={{ minHeight: "35px" }}
                        $padding={0}
                        $direction="horizontal"
                        $align="start"
                        $oppositeAlign="start"
                        $fullWidth
                    >
                        {/* labels */}
                        <Skeleton height={32} width={70} />
                        <Skeleton height={32} width={70} />
                    </Space>
                </Space>
            </Space>
            <Space $fullWidth={isMobile} $padding={isSmallScreen ? "0 10px 10px 10px" : 0}>
                <InstallButton
                    style={{
                        cursor: "progress",
                    }}
                    onShowInstaller={() => {}}
                    disabled
                    $fullWidth={isMobile}
                />
            </Space>
        </Space>
    );
};

export default MarketplaceProjectRowSkeleton;
