import { CgSmileSad } from "@react-icons/all-files/cg/CgSmileSad";
import { FaRegMeh } from "@react-icons/all-files/fa/FaRegMeh";
import { FaRegSadTear } from "@react-icons/all-files/fa/FaRegSadTear";
import { FaRegSmile } from "@react-icons/all-files/fa/FaRegSmile";
import { IoMdHappy } from "@react-icons/all-files/io/IoMdHappy";
import { InternalLink, Paragraph, Space } from "csrvui";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useTheme from "../../../../shared/hooks/useCustomTheme";
import { GetMarketProjectResponse } from "../../types";

interface Props {
    project: GetMarketProjectResponse;
    onClick?: () => void;
}

const Rating = ({ project, onClick }: Props) => {
    const negative = project.negative_reviews || 0;
    const positive = project.positive_reviews || 0;

    const totalRating = negative + positive;
    const positiveRating = (positive / totalRating) * 100;
    const { serverIdentity } = useParams();
    const { t } = useTranslation();

    const reviewsTabLink = serverIdentity
        ? `/m/${serverIdentity}/${project.slug}?tab=reviews`
        : `/m/${project.slug}?tab=reviews`;

    const theme = useTheme();

    if (totalRating === 0) {
        return (
            <Space
                style={{
                    opacity: 0.7,
                    backgroundColor: theme.colors.primaryBackground,
                    padding: "8px 15px",
                    borderRadius: "8px",
                    color: theme.colors.white,
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.2)",
                }}
                $direction="horizontal"
                $align="center"
                $oppositeAlign="center"
                $padding={0}
            >
                <Paragraph style={{ fontWeight: 550, color: theme.colors.dark }}>
                    {t("marketplace.ratings.no_ratings")}
                </Paragraph>{" "}
            </Space>
        );
    }

    const getRating = () => {
        switch (true) {
            case positiveRating < 20:
                return {
                    icon: <FaRegSadTear />,
                    text: t("marketplace.ratings.overwhelmingly_negative"),
                    color: theme.colors.danger,
                };
            case positiveRating < 40:
                return {
                    icon: (
                        <Space $padding={0} style={{ marginTop: "-3px" }}>
                            <CgSmileSad size={21} />
                        </Space>
                    ),
                    text: t("marketplace.ratings.mostly_negative"),
                    color: theme.colors.warning,
                };
            case positiveRating < 60:
                return {
                    icon: (
                        <Space $padding={0} style={{ marginTop: "-1px" }}>
                            <FaRegMeh size={18} />
                        </Space>
                    ),
                    text: t("marketplace.ratings.mixed"),
                    color: theme.colors.info,
                };
            case positiveRating < 80:
                return {
                    icon: <FaRegSmile />,
                    text: t("marketplace.ratings.mostly_positive"),
                    color: theme.colors.success,
                };
            default:
                return {
                    icon: (
                        <Space $padding={0} style={{ marginTop: "-3px" }}>
                            <IoMdHappy size={22} />
                        </Space>
                    ),
                    text: t("marketplace.ratings.overwhelmingly_positive"),
                    color: theme.colors.success,
                };
        }
    };

    const { icon, text, color } = getRating();

    return (
        <InternalLink
            data-ph-capture-attribute-id="rating:review_tab_link"
            data-ph-capture-attribute-review-tab-link={reviewsTabLink}
            style={{
                padding: 0,
                textDecoration: "none",
            }}
            onClick={onClick}
            to={reviewsTabLink}
        >
            <Space
                style={{
                    backgroundColor: color,
                    padding: "8px 15px",
                    borderRadius: "8px",
                    color: theme.colors.white,
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.2)",
                }}
                $direction="horizontal"
                $align="center"
                $oppositeAlign="center"
                $padding={0}
            >
                {icon} <Paragraph style={{ fontWeight: 550, color: theme.colors.white }}>{text}</Paragraph>{" "}
                <Paragraph style={{ color: theme.colors.white, fontWeight: 550 }}>({totalRating})</Paragraph>
            </Space>
        </InternalLink>
    );
};

export default Rating;
