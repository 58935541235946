import styled from "styled-components";
import background_image from "../../../../assets/images/event_alert_bg.svg";
import background_image_success from "../../../../assets/images/event_alert_success_bg.svg";

export const EventAlertWrapper = styled.div<{ variant?: "default" | "success" }>`
    display: flex;
    justify-content: space-between;
    background-image: url(${(props) => (props.variant === "success" ? background_image_success : background_image)});
    background-size: cover;
    background-position: center;
    border-radius: 8px;
    padding: 30px;

    border-radius: 8px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    margin-bottom: 20px;
    transition: transform 0.2s ease-in-out;
    gap: 20px;

    @media (max-width: 1000px) {
        flex-direction: column;
    }
`;
