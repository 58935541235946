const standarizeUrl = (
    inputUrl?: string,
    options?: {
        width?: number;
        quality?: number;
        placeholder?: string;
    }
) => {
    const width = options?.width || 1920;
    const quality = options?.quality || 100;
    const format = /^\/cdn-cgi\/image\/width=\d+,quality=\d+/;

    if (!inputUrl) {
        return `https://placehold.co/${width}x${width}?text=${options?.placeholder || "CSRV"}`;
    }

    // Obsługa względnych ścieżek
    let url: URL;
    try {
        url = new URL(inputUrl, import.meta.url); // Obsłuży względne ścieżki względem modułu
    } catch {
        url = new URL(inputUrl, window.location.origin); // Obsłuży pełne ścieżki w przeglądarce
    }

    if (
        process.env.NODE_ENV === "development" ||
        format.test(url.pathname) ||
        window.location.hostname.includes("craftserve.ovh")
    ) {
        return url.toString();
    }

    const standardizedPath = `/cdn-cgi/image/width=${width},quality=${quality}${url.pathname}`;
    url.pathname = standardizedPath;

    return url.toString();
};

export default standarizeUrl;
