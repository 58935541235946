import { Money } from "ts-money";
import { BillingProduct } from "../../Billings/types";

const getRawPriceForProduct = ({ prices }: BillingProduct, localCurrency: string) => {
    const hasPrices = prices && prices.length > 0;

    const cheapestPrice =
        hasPrices &&
        [...prices]
            .filter((price) => price.gross_price.currency === localCurrency)
            .sort((a, b) => a.gross_price.amount - b.gross_price.amount)[0];

    return cheapestPrice ? cheapestPrice.gross_price : new Money(0, localCurrency);
};

export default getRawPriceForProduct;
