import styled from "styled-components";

interface StyledCardProps {
    variant?: "image" | "description";
}

export const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(400px, 900px));
    grid-gap: 20px 50px;
    justify-content: center;

    @media (max-width: 1150px) {
        grid-template-columns: 1fr;
        align-items: start;
    }
`;

export const StyledImage = styled.img`
    width: 100%;
    height: auto;
    border-radius: 12px;
    // aspect-ratio: 1.57 / 1;
    // aspect-ratio: 300 / 191;
    aspect-ratio: 753 / 479;
    maxheight: 573px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
`;

export const StyledCard = styled.div<StyledCardProps>`
    border-radius: 12px;
    background: linear-gradient(269.7deg, #316c2d 10.81%, #1c5718 74.35%);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    height: 100%;
    flex-direction: row;
    position: relative;
    justify-content: center;
    padding: 20px;
    align-items: center;
    overflow: hidden;

    @media (max-width: 1000px) {
        padding: 10px;
    }
`;
