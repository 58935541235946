import {
    Infer,
    any,
    array,
    boolean,
    date,
    enums,
    instance,
    integer,
    nullable,
    number,
    object,
    optional,
    record,
    string,
    union,
} from "superstruct";
import { Money } from "ts-money";
import { Coupon, FetchDataStore, ServerID, UserID, WalletLog } from "../../shared/types";
import { BillingProduct, BillingService, GameServerProductGameParameter } from "../Billings/types";
import { InstallProjectPayload } from "../Marketplace/types";
import { FilesTypes } from "./modules/Files";

export enum PaymentType {
    PayByWallet = "pay_by_wallet",
    Payu = "payu",
    Paypal = "paypal",
    PayLater = "pay_later",
    Blik = "blik",
}

export interface ServerStats {
    tcp_conns: number;
    cpu_limit: number;
    cpu_used: number;
    memory_current: number;
    memory_limit: number | undefined;
    time: number;
}

export interface InstallPackagePayload extends InstallProjectPayload {
    package: string;
}

export interface ChangeEngineFormValues {
    selectAll: boolean;
    backup: boolean;
    clearData: boolean;
    keepFiles: boolean;
    keepFilesPaths: string[];
}

export interface ProcessFileData {
    files: string[];
    path: string;
    size: number;
    user_id: UserID;
}

export interface CurrentServerStateFragment {
    target: FetchDataStore<Server | null>;
    quota: FetchDataStore<ServerQuota>;
    paymentsHistory: FetchDataStore<WalletLog[]>;
    ststs: ServerStats[];
    consoleLogs: string[];
    files: FilesTypes.Paths;
}

export type SettlementMethod = "monthly" | "3-month";
export type ServerType = "grass" | "diamond";
export type ServerFeatures = { value: string; available: boolean }[];
export type ServerDifficultyLevel = "survival" | "creative" | "adventure";
export type ServerGameMode = "peaceful" | "easy" | "normal" | "hard";
export type ServerEngine = "paper" | "vanilla" | "fabric" | "forge";
export type MapOptionsType = {
    mapType: "standard" | "flat" | "largeBiomes";
    nether: boolean;
    pvp: boolean;
    animals: boolean;
    commandBlocks: boolean;
    npcsVillages: boolean;
    flying: boolean;
    npc: boolean;
    monsters: boolean;
};

export type ClusterID = string;

export const UserCapabilities = object({
    user_id: string(),
    resource_id: nullable(string()),
    resource_type: string(),
    capabilities: record(string(), any()),
});

export type UserCapabilities = Infer<typeof UserCapabilities>;

export const DomainTarget = object({
    app: string(),
    record_name: string(),
    record_type: string(),
    dns_content: string(),
    mode: nullable(string()),
});

export type DomainTarget = Infer<typeof DomainTarget>;

export const BillingProductPrice = object({
    cluster_id: string(),
    price: instance(Money),
    period: string(),
});

export const BillingServiceUpgradablesResponse = object({
    product_id: string(),
    billing_periods: array(BillingProductPrice),
    upgrade_price: nullable(instance(Money)),
});

export type BillingServiceUpgradablesResponse = Infer<typeof BillingServiceUpgradablesResponse>;

export const UpgradeServiceResponse = object({ create_transaction: boolean() });

export type UpgradeServiceResponse = Infer<typeof UpgradeServiceResponse>;

export interface UpgradeServicePayload {
    upgrade_to: string | null;
    next_period: boolean;
    upgrade_billing_period: string | null;
}

export const Domain = object({
    id: number(), // values.DomainId
    server_id: string(), // values.BillingServiceId
    user_id: string(), // values.UserID
    kind: string(), // values.DomainKind
    fqdn: string(),
    sub_domain: nullable(string()),

    zone_name: string(),
    external_ns: nullable(string()),

    dpl_uid: nullable(string()),
    dpl_request_id: nullable(number()),

    targets: array(DomainTarget),

    updated_at: date(),
    created_at: date(),
    expires: nullable(date()),
});

export type Domain = Infer<typeof Domain>;

export const NodePort = object({
    port: number(),
    type: string(),
    cluster_id: string(),
    target_port: number(),
    service_id: string(),
    name: string(),
    protocol: string(),
    created_by: nullable(string()),
    updated_at: date(),
});

export type NodePort = Infer<typeof NodePort>;

export const ReleasePortPayload = object({ name: string() });

export type ReleasePortPayload = Infer<typeof ReleasePortPayload>;

export const GameServerProductParameters = object({
    allowed_packages: optional(array(string())),
    cleanup_after: optional(string()),
    cpu: optional(
        object({
            daemon: number(),
            limit: string(),
            request: string(),
        })
    ),
    game: optional(GameServerProductGameParameter),

    memory: optional(
        object({
            limit: optional(string()),
            request: optional(string()),
            game_request: optional(string()),
        })
    ),
    secondary_domains: optional(number()),
    image: optional(string()),
    storage: optional(number()),
    user_parameters: optional(record(string(), any())),
});

export type GameServerProductParameters = Infer<typeof GameServerProductParameters>;

export const PortsParameter = object({
    name: string(),
    container_port: number(),
    protocol: string(),
});

export type PortsParameter = Infer<typeof PortsParameter>;

export const Server = object({
    id: string(),
    cluster_id: string(),
    persistent_volume: nullable(string()),
    cleanup_date: nullable(date()),
    autostart: boolean(),
    disabled: boolean(),
    hidden: boolean(),
    testing: boolean(),
    name: string(),
    referrer: nullable(string()),
    sponsored: boolean(),
    auto_renew: boolean(),
    deleted_at: nullable(date()),
    last_node: nullable(string()),
});
export type Server = Infer<typeof Server>;

export const ServerWithParameters = object({
    id: string(), // values.BillingServiceId
    pod: nullable(string()), // *string
    cluster_id: string(), // values.ClusterId
    persistent_volume: nullable(string()), // *string
    cleanup_date: nullable(date()), // *time.Time
    autostart: boolean(), // bool
    disabled: boolean(), // bool
    hidden: boolean(), // bool
    testing: boolean(), // bool
    name: string(), // string
    referrer: nullable(string()), // *string
    sponsored: boolean(), // bool
    auto_renew: boolean(), // bool
    deleted_at: nullable(date()), // *time.Time
    last_node: nullable(string()), // *string
    active: boolean(), // bool
    parameters: GameServerProductParameters,
    user_capabilities: record(string(), union([number(), boolean(), string()])), // map[values.CapabilityType]any
    domains: array(Domain), // []entities.Domain
    cluster_ports: array(NodePort), // []entities.ClusterPort
    can_migrate: boolean(), // bool
});
export type ServerWithParameters = Infer<typeof ServerWithParameters>;

export const Cluster = object({
    id: string(), // values.ClusterId
    visible: boolean(), // bool
    disabled: boolean(), // bool
    host: string(), // string
    zone_name: string(), // string
    metadata: record(string(), any()), // json.RawMessage
});
export type Cluster = Infer<typeof Cluster>;

export const ServerQuota = object({
    type: number(), // int64
    bsize: number(), // int64
    blocks: number(), // uint64
    bfree: number(), // uint64
    bavail: number(), // uint64
    files: number(), // uint64
    ffree: number(), // uint64
    flags: number(), // int64
});

export type ServerQuota = Infer<typeof ServerQuota>;
export interface MinecraftUserParameters {
    engine: string;
}

export const BillingServicesWithCost = object({
    cost: instance(Money), // monies.Money
    services: array(BillingService), // []PublicBillingService
});

export const RequestedBillingServices = object({
    name: string(),
    user_parameters: nullable(record(string(), any())),
});
export type RequestedBillingServices = Infer<typeof RequestedBillingServices>;

export interface DomainPayload {
    subdomain: string;
    zone_name: string;
}

export interface CreateServerPayload {
    game: string;
    cluster_id: ClusterID;
    requested_billing_services: RequestedBillingServices[];
    billing_period: string;
    currency: string;
    domain: DomainPayload;
    vouchers: string[];
    terms: boolean;
    testing_server: boolean;
}

export interface PayForServerPayload {
    serverId: ServerID;
    vouchers: string[];
}

export enum VoucherType {
    ADD_DAYS = "ADD_DAYS",
    ADD_FUNDS = "ADD_FUNDS",
    DISCOUNT = "DISCOUNT",
}

export enum InstallEngineStatus {
    IDLE = "IDLE",
    INSTALLING = "INSTALLING",
    ERROR = "ERROR",
    COMPLETE = "COMPLETE",
}

export interface VoucherAddFundsData {
    values: {
        [currency: string]: Money;
    };
}

export interface VoucherAddDaysData {
    value: string;
}

export interface VoucherCreateServerData {
    billing_period: string;
    cluster_id: string;
    products: {
        product_id: string;
        value: string;
        user_parameters: MinecraftUserParameters;
    }[];
}

export const VoucherTarget = object({
    product_id: string(),
    billing_period: string(),
    cluster_id: string(),
    user_parameters: optional(record(string(), any())),
});

export type VoucherTarget = Infer<typeof VoucherTarget>;

export const ChangeEnginePayload = object({
    engine: string(),
    keep_files: array(string()),
});

export type ChangeEnginePayload = Infer<typeof ChangeEnginePayload>;

export const ChangeEngineResponse = object({
    process_id: string(),
});

export type ChangeEngineResponse = Infer<typeof ChangeEngineResponse>;

export const VoucherAction = object({
    targets: optional(array(VoucherTarget)),
    add_hours: optional(string()),
    new_cost: optional(instance(Money)),
    coupon: optional(Coupon),
    wallet_tx: optional(record(string(), instance(Money))),
});
export type VoucherAction = Infer<typeof VoucherAction>;

export type BillingServicesWithCost = Infer<typeof BillingServicesWithCost>;

export const Voucher = object({
    id: string(), // string
    created_at: date(), // time.Time
    expires: nullable(date()), // *time.Time
    last_use_id: nullable(number()), // *int
    group_id: nullable(string()), // *string
    uses_left: nullable(number()), // *int
    per_user: nullable(number()), // *int
    data: array(VoucherAction), // []entities.VoucherAction
});
export type Voucher = Infer<typeof Voucher>;

export interface CreateServerFromVoucherPayload {
    name: string;
    voucher_id: string;
    currency: string;
    domain: {
        subdomain: string;
        zone_name: string;
    };
}

export const VoucherUse = object({
    id: string(),
    ts: date(),
    user_id: string(),
    voucher_id: string(),
    details: object(),
});
export type VoucherUse = Infer<typeof VoucherUse>;

export const VoucherUseWithVoucher = object({
    id: string(),
    ts: date(),
    user_id: string(),
    voucher_id: string(),
    details: object(),
    voucher: Voucher,
});
export type VoucherUseWithVoucher = Infer<typeof VoucherUseWithVoucher>;

export const AllocatePortPayload = object({
    name: string(),
    protocol: string(),
});

export type AllocatePortPayload = Infer<typeof AllocatePortPayload>;

export const AcquirePortResponse = object({
    name: string(), // string
    protocol: string(), // v1.Protocol
    port: number(), // int
});

export type AcquirePortResponse = Infer<typeof AcquirePortResponse>;

export interface VoucherProfit {
    id: string;
    data: VoucherAction[];
    error?: string;
}

export interface Log {
    location: string;
    type: string;
    description: string;
    createdAt: Date;
}

export interface LogDetails {
    id: number;
    level: number;
    resource_id: string;
    resource_type: string;
    created_at: Date;
    action: string;
    data: string;
    success: boolean;
}

export interface BenchmarkStat {
    memory: number;
    cpu_used: number;
    cpu_limit: number;
    time: number;
    memory_limit: number;
}

export interface AddVoucherPayload {
    voucher: string;
}

export interface SetSecondaryDomainPayload {
    subdomain: string;
    zone_name: string;
}

export interface CheckAvailabilityDomainPayload {
    subdomain: string;
    zone_name: string;
}

export interface InstalledEngine {
    name: string;
    version: string;
    labels: Record<string, string>;
    depends: [];
    downloadfiles: [];
    manifest: {
        name: string;
        version: string;
    };
    fileshash: string;
    provides: [];
    subdir: string;
    tags: string[];
}

export const PackageManifest = object({
    manifestVersion: string(), // ManifestVersion
    manifestHash: string(), // ManifestHash
    name: string(), // string
    subdir: string(), // string
    version: string(), // string
    versionFormat: string(), // string
    depends: array(
        object({
            name: string(), // PackageSelector
            tag: string(), // PackageSelector
        })
    ),
    provides: array(string()),
    setEnv: array(
        object({
            key: string(),
            value: string(),
        })
    ), // SetEnvSlice
    labels: object({
        BUILD_TIME: string(),
        GAME: string(),
        JAVA_VERSION: string(),
        MINECRAFT_JAVA_VERSION: string(),
        MOD_LOADER: string(),
        TYPE: string(),
    }), // map[string]string
    filesHash: string(), // FilesHash
    downloadFiles: array(
        object({
            name: string(), // DownloadFileInfo
            url: string(), // DownloadFileInfo
            hash: string(), // DownloadFileInfo
        })
    ),
});

export type PackageManifest = Infer<typeof PackageManifest>;

export const PublicMarketProjectMedia = object({
    name: string(),
    url: string(),
});

export type PublicMarketProjectMedia = Infer<typeof PublicMarketProjectMedia>;

export interface EngineWithMarketplaceData extends PackageManifest {
    media: PublicMarketProjectMedia[];
    priority: number;
    short_description: string;
}

export const MinecraftEnginesMap = array(PackageManifest);
export type MinecraftEnginesMap = Infer<typeof MinecraftEnginesMap>;

const TotalPrice = object({
    cluster_id: string(),
    price: instance(Money),
    period: string(),
});
type TotalPrice = Infer<typeof TotalPrice>;

export const Offer = object({
    id: string(),
    total_price: array(TotalPrice),
});

export type Offer = Infer<typeof Offer>;

export interface MinecraftServerProperties {
    "enable-jmx-monitoring": boolean;
    "rcon.port": number;
    "level-seed": string;
    gamemode: ServerDifficultyLevel;
    "enable-command-block": boolean;
    "enable-query": boolean;
    "generator-settings": object;
    "enforce-secure-profile": boolean;
    "level-name": string;
    motd: string;
    "query.port": number;
    pvp: boolean;
    "generate-structures": boolean;
    "max-chained-neighbor-updates": number;
    difficulty: "peaceful" | "easy" | "normal" | "hard";
    "network-compression-threshold": number;
    "max-tick-time": number;
    "require-resource-pack": boolean;
    "use-native-transport": boolean;
    "max-players": number;
    "online-mode": boolean;
    "enable-status": boolean;
    "allow-flight": boolean;
    "broadcast-rcon-to-ops": boolean;
    "view-distance": number;
    "server-name": string;
    "server-ip": string;
    "resource-pack-prompt": string;
    "allow-nether": boolean;
    "server-port": number;
    "enable-rcon": boolean;
    "sync-chunk-writes": boolean;
    "op-permission-level": number;
    "prevent-proxy-connections": boolean;
    "hide-online-players": boolean;
    "resource-pack": string;
    "entity-broadcast-range-percentage": number;
    "simulation-distance": number;
    "rcon.password": string;
    "player-idle-timeout": number;
    debug: boolean;
    "force-gamemode": boolean;
    "rate-limit": number;
    hardcore: boolean;
    "white-list": boolean;
    "broadcast-console-to-ops": boolean;
    "spawn-npcs": boolean;
    "previews-chat": boolean;
    "spawn-animals": boolean;
    "function-permission-level": 2;
    "level-type": "minecraft:normal" | string;
    "text-filtering-config": string;
    "spawn-monsters": boolean;
    "enforce-whitelist": boolean;
    "spawn-protection": number;
    "resource-pack-sha1": string;
    "max-world-size": 29999984;
}

export type SetFieldValueType = (field: string, value: unknown, shouldValidate?: boolean | undefined) => void;

export const MinecraftVersionType = enums(["release", "snapshot"]);

export const MinecraftVersions = object({
    id: string(),
    type: MinecraftVersionType, // MinecraftVersionType
    url: string(),
    time: date(), // time.Time
    releaseTime: date(), // time.Time
});

export type MinecraftVersions = Infer<typeof MinecraftVersions>;

export const SearchGamesResponse = object({
    "minecraft-java": object({
        latest: object({
            release: string(),
            snapshot: string(),
        }),
        versions: array(MinecraftVersions), // []minecraft.MinecraftVersion
    }),
});

export type SearchGamesResponse = Infer<typeof SearchGamesResponse>;

export const CreateServerResponse = object({
    server: ServerWithParameters, // ServerWithParameters
    billing_services: array(BillingService), // []PublicBillingService
});

export type CreateServerResponse = Infer<typeof CreateServerResponse>;

export type UserTicketsResponse = Array<{
    id: number;
    url: string;
    subject: string;
    updated_at: string;
    status: string;
    created_at: string;
}>;

export interface MigratePayload {
    serverId: ServerID;
    cause: string;
    fallbackBackupId?: string | null;
    // admin
    force?: boolean;
    target?: string;
}

export interface MinecraftDaemonData {
    data: MinecraftDaemonMessage[];
    stats?: ServerStats[];
    bytes: number;
    totalBytes?: number;
}

export const MinecraftDaemonMessage = object({
    time: string(),
    log: string(),
});

export type MinecraftDaemonMessage = Infer<typeof MinecraftDaemonMessage>;

export const ConsoleHistoryResponse = object({
    msg: string(),
    bytes: number(),
    stream: string(),
});

export type ConsoleHistoryResponse = Infer<typeof ConsoleHistoryResponse>;

export const ReadConsoleFragmentResponse = object({
    offset: number(),
    length: number(),
    totalBytes: number(),
    data: string(),
});

export type PointerToastType = "success" | "error" | "loading" | "blank" | "custom";

export interface PointerToast {
    id: string;
    text: string;
    type: PointerToastType;
}

export const GenerateRemotefsTokenResponse = object({
    token: string(),
});

export type GenerateRemotefsTokenResponse = Infer<typeof GenerateRemotefsTokenResponse>;
export type ReadConsoleFragmentResponse = Infer<typeof ReadConsoleFragmentResponse>;

export const GetConsoleFragmentResponse = object({
    offset: integer(),
    length: integer(),
    totalBytes: integer(),
    data: string(),
});

export type GetConsoleFragmentResponse = Infer<typeof GetConsoleFragmentResponse>;

export interface Memory {
    limit?: string;
    request?: string;
}

export interface CPU {
    daemon?: number;
    limit?: string;
    request?: string;
}

export interface AdminPayload extends Partial<AdminSettingsFormValues> {
    memory?: Memory;
    cpu?: CPU;
    game?: {
        autoinstall?: boolean;
    };
}

export interface AdminSettingsFormValues {
    sleep: boolean;
    image: string | undefined;
    storage: number;
    autoinstall: boolean;
    memoryGameLimitSuffix: string;
    memoryGameRequest: number;
    memoryGameRequestSuffix: string;
    limitSuffix: string;
    requestSuffix: string;
    memoryPodLimit: number;
    memoryPodLimitSuffix: string;
    cpuDaemon: number;
    limit: number;
    request: number;
    cpuLimit: number;
    cpuLimitSuffix: string;
}

export interface CreateServerFormValues {
    billing_period: string;
    cluster: string;
    game?: string;
    engine?: string;
    vouchersProfits: VoucherProfit[];
    product: BillingProduct;
    additionalProducts: BillingProduct[];
    paymentMethod: PaymentType;
    eulaAccepted: boolean;
    regulationsAccepted: boolean;
    subdomain: string;
    zone_name: string;
}

export interface CreateCraftumFormValues {
    product: BillingProduct;
    regulationsAccepted: boolean;
    csrv_server_id: string;
}

export interface AutostartMutationPayload {
    serverId: ServerID;
    hardRestart?: boolean;
}
