import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { useEffect, useMemo, useRef } from "react";
import { HelmetProvider } from "react-helmet-async";
import { Provider, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "styled-components";
import "./i18n/i18n";
import PointerInfo from "./modules/Server/modules/Files/components/FileTreeExplorer/components/FileTreeExplorerItem/components/PointerInfo";
import { getPointerToasts } from "./modules/Server/slices/selectors";
import RootRouter from "./rootRouter";
import { store } from "./rootStore";
import ErrorBoundary from "./shared/components/ErrorBoundary";
import { GlobalStyles } from "./shared/components/GlobalStyles";
import debug from "./shared/helpers/debug";
import { getDebugEnabled, setDebugEnabled } from "./shared/helpers/localStorage";
import { SidebarContext } from "./shared/hooks/SidebarContext";
import useDarkMode from "./shared/hooks/useDarkMode";
import useSidebar from "./shared/hooks/useSidebar";

const PointerToasts = () => {
    const pointerToasts = useSelector(getPointerToasts);

    return <PointerInfo info={pointerToasts} />;
};

function App() {
    const { sidebarMode, toggleSidebar, hideSidebar, subsidebarMode, setSubsidebar } = useSidebar();
    const { theme, mode } = useDarkMode();
    const toastRef = useRef(null);

    useEffect(() => {
        const initPosthog = async () => {
            if (window.location.hostname !== "localhost" && window.location.hostname !== "craftserve.ovh") {
                posthog.init("phc_m5UB34l7oYtIkzXIZ3zKLjOM3hrydGd0swf2jlS33nB", {
                    ui_host: "https://eu.i.posthog.com",
                    api_host: "https://wieprz.craftserve.com",
                    person_profiles: "always", // or 'always' to create profiles for anonymous users as well
                    capture_pageview: false,
                });
            }
        };

        initPosthog();

        const debugEnabled = getDebugEnabled();
        if (!debugEnabled) setDebugEnabled(false);

        debug("Init");
    }, []);

    const sidebarContextValue = useMemo(() => {
        return {
            sidebarMode,
            toggleSidebar,
            hideSidebar,
            subsidebar: subsidebarMode,
            setSubsidebar,
        };
    }, [sidebarMode, toggleSidebar, hideSidebar, subsidebarMode, setSubsidebar]);

    return (
        <HelmetProvider>
            <ErrorBoundary>
                <Provider store={store}>
                    <PostHogProvider client={posthog}>
                        <ThemeProvider theme={theme}>
                            <SidebarContext.Provider value={sidebarContextValue}>
                                <GlobalStyles />

                                <RootRouter />
                                <ToastContainer
                                    ref={toastRef}
                                    theme={mode}
                                    style={{
                                        wordBreak: "break-word",
                                    }}
                                    hideProgressBar
                                    pauseOnHover
                                    autoClose={5000}
                                    closeOnClick={false}
                                    draggable={false}
                                    position="top-left"
                                />
                                <PointerToasts />
                            </SidebarContext.Provider>
                        </ThemeProvider>
                    </PostHogProvider>
                </Provider>
            </ErrorBoundary>
        </HelmetProvider>
    );
}

export default App;
