import { Button } from "csrvui";
import { SpaceProps } from "csrvui/dist/esm/components/Space";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useTheme from "../../../../shared/hooks/useCustomTheme";
import { Tab } from "../../types";
import { SwitchTabBackground, SwitchWrapper } from "./style";

interface Props extends Omit<SpaceProps, "onChange"> {
    tabs: Tab[];
    onChange: (tab: Tab) => void;
    tab: string;
    switchProps?: SpaceProps;
    selectedColor?: string;
    variant?: "small" | "default";
}

const SwitchTab = ({ onChange, tabs, tab, selectedColor, switchProps, variant, ...props }: Props) => {
    const theme = useTheme();
    const tabRefs = useRef<(HTMLDivElement | null)[]>([]);
    const currentTabRef = useMemo(() => tabRefs.current.find((t) => t && t.id === tab), [tabRefs, tab]);
    const [xPos, setXPos] = useState((currentTabRef && currentTabRef.offsetLeft) || 0);
    const [yPos, setYPos] = useState((currentTabRef && currentTabRef.offsetTop) || 0);
    const [width, setWidth] = useState((currentTabRef && currentTabRef.offsetWidth) || 0);

    useEffect(() => {
        const currentTabRef = tabRefs.current.find((t) => t && t.id === tab);
        setXPos((currentTabRef && currentTabRef.offsetLeft) || 0);
        setYPos((currentTabRef && currentTabRef.offsetTop) || 0);
        setWidth((currentTabRef && currentTabRef.offsetWidth) || 0);
    }, [tab]);

    const handleChange = useCallback(
        (index: number, tab: Tab) => {
            const tabButton = tabRefs.current[index];
            if (tabButton) {
                setXPos(tabButton.offsetLeft);
                setWidth(tabButton.offsetWidth);
                setYPos(tabButton.offsetTop);
            }
            onChange(tab);
        },
        [onChange]
    );

    return (
        <SwitchWrapper
            {...props}
            style={{
                backgroundColor: theme.colors.primaryBackground,
                ...props.style,
            }}
        >
            <SwitchTabBackground variant={variant} top={yPos} width={width} left={xPos} {...switchProps} />
            {tabs.map((el, index) => (
                <div
                    id={el.id}
                    key={el.id}
                    style={{ zIndex: 1 }}
                    ref={(element) => {
                        tabRefs.current[index] = element;
                    }}
                >
                    <Button
                        data-ph-capture-attribute-id={`switch_tab:button_${el.label}`}
                        $variant="transparent"
                        style={{
                            padding: variant === "small" ? "5px" : "10px",
                            fontWeight: 550,
                            color: tab === el.id ? selectedColor : theme.colors.dark,
                        }}
                        onClick={() => handleChange(index, el)}
                    >
                        {el.label}
                    </Button>
                </div>
            ))}
        </SwitchWrapper>
    );
};

export default SwitchTab;
