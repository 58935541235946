import { CsrvProcessType } from "csrvprocess";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import CustomError from "../../../shared/CustomError";
import { CurrentServerContext } from "../../../shared/components/CurrentServerProvider";
import { emitRedeemTestServerEvent } from "../../../shared/helpers/ga";
import showToast, { showApiError } from "../../../shared/helpers/showToast";
import { ProcessesContext } from "../../../shared/hooks/ProcessesContext";
import useLocalPreferences from "../../../shared/hooks/useLocalPreferences";
import { ServerID, WalletID } from "../../../shared/types";
import { BillingProduct } from "../../Billings/types";
import { useCompletePaypalMutation, useCreateTransactionMutation } from "../../Payments/api";
import { TransactionAction } from "../../Payments/types";
import { useAddVoucherMutation, useRenewServiceMutation } from "../../Services/api";
import useUserData from "../../User/hooks/useUserData";
import { useCreateServerMutation } from "../api";
import { GameType } from "../constants";
import * as ServerTypes from "../types";
import useClusters from "./useClusters";
import useDomains from "./useDomains";

export default function () {
    const { serverIdentity } = useParams();
    const navigate = useNavigate();
    const { refreshCurrentServerData, server, service } = useContext(CurrentServerContext);
    const { t } = useTranslation();
    const { addSimpleMessage } = useContext(ProcessesContext);
    const { resubscribe } = useContext(ProcessesContext);
    const [create] = useCreateServerMutation();
    const [addVoucherToServer] = useAddVoucherMutation();
    const { wallet } = useContext(CurrentServerContext);
    const [renew] = useRenewServiceMutation();
    const [createTransaction] = useCreateTransactionMutation();
    const [completePaypalRequest] = useCompletePaypalMutation();
    const { generateDomain } = useDomains();
    const { updateUserData } = useUserData();
    const { availableClusters } = useClusters();
    const { currency } = useLocalPreferences();

    const payForServer = async (
        serverId: ServerID,
        paymentMethod: ServerTypes.PaymentType,
        walletId: WalletID,
        action?: TransactionAction,
        data?: Record<string, string>
    ) => {
        switch (paymentMethod) {
            case ServerTypes.PaymentType.Payu:
                try {
                    const response = await createTransaction({
                        action: action || TransactionAction.RENEW_SERVICE,
                        payment_details: {},
                        payment_type: paymentMethod,
                        service_id: serverId,
                        data: data || {},
                        wallet_id: walletId,
                    }).unwrap();

                    if (response.redirect_uri) {
                        window.location.href = response.redirect_uri;
                    }

                    resubscribe(serverId);
                    refreshCurrentServerData();
                } catch (err) {
                    showApiError(err);
                }
                break;
            case ServerTypes.PaymentType.Blik:
                try {
                    const response = await createTransaction({
                        action: action || TransactionAction.RENEW_SERVICE,
                        payment_details: {},
                        payment_type: ServerTypes.PaymentType.Payu,
                        service_id: serverId,
                        data: data || {},
                        blik: true,
                        wallet_id: walletId,
                    }).unwrap();

                    if (response.redirect_uri) {
                        window.location.href = response.redirect_uri;
                    }

                    resubscribe(serverId);
                    refreshCurrentServerData();
                } catch (err) {
                    showApiError(err);
                }
                break;
            case ServerTypes.PaymentType.Paypal:
                try {
                    const response = await createTransaction({
                        action: action || TransactionAction.RENEW_SERVICE,
                        payment_details: {},
                        payment_type: paymentMethod,
                        service_id: serverId,
                        wallet_id: walletId,
                        data: data || {},
                    }).unwrap();

                    if (response.redirect_uri) {
                        window.location.href = response.redirect_uri;
                    }

                    resubscribe(serverId);
                    refreshCurrentServerData();
                } catch (err) {
                    showApiError(err);
                }
                break;
            case ServerTypes.PaymentType.PayByWallet:
                try {
                    if (!wallet) {
                        throw new CustomError(t("create_server_form.payment_failed"));
                    }

                    await renew({
                        serviceId: serverId,
                        payload: {
                            duration: service.billing_period,
                            charge: true,
                            cause: "by user",
                            silent: false,
                        },
                    }).unwrap();

                    resubscribe(serverId);
                    await refreshCurrentServerData();

                    navigate(`/s/${serverIdentity}/payments`);
                } catch (err) {
                    showApiError(err);

                    throw err;
                }
                break;
            default:
                resubscribe(serverId);
                await refreshCurrentServerData();
                navigate(`/s/${serverId}`);
                break;
        }
    };

    const createServer = async (
        payload: ServerTypes.CreateServerPayload,
        paymentMethod: ServerTypes.PaymentType,
        skipPayment?: boolean
    ) => {
        try {
            const response = await create(payload).unwrap();
            addSimpleMessage(CsrvProcessType.SERVER_CREATE_STATUS, true);

            if (skipPayment) {
                window.location.href = `/s/${response.server.id}`;
                return;
            }

            payForServer(response.server.id, paymentMethod, response.billing_services[0].wallet_id);
        } catch (e) {
            showApiError(e);

            throw e;
        }
    };

    const redeemFreeServer = async (product: BillingProduct, terms: boolean, parameters?: Record<string, string>) => {
        const cluster = availableClusters && availableClusters[0];
        const locallyRedeemedFreeServer = localStorage.getItem("redeemed_free_server");

        if (!cluster) {
            showToast({
                variant: "error",
                text: t("errors.redeem_free_server_no_clusters"),
            });
            return;
        }

        const generatedDomain = await generateDomain(cluster.zone_name);

        await createServer(
            {
                game: GameType.MINECRAFT_JAVA_EDITION, // TODO: pozniej brac to z produktu>
                cluster_id: cluster.id,
                billing_period: product.prices && product.prices.length > 0 ? product.prices[0].period : "720h",
                requested_billing_services: [
                    {
                        name: product.id,
                        user_parameters: {
                            engine: product.parameters.engine,
                            ...parameters,
                        },
                    },
                ],
                currency,
                domain: {
                    subdomain: generatedDomain.subdomain,
                    zone_name: generatedDomain.zone_name,
                },
                terms,
                vouchers: [],
                testing_server: true,
            },
            ServerTypes.PaymentType.PayLater
        );

        emitRedeemTestServerEvent({
            product_id: product.id,
            package: product.parameters.engine,
        });

        showToast({
            variant: "success",
            text: t("redeem_server_modal.success"),
        });

        updateUserData();

        const redeemedFreeServers = locallyRedeemedFreeServer ? locallyRedeemedFreeServer.split(",") : [];
        redeemedFreeServers.push(product.id);
        localStorage.setItem("redeemed_free_server", redeemedFreeServers.join(","));

        navigate(`/s/${generatedDomain.subdomain}`); // TODO: uzupelnic
    };

    const renewServer = async (payload: ServerTypes.PayForServerPayload, paymentMethod: ServerTypes.PaymentType) => {
        try {
            payload.vouchers.forEach(async (voucher) => {
                await addVoucherToServer({ serverId: server.id, voucher }).unwrap();
            });

            await payForServer(payload.serverId, paymentMethod, wallet.id);
            addSimpleMessage(CsrvProcessType.PAY_FOR_SERVER_STATUS, true);

            setTimeout(() => {
                refreshCurrentServerData();
            }, 500);
        } catch (err) {
            showApiError(err);
        }
    };

    const completePaypal = async (orderId: string) => {
        try {
            const response = await completePaypalRequest(orderId).unwrap();
            if (response.action !== "CRAFTUM") {
                refreshCurrentServerData();
            }
            return response;
        } catch (err) {
            showApiError(err);
            return null;
        }
    };

    return { createServer, payForServer, renewServer, completePaypal, redeemFreeServer };
}
