import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getCurrentScope } from "@sentry/browser";
import { array } from "superstruct";
import { HttpStatus } from "../../constants";
import { HostUrl } from "../../rootTypes";
import { transformToStruct } from "../../shared/helpers/customQuery";
import { ServerID } from "../../shared/types";
import { BillingService } from "../Billings/types";
import { RenewServerPayload } from "../Payments/types";
import { BillingServiceUpgradablesResponse, UpgradeServicePayload, UpgradeServiceResponse } from "../Server/types";

export const api = createApi({
    reducerPath: "services",
    baseQuery: fetchBaseQuery({
        baseUrl: `${HostUrl}/api/billings/services`,
        prepareHeaders: (headers) => {
            const transactionId = Math.random().toString(36).substr(2, 9);
            const scope = getCurrentScope();
            if (scope) {
                scope.setTag("transaction_id", transactionId);
            }
            headers.set("X-transation-ID", transactionId);

            return headers;
        },
    }),
    endpoints: (builder) => ({
        addVoucher: builder.mutation<void, { serverId: ServerID; voucher: string }>({
            query: (payload) => ({
                url: `/${payload.serverId}/voucher`,
                method: "POST",
                body: { voucher: payload.voucher },
                validateStatus: (response) => response.status === HttpStatus.Created,
            }),
        }),
        renewService: builder.mutation<void, { serviceId: ServerID; payload: RenewServerPayload }>({
            query: ({ serviceId, payload }) => ({
                url: `/${serviceId}`,
                method: "POST",
                body: JSON.stringify(payload),
                validateStatus: (response) => response.status === HttpStatus.Created,
            }),
        }),
        getBillingUpgradables: builder.query<BillingServiceUpgradablesResponse[], { serviceId: ServerID }>({
            query: ({ serviceId }) => ({
                url: `/${serviceId}/upgrade`,
                method: "GET",
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
            transformResponse: (response: BillingServiceUpgradablesResponse[]) => {
                return transformToStruct(response, array(BillingServiceUpgradablesResponse), "/upgrade");
            },
        }),
        deleteBillingUpgradables: builder.mutation<BillingServiceUpgradablesResponse[], { serviceId: ServerID }>({
            query: ({ serviceId }) => ({
                url: `/${serviceId}/upgrade/cancel`,
                method: "DELETE",
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
        }),
        upgradeBillingService: builder.mutation<
            UpgradeServiceResponse,
            { serviceId: ServerID; payload: UpgradeServicePayload }
        >({
            query: ({ serviceId, payload }) => ({
                url: `/${serviceId}/upgrade`,
                body: JSON.stringify(payload),
                method: "POST",
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
        }),
        getById: builder.query<BillingService, string>({
            query: (id) => ({
                url: `/${id}`,
                method: "GET",
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
        }),
    }),
});

export const {
    useAddVoucherMutation,
    useRenewServiceMutation,
    useGetByIdQuery,
    useLazyGetByIdQuery,
    useGetBillingUpgradablesQuery,
    useDeleteBillingUpgradablesMutation,
    useUpgradeBillingServiceMutation,
} = api;
