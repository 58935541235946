import { useCallback } from "react";

import { differenceInDays } from "date-fns";
import useUserData from "./useUserData";

const useUserVouchers = () => {
    const { user } = useUserData();

    const userAlreadyTestedServer = useCallback(
        (serverType: string) => {
            const locallyRedeemedFreeServer = localStorage.getItem("redeemed_free_server");

            if (locallyRedeemedFreeServer && locallyRedeemedFreeServer.split(",").includes(serverType)) {
                return true;
            }

            return (
                !!user &&
                !!user.voucher_uses &&
                user.voucher_uses.some((use) => {
                    const isTestingServer =
                        use.voucher.group_id && use.voucher.group_id === `testing-server-${serverType}`;
                    const usedInLast90Days = differenceInDays(new Date(), new Date(use.voucher.created_at)) <= 90;

                    return isTestingServer && usedInLast90Days;
                })
            );
        },
        [user]
    );

    return { userAlreadyTestedServer };
};

export default useUserVouchers;
