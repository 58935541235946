import styled, { css } from "styled-components";

export const MarketplaceProjectCard = styled.div<{ border?: string; variant?: "default" | "wide" }>`
    width: 100%;
    height: 100%;
    border: ${({ border }) => border || `1px solid ${border}`};
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
    box-shadow: 0px 2px 5px ${({ theme }) => theme.colors.dark};

    ${({ variant }) =>
        variant === "wide" &&
        css`
            aspect-ratio: 16 / 9;
        `}
`;

export const MarketplaceProjectBadge = styled.div`
    width: fit-content;
    background-color: ${({ theme }) => theme.colors.danger};
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 8px 100px;
    z-index: 1;
    position: absolute;
    color: ${({ theme }) => theme.colors.secondaryText};
    font-weight: 550;
    font-size: 22px;
    transform: rotate(-45deg);
    top: 30px;
    left: -90px;
`;

export const CardPlayer = styled.video<{ show: boolean }>`
    display: ${({ show }) => (show ? "block" : "hidden")};
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
    position: absolute;
    z-index: 0;
    opacity: ${({ show }) => (show ? 1 : 0)};
    transition: opacity 0.3s ease-in-out;
`;

export const CardPlayerLoader = styled.div<{ show: boolean }>`
    display: ${({ show }) => (show ? "flex" : "hidden")};
    width: 100%;
    height: 100%;
    border-radius: 8px;
    position: absolute;
    z-index: 0;
    opacity: ${({ show }) => (show ? 1 : 0)};
    backdrop-filter: blur(5px);
    align-items: center;
    justify-content: center;
`;

export const CardThumbnail = styled.picture<{ show: boolean }>`
    display: ${({ show }) => (show ? "block" : "hidden")};
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
    position: absolute;
    z-index: 0;
    opacity: ${({ show }) => (show ? 1 : 0)};
    transition: opacity 0.3s ease-in-out;

    & source,
    & img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        position: absolute;
    }
`;

export const ProductThumbnail = styled.img<{ show: boolean }>`
    display: ${({ show }) => (show ? "block" : "hidden")};
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
    position: absolute;
    z-index: 0;
    opacity: ${({ show }) => (show ? 1 : 0)};
    transition: opacity 0.3s ease-in-out;
`;

export const MarketplaceProjectContent = styled.div<{ gradient?: string }>`
    width: 100%;
    height: 135px;
    background: ${({ gradient }) =>
        gradient || "linear-gradient(0, rgba(35, 35, 35, 0.9) 0%, rgba(35, 35, 35, 0) 100%)"};
    marginbottom: 1px;
    border-radius: 0 0 8px 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 12px;
    z-index: 1;
`;
