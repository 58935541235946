import { Button, Paragraph, Space } from "csrvui";
import { useTranslation } from "react-i18next";
import useTheme from "../../../../shared/hooks/useCustomTheme";
import { ScheduledServiceAction } from "../../../Billings/types";
import { EventAlertWrapper } from "../../../Marketplace/components/EventAlert/style";

interface Props {
    action: ScheduledServiceAction;
    onCancel: () => void;
}

const UpgradeActionAlert = ({ action, onCancel }: Props) => {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <EventAlertWrapper style={{ margin: "0 20px 15px 20px" }} key={action.id}>
            <Space $padding={0}>
                <Paragraph
                    $level={3}
                    style={{
                        color: theme.colors.secondaryText,
                        fontWeight: 550,
                    }}
                >
                    {t("upgrade_server.planned_actions", {
                        date: t("dates.simple", { date: action.date }),
                    })}
                </Paragraph>
                <Space $padding={0}>
                    {action.details.planned_upgrade.upgrade_to && (
                        <Paragraph
                            $level={3}
                            style={{
                                color: theme.colors.secondaryText,
                            }}
                        >
                            {t("upgrade_server.planned_upgrade_to", {
                                upgrade_to: action.details.planned_upgrade.upgrade_to,
                            })}
                        </Paragraph>
                    )}
                    {action.details.planned_upgrade.billing_period && (
                        <Paragraph
                            $level={3}
                            style={{
                                color: theme.colors.secondaryText,
                            }}
                        >
                            {t("upgrade_server.planned_upgrade_billing_period", {
                                billing_period: action.details.planned_upgrade.upgrade_billing_period,
                            })}
                        </Paragraph>
                    )}
                </Space>
            </Space>
            <Button onClick={onCancel} $variant="danger">
                {t("common.cancel")}
            </Button>
        </EventAlertWrapper>
    );
};

export default UpgradeActionAlert;
