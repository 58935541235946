import { FiMenu } from "@react-icons/all-files/fi/FiMenu";
import { Button, Space } from "csrvui";
import { Link } from "react-router-dom";
import MobileLogo from "../../../assets/images/logo-mobile.png";
import Logo from "../../../assets/images/logo.png";
import useUserData from "../../../modules/User/hooks/useUserData";
import useSidebarContext from "../../hooks/SidebarContext";
import useWindowSize from "../../hooks/useWindowSize";
import { StyledHeader } from "./style";

const PanelHeader: React.FC<{ hideMenu?: boolean; dark?: boolean }> = ({ dark }) => {
    const { toggleSidebar } = useSidebarContext();
    const { user } = useUserData();
    const { width } = useWindowSize();

    return (
        <StyledHeader dark={dark}>
            <Space
                $direction="horizontal"
                $align="space-between"
                $fullWidth
                style={{
                    margin: "0 auto",
                }}
            >
                <Link to="/">
                    <img src={width > 992 ? Logo : MobileLogo} alt="Craftserve logo" />
                </Link>

                {user && (
                    <Button
                        $variant={!dark && width < 992 ? "white" : "primary"}
                        LeftIcon={<FiMenu />}
                        onClick={() => {
                            toggleSidebar();
                        }}
                    />
                )}
            </Space>
        </StyledHeader>
    );
};

export default PanelHeader;
