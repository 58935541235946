import { InternalLink } from "csrvui";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import useDebugState from "../../../modules/Server/hooks/useDebugState";
import useHiddenDirectoriesState from "../../../modules/Server/hooks/useHiddenDirectoriesState";
import usePreviewState from "../../../modules/Server/hooks/usePreviewState";
import useCustomTheme from "../../hooks/useCustomTheme";
import { VisualLinkMode } from "../../types";
import { AdminWrapper, MenuContainer } from "./style";

const AdminPanel = () => {
    const { t } = useTranslation();
    const [isMenuExpanded, setIsMenuExpanded] = useState(false);
    const [consoleDebug, toggleConsoleDebug] = useDebugState();
    const [showHiddenFolders, toggleHiddenFolders] = useHiddenDirectoriesState();
    const [isPreview, toggleIsPreview] = usePreviewState();
    const theme = useCustomTheme();

    const toggleMenu = () => setIsMenuExpanded((prev) => !prev);

    const handleCheckboxClick = (e: React.MouseEvent) => {
        e.stopPropagation();
    };

    return (
        <AdminWrapper>
            <button
                type="button"
                onClick={toggleMenu}
                style={{
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    fontWeight: "bold",
                    fontSize: "28px",
                    color: "white",
                }}
                aria-expanded={isMenuExpanded}
            >
                {t("common.admin")}
            </button>

            {isMenuExpanded && (
                <MenuContainer>
                    <label htmlFor="debug">
                        <input
                            id="debug"
                            data-ph-capture-attribute-id="admin_panel:debug_toggle"
                            type="checkbox"
                            checked={consoleDebug}
                            onChange={toggleConsoleDebug}
                            onClick={handleCheckboxClick}
                        />
                        {t("common.debugToggle")}
                    </label>
                    <label htmlFor="hiddenFolders">
                        <input
                            id="hiddenFolders"
                            data-ph-capture-attribute-id="admin_panel:hidden_folders_toggle"
                            type="checkbox"
                            checked={showHiddenFolders}
                            onChange={toggleHiddenFolders}
                            onClick={handleCheckboxClick}
                        />
                        {t("common.showHiddenFolders")}
                    </label>
                    <label htmlFor="isPreview">
                        <input
                            id="isPreview"
                            data-ph-capture-attribute-id="admin_panel:is_preview_toggle"
                            type="checkbox"
                            checked={isPreview}
                            onChange={toggleIsPreview}
                            onClick={handleCheckboxClick}
                        />
                        {t("common.isPreview")}
                    </label>

                    <InternalLink
                        $inline
                        $fullWidth
                        $underline={false}
                        $sizeVariant="big"
                        $mode={VisualLinkMode.SHOW}
                        $variant="white"
                        $hoverColor={theme.colors.success}
                        to="/admin"
                        style={{
                            borderRadius: "6px",
                            backgroundColor: theme.colors.admin,
                            border: `1px solid ${theme.colors.white}`,
                            padding: "10px 12px",
                            whiteSpace: "nowrap",
                            textAlign: "center",
                        }}
                    >
                        {t("common.manageAdminData")}
                    </InternalLink>
                </MenuContainer>
            )}
        </AdminWrapper>
    );
};

export default AdminPanel;
