import styled from "styled-components";

export const Grid = styled.div<{ maxWidth?: string; minWidth?: string }>`
    display: grid;
    gap: 10px;
    padding: 20px 0;
    width: 100%;
    grid-template-columns: ${({ maxWidth, minWidth }) =>
        `repeat(auto-fill, minmax(${minWidth || "400px"}, ${maxWidth || "1fr"}))`};
    grid-auto-flow: dense;
    grid-auto-rows: 300px;
    // grid-template-rows: 300px 1fr;

    @media (max-width: 768px) {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
`;

export const ShowcaseGrid = styled.div`
    display: grid;
    gap: 10px;
    padding: 20px 0;
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
    grid-auto-flow: dense;
    grid-auto-rows: 350px;

    @media (max-width: 768px) {
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
`;

export const List = styled.div``;
