import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    getLocalCurrency,
    getLocalLanguage,
    getUILocalOptions,
    setLocalCurrency,
    setLocalLanguage,
} from "../helpers/localStorage";

export default function () {
    const [browserLanguage, setBrowserLanguage] = useState(() => getLocalLanguage());
    const [userInterfaceOptions, setUserInterfaceOptions] = useState(() => getUILocalOptions());
    const [currency, setCurrency] = useState(() => getLocalCurrency());
    const {
        i18n: { language },
    } = useTranslation();

    useEffect(() => {
        if (!browserLanguage) {
            switch (language) {
                case "en":
                    setBrowserLanguage("en-GB");
                    setLocalLanguage("en-GB");
                    setLocalCurrency("USD");
                    break;
                case "pl":
                    setBrowserLanguage("pl-PL");
                    setLocalLanguage("pl-PL");
                    setLocalCurrency("PLN");
                    break;
                default:
                    setBrowserLanguage("pl-PL");
                    setLocalLanguage("pl-PL");
                    setLocalCurrency("PLN");
                    break;
                    break;
            }
        }

        const refeshOptions = () => {
            setUserInterfaceOptions(getUILocalOptions());
        };
        window.addEventListener("storage", refeshOptions);

        return () => {
            window.removeEventListener("storage", refeshOptions);
        };
    }, [browserLanguage, language]);

    const changeLanguage = (symbol: string) => {
        setBrowserLanguage(symbol);
        setLocalLanguage(symbol);
    };

    const changeCurrency = (symbol: string) => {
        setCurrency(symbol);
        setLocalCurrency(symbol);
    };

    return { browserLanguage, currency, userInterfaceOptions, changeLanguage, changeCurrency };
}
