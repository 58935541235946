import { useCallback } from "react";
import { Money } from "ts-money";
import * as BillingsTypes from "../../Billings/types";
import * as ServerTypes from "../types";

export default function () {
    const calculateProductCost = useCallback(
        (products: BillingsTypes.BillingProduct[], cluster: string, billing_period: string) => {
            const productsPrices = products.map((product) => {
                if (!product.prices) {
                    throw new Error("Product prices not found");
                }

                const targetPrice = product.prices.find(
                    (price) => price.period === billing_period && price.cluster_id === cluster
                );

                if (!targetPrice) {
                    return null;
                }

                const { amount, currency } = targetPrice.gross_price;

                // vouchersProfits
                //     .map((profit) => profit.data)
                //     .forEach((profit) => {
                //         const discounts = profit.filter((p) => "coupon" in p);
                //         discounts.forEach((action) => {
                //             if (!action.coupon) {
                //                 return;
                //             }
                //             const expression = parse(action.coupon.expression);
                //             amount = Math.floor(expressionEval(expression, { price: amount }));
                //         });
                //     });

                return new Money(amount, currency);
            });

            const validPrices = productsPrices.filter((price): price is Money => price !== null);

            if (validPrices.length === 0) {
                return null;
            }

            return validPrices.reduce((acc, price) => acc.add(price), new Money(0, validPrices[0].currency));
        },
        []
    );

    const calculateCostWithProfits = useCallback((cost: Money, profits: ServerTypes.VoucherProfit[]) => {
        let finalCost = cost;

        profits
            .flatMap((profit) => profit.data)
            .filter((profit) => "new_cost" in profit)
            .forEach((profit) => {
                if (!profit.new_cost) {
                    return;
                }
                const newCost = profit.new_cost;
                if (finalCost.greaterThan(newCost)) {
                    finalCost = newCost;
                }
            });

        return finalCost;
    }, []);

    return { calculateProductCost, calculateCostWithProfits };
}
