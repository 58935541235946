import { NumberDictionary, adjectives, colors, uniqueNamesGenerator } from "unique-names-generator";
import { minecraftMobs } from "../../constants";
import { DomainPayload } from "../../modules/Server/types";

const numberDictionary = NumberDictionary.generate({ min: 1, max: 10000 });

export function generateMinecraftDomain(zone_name: string, withNumber?: boolean): DomainPayload {
    const name = uniqueNamesGenerator({
        dictionaries: !withNumber
            ? [adjectives, colors, minecraftMobs]
            : [adjectives, colors, minecraftMobs, numberDictionary],
        style: "lowerCase",
        separator: "-",
    });

    return {
        subdomain: name,
        zone_name,
    };
}
