import { Space } from "csrvui";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import DiscordIcon from "../../../assets/icons/discord-icon.svg";
import Hero from "../../../assets/images/hero_left.webp";
import BackgroundImageDesktop from "../../../assets/images/landingDesktop.webp";
import BackgroundImageMobile from "../../../assets/images/landingSmall.webp";
import standarizeUrl from "../../../modules/Marketplace/helpers/standarizeUrl";
import { VisualLinkMode } from "../../types";
import { SocialIcons } from "../SocialIcons";
import {
    Description,
    DiscordIconImg,
    DiscordLink,
    HeroContainer,
    StyledInternalLink,
    StyledIntroduction,
    StyledParagraph,
    StyledSpace,
    StyledSpan,
    StyledTitle,
} from "./style";

const HomeIntroduction = () => {
    const { t } = useTranslation();

    const title = useMemo(
        () => (
            <>
                <span>{t("landing.your_server")}</span> <StyledSpan>{t("landing.your_rules")}</StyledSpan>,{" "}
                <span>{t("landing.start_in")}</span> <StyledSpan>{t("landing.minute")}</StyledSpan>
            </>
        ),
        [t]
    );

    const backgroundMobile = useMemo(() => standarizeUrl(BackgroundImageMobile), []);
    const backgroundDesktop = useMemo(() => standarizeUrl(BackgroundImageDesktop), []);
    const heroUrl = useMemo(() => standarizeUrl(Hero), []);

    useEffect(() => {
        const link = document.createElement("link");
        link.rel = "preload";
        link.href = heroUrl;
        link.as = "image";
        document.head.appendChild(link);
    }, [heroUrl]);

    return (
        <StyledIntroduction backgroundMobile={backgroundMobile} backgroundDesktop={backgroundDesktop}>
            <Space $direction="horizontal" $gap={15} style={{}} $wrap={false}>
                <Description>
                    <StyledTitle>{title}</StyledTitle>
                    <StyledSpace $padding={0} $direction="horizontal" $wrap={false}>
                        <StyledInternalLink
                            data-ph-capture-attribute-id="home_introduction:join_the_game_link"
                            $mode={VisualLinkMode.SHOW}
                            $variant="primary"
                            $sizeVariant="big"
                            $filled
                            $underline={false}
                            to="#offers"
                        >
                            <StyledParagraph $centered>{t("landing.joinTheGame")}</StyledParagraph>
                        </StyledInternalLink>

                        <DiscordLink
                            data-ph-capture-attribute-id="home_introduction:discord_link"
                            $mode={VisualLinkMode.SHOW}
                            $variant="primary"
                            $sizeVariant="big"
                            $filled
                            $underline={false}
                            href="https://discord.gg/craftserve-387222965131149313"
                        >
                            <DiscordIconImg src={DiscordIcon} alt="Discord" />
                        </DiscordLink>
                    </StyledSpace>
                </Description>
                <HeroContainer>
                    <img
                        src={heroUrl}
                        alt="Minecraft hero"
                        style={{
                            width: 580,
                            height: 580,
                        }}
                    />
                </HeroContainer>
                <SocialIcons />
            </Space>
        </StyledIntroduction>
    );
};

export default HomeIntroduction;
