import { FaExternalLinkAlt } from "@react-icons/all-files/fa/FaExternalLinkAlt";
import { FaFire } from "@react-icons/all-files/fa/FaFire";
import { Button, Paragraph, Space, Title } from "csrvui";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import stars from "../../../../assets/images/stars.svg";
import { FeatureFlags, FeatureFlagsValues } from "../../../../constants";
import { getFeatureFlag } from "../../../../shared/helpers/sessionStorage";
import useTheme from "../../../../shared/hooks/useCustomTheme";
import { PlayButton } from "../../../Marketplace/components/PlayButton";
import RedeemFreeServerModal from "../../../Marketplace/components/RedeemFreeServerModal";
import standarizeUrl from "../../../Marketplace/helpers/standarizeUrl";
import useCreateServer from "../../../Server/hooks/useCreateServer";
import useProductStrategySelector from "../../../Server/strategies/useProductStrategySelector";
import getPriceForProduct from "../../../User/helpers/getPriceForProduct";
import useUserData from "../../../User/hooks/useUserData";
import useUserVouchers from "../../../User/hooks/useUserVouchers";
import { BillingProduct } from "../../types";
import {
    Badge,
    Divider,
    DividerTitle,
    FeatureTile,
    FeatureTileWrapper,
    Floating,
    PriceChip,
    ProductCardBody,
    ProductCardHeading,
    ProductLink,
    StyledProductCard,
    Table,
    TableRow,
} from "./style";

interface Props {
    data: BillingProduct;
    showBadge?: boolean;
    showOutline?: boolean;
    onShowDetails?: () => void;
    variant?: "default" | "upgrade";
    disabled?: boolean;
    options?: {
        price?: string;
    };
}

const ProductCard = ({ data, showBadge, onShowDetails, showOutline, disabled, options, variant }: Props) => {
    const strategy = useProductStrategySelector(data.id);
    const theme = useTheme();
    const { user } = useUserData();
    const { t } = useTranslation();
    const { redeemFreeServer } = useCreateServer();
    const { userAlreadyTestedServer } = useUserVouchers();
    const canUserFreeServer = !userAlreadyTestedServer(data.id);

    const isUpgradeVariant = variant === "upgrade";

    const isDisabled = !data.available;

    const [modalSettings, setModalSettings] = useState<{
        isOpen: boolean;
        product: BillingProduct | null;
    }>({
        isOpen: false,
        product: null,
    });

    const productPrice = getPriceForProduct(data);

    const price = useMemo(() => (options && options.price) || productPrice, [options, productPrice]);

    if (!strategy) {
        return null;
    }

    const { tableFeatures, getProductScheme, features, getProductName, getProductCardOutline, getProductAssets } =
        strategy;

    const color = getProductScheme();
    const productFeatures = features();
    const { smallIcon, featureIcon, cardThumbnail, thumb, cardFloatingIcon } = getProductAssets();
    const productTableFeatures = tableFeatures();

    let buttonText;
    if (isDisabled) {
        buttonText = `${price} ${t("marketplace.disabled_product_button")}`;
    } else if (canUserFreeServer) {
        buttonText =
            getFeatureFlag(FeatureFlags.EXPERIMENT_REDEEM_SERVER_FOR_FREE) === FeatureFlagsValues.FOR_FREE_24H
                ? t("landing.for_free_24h")
                : t("landing.for_free");
    } else {
        buttonText = `${t("marketplace.create_server")} `;
    }

    const paragraphColor = canUserFreeServer ? theme.colors.primaryText : theme.colors.secondaryText;

    return (
        <>
            <ProductLink
                data-ph-capture-attribute-id="product_card:product_link"
                data-ph-capture-attribute-product={data.id}
                className={isDisabled || disabled ? "disabledLink" : undefined}
                to={`/offers/create?products=${data.id}`}
            >
                {showBadge && (
                    <Badge>
                        <Space style={{ zIndex: 2 }} $padding={0} $direction="horizontal">
                            <FaFire size={25} />
                            <Paragraph
                                style={{
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                }}
                            >
                                Najczęściej wybierane
                            </Paragraph>
                        </Space>

                        <img
                            style={{ position: "absolute", left: 0, top: -10 }}
                            src={stars}
                            alt=""
                            aria-disabled
                            loading="lazy"
                            width="100%"
                            height="80"
                        />
                    </Badge>
                )}
                <StyledProductCard
                    disabled={isDisabled}
                    style={{
                        background:
                            canUserFreeServer && !disabled
                                ? "linear-gradient(10deg, #FAC203, #FBFB00)"
                                : getProductCardOutline(),
                    }}
                    showOutline={showBadge || showOutline}
                >
                    <ProductCardHeading bgImage={standarizeUrl(cardThumbnail)}>
                        <Divider
                            aria-disabled="true"
                            aria-hidden="true"
                            style={{
                                position: "absolute",
                                height: "calc(100% - 77px)",
                                width: "3px",
                                top: 40,
                                left: "11px",
                            }}
                        />
                        <Divider
                            aria-disabled="true"
                            aria-hidden="true"
                            style={{
                                position: "absolute",
                                width: "10%",
                                bottom: -3,
                                left: 11,
                            }}
                        />

                        <Divider
                            aria-disabled="true"
                            aria-hidden="true"
                            style={{
                                position: "absolute",
                                height: "calc(100% - 147px)",
                                width: "3px",
                                top: 65,
                                right: "20px",
                            }}
                        />

                        <Space $padding="20px 20px 0 20px" $wrap={false} $direction="horizontal" $align="space-between">
                            <Space style={{ flex: "0 1 auto", whiteSpace: "nowrap" }} $padding={0}>
                                <Title
                                    style={{
                                        fontSize: "24px",
                                        fontWeight: "bold",
                                        textTransform: "uppercase",
                                        color: theme.colors.secondaryText,
                                    }}
                                    $level={3}
                                >
                                    {getProductName()}
                                </Title>
                            </Space>
                            <Divider
                                aria-disabled="true"
                                aria-hidden="true"
                                style={{
                                    margin: "-40px 15px 0 15px",
                                }}
                            />
                            <Space
                                $padding={0}
                                $align="center"
                                $oppositeAlign="center"
                                style={{
                                    backgroundColor: theme.colors.primaryBackground,
                                    width: "40px",
                                    height: "40px",
                                    borderRadius: "50%",
                                    boxShadow: "0px 0px 4px 4px rgba(0, 0, 0, 0.25)",
                                    flex: "0 0 auto",
                                }}
                            >
                                <img
                                    loading="lazy"
                                    width={25}
                                    height={28}
                                    src={standarizeUrl(smallIcon)}
                                    alt="Product icon"
                                />
                            </Space>
                        </Space>
                        <Space $padding={0} $direction="horizontal" $fullWidth $align="center" $oppositeAlign="center">
                            <Floating
                                style={{
                                    animationDelay: "0.8s",
                                }}
                            >
                                <img
                                    alt=""
                                    loading="lazy"
                                    width={40}
                                    height={62}
                                    src={standarizeUrl(cardFloatingIcon)}
                                    aria-disabled
                                    aria-hidden
                                />
                            </Floating>
                            <img
                                loading="lazy"
                                src={standarizeUrl(thumb)}
                                alt="Product thumbnail"
                                width={250}
                                height={150}
                            />
                            <Floating>
                                <img
                                    loading="lazy"
                                    width={40}
                                    height={62}
                                    alt=""
                                    src={standarizeUrl(cardFloatingIcon)}
                                    aria-disabled
                                    aria-hidden
                                />
                            </Floating>
                        </Space>
                        <Space $fullWidth $align="end" $direction="horizontal" $wrap={false} $padding="0 20px">
                            <Divider
                                aria-disabled="true"
                                aria-hidden="true"
                                style={{
                                    width: "100%",
                                    flex: "1 1 auto",
                                    maxWidth: "100%",
                                    margin: "0  10px -24px 90px",
                                    left: "20%",
                                }}
                            />
                            <PriceChip>{price}</PriceChip>
                        </Space>
                    </ProductCardHeading>
                    <ProductCardBody>
                        <Space $padding={0}>
                            <DividerTitle>
                                {t("common.server")} {getProductName()}
                            </DividerTitle>
                        </Space>
                        <Space
                            $padding="10px 20px"
                            $direction="horizontal"
                            $oppositeAlign="center"
                            $align="space-between"
                        >
                            {productFeatures &&
                                productFeatures.map((feature) => (
                                    <FeatureTileWrapper key={`${feature.primaryText}-${feature.secondaryText}`}>
                                        <FeatureTile
                                            bg={featureIcon}
                                            style={{
                                                fontSize: "24px",
                                                backgroundColor: getProductScheme(),
                                            }}
                                        >
                                            {feature.primaryText}
                                        </FeatureTile>
                                        {feature.secondaryText}
                                    </FeatureTileWrapper>
                                ))}
                        </Space>
                        <Table>
                            <tbody>
                                {productTableFeatures &&
                                    productTableFeatures.map((feature) => (
                                        <TableRow key={feature.label}>
                                            <td>{feature.label}</td>
                                            <td>{feature.value}</td>
                                        </TableRow>
                                    ))}
                                <TableRow>
                                    <td
                                        style={{
                                            width: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            minHeight: "25px",
                                            color: theme.colors.primaryText,
                                        }}
                                    >
                                        {!isDisabled && onShowDetails && (
                                            <>
                                                <Button
                                                    data-ph-capture-attribute-id="product_card:more_info_button"
                                                    data-ph-capture-attribute-product={data.id}
                                                    $variant="transparent"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        onShowDetails();
                                                    }}
                                                    style={{
                                                        padding: 0,
                                                        fontSize: "20px",
                                                        fontWeight: "bold",
                                                        gap: 0,
                                                        textDecoration: "underline",
                                                    }}
                                                >
                                                    {t("common.more_info")}
                                                </Button>
                                                <FaExternalLinkAlt />
                                            </>
                                        )}
                                    </td>
                                </TableRow>
                            </tbody>
                        </Table>
                    </ProductCardBody>
                    {!isUpgradeVariant && (
                        <PlayButton
                            data-ph-capture-attribute-id="product_card:play_button"
                            data-ph-capture-attribute-product={data.id}
                            data-ph-capture-attribute-redeem-free-server={canUserFreeServer}
                            disabled={isDisabled}
                            onClick={(e) => {
                                if (user && canUserFreeServer) {
                                    e.preventDefault();
                                    redeemFreeServer(data, true);
                                } else if (canUserFreeServer) {
                                    e.preventDefault();
                                    setModalSettings({
                                        isOpen: true,
                                        product: data,
                                    });
                                }
                            }}
                            variant={canUserFreeServer && !isDisabled ? "free" : "default"}
                            $fullWidth
                            style={{
                                padding: "40px 30px 15px 30px",
                                marginTop: "-20px",
                                border: "none",
                                minHeight: "86px",
                                zIndex: 0,
                                ...(canUserFreeServer ? {} : { background: color }),
                                boxShadow: "none",
                            }}
                        >
                            {buttonText}
                            {!canUserFreeServer && (
                                <span
                                    style={{
                                        fontWeight: 550,
                                        color: paragraphColor,
                                    }}
                                >
                                    {price}
                                </span>
                            )}
                        </PlayButton>
                    )}
                </StyledProductCard>
            </ProductLink>
            <RedeemFreeServerModal
                open={modalSettings.isOpen}
                product={modalSettings.product}
                onClose={() => setModalSettings({ isOpen: false, product: null })}
            />
        </>
    );
};

export default ProductCard;
