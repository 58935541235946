import { useCallback, useMemo } from "react";
import * as BillingsTypes from "../../Billings/types";
import { useAvailableClustersQuery } from "../clusterApi";

export default function () {
    const { data, isLoading } = useAvailableClustersQuery();

    const availableClusters = useMemo(() => {
        if (!data) {
            return [];
        }

        return data?.filter((cluster) => cluster.visible && !cluster.disabled);
    }, [data]);

    const getProductClusters = useCallback(
        (product: BillingsTypes.BillingProduct) => {
            if (!availableClusters) {
                return [];
            }

            if (Object.keys(product.availability).some((cluster) => cluster === "*")) {
                return availableClusters.filter((cluster) => cluster.visible);
            }

            return availableClusters.filter(
                (cluster) =>
                    cluster.visible &&
                    product.availability[cluster.id] &&
                    product.prices.find((priceItem) => priceItem.cluster_id === cluster.id) !== undefined
            );
        },
        [availableClusters]
    );

    return { availableClusters, isLoading, getProductClusters };
}
