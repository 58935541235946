import styled from "styled-components";

export const StyledLikeButton = styled("button")<{ isLiked: boolean }>`
    width: 137px;
    height: 38px;
    display: flex;
    flex-direction: row;
    gap: 13px;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.colors.primaryBackground};
    background-color: ${({ theme, isLiked }) => (isLiked ? theme.colors.danger : theme.colors.dark)};
    border: none;
    transition: opacity 0.5s;
    cursor: ${({ isLiked }) => (isLiked ? "default" : "pointer")};
    box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.1);

    &:hover {
        opacity: 0.8;
    }

    &:active {
        color: ${({ theme }) => theme.colors.primaryBackground};
        background-color: ${({ theme }) => theme.colors.danger};
    }

    &:active svg {
        scale: ${({ isLiked }) => (isLiked ? 1 : 1.4)};
    }
`;
