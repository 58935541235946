import { Struct } from "superstruct";
import { Money } from "ts-money";

/* eslint-disable */

export default function transformData<T>(data: T, structure: Struct<any>): any {
    // when api returns some of the data as null or undefined between the fields we don't want to transform it bc it's not possible and it will crash the entire app
    if (!structure.schema || !data) {
        return data;
    }

    // can be a single struct (response with fields) or just an another struct
    const schema = structure.schema as Record<string, Struct<T>> | Struct<T>;

    // type guard for Record<string, Struct<T>>, check if value is nested Struct
    function isRecord(value: any): value is Record<string, Struct<T>> {
        return typeof value === "object" && value !== null && !Array.isArray(value);
    }

    // type guard for Struct<T>, check if value is Struct
    const isDateType = (key: string): boolean => isRecord(schema) && schema[key].type === "date";
    const isArrayType = (key: string): boolean =>
        isRecord(schema) && schema[key].type === "array" && (schema[key].schema as Struct<T>).type === "object";
    const isObjectType = (key: string): boolean =>
        isRecord(schema) && schema[key].type === "object" && !!schema[key].schema;
    const isInstanceType = (key: string): boolean => isRecord(schema) && schema[key].type === "instance";

    const datesKeys = Object.keys(schema).filter(isDateType);
    const arrays = Object.keys(schema).filter(isArrayType);
    const objects = Object.keys(schema).filter(isObjectType);
    const instances = Object.keys(schema).filter(isInstanceType);

    if (Array.isArray(data)) {
        // when data is an array,then transform each item in the array
        return data.map((item) => transformData(item, schema as Struct<T>)) as unknown as T;
    }

    if (datesKeys.length > 0) {
        // for each key which is a date in data, transform it to a Date instance
        for (const key of datesKeys) {
            if (data && (data as Record<string, unknown>)[key]) {
                (data as Record<string, unknown>)[key] = new Date(data[key as keyof typeof data] as string);
            } else {
                (data as Record<string, unknown>)[key] = null;
            }
        }
    }

    if (arrays.length > 0) {
        for (const key of arrays) {
            const array = data[key as keyof typeof data] as any[];
            const arraySchema = schema[key as keyof typeof schema] as Record<string, Struct<T>> | Struct<T>;

            if (array && Array.isArray(array) && arraySchema && isRecord(arraySchema)) {
                // it's expected any cuz we don't know the type of data and we can store any type in the array
                array.map((item: unknown) => transformData(item, arraySchema.schema));
            }
        }
    }

    // if (array && Array.isArray(array)) {
    //     // Zmieniamy "schema[key]" na odpowiedni typ Struct<T>
    //     const arraySchema = schema[key as keyof typeof schema];

    //     // Zakładając, że schema[key] jest typu Struct<T> i zawiera informacje o schemacie wewnętrznym,
    //     // używamy "schema" zdefiniowanego w superstruct
    //     if (arraySchema && isRecord(arraySchema) && arraySchema.schema) {
    //         array.forEach((item: unknown) => {
    //             transformData(item, arraySchema.schema);
    //         });
    //     }
    // }

    if (objects.length > 0) {
        // for each key which is an object, transform the object
        for (const key of objects) {
            if ((data as Record<string, unknown>)[key]) {
                (data as Record<string, unknown>)[key] = transformData(
                    (data as Record<string, unknown>)[key] as T,
                    schema[key as keyof typeof schema] as Struct<T>
                );
            }
        }
    }

    if (instances.length > 0) {
        // for each key which is an instance of Money, transform it to a Money instance
        for (const key of instances) {
            if ((data as Record<string, unknown>)[key]) {
                const moneyInstance = (data as Record<string, { amount: number; currency: string }>)[key];
                (data as Record<string, unknown>)[key] = new Money(moneyInstance.amount, moneyInstance.currency);
            }
        }
    }

    return data;
}
