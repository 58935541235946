import { useGetMarketplaceMetricsQuery } from "../api";
import { MarketplaceMetricPayload } from "../types";

const useMetrics = (options: MarketplaceMetricPayload) => {
    const { isLoading, data, isError } = useGetMarketplaceMetricsQuery(options);

    return { data, isLoading, isError };
};

export default useMetrics;
