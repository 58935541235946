import { combineReducers } from "redux";
import { adminApi } from "./modules/Admin/api";
import * as BillingsApi from "./modules/Billings/api";
import { craftumsApi } from "./modules/Craftums/api";
import { marketplaceApi } from "./modules/Marketplace/api";
import * as PaymentsApi from "./modules/Payments/api";
import * as ServerApi from "./modules/Server/api";
import { clusterApi } from "./modules/Server/clusterApi";
import * as BackupsApi from "./modules/Server/modules/Backups/api";
import { api as walletsApi } from "./modules/Server/modules/Wallets/api";
import consoleLogsReducer from "./modules/Server/slices/consoleSlice";
import currentServerReducer from "./modules/Server/slices/currentServerSlice";
import routeChangeAlertReducer from "./modules/Server/slices/routeChangeAlertSlice";
import serverStatusReducer from "./modules/Server/slices/serverStatusSlice";

import FileManagerReducer from "./modules/Server/slices/fileManagerSlice";
import pointerToastsSlice from "./modules/Server/slices/pointerToastsSlice";
import { ServicesApi } from "./modules/Services";
import * as UserApi from "./modules/User/api";
import { supportApi } from "./modules/User/api";
import authReducer from "./modules/User/slices/authSlice";
import taxInfoReducer from "./modules/User/slices/taxInfoSlice";

const reducer = combineReducers({
    [UserApi.api.reducerPath]: UserApi.api.reducer,
    [BillingsApi.api.reducerPath]: BillingsApi.api.reducer,
    [ServerApi.api.reducerPath]: ServerApi.api.reducer,
    [ServerApi.eventLogsApi.reducerPath]: ServerApi.eventLogsApi.reducer,
    [ServerApi.domainsApi.reducerPath]: ServerApi.domainsApi.reducer,
    [clusterApi.reducerPath]: clusterApi.reducer,
    [PaymentsApi.paymentsApi.reducerPath]: PaymentsApi.paymentsApi.reducer,
    [ServicesApi.api.reducerPath]: ServicesApi.api.reducer,
    [BackupsApi.api.reducerPath]: BackupsApi.api.reducer,
    [marketplaceApi.reducerPath]: marketplaceApi.reducer,
    [supportApi.reducerPath]: supportApi.reducer,
    [walletsApi.reducerPath]: walletsApi.reducer,
    [craftumsApi.reducerPath]: craftumsApi.reducer,
    [ServerApi.packagesApi.reducerPath]: ServerApi.packagesApi.reducer,
    [adminApi.reducerPath]: adminApi.reducer,
    auth: authReducer,
    fileManagerData: FileManagerReducer,
    currentData: currentServerReducer,
    serverStatus: serverStatusReducer,
    consoleLogs: consoleLogsReducer,
    taxInfo: taxInfoReducer,
    routeAlert: routeChangeAlertReducer,
    pointerToasts: pointerToastsSlice,
});

export type RootState = ReturnType<typeof reducer>;

export default reducer;
