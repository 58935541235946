import {
    Infer,
    any,
    array,
    assign,
    boolean,
    date,
    enums,
    nullable,
    number,
    object,
    optional,
    record,
    string,
    union,
} from "superstruct";
import { Rating } from "../../constants";
import { ProjectReleaseType } from "../../shared/types";

export interface AuthorInfo {
    author_name: string | null;
    website_url?: string | null;
}

export interface Tab {
    label: string;
    id: string;
}

export interface MarketplaceCardOptions {
    forceShowThumbUrl?: string;
}

export type MarketplaceLabel = Record<string, string>;

export interface InstallReleaseOptions {
    package: PackageSelector;
    force: boolean;
    install_deps: boolean;
}

export interface InstallProjectPayload {
    force: boolean;
    install_deps: boolean;
    backup: boolean;
    clearData: boolean;
    keepFilesPaths?: string[];
}

export interface InstallprojectModalOptions {
    hideInstallOptions: boolean;
}

export const ProjectMember = object({
    user_id: string(), // values.UserID
    username: string(), // string
    role: string(), // values.MarketProjectRole
});

export type ProjectMember = Infer<typeof ProjectMember>;

export interface PackageSelectorObj {
    name: string;
    tag: string;
    expr: string;
    provides: PackageSelector[];
    labels: Record<string, string>;
}

export interface InstalledPackage extends Partial<PackageSelectorObj> {
    version: string;
    hash: string;
}

export type PackageSelector = string | PackageSelectorObj;

export interface TransformedPackage {
    name: string;
    version: string;
    hash: string;
    provides: PackageSelector[];
}

export interface NewDependencyFormValues {
    name: string;
    tag: string;
    expr: string;
    labels: {
        id: string;
        key: string;
        value: string;
    }[];
}

export interface ReleaseArtifact {
    type: string;
    url: string;
    hash: string;
    details: Record<string, unknown>;
}

export interface ReviewsQueryData {
    limit?: number;
    projectId: string;
    before?: string;
}

export interface CreateReviewData {
    projectId: string;
    data: {
        comment: string;
        rate: string;
        release_id: string;
    };
}

export interface FollowMarketplaceProjectData {
    slug: string;
}

export interface ReviewsData {
    projectId?: string;
}

export const ProjectRelease = object({
    id: string(), // values.ReleaseId
    project_id: string(), // values.MarketProjectId
    release_type: string(), // values.ReleaseType
    description: string(),
    artifacts: array(
        object({
            type: string(),
            url: string(),
            hash: string(),
            deleted_at: nullable(date()), // *time.Time
            details: record(string(), any()), // entities.ReleaseArtifact
        })
    ),
    created_at: date(), // time.Time
    published_by: string(), // values.UserID
    deleted_at: nullable(date()), // *time.Time
});

export const GetProjectReleaseResponse = object({
    has_more: boolean(), // bool
    count: number(), // int64
    releases: array(ProjectRelease), // []PublicProjectRelease
});
export type ProjectReleaseResponse = Infer<typeof GetProjectReleaseResponse>;

export const MarketProjectReview = object({
    id: string(),
    project_id: string(),
    release_id: string(),
    user_id: string(),
    username: string(),
    rate: enums(Object.values(Rating)),
    comment: string(),
    created_at: date(),
    deleted_at: nullable(date()),
});

export type MarketProjectReview = Infer<typeof MarketProjectReview>;

export const GetMarketProjectReviewsResponse = object({
    has_more: boolean(),
    reviews: array(MarketProjectReview),
});

export type GetMarketProjectReviewsResponse = Infer<typeof GetMarketProjectReviewsResponse>;

// export interface GetMarketProjectReviewsResponse {
//     reviews: PublicMarketProjectReview[];
// }

export type ProjectRelease = Infer<typeof ProjectRelease>;

export enum ReleaseType {
    STABLE = "stable",
    LATEST = "latest",
    PRIVATE = "private",
}

const MarketProjectMedia = object({
    name: string(),
    url: string(),
});

export const MarketProject = object({
    id: string(), // values.MarketProjectId
    slug: string(),
    name: string(),
    project_type: ProjectReleaseType, // values.MarketProjectType
    description: string(),
    short_description: string(),
    labels: array(string()),
    author_info: object({
        author_name: nullable(string()),
        website_url: nullable(string()),
    }), // values.AuthorInfo
    project_members: array(ProjectMember), // []PublicProjectMember
    media: array(MarketProjectMedia),
    visible: boolean(),
    verified_at: nullable(date()), // *time.Time
    created_at: date(), // time.Time
    archived_at: nullable(date()), // *time.Time
    latest_release: nullable(ProjectRelease), // *PublicProjectRelease
});
export type MarketProject = Infer<typeof MarketProject>;

export const MarketEventMetric = object({
    user_id: string(),
    username: string(),
    email: string(),
    project_id: string(),
    project_slug: string(),
    project_name: string(),
    points: number(),
});

export type MarketEventMetric = Infer<typeof MarketEventMetric>;

export interface MarketplaceMetricPayload {
    label: string;
    metricType: string;
}

export const GetMarketProjectResponse = assign(
    MarketProject,
    object({
        followers: number(),
        review: optional(MarketProjectReview),
        positive_reviews: number(),
        negative_reviews: number(),
        user_capabilities: record(string(), union([string(), number(), boolean()])), // map[values.CapabilityType]any
    })
);

export type GetMarketProjectResponse = Infer<typeof GetMarketProjectResponse>;

export const FollowedMarketplaceProjects = object({
    followed: array(MarketProject),
});

export type FollowedMarketplaceProjects = Infer<typeof FollowedMarketplaceProjects>;

export interface ReleaseArtifactFile {
    name: string;
    type: string;
    file: File;
}

export interface MarketplaceProjectWithLabels extends MarketProject {
    packageLabels: Record<string, string>;
    hash: string;
    version: string;
}

export interface ReviewFormValues {
    release_id: string;
    comment: string;
    rate: string;
}

export interface ReleaseArtifactMetadata {
    name: string;
    type: string;
}

export interface insertProjectReleasePayload {
    releaseType: ReleaseType;
    description: string;
    files: ReleaseFileData[];
    filesMetadata: ReleaseArtifactMetadata[];
}

export interface CreateProjectReleasePayload extends Omit<ProjectRelease, "id" | "createdAt" | "projectMembers"> {
    projectType: ProjectReleaseType;
}

export interface CreateMarketProjectPayload {
    name: string;
    slug: string;
    disable_suffix: boolean;
    short_description: string;
    description: string;
    labels: string[];
    project_type: ProjectReleaseType;
    author_info: AuthorInfo;
    media: File[];
}

export interface EditMarketProjectPayload {
    short_description: string;
    description: string;
    labels: string[];
    project_type: ProjectReleaseType;
    archived: boolean;

    media: File[];
}

export interface CreateProjectFromServerPayload {
    description: string;
    file_paths: string[];
    slug: string;
    version: string;
    package_labels: Record<string, string>;
    depends_on?: Array<string | PackageSelector>;
    release_type: string;
}

export interface CreateMarketProjectFormValues {
    name: string;
    slug: string;
    short_description: string;
    description: string;
    project_type: ProjectReleaseType;
    author_name: string;
    author_website_url: string;
    gallery: File[];
    icon?: File;
    video?: File;
    thumbnail?: File;
    labels: string[];
    archived: boolean;
    disable_suffix: boolean;
}

export interface EditMarketProjectFormValues {
    name: string;
    slug: string;
    short_description: string;
    description: string;
    project_type: ProjectReleaseType;
    author_name: string;
    author_website_url: string;
    gallery: File[];
    icon?: File;
    video?: File;
    thumbnail?: File;
    labels: string[];
    archived: boolean;
    disable_suffix: boolean;
}

export enum MarketFilesName {
    ICON = "icon",
    VIDEO = "video",
    THUMBNAIL = "thumbnail",
}

export interface EditProjectReleasePayload {
    description: string;
    labels: string[];
    projectType: ProjectReleaseType;
    archived: boolean;
}

export interface ReleaseFileData {
    path: string;
    name: string;
}

export interface ProjectReleaseQuery {
    order?: string;
    limit?: number;
    after?: string;
}

export interface RedeemServerFormValues {
    email: string;
    verificationCode: string;
    agreement: boolean;
    agreement2: boolean;
    eula: boolean;
}

export interface DeleteMediaPayload {
    slug: string;
    name: string;
}
