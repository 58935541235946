import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import Logo from "../../../assets/images/logo.png";
import { ScreensTitles } from "../../../constants";
import { ScreenTitle } from "../../../rootTypes";
import { Props } from "./types";

const locales = {
    en: "en_GB",
    pl: "pl_PL",
};

// "SEO": {
//         "description": "Najlepszy hosting serwerów Minecraft w Polsce. Twórz i zarządzaj swoim światem Minecraft z nami - szybki, niezawodny i bezpieczny hosting serwerów Minecraft. Zgarnij darmowe serwery Minecraft i ciesz się grą bez ograniczeń.",
//         "keywords": "najlepszy hosting minecraft, hosting serwerów minecraft, darmowe serwery minecraft, szybki hosting minecraft, niezawodny hosting minecraft, bezpieczny hosting minecraft, serwery minecraft, minecraft hosting"
//     },

const data = {
    description:
        "Najlepszy hosting serwerów Minecraft w Polsce. Twórz i zarządzaj swoim światem Minecraft z nami - szybki, niezawodny i bezpieczny hosting serwerów Minecraft. Zgarnij darmowe serwery Minecraft i ciesz się grą bez ograniczeń.",
    keywords:
        "najlepszy hosting minecraft, hosting serwerów minecraft, darmowe serwery minecraft, szybki hosting minecraft, niezawodny hosting minecraft, bezpieczny hosting minecraft, serwery minecraft, minecraft hosting, Craftserve Serwer minecraft, Minecraft hosting, Server minecraft, Craftserve pl, Hosting minecraft server, Jak założyć serwer minecraft, Serwer minecraft hosting, Hosting serwerów minecraft, Craft minecraft, Hosting serwerów mc, Serwery minecraft do kupienia, Najlepszy hosting minecraft, Pluginy minecraft, Mody minecraft",
    ogType: "website",
    ogImage: Logo,
    twitterCard: "summary_large_image",
    twitterImage: Logo,
};

const SEO: React.FC<Props> = ({ title, keywords, url, ogType, ogImage, twitterCard, twitterImage }) => {
    const {
        i18n: { language },
    } = useTranslation();

    return (
        <Helmet>
            <html lang={language} />
            <title>{title ? `${ScreensTitles[title as ScreenTitle]} | Craftserve` : "Craftserve"}</title>
            <link rel="apple-touch-icon" sizes="57x57" href="/static/apple-icon-57x57.png" />
            <link rel="apple-touch-icon" sizes="60x60" href="/static/apple-icon-60x60.png" />
            <link rel="apple-touch-icon" sizes="72x72" href="/static/apple-icon-72x72.png" />
            <link rel="apple-touch-icon" sizes="76x76" href="/static/apple-icon-76x76.png" />
            <link rel="apple-touch-icon" sizes="114x114" href="/static/apple-icon-114x114.png" />
            <link rel="apple-touch-icon" sizes="120x120" href="/static/apple-icon-120x120.png" />
            <link rel="apple-touch-icon" sizes="144x144" href="/static/apple-icon-144x144.png" />
            <link rel="apple-touch-icon" sizes="152x152" href="/static/apple-icon-152x152.png" />
            <link rel="apple-touch-icon" sizes="180x180" href="/static/apple-icon-180x180.png" />
            <link rel="icon" type="image/png" sizes="192x192" href="/static/android-icon-192x192.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/static/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="96x96" href="/static/favicon-96x96.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/static/favicon-16x16.png" />
            <link rel="manifest" href="/static/manifest.json" />
            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta name="msapplication-TileImage" content="/static/ms-icon-144x144.png" />
            <link rel="canonical" href={url} />
            <base href="/" />
            <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="theme-color" content="#234d20" />
            <meta name="description" content={data.description} />
            <meta name="keywords" content={keywords || data.keywords} />
            <meta property="og:title" content={title || "Craftserve"} />
            <meta property="og:description" content={data.description} />
            <meta property="og:keywords" content={keywords || data.keywords} />
            <meta property="og:type" content={ogType || "website"} />
            <meta property="og:image" content={ogImage || Logo} />
            <meta property="og:url" content={url || "https://craftserve.com"} />
            <meta property="og:locale" content={locales[language as keyof typeof locales]} />
            <meta property="twitter:title" content={title || "Craftserve"} />
            <meta property="twitter:card" content={twitterCard || "summary_large_image"} />
            <meta property="twitter:url" content={url || "https://craftserve.com"} />
            <meta property="twitter:description" content={data.description} />
            <meta property="twitter:image" content={twitterImage} />
        </Helmet>
    );
};

export default SEO;
