import { MdError } from "@react-icons/all-files/md/MdError";
import { Button, Paragraph, Space } from "csrvui";
import { useTranslation } from "react-i18next";
import { StyledLoaderSmall } from "../../../../shared/components/PanelLoader/style";
import ResponsiveModal from "../../../../shared/components/ResponsiveModal";
import useTheme from "../../../../shared/hooks/useCustomTheme";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    error?: boolean;
}

const PackageCreationProgressModal = ({ isOpen, onClose, error }: Props) => {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <ResponsiveModal
            center
            styles={{
                modal: {
                    borderRadius: "8px",
                    padding: "20px",
                    maxWidth: "550px",
                },
            }}
            open={isOpen}
            onClose={onClose}
        >
            <Space $padding={0}>
                {error ? (
                    <>
                        <Space
                            style={{
                                color: theme.colors.danger,
                            }}
                            $oppositeAlign="center"
                            $direction="horizontal"
                            $padding={0}
                        >
                            <Space $padding={0} style={{ marginTop: "-3px" }}>
                                <MdError size={24} />
                            </Space>
                            <Paragraph
                                style={{
                                    color: theme.colors.danger,
                                    fontWeight: 550,
                                }}
                                $level={3}
                            >
                                {t("marketplace.package_creation_failed")}
                            </Paragraph>
                        </Space>
                        <Paragraph>{t("marketplace.package_creation_failed_subtitle")}</Paragraph>
                    </>
                ) : (
                    <>
                        <Space $oppositeAlign="center" $direction="horizontal" $padding={0}>
                            <StyledLoaderSmall />
                            <Paragraph
                                style={{
                                    fontWeight: 550,
                                }}
                                $level={3}
                            >
                                {t("marketplace.package_creation_in_progress")}
                            </Paragraph>
                        </Space>

                        <Paragraph $level={4}>{t("marketplace.package_creation_in_progress_subtitle")}</Paragraph>
                    </>
                )}
                <Space $padding="20px 0 0 0">
                    <Button onClick={onClose} $variant={error ? "danger" : "success"}>
                        {t("common.continue")}
                    </Button>
                </Space>
            </Space>
        </ResponsiveModal>
    );
};

export default PackageCreationProgressModal;
