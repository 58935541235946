// we want to have any there cuz capabilities can store any type of data
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const hasCapabilityInObject = (object: Record<string, any>, capability: string) => {
    const fields = capability.split(".");
    let value = false;
    fields.forEach((f) => {
        const key = fields.slice(0, fields.indexOf(f) + 1).join(".");
        if (object[key]) {
            value = object[key];
        }
    });

    return value;
};
