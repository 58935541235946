import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { UserPermissions } from "../../../shared/types";
import { PaymentType } from "../../Server/types";
import { selectCurrentUser, userPermissions } from "../slices/selectors";
import * as UserTypes from "../types";
import useVerify from "./useVerify";

export default function () {
    const user = useSelector(selectCurrentUser);
    const { isLoading, refresh } = useVerify();
    const permissions = useSelector(userPermissions);

    const updateUserData = useCallback(() => {
        refresh();
    }, [refresh]);

    const canPayByPaypal = Object.hasOwn(permissions, UserPermissions.PAYPAL);
    const canPayByPayU = Object.hasOwn(permissions, UserPermissions.PAYU);
    const canPayByBlik = Object.hasOwn(permissions, UserPermissions.BLIK);
    const hasCraftumPermission = Object.hasOwn(permissions, UserPermissions.CRAFTUM);
    const hasOffersPermission = Object.hasOwn(permissions, UserPermissions.OFFERS);

    const getAvailablePaymentMethods = useCallback(() => {
        const methods = [];
        if (canPayByPaypal) {
            methods.push(PaymentType.Paypal);
        }
        if (canPayByPayU) {
            methods.push(PaymentType.Payu);
        }
        if (canPayByBlik) {
            methods.push(PaymentType.Blik);
        }
        return methods;
    }, [canPayByPaypal, canPayByPayU, canPayByBlik]);

    const availablePaymentMethods = useMemo(() => getAvailablePaymentMethods(), [getAvailablePaymentMethods]);

    return {
        user: user as UserTypes.User,
        updateUserData,
        isLoading,
        canPayByPayU,
        canPayByPaypal,
        hasCraftumPermission,
        hasOffersPermission,
        availablePaymentMethods,
        getAvailablePaymentMethods,
    };
}
