import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useLocation } from "react-router-dom";
import { useAppSelector } from "../../../../hooks";
import PanelLoader from "../../../../shared/components/PanelLoader";
import useVerify from "../../hooks/useVerify";
import { selectCurrentUser, wasUserVerified } from "../../slices/selectors";
import { Props } from "./types";

const ProtectedRoute: React.FC<Props> = ({ children }) => {
    const location = useLocation();
    const { t } = useTranslation();
    const { isLoading, isLoggedIn, verifyUser } = useVerify();
    const isVerified = useAppSelector(wasUserVerified);
    const user = useAppSelector(selectCurrentUser);

    useEffect(() => {
        if (!isVerified) {
            verifyUser();
        }
    }, [isVerified, verifyUser]);

    if ((!isLoading && !isLoggedIn) || !user) {
        return <Navigate to="/login" replace state={{ from: `${location.pathname}${location.search}` }} />;
    }

    return (
        <>
            <PanelLoader text={t("loading.authorizing")} inProp={isLoading} />
            {isLoggedIn ? children : null}
        </>
    );
};

export default ProtectedRoute;
