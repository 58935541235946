import { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import useVerify from "../../../modules/User/hooks/useVerify";
import { ProcessesContext } from "../../hooks/ProcessesContext";
import ProcessesContainer from "../ProcessesContainer";

interface Props {
    children: JSX.Element;
    id?: string;
    hideMenu?: boolean;
    dark?: boolean;
}

const Wrapper = styled.div`
    position: relative;
    min-height: 100vh;

    & > div {
        height: 100%;
        margin-bottom: 80px;
    }

    &.with-padding {
        padding-bottom: 180px;

        @media (max-width: 1000px) {
            padding-bottom: 0px;
        }

        @media (min-width: 1500px) {
            padding-bottom: 250px;
        }
    }

    &.without-padding {
        padding-bottom: 0;
    }
`;

const PanelWrapper: React.FC<Props> = ({ children, id }) => {
    const { isLoggedIn } = useVerify();
    const [isProcessVisible, setProcessVisible] = useState(false);
    const processContainerRef = useRef<HTMLDivElement>(null);
    const { processes } = useContext(ProcessesContext);

    // Monitorujemy zmiany w procesach i dynamicznie sprawdzamy, czy `ProcessesContainer` jest widoczny
    useEffect(() => {
        if (!processContainerRef.current) return undefined;

        const observer = new IntersectionObserver(
            ([entry]) => {
                setProcessVisible(entry.isIntersecting);
            },
            { root: null, threshold: 0 }
        );

        observer.observe(processContainerRef.current);

        const observerTarget = processContainerRef.current;
        return () => {
            if (observerTarget) {
                observer.unobserve(observerTarget);
            }
        };
    }, [processes]);

    return (
        <>
            {/* <PanelHeader hideMenu={hideMenu} dark={dark} /> */}
            <Wrapper id={id} className={isProcessVisible ? "with-padding" : "without-padding"}>
                {children}
            </Wrapper>
            {isLoggedIn && <ProcessesContainer ref={processContainerRef} />}
        </>
    );
};

export default PanelWrapper;
