import { configureStore } from "@reduxjs/toolkit";
import { adminApi } from "./modules/Admin/api";
import * as BillingsApi from "./modules/Billings/api";
import { craftumsApi } from "./modules/Craftums/api";
import * as MarketplaceApi from "./modules/Marketplace/api";
import * as PaymentsApi from "./modules/Payments/api";
import * as ServerApi from "./modules/Server/api";
import { clusterApi } from "./modules/Server/clusterApi";
import * as BackupsApi from "./modules/Server/modules/Backups/api";
import { api as walletsApi } from "./modules/Server/modules/Wallets/api";
import { ServicesApi } from "./modules/Services";
import * as UserApi from "./modules/User/api";
import { supportApi } from "./modules/User/api";

import reducer from "./rootReducer";

export const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false }).concat(
            BillingsApi.api.middleware,
            ServerApi.api.middleware,
            ServerApi.eventLogsApi.middleware,
            ServerApi.domainsApi.middleware,
            clusterApi.middleware,
            PaymentsApi.paymentsApi.middleware,
            UserApi.api.middleware,
            ServicesApi.api.middleware,
            BackupsApi.api.middleware,
            MarketplaceApi.marketplaceApi.middleware,
            supportApi.middleware,
            walletsApi.middleware,
            craftumsApi.middleware,
            adminApi.middleware,
            ServerApi.packagesApi.middleware
        ),
});

export type AppDispatch = typeof store.dispatch;
// export type RootState = ReturnType<typeof reducer>;
