import { useEffect, useState } from "react";

const useVideo = (src?: string, projectThumbnail?: string) => {
    const [thumbnail, setThumbnail] = useState<string>();

    useEffect(() => {
        if (!src || projectThumbnail) return undefined;

        const video = document.createElement("video");
        const canvas = document.createElement("canvas");

        video.src = src;
        video.muted = true;
        video.crossOrigin = "anonymous";
        video.load();

        const generateThumbnail = () => {
            const timeToSeek = Math.min(10, video.duration / 2);
            video.currentTime = timeToSeek;

            video.addEventListener(
                "seeked",
                () => {
                    if (video.videoWidth && video.videoHeight) {
                        canvas.width = video.videoWidth;
                        canvas.height = video.videoHeight;

                        const ctx = canvas.getContext("2d");
                        if (ctx) {
                            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
                            const thumbnailURL = canvas.toDataURL("image/png");
                            setThumbnail(thumbnailURL);
                        }
                    }
                },
                { once: true }
            );
        };

        const handleLoadedData = () => {
            generateThumbnail();
        };

        // const handleError = () => {
        //     console.error("An error occured when generating an thumbnail", video);
        // };

        video.addEventListener("canplay", handleLoadedData, { once: true });
        // video.addEventListener("error", handleError, { once: true });

        return () => {
            video.removeEventListener("canplay", handleLoadedData);
            // video.removeEventListener("error", handleError);
            video.removeEventListener("seeked", generateThumbnail);
            video.remove();
        };
    }, [projectThumbnail, src]);

    return { thumbnail };
};

export default useVideo;
