import { RiDownloadLine } from "@react-icons/all-files/ri/RiDownloadLine";
import { InternalLink, Paragraph, Space } from "csrvui";
import { useTranslation } from "react-i18next";
import { To, useParams } from "react-router-dom";
import { MarketplaceTranslatedLabels } from "../../../../constants";
import useTheme from "../../../../shared/hooks/useCustomTheme";
import useProjectAssets from "../../hooks/useProjectAssets";
import { MarketProject } from "../../types";
import MarketplaceProjectRowSkeleton from "../MarketplaceProjectRowSkeleton";
import {
    Container,
    DescriptionParagraph,
    InstallButtonContainer,
    LabelsContainer,
    StyledChip,
    StyledImage,
    StyledLink,
    TitleParagraph,
} from "./style";

interface Props {
    project?: MarketProject;
    to: To;
}

const MarketplaceProjectRow = ({ project, to }: Props) => {
    const theme = useTheme();
    const isMobile = window.innerWidth < 450;
    const { serverIdentity } = useParams();
    const { t } = useTranslation();
    const marketplaceUrl = serverIdentity ? `/s/${serverIdentity}/m` : "/m";
    const { icon } = useProjectAssets(project);

    if (!project) {
        return <MarketplaceProjectRowSkeleton />;
    }

    return (
        <StyledLink
            data-ph-capture-attribute-id="marketplace_project_row:link"
            data-ph-capture-attribute-to={to}
            to={to}
        >
            <Container>
                {isMobile ? (
                    <>
                        <StyledImage
                            alt={project.name}
                            src={icon || `https://placehold.co/100x100?text=${project.name}`}
                        />
                        <TitleParagraph $level={3}>{project.name}</TitleParagraph>
                        <DescriptionParagraph>{project.short_description}</DescriptionParagraph>
                        <LabelsContainer>
                            {project.labels
                                .filter((label) => !label.includes("csrv:endorsed"))
                                .map((label) => (
                                    <StyledChip
                                        key={label}
                                        bgColor={theme.colors.primaryBackground}
                                        textColor={theme.colors.dark}
                                        textProps={{
                                            style: {
                                                fontWeight: 500,
                                            },
                                        }}
                                    >
                                        {label in MarketplaceTranslatedLabels
                                            ? MarketplaceTranslatedLabels[
                                                  label as keyof typeof MarketplaceTranslatedLabels
                                              ]
                                            : label}
                                    </StyledChip>
                                ))}
                        </LabelsContainer>
                        <InternalLink
                            data-ph-capture-attribute-id="marketplace_project_row:project_link"
                            data-ph-capture-attribute-to={`${marketplaceUrl}/${project.slug}`}
                            $underline={false}
                            $filled
                            $sizeVariant="big"
                            $variant="primary"
                            to={`${marketplaceUrl}/${project.slug}`}
                        >
                            <InstallButtonContainer>
                                <Space $padding={0}>
                                    <RiDownloadLine size={20} />
                                </Space>
                                {t("common.install")}
                            </InstallButtonContainer>
                        </InternalLink>
                    </>
                ) : (
                    <>
                        <Space $wrap={false} $direction="horizontal" $padding={0}>
                            <StyledImage
                                alt={project.name}
                                src={icon || `https://placehold.co/100x100?text=${project.name}`}
                            />
                            <Space $align="start">
                                <Space $direction="horizontal" $oppositeAlign="center" $padding={0}>
                                    <TitleParagraph $level={3}>{project.name}</TitleParagraph>
                                    <Paragraph>{project.author_info.author_name}</Paragraph>
                                </Space>
                                <DescriptionParagraph>{project.short_description}</DescriptionParagraph>
                                <LabelsContainer>
                                    {project.labels &&
                                        project.labels
                                            .filter((label) => !label.includes("csrv:endorsed"))
                                            .map((label) => (
                                                <StyledChip
                                                    key={label}
                                                    bgColor={theme.colors.primaryBackground}
                                                    textColor={theme.colors.dark}
                                                    textProps={{
                                                        style: {
                                                            fontWeight: 500,
                                                        },
                                                    }}
                                                >
                                                    {label in MarketplaceTranslatedLabels
                                                        ? MarketplaceTranslatedLabels[
                                                              label as keyof typeof MarketplaceTranslatedLabels
                                                          ]
                                                        : label}
                                                </StyledChip>
                                            ))}
                                </LabelsContainer>
                            </Space>
                        </Space>
                        <Space $fullWidth={false} $padding={0}>
                            <InternalLink
                                data-ph-capture-attribute-id="marketplace_project_row:project_link"
                                data-ph-capture-attribute-to={`${marketplaceUrl}/${project.slug}`}
                                $underline={false}
                                $filled
                                $fullWidth={false}
                                $sizeVariant="big"
                                $variant="primary"
                                to={`${marketplaceUrl}/${project.slug}`}
                            >
                                <InstallButtonContainer>
                                    <Space $padding={0}>
                                        <RiDownloadLine size={20} />
                                    </Space>
                                    {t("common.install")}
                                </InstallButtonContainer>
                            </InternalLink>
                        </Space>
                    </>
                )}
            </Container>
        </StyledLink>
    );
};

export default MarketplaceProjectRow;
