import { IoMdInfinite } from "@react-icons/all-files/io/IoMdInfinite";
import { Important, Paragraph, Space, Title } from "csrvui";
import { useTranslation } from "react-i18next";
import AmethystCardBackground from "../../../assets/images/amethyst_bg.webp";
import AmethystFloatingIcon from "../../../assets/images/amethyst_floating.webp";
import AmethystIcon from "../../../assets/images/amethyst_icon.png";
import AmethystTumb from "../../../assets/images/amethyst_thumb.webp";
import AmethystSmallIcon from "../../../assets/images/block-of-amethyst-small.webp";
import DirtSmallIcon from "../../../assets/images/block-of-dirt-small.webp";
import GrassSmallIcon from "../../../assets/images/block-of-grass-small.webp";
import GrassFloatingIcon from "../../../assets/images/cloud.webp";
import CraftumIcon from "../../../assets/images/CraftumPlaceholderIcon.svg";
import DirtIcon from "../../../assets/images/dirt.webp";
import DirtCardBackground from "../../../assets/images/dirt_bg.svg";
import DirtTumb from "../../../assets/images/dirt_thumb.webp";
import FeatureDirtIcon from "../../../assets/images/FeatureDirtIcon.webp";
import GrassCardBackground from "../../../assets/images/grass_bg.svg";
import GrassIcon from "../../../assets/images/grass_icon.png";
import grass from "../../../assets/images/grass_thumb.webp";
import FeatureGrassIcon from "../../../assets/images/grass_triangle.svg";
import FeatureAmethystIcon from "../../../assets/images/product_showcase_triangle.svg";
import { Details } from "../../Billings/components/LandingProductCard/style";

export interface ProductStrategy {
    details(): React.ReactNode;
    getProductIcon(): string;
    getProductThumb(): string;
    tableFeatures(): { label: string; value: string }[];
    renderFormHeading(): React.ReactNode;
    renderModalContent(): React.ReactNode;
    getCardThumb(): string;
    getCardFloatingIcon(): string;
    features(): { primaryText: string | React.ReactNode; secondaryText: string }[];
    getProductScheme(): string;
    getFeatures(): boolean[];
    getFeatureIcon(): string;
    getProductName(): string;
    getProductAssets(): {
        thumb: string;
        icon: string;
        cardThumbnail: string;
        cardFloatingIcon: string;
        featureIcon: string;
        smallIcon: string;
    };
    getProductCardOutline(): string;
}

const common = {
    name: "Amethyst",
    scheme: "#AC2EC0",
    images: {
        thumb: AmethystTumb,
        icon: AmethystIcon,
        cardThumbnail: AmethystCardBackground,
        cardFloatingIcon: AmethystFloatingIcon,
        featureIcon: FeatureAmethystIcon,
        smallIcon: AmethystSmallIcon,
    },
};

export class AmethystStrategy implements ProductStrategy {
    private common = common;

    features = () => {
        const { t } = useTranslation();

        return [
            {
                primaryText: <IoMdInfinite size={35} />,
                secondaryText: t("productDetails.players"),
            },
            {
                primaryText: <IoMdInfinite size={35} />,
                secondaryText: t("productDetails.ram"),
            },
            {
                primaryText: <IoMdInfinite size={35} />,
                secondaryText: t("productDetails.plugins"),
            },
            {
                primaryText: "4",
                secondaryText: "VCORE CPU",
            },
        ];
    };

    tableFeatures = () => {
        const { t } = useTranslation();

        return [
            {
                label: "vCPU Zen4 4GHz+",
                value: "4",
            },
            {
                label: t("productDetails.disk"),
                value: "10GB",
            },
            {
                label: t("productDetails.addons_from_modpile"),
                value: t("common.yes"),
            },
            {
                label: t("productDetails.external_addons"),
                value: t("common.yes"),
            },
            {
                label: t("productDetails.all_engines"),
                value: t("common.yes"),
            },
        ];
    };

    details = (): React.ReactNode => {
        const Component = () => {
            const { t } = useTranslation();

            return (
                <Details>
                    <Paragraph>{t("productDetails.amethyst.product_info")}</Paragraph>
                </Details>
            );
        };

        return <Component />;
    };

    renderModalContent = (): React.ReactNode => {
        const Component = () => {
            const { t } = useTranslation();

            const content = {
                title: t("productDetails.amethyst.title"),
                features: [
                    t("productDetails.amethyst.desc"),
                    t("productDetails.amethyst.desc2"),
                    t("productDetails.amethyst.desc3"),
                    t("productDetails.amethyst.desc4"),
                    t("productDetails.amethyst.desc5"),
                    // t("productDetails.amethyst.desc6"),
                ],
            };

            return (
                <>
                    <Title style={{ marginBottom: 8 }}>{content.title}</Title>

                    <Space $fullWidth $padding={0}>
                        {content.features.map((feature: string) => (
                            <Paragraph $level={4} style={{ fontWeight: 700 }} key={feature}>
                                {feature}
                            </Paragraph>
                        ))}
                    </Space>
                </>
            );
        };

        return <Component />;
    };

    getProductIcon = (): string => this.common.images.icon;

    getProductThumb = (): string => this.common.images.thumb;

    getCardThumb = (): string => this.common.images.cardThumbnail;

    getCardFloatingIcon = (): string => this.common.images.cardFloatingIcon;

    getProductScheme = (): string => this.common.scheme;

    getFeatureIcon = (): string => this.common.images.featureIcon;

    getProductName = (): string => this.common.name;

    getProductCardOutline = (): string => "linear-gradient(180deg, #CE26BD 0%, #AE38E4 100%)";

    getProductAssets = () => this.common.images;

    renderFormHeading = (): React.ReactNode => {
        const Component = () => {
            const { t } = useTranslation();

            return (
                <>
                    <Space
                        style={{ borderRadius: "12px" }}
                        $background={common.scheme}
                        $width="163px"
                        $height="163px"
                        $align="center"
                    >
                        <img width={120} alt="Offer icon" src={common.images.icon} />
                    </Space>
                    <Space $width="700px" $align="start">
                        <Paragraph $level={2} $align="left" style={{ width: "100%", fontWeight: 500 }}>
                            {t("create_server_form.create_new")} {common.name}
                        </Paragraph>
                        <Paragraph>{t("products.amethyst.desc")}</Paragraph>
                        <Paragraph>{t("products.amethyst.desc2")}</Paragraph>
                        <Paragraph>{t("products.amethyst.desc3")}</Paragraph>
                    </Space>
                </>
            );
        };

        return <Component />;
    };

    getFeatures = (): boolean[] => new Array(7).fill(true);
}

const freeCommon = {
    name: "FREE",
    scheme: "#86592C",
    images: {
        thumb: DirtTumb,
        icon: DirtIcon,
        cardThumbnail: DirtCardBackground,
        cardFloatingIcon: GrassFloatingIcon,
        featureIcon: FeatureDirtIcon,
        smallIcon: DirtSmallIcon,
    },
};
export class FreeStrategy implements ProductStrategy {
    features = () => {
        const { t } = useTranslation();

        return [
            {
                primaryText: "5",
                secondaryText: t("productDetails.players"),
            },
            {
                primaryText: "2GB",
                secondaryText: t("productDetails.ram"),
            },
            {
                primaryText: "5",
                secondaryText: t("productDetails.plugins"),
            },
            {
                primaryText: "1",
                secondaryText: "VCORE CPU",
            },
        ];
    };

    tableFeatures = () => {
        const { t } = useTranslation();

        return [
            {
                label: "vCPU Zen4 4GHz+",
                value: "1",
            },
            {
                label: t("productDetails.disk"),
                value: "1GB",
            },

            {
                label: t("productDetails.addons_from_modpile"),
                value: t("common.yes"),
            },
            {
                label: t("productDetails.external_addons"),
                value: t("common.no"),
            },
            {
                label: t("productDetails.plugins_engines"),
                value: t("common.yes"),
            },
        ];
    };

    details = (): React.ReactNode => {
        const Component = () => {
            const { t } = useTranslation();

            return (
                <Details>
                    <Paragraph>{t("productDetails.dirt.product_info")}</Paragraph>
                </Details>
            );
        };

        return <Component />;
    };

    renderModalContent = (): React.ReactNode => {
        const Component = () => {
            const { t } = useTranslation();

            const content = {
                title: t("productDetails.dirt.title"),
                features: [
                    t("productDetails.dirt.desc"),
                    t("productDetails.dirt.desc2"),
                    t("productDetails.dirt.desc3"),
                    t("productDetails.dirt.desc4"),
                    t("productDetails.dirt.desc5"),
                    t("productDetails.dirt.desc6"),
                    t("productDetails.dirt.desc7"),
                    // t("productDetails.amethyst.desc6"),
                ],
            };

            return (
                <>
                    <Title style={{ marginBottom: 8 }}>{content.title}</Title>
                    <Space $padding={0}>
                        {content.features.map((feature: string) => (
                            <Paragraph $level={4} style={{ fontWeight: 700 }} key={feature}>
                                {feature}
                            </Paragraph>
                        ))}
                    </Space>
                </>
            );
        };

        return <Component />;
    };

    getProductIcon = (): string => freeCommon.images.icon;

    getProductThumb = (): string => freeCommon.images.thumb;

    getCardThumb = (): string => freeCommon.images.cardThumbnail;

    getCardFloatingIcon = (): string => freeCommon.images.cardFloatingIcon;

    getProductScheme = (): string => freeCommon.scheme;

    getFeatureIcon = (): string => freeCommon.images.featureIcon;

    getProductName = (): string => freeCommon.name;

    getProductCardOutline = (): string => "linear-gradient(180deg, #95683A 0%, #82572C 100%)";

    getProductAssets = () => freeCommon.images;

    renderFormHeading = (): React.ReactNode => {
        const Component = () => {
            const { t } = useTranslation();

            return (
                <>
                    <Space
                        style={{ borderRadius: "12px" }}
                        $background={freeCommon.scheme}
                        $width="163px"
                        $height="163px"
                        $align="center"
                    >
                        <img width={120} alt="Offer icon" src={freeCommon.images.icon} />
                    </Space>
                    <Space $width="700px" $align="start">
                        <Paragraph $level={2} $align="left" style={{ width: "100%", fontWeight: 500 }}>
                            {t("create_server_form.create_new")} {freeCommon.name}
                        </Paragraph>
                        <Paragraph>{t("products.dirt.desc")}</Paragraph>
                        <Paragraph>{t("products.dirt.desc2")}</Paragraph>
                        <Paragraph>{t("products.dirt.desc3")}</Paragraph>
                    </Space>
                </>
            );
        };

        return <Component />;
    };

    getFeatures = (): boolean[] => new Array(7).fill(true);
}

export class CraftumStrategy implements ProductStrategy {
    private common = {
        name: "Craftum",
        scheme: "#5100FF",
        images: {
            thumb: CraftumIcon,
            icon: CraftumIcon,
            cardThumbnail: GrassCardBackground,
            cardFloatingIcon: GrassFloatingIcon,
            featureIcon: FeatureGrassIcon,
            smallIcon: GrassSmallIcon,
        },
    };

    features = () => {
        return [];
    };

    tableFeatures = () => {
        return [];
    };

    details = (): React.ReactNode => {
        const Component = () => {
            const { t } = useTranslation();

            // If you want to change the details of the product, you should also look at faq component at diamond_vs_amethyst.content
            return (
                <Details>
                    <Paragraph>
                        <Important>{t("productDetails.amethyst.no_player_limit")}</Important>
                    </Paragraph>
                    <Paragraph>
                        <Important>{t("productDetails.amethyst.up_to_24GB_ram")}</Important>{" "}
                        {t("productDetails.amethyst.and")}{" "}
                        <Important>{t("productDetails.amethyst.4_cpu_cores")}</Important>
                    </Paragraph>
                    <Paragraph>
                        <Important>{t("productDetails.amethyst.10GB_nvme_ssd")}</Important>
                    </Paragraph>
                    <Paragraph>{t("productDetails.amethyst.premium_non_premium_players")}</Paragraph>
                    <Paragraph>{t("productDetails.amethyst.server_types")}</Paragraph>
                    <Paragraph style={{ textDecoration: "line-through" }}>
                        {t("productDetails.amethyst.itemshop_map_mysql")}
                    </Paragraph>
                    <Paragraph>{t("productDetails.amethyst.backups")}</Paragraph>
                </Details>
            );
        };

        return <Component />;
    };

    renderModalContent = (): React.ReactNode => {
        const Component = () => {
            const { t } = useTranslation();

            const content = {
                title: t("productDetails.craftum.title"),
                features: [t("productDetails.craftum.desc")],
            };

            return (
                <>
                    <Title style={{ marginBottom: 8 }}>{content.title}</Title>
                    <Space $padding={0}>
                        {content.features.map((feature: string) => (
                            <Paragraph $level={4} style={{ fontWeight: 700 }} key={feature}>
                                {feature}
                            </Paragraph>
                        ))}
                    </Space>
                </>
            );
        };

        return <Component />;
    };

    getProductIcon = (): string => this.common.images.icon;

    getProductThumb = (): string => this.common.images.thumb;

    getCardThumb = (): string => this.common.images.cardThumbnail;

    getCardFloatingIcon = (): string => this.common.images.cardFloatingIcon;

    getProductScheme = (): string => this.common.scheme;

    getFeatureIcon = (): string => this.common.images.featureIcon;

    getProductName = (): string => this.common.name;

    getProductCardOutline = (): string => "linear-gradient(180deg, #02BB68 0%, #0C8A52 100%)";

    getProductAssets = () => this.common.images;

    renderFormHeading = (): React.ReactNode => {
        const Component = () => {
            const { t } = useTranslation();

            return (
                <>
                    <Space
                        style={{ borderRadius: "12px" }}
                        $background={common.scheme}
                        $width="163px"
                        $height="163px"
                        $align="center"
                    >
                        <img width={120} alt="Offer icon" src={common.images.icon} />
                    </Space>
                    <Space $width="700px" $align="start">
                        <Paragraph $level={2} $align="left" style={{ width: "100%", fontWeight: 500 }}>
                            {t("create_server_form.create_new")} {common.name}
                        </Paragraph>
                        <Paragraph>{t("products.craftum.desc")}</Paragraph>
                    </Space>
                </>
            );
        };

        return <Component />;
    };

    getFeatures = (): boolean[] => new Array(7).fill(true);
}

const grassCommon = {
    name: "Grass",
    scheme: "#02BB68",
    images: {
        thumb: grass,
        icon: GrassIcon,
        cardThumbnail: GrassCardBackground,
        cardFloatingIcon: GrassFloatingIcon,
        featureIcon: FeatureGrassIcon,
        smallIcon: GrassSmallIcon,
    },
};
export class GrassStrategy implements ProductStrategy {
    private common = grassCommon;

    features = () => {
        const { t } = useTranslation();

        return [
            {
                primaryText: "10",
                secondaryText: t("productDetails.players"),
            },
            {
                primaryText: "4GB",
                secondaryText: t("productDetails.ram"),
            },
            {
                primaryText: "5",
                secondaryText: t("productDetails.plugins"),
            },
            {
                primaryText: "2",
                secondaryText: "VCORE CPU",
            },
        ];
    };

    details = (): React.ReactNode => {
        const Component = () => {
            const { t } = useTranslation();

            return (
                <Details>
                    <Paragraph>{t("productDetails.grass.product_info")}</Paragraph>
                    {/* <Paragraph>{t("productDetails.grass.backups")}</Paragraph> */}
                </Details>
            );
        };

        return <Component />;
    };

    tableFeatures = () => {
        const { t } = useTranslation();

        return [
            {
                label: "vCPU Zen4 4GHz+",
                value: "2",
            },
            {
                label: t("productDetails.disk"),
                value: "5GB",
            },

            {
                label: t("productDetails.addons_from_modpile"),
                value: t("common.yes"),
            },
            {
                label: t("productDetails.external_addons"),
                value: t("common.yes"),
            },
            {
                label: t("productDetails.plugins_engines"),
                value: t("common.yes"),
            },
        ];
    };

    renderModalContent = (): React.ReactNode => {
        const Component = () => {
            const { t } = useTranslation();

            const content = {
                title: t("productDetails.grass.title"),
                features: [
                    t("productDetails.grass.desc"),
                    t("productDetails.grass.desc2"),
                    t("productDetails.grass.desc3"),
                    t("productDetails.grass.desc4"),
                    t("productDetails.grass.desc5"),
                ],
            };

            return (
                <>
                    <Title style={{ marginBottom: 8 }}>{content.title}</Title>
                    <Space $padding={0}>
                        {content.features.map((feature: string) => (
                            <Paragraph $level={4} style={{ fontWeight: 700 }} key={feature}>
                                {feature}
                            </Paragraph>
                        ))}
                    </Space>
                </>
            );
        };

        return <Component />;
    };

    getProductIcon = (): string => this.common.images.icon;

    getProductThumb = (): string => this.common.images.thumb;

    getProductScheme = (): string => this.common.scheme;

    getCardThumb = (): string => this.common.images.cardThumbnail;

    getCardFloatingIcon = (): string => this.common.images.cardFloatingIcon;

    getFeatureIcon = (): string => this.common.images.featureIcon;

    getProductName = (): string => this.common.name;

    getProductCardOutline = (): string => "linear-gradient(180deg, #02BB68 0%, #0C8A52 100%)";

    getProductAssets = () => this.common.images;

    renderFormHeading = (): React.ReactNode => {
        const Component = () => {
            const { t } = useTranslation();

            return (
                <>
                    <Space
                        style={{ borderRadius: "12px" }}
                        $background={grassCommon.scheme}
                        $width="163px"
                        $height="163px"
                        $align="center"
                    >
                        <img width={120} alt="Offer icon" src={grassCommon.images.icon} />
                    </Space>
                    <Space $width="700px" $align="start">
                        <Paragraph $level={2} $align="left" style={{ width: "100%", fontWeight: 500 }}>
                            {t("create_server_form.create_new")} {grassCommon.name}
                        </Paragraph>
                        <Paragraph>{t("products.grass.desc")}</Paragraph>
                        <Paragraph>{t("products.grass.desc2")}</Paragraph>
                        <Paragraph>{t("products.grass.desc3")}</Paragraph>
                    </Space>
                </>
            );
        };

        return <Component />;
    };

    getFeatures = (): boolean[] => [true, true, true, true, false, false, false];
}

export class DefaultStrategy implements ProductStrategy {
    private common = {
        name: "",
        scheme: "#02BB68",
        images: {
            thumb: grass,
            icon: GrassIcon,
            cardThumbnail: GrassCardBackground,
            cardFloatingIcon: GrassFloatingIcon,
            featureIcon: FeatureGrassIcon,
            smallIcon: GrassSmallIcon,
        },
    };

    features = () => {
        const { t } = useTranslation();

        return [
            {
                primaryText: "-",
                secondaryText: t("productDetails.players"),
            },
            {
                primaryText: "-",
                secondaryText: t("productDetails.ram"),
            },
            {
                primaryText: "-",
                secondaryText: t("productDetails.plugins"),
            },
            {
                primaryText: "-",
                secondaryText: "VCORE CPU",
            },
        ];
    };

    details = (): React.ReactNode => {
        const Component = () => {
            const { t } = useTranslation();

            return (
                <Details>
                    <Paragraph>{t("productDetails.unknown.product_info")}</Paragraph>
                    {/* <Paragraph>{t("productDetails.grass.backups")}</Paragraph> */}
                </Details>
            );
        };

        return <Component />;
    };

    tableFeatures = () => {
        const { t } = useTranslation();

        return [
            {
                label: "vCPU Zen4 4GHz+",
                value: "-",
            },
            {
                label: t("productDetails.disk"),
                value: "-",
            },

            {
                label: t("productDetails.addons_from_modpile"),
                value: "-",
            },
            {
                label: t("productDetails.external_addons"),
                value: "-",
            },
            {
                label: t("productDetails.all_engines"),
                value: "-",
            },
        ];
    };

    renderModalContent = (): React.ReactNode => {
        const Component = () => {
            const { t } = useTranslation();

            const content = {
                title: t("productDetails.unknown.title"),
                features: [
                    t("productDetails.unknown.desc"),
                    t("productDetails.unknown.desc2"),
                    t("productDetails.unknown.desc3"),
                    t("productDetails.unknown.desc4"),
                    t("productDetails.unknown.desc5"),
                ],
            };

            return (
                <>
                    <Title style={{ marginBottom: 8 }}>{content.title}</Title>
                    <Space $padding={0}>
                        {content.features.map((feature: string) => (
                            <Paragraph $level={4} style={{ fontWeight: 700 }} key={feature}>
                                {feature}
                            </Paragraph>
                        ))}
                    </Space>
                </>
            );
        };

        return <Component />;
    };

    getProductIcon = (): string => common.images.icon;

    getProductThumb = (): string => common.images.thumb;

    getProductScheme = (): string => common.scheme;

    getCardThumb = (): string => common.images.cardThumbnail;

    getCardFloatingIcon = (): string => common.images.cardFloatingIcon;

    getFeatureIcon = (): string => common.images.featureIcon;

    getProductName = (): string => common.name;

    getProductCardOutline = (): string => "linear-gradient(180deg, #02BB68 0%, #0C8A52 100%)";

    getProductAssets = () => this.common.images;

    renderFormHeading = (): React.ReactNode => {
        const Component = () => {
            const { t } = useTranslation();

            return (
                <>
                    <Space
                        style={{ borderRadius: "12px" }}
                        $background={common.scheme}
                        $width="163px"
                        $height="163px"
                        $align="center"
                    >
                        <img width={120} alt="Offer icon" src={common.images.icon} />
                    </Space>
                    <Space $width="700px" $align="start">
                        <Paragraph $level={2} $align="left" style={{ width: "100%", fontWeight: 500 }}>
                            {t("create_server_form.create_new")} {common.name}
                        </Paragraph>
                        <Paragraph>{t("products.unknown.desc")}</Paragraph>
                        <Paragraph>{t("products.unknown.desc2")}</Paragraph>
                        <Paragraph>{t("products.unknown.desc3")}</Paragraph>
                    </Space>
                </>
            );
        };

        return <Component />;
    };

    getFeatures = (): boolean[] => [false, false, false, false, false, false, false];
}
