import { FaEyeSlash } from "@react-icons/all-files/fa/FaEyeSlash";
import { FaLock } from "@react-icons/all-files/fa/FaLock";
import { IoEye } from "@react-icons/all-files/io5/IoEye";
import { Space, Tooltip } from "csrvui";
import { t } from "i18next";
import Chip from "../../../../shared/components/Chip";
import useTheme from "../../../../shared/hooks/useCustomTheme";
import usePermissions from "../../../../shared/hooks/usePermissions";
import useUserData from "../../../User/hooks/useUserData";
import { MarketProject } from "../../types";

interface Props {
    project: MarketProject;
    variant?: "default" | "icon";
}

const ProjectVisibilityChip = ({ project, variant = "default" }: Props) => {
    const theme = useTheme();
    const isIcon = variant === "icon";
    const { user } = useUserData();
    const isProjectMember =
        user &&
        project &&
        project.project_members &&
        project.project_members.some((member) => member.user_id === user.id);
    const { isAdmin } = usePermissions();

    if (!isProjectMember && project.project_type === "public" && !isAdmin) {
        return null;
    }

    const visibility = () => {
        switch (project.project_type) {
            case "private": {
                return {
                    label: t("common.private_type"),
                    tooltip: t("common.private_description"),
                    color: theme.colors.danger,
                    icon: (
                        <FaLock
                            style={{
                                color: isIcon ? theme.colors.danger : theme.colors.white,
                            }}
                        />
                    ),
                };
            }
            case "non_public": {
                return {
                    label: t("common.non_public_type"),
                    tooltip: t("common.non_public_description"),
                    color: theme.colors.warning,
                    icon: (
                        <FaEyeSlash
                            style={{
                                color: isIcon ? theme.colors.warning : theme.colors.white,
                            }}
                        />
                    ),
                };
            }
            default: {
                return {
                    label: t("common.public_type"),
                    tooltip: t("common.public_description"),
                    color: theme.colors.success,
                    icon: (
                        <IoEye
                            style={{
                                color: isIcon ? theme.colors.success : theme.colors.white,
                            }}
                        />
                    ),
                };
            }
        }
    };

    const { color, icon, tooltip, label } = visibility();

    if (isIcon) {
        return (
            <Tooltip
                style={{
                    fontSize: "18px",
                }}
                $position="right"
                text={tooltip}
            >
                <Space style={{ marginTop: "-1px" }} $padding={0}>
                    {icon}
                </Space>
            </Tooltip>
        );
    }

    return (
        <Tooltip $position="right" text={tooltip}>
            <Chip
                style={{
                    display: "flex",
                    borderRadius: "4px",
                    padding: "5px 10px",
                }}
                bgColor={color}
                textProps={{
                    style: {
                        display: "inline-flex",
                        gap: "8px",
                    },
                }}
            >
                {icon}
                {label}
            </Chip>
        </Tooltip>
    );
};

export default ProjectVisibilityChip;
