import { ServerID } from "csrvfs";
import { CsrvProcessState, CsrvProcessType } from "csrvprocess";
import { useState } from "react";
import { ChunkedUploaderClient } from "ts-chunked-uploader/dist";
import { ErrorCode, ModpileUrl } from "../../../../../rootTypes";
import CustomError from "../../../../../shared/CustomError";
import { showApiError } from "../../../../../shared/helpers/showToast";
import { useDisableAutostartAndStopMutation, useInstallPackageMutation, useStopServerMutation } from "../../../api";
import { useProcessNavigation } from "./useProcessNavigation";

function useInstallRelease() {
    const [installPlugin] = useInstallPackageMutation();
    const [isInstalling, setIsInstalling] = useState(false);
    const [serverToNavigate, setServerToNavigate] = useState<string | null>(null);

    useProcessNavigation(serverToNavigate, CsrvProcessType.SERVER_INSTALL_PACKAGE);

    const installRelease = async (
        packageSelectorNameWithHash: string,
        serverId: ServerID,
        onServerIsRunning: () => void,
        onSuccess: () => void,
        options: {
            force: boolean;
            install_deps: boolean;
        }
    ) => {
        try {
            setIsInstalling(true);
            await installPlugin({
                payload: {
                    ...options,
                    package: packageSelectorNameWithHash, // to psuje typy!
                },
                serverId,
            }).unwrap();

            onSuccess();

            document.dispatchEvent(new CustomEvent("appInstalled"));

            setServerToNavigate(serverId);
        } catch (e) {
            if (e instanceof Error && "data" in e) {
                const err = e.data as CustomError;
                if (err.error === ErrorCode.SERVER_IS_RUNNING) {
                    onServerIsRunning();
                }
            } else {
                showApiError(e);
            }
        } finally {
            setIsInstalling(false);
        }
    };

    return { installRelease, isInstalling };
}

export default function useReleases() {
    const { installRelease, isInstalling } = useInstallRelease();
    const [stopServer] = useStopServerMutation();
    const [disableAutostartAndStop] = useDisableAutostartAndStopMutation();

    const stopServerAndInstallRelease = async (
        appWithHash: string,
        target: string,
        clear: () => void,
        hardRestart?: boolean
    ) => {
        const success = await disableAutostartAndStop({
            serverId: target,
            hardRestart,
        });
        if (success) {
            stopServer(target as string);
        }

        window.addEventListener(`process-${CsrvProcessType.STOPPING_GAME}-${CsrvProcessState.DONE}`, async (e) => {
            const detail = e as CustomEvent;
            const serverId = detail.detail.serverId as ServerID;
            if (serverId === target) {
                installRelease(
                    appWithHash,
                    target as string,
                    () => {
                        clear();
                    },
                    () => {
                        clear();
                    },
                    { force: true, install_deps: true }
                );
            }
        });
    };

    const headersInit: HeadersInit = new Headers();

    const uploadFileForRelease = async (file: File, slug: string) => {
        const uploader = new ChunkedUploaderClient(
            `${ModpileUrl}/api/v1/marketplace/projects/${slug}/releases/upload`,
            15 * 1024 * 1024,
            headersInit
        );

        try {
            const resultPath = await uploader.upload(file, "", 15 * 1024 * 1024);
            return resultPath;
        } catch (e) {
            showApiError(e);
            return undefined;
        }
    };

    return { stopServerAndInstallRelease, installRelease, isInstalling, uploadFileForRelease };
}
