import { Button, InternalLink, Paragraph, Space } from "csrvui";
import { useTranslation } from "react-i18next";
import { ModalProps } from "react-responsive-modal";
import useTheme from "../../hooks/useCustomTheme";
import ResponsiveModal from "../ResponsiveModal";

interface Props extends ModalProps {
    onConfirm: string;
}

const RouteChangeAlertModal = ({ onConfirm, ...props }: Props) => {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <ResponsiveModal center {...props}>
            <Space $padding="0 30px 0 0">
                <Paragraph $level={3} style={{ fontWeight: 600 }}>
                    {t("common.route_change_alert_title")}
                </Paragraph>
                <Paragraph>{t("common.route_change_alert_text")}</Paragraph>
            </Space>
            <Space style={{ marginTop: "30px" }} $fullWidth $align="start" $padding={0} $direction="horizontal">
                <Button onClick={() => props.onClose()}>{t("common.cancel")}</Button>
                <InternalLink
                    style={{
                        backgroundColor: theme.colors.danger,
                        color: theme.colors.white,
                        textDecoration: "none",
                    }}
                    onClick={() => props.onClose()}
                    to={onConfirm}
                >
                    {t("common.continue")}
                </InternalLink>
            </Space>
        </ResponsiveModal>
    );
};

export default RouteChangeAlertModal;
