import BlikIcon from "../../../assets/icons/BlikIcon.svg";
import PaypalIcon from "../../../assets/icons/paypal.svg";
import PayuIcon from "../../../assets/icons/payu.svg";
import { PaymentType } from "../../Server/types";

export default function getPaymentImage(paymentMethod: string) {
    switch (paymentMethod) {
        case PaymentType.Paypal:
            return PaypalIcon;
        case PaymentType.Payu:
            return PayuIcon;
        case PaymentType.Blik:
            return BlikIcon;
        default:
            return null;
    }
}
