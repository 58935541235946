/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { equals } from "ramda";
import { Money } from "ts-money";
import * as BillingsTypes from "../../Billings/types";
import { MarketProject } from "../../Marketplace/types";
import * as UserTypes from "../../User/types";
import * as ServerTypes from "../types";

interface CurrentServerSliceState {
    server: {
        data: ServerTypes.ServerWithParameters | null;
        loading: boolean;
    };
    service: {
        data: BillingsTypes.BillingService | null;
        loading: boolean;
    };
    primaryEngine: string | null;
    serviceCost: {
        data: Money | null;
        loading: boolean;
    };
    wallet: {
        data: UserTypes.Wallet | null;
        loading: boolean;
    };
    installedPackages: {
        data: Record<
            string,
            {
                version: string;
                hash: string;
                provides: string[];
                labels: Record<string, string>;
            }
        >;
        loading: boolean;
    };
    installedApps: {
        data: MarketProject[];
        loading: boolean;
    };
}

const currentDataSlice = createSlice({
    name: "currentData",
    initialState: {
        server: {
            data: null,
            loading: true,
        },
        service: {
            data: null,
            loading: true,
        },
        primaryEngine: null,
        serviceCost: {
            data: null,
            loading: true,
        },
        wallet: {
            data: null,
            loading: true,
        },
        installedPackages: {
            data: {},
            loading: true,
        },
        installedApps: {
            data: [],
            loading: true,
        },
        loading: true,
        error: null,
    } as CurrentServerSliceState,
    reducers: {
        setCurrentServer: (state, { payload }: PayloadAction<ServerTypes.ServerWithParameters>) => {
            if (!equals(state.server.data, payload)) {
                state.server = {
                    data: payload,
                    loading: false,
                };
            }
            if (state.primaryEngine !== payload.parameters.game?.engine) {
                state.primaryEngine = payload.parameters.game?.engine || null;
            }
        },
        setCurrentService: (state, { payload }: PayloadAction<BillingsTypes.BillingService>) => {
            if (!equals(state.service.data, payload)) {
                state.service = {
                    data: payload,
                    loading: false,
                };
            }
        },
        setCurrentServiceCost: (state, { payload }: PayloadAction<Money>) => {
            if (!equals(state.serviceCost.data, payload)) {
                state.serviceCost = {
                    data: payload,
                    loading: false,
                };
            }
        },
        setCurrentWallet: (state, { payload }: PayloadAction<UserTypes.Wallet>) => {
            if (!equals(state.wallet.data, payload)) {
                state.wallet = {
                    data: payload,
                    loading: false,
                };
            }
        },
        setInstalledPackages: (
            state,
            {
                payload: { installed },
            }: PayloadAction<{
                installed: Record<
                    string,
                    {
                        version: string;
                        hash: string;
                        provides: string[];
                        labels: Record<string, string>;
                    }
                >;
            }>
        ) => {
            if (!equals(state.installedPackages.data, installed)) {
                state.installedPackages = {
                    data: installed,
                    loading: false,
                };
            }
        },
        setInstalledApps: (state, { payload }: PayloadAction<MarketProject[]>) => {
            if (!equals(state.installedApps.data, payload)) {
                state.installedApps = {
                    data: payload,
                    loading: false,
                };
            }
        },
        setInstalledPackagesLoading: (state, { payload }: PayloadAction<boolean>) => {
            if (state.installedPackages.loading !== payload) {
                state.installedPackages.loading = payload;
            }
        },
        clearSlice: (state) => {
            state.server = {
                data: null,
                loading: true,
            };
            state.service = {
                data: null,
                loading: true,
            };
            state.serviceCost = {
                data: null,
                loading: true,
            };
            state.wallet = {
                data: null,
                loading: true,
            };
            state.installedPackages = {
                data: {},
                loading: true,
            };
            state.installedApps = {
                data: [],
                loading: true,
            };
        },
    },
});

export const {
    setCurrentServer,
    setCurrentService,
    setCurrentServiceCost,
    setCurrentWallet,
    setInstalledPackages,
    setInstalledPackagesLoading,
    setInstalledApps,
    clearSlice,
} = currentDataSlice.actions;

export default currentDataSlice.reducer;
