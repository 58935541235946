import styled from "styled-components";
import { Image, Paragraph, Space } from "csrvui";
import { Link } from "react-router-dom";
import Chip from "../../../../shared/components/Chip";

const mobileBreakpoint = "450px";

export const StyledLink = styled(Link)`
    text-decoration: none;
    width: 100%;
`;

export const Container = styled(Space)`
    background: ${({ theme }) => theme.colors.secondaryBackground};
    width: 100%;
    flex-direction: row;
    padding: 8px 20px;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

    @media (max-width: ${mobileBreakpoint}) {
        flex-direction: column;
        padding: 16px 16px;
        justify-content: flex-start;
    }
`;

export const StyledImage = styled(Image)`
    margin-bottom: 0;

    @media (max-width: ${mobileBreakpoint}) {
        margin-bottom: 12px;
    }
`;

export const TitleParagraph = styled(Paragraph)`
    font-weight: 600;
    text-align: left;
    margin-bottom: 0;
    font-size: 22px;

    @media (max-width: ${mobileBreakpoint}) {
        text-align: center;
        margin-bottom: 8px;
    }
`;

export const DescriptionParagraph = styled(Paragraph)`
    text-align: left;
    margin-bottom: 0;
    max-width: 1200px;

    @media (max-width: ${mobileBreakpoint}) {
        text-align: center;
        margin-bottom: 12px;
        max-width: none;
    }
`;

export const LabelsContainer = styled(Space)`
    padding: 0;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 0;
    min-height: 35px;

    @media (max-width: ${mobileBreakpoint}) {
        justify-content: center;
        margin-bottom: 12px;
        min-height: auto;
    }
`;

export const StyledChip = styled(Chip)`
    border-radius: 4px;
    padding: 5px 10px;
    margin-bottom: 5px;
`;

export const InstallButtonContainer = styled(Space)`
    padding: 0;
    align-items: center;
    flex-direction: row;
`;
