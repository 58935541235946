import {
    Infer,
    any,
    array,
    boolean,
    date,
    instance,
    nullable,
    number,
    object,
    optional,
    record,
    string,
} from "superstruct";
import { Money } from "ts-money";
import { AppliedCoupon, CountryTaxRate } from "../../shared/types";

export enum BillingProductType {
    GAME_SERVER = "GAME_SERVER",
    EXTRA_STORAGE = "EXTRA_STORAGE",
    EXTRA_CPU = "EXTRA_CPU",
    EXTRA_GAME = "EXTRA_GAME",
}

export const ScheduledServiceAction = object({
    id: string(), // values.ScheduledActionId
    service_id: string(), // values.BillingServiceId
    action: string(), // values.ScheduledServiceActionType
    last_run: nullable(date()), // *time.Time
    done: nullable(date()), // *time.Time
    date: date(), // time.Time
    details: any(), // json.RawMessage
    deleted_at: nullable(date()), // *time.Time
});

export type ScheduledServiceAction = Infer<typeof ScheduledServiceAction>;

export const BillingService = object({
    service_id: string(), // values.BillingServiceId
    product: string(), // string
    type: string(), // values.BillingServiceType
    wallet_id: string(), // values.WalletId
    coupons: array(AppliedCoupon), // []values.AppliedCoupon
    parameters: record(string(), any()), // map[values.ParameterKey]interface{}
    expires: nullable(date()), // *time.Time
    never_expiring: boolean(), // bool
    created_at: date(), // time.Time
    cost: nullable(instance(Money)), // *monies.Money
    gross_cost: nullable(instance(Money)), // *monies.Money
    country_tax_rate: CountryTaxRate, // values.CountryTaxRate
    auto_renew: boolean(), // bool
    parent_id: nullable(string()), // *values.BillingServiceId
    billing_period: string(), // *values.DurationString
    owner: string(), // values.UserID
    scheduled_actions: array(ScheduledServiceAction), // []entities.ScheduledServiceAction
});

export type BillingService = Infer<typeof BillingService>;

export interface StorageProductParameters {
    storage: number;
}

export const GameServerProductGameParameter = object({
    allowed_engines: optional(array(string())),
    blacklisted_packages: optional(array(string())),
    engine: optional(string()),
    max_players: optional(number()),
    sleep: optional(boolean()),
    max_plugins: optional(number()),
    allowed_playtime: optional(number()),
    autoinstall: optional(boolean()),
    allowed_run_levelrs: optional(array(string())),
    antibot: optional(boolean()),
    game: optional(string()),
});
export type GameServerProductGameParameter = Infer<typeof GameServerProductGameParameter>;

export const GameServerProductParameters = object({
    allowed_packages: array(string()),
    cpu_limit: number(),
    game: GameServerProductGameParameter,
    java: object({
        java: string(),
        version: string(),
        xms: number(),
        xmx: number(),
    }),
    memory: object({
        daemon: number(),
        pod: number(),
    }),
    secondary_domains: number(),
    storage: number(),
});

export type GameServerProductParameters = Infer<typeof GameServerProductParameters>;

export const BillingProductPrice = object({
    cluster_id: string(), // string
    price: instance(Money), // Money
    gross_price: instance(Money), // Money
    period: string(), // string
    country_tax_rate: CountryTaxRate, // CountryTaxRate
});

export type BillingProductPrice = Infer<typeof BillingProductPrice>;
export const BillingProduct = object({
    id: string(), // values.BillingProductId
    type: string(), // values.BillingServiceType
    parameters: record(string(), any()), // map[values.ParameterKey]interface{}
    depends_on_products: array(string()), // []string
    visible: boolean(), // bool
    available: boolean(), // bool
    availability: record(string(), boolean()), // map[values.ClusterId]bool
    prices: array(BillingProductPrice), // []PublicBillingProductPrice
    upgrade_targets: array(string()), // []values.BillingProductId
});
export type BillingProduct = Infer<typeof BillingProduct>;

export interface BillingProductsRequest {
    name: string;
    user_parameters: {
        units: number;
    };
}

export const BillingServiceCostResponse = object({
    services: array(BillingService),
    cost: instance(Money),
});
export type BillingServiceCostResponse = Infer<typeof BillingServiceCostResponse>;
