import styled from "styled-components";

export const SocialIconsWrapper = styled.div`
    position: absolute;
    bottom: 100px;
    right: 25%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media (max-width: 1191px) {
        left: 50%;
        bottom: 70px;
        transform: translateX(-50%);
        justify-content: center;
    }

    @media (max-width: 1840px) {
        right: 20%;
    }

    @media (max-width: 1440px) {
        right: 14%;
    }

    @media (max-width: 1240px) {
        right: 8%;
    }

    a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: 24px;
        z-index: 3;

        &:last-child {
            margin-right: 0;
        }
    }
`;

export const StyledIcon = styled.img`
    width: 24px;
    height: 24px;
    &:not(:last-child) {
        margin-right: 24px;
    }
`;
