import fx from "money";
import { useCallback, useEffect, useState } from "react";
import { Currencies, Money } from "ts-money";
import { getLocalCurrency, setLocalCurrency } from "../helpers/localStorage";

// const localStorageCurrencyItem = "craftserve-local-currency";

interface Currency {
    symbol: string;
    name: string;
    symbol_native: string;
    decimal_digits: number;
    rounding: number;
    code: string;
    name_plural: string;
}

export default function () {
    const [userCurrency, setUserCurrency] = useState(getLocalCurrency() || "PLN");
    fx.base = "USD";
    fx.rates = {
        USD: 1,
        // TODO: should be sent from backend?
        PLN: 4.05,
    };

    const printLocalPrice = useCallback(
        (price: Money) => {
            if (userCurrency === price.getCurrencyInfo().code) {
                return `${price.toString()} ${price.getCurrencyInfo().symbol}`;
            }

            const localCurrency = Currencies[userCurrency as keyof typeof Currencies];
            const amount = Math.ceil(fx.convert(price.getAmount(), { from: price.getCurrency(), to: userCurrency }));
            const p = new Money(amount, localCurrency);
            return `${p.toString()} ${localCurrency.symbol}`;
        },
        [userCurrency]
    );

    const printPrice = (price: Money) => `${price.toString()} ${price.getCurrencyInfo().symbol}`;

    const getLocalCurrencySymbol = () => {
        const currencyCode = getLocalCurrency() as keyof typeof Currencies;
        if (currencyCode) {
            return Currencies[currencyCode].symbol;
        }

        return "$";
    };

    const setMoneyCurrency = (currency: Currency) => {
        setUserCurrency(currency.code);
        setLocalCurrency(currency.code);
    };

    const USDtoPLN = (price: Money): Money => {
        const amount = fx.convert(price.getAmount(), { from: Currencies.USD, to: Currencies.PLN });
        return new Money(amount, Currencies.PLN);
    };

    const PLNtoUSD = (price: Money): Money => {
        const amount = fx.convert(price.getAmount(), { from: Currencies.PLN, to: Currencies.USD });
        return new Money(amount, Currencies.USD);
    };

    const set = () => {
        const currencyCode = getLocalCurrency() as keyof typeof Currencies;
        if (currencyCode) {
            setUserCurrency(currencyCode);
        } else {
            setUserCurrency("USD");
        }
    };

    useEffect(() => {
        window.addEventListener("storage", set);

        return () => window.removeEventListener("storage", set);
    }, []);

    return {
        printPrice,
        printLocalPrice,
        getLocalCurrencySymbol,
        setMoneyCurrency,
        currency: userCurrency,
        USDtoPLN,
        PLNtoUSD,
    };
}
