import { array, date, enums, Infer, nullable, number, object, string } from "superstruct";
import { ServerID, WalletID } from "../../shared/types";
import { PaymentType } from "../Server/types";

export enum TransactionStatus {
    DRAFT = "DRAFT", // TransactionDraft
    NEW = "NEW", // TransactionNew
    PROCESSING = "PROCESSING", // TransactionProcessing
    READY_TO_CAPTURE = "READY_TO_CAPTURE", // TransactionReadyToCapture
    COMPLETED = "COMPLETED", // TransactionCompleted
    FAILED = "FAILED", // TransactionFailed
    CANCELLED = "CANCELLED", // TransactionCancelled
}

export enum TransactionAction {
    RENEW_SERVICE = "RENEW_SERVICE", // TransactionRenewService
    DEPOSIT = "DEPOSIT", // TransactionDeposit
    UPGRADE_SERVICE = "UPGRADE_SERVICE", // TransactionUpgradeService
}

export const TransactionPosition = object({
    name: string(),
    tax: number(),
    total_price_gross: number(),
    quantity: number(),
});

export type TransactionPosition = Infer<typeof TransactionPosition>;

export const Transaction = object({
    id: string(), // values.TransactionId
    status: enums(Object.values(TransactionStatus)), // values.TransactionStatus
    action: enums(Object.values(TransactionAction)), // values.TransactionAction
    invoiceid: nullable(number()), // *int
    ts: date(), // time.Time
    amount: number(), // int64
    currency: string(), // monies.CurrencyCode
    wallet_id: string(), // values.WalletId
    positions: array(TransactionPosition),
    country_tax_rate: object({
        country_code: string(),
        vat_rate: number(),
    }),
});
export type Transaction = Infer<typeof Transaction>;

export type TransactionID = string;

export const CreateTransactionResponse = object({
    redirect_uri: nullable(string()), // *string
});

export type TransactionResponse = Infer<typeof CreateTransactionResponse>;

export interface CreateTransactionPayload {
    action: TransactionAction;
    data: object;
    payment_details: object;
    payment_type: PaymentType;
    service_id: ServerID | null;
    wallet_id: WalletID;
    blik?: boolean;
}

export interface UpdateTransactionPayload {
    payment_type: PaymentType;
}

export const UpdateTransactionResponse = object({
    redirect_uri: string(),
});

export type UpdateTransactionResponse = Infer<typeof UpdateTransactionResponse>;

export interface RenewServerPayload {
    duration: string;
    charge: boolean;
    cause: string;
    silent: boolean;
}

export interface CompletePaymentResponse {
    id: TransactionID;
    status: string;
    action: string;
}
