import { Space } from "csrvui";
import ranking_bg from "../../../../assets/images/ranking_bg.svg";
import { MarketEventMetric } from "../../types";
import RankingPlatform from "../RankingPlatform";
import RankingTableRow from "../RankingTableRow";

interface Props {
    data: MarketEventMetric[];
}

const EventRanking = ({ data }: Props) => {
    const isSmallScreen = window.innerWidth < 1050;
    const isMobile = window.innerWidth < 600;

    const RankingPlatformData = [
        {
            place: 2,
            data: data[1] || null,
        },
        {
            place: 1,
            data: data[0] || null,
        },
        {
            place: 3,
            data: data[2] || null,
        },
    ];

    const getMaxWidth = () => {
        let getMaxWidth;
        if (isSmallScreen) {
            getMaxWidth = "calc(100vw - 40px)";
        } else if (data.length <= 3) {
            getMaxWidth = "100%";
        } else {
            getMaxWidth = "650px";
        }

        return getMaxWidth;
    };

    return (
        <Space $padding={0} $wrap={isSmallScreen} $direction="horizontal">
            <Space
                style={{
                    backgroundImage: `url("${ranking_bg}")`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    borderRadius: "12px",
                    maxWidth: getMaxWidth(),
                    padding: isMobile ? "60px 0 0 0" : "120px 60px 0px 60px",
                    overflow: "hidden",
                    width: "100%",
                    height: data.length <= 3 ? "40vh" : "587px",
                    maxHeight: data.length <= 3 ? "500px" : "2000px",
                    minHeight: isSmallScreen ? "350px" : "400px",
                }}
                $wrap={false}
                $oppositeAlign="end"
                $align="center"
                $gap={0}
                $direction="horizontal"
            >
                <Space
                    style={{
                        transform: isMobile ? "scale(.8)" : "inherit",
                        marginBottom: isMobile ? "-24px" : "0",
                    }}
                    $wrap={false}
                    $gap={0}
                    $direction="horizontal"
                    $padding={0}
                >
                    {RankingPlatformData.map((item) => (
                        <RankingPlatform key={item.place} data={item.data} place={item.place} />
                    ))}
                </Space>
            </Space>
            {data.length > 3 && (
                <Space $fullWidth $padding={0}>
                    {data.slice(3, 10).map((item, index) => (
                        <RankingTableRow key={item.project_id} data={item} place={index + 4} />
                    ))}
                </Space>
            )}
        </Space>
    );
};

export default EventRanking;
