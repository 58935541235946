import CsrvProcess, { CsrvProcessInstance, CsrvProcessMessage, CsrvProcessState, CsrvProcessType } from "csrvprocess";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { equals } from "ramda";
import { useAppSelector } from "../../../hooks";
import { useLazyServerByIdQuery } from "../../../modules/Server/api";
import { selectCurrentUser } from "../../../modules/User/slices/selectors";
import { HostUrl } from "../../../rootTypes";
import debug from "../../helpers/debug";
import { setUILocalOption } from "../../helpers/localStorage";
import { ProcessesContext } from "../../hooks/ProcessesContext";
import { ProcessEventType } from "../../types/ProcessEventType";

interface Props {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: any;
}

export const ProcessesProvider: React.FC<Props> = ({ children }) => {
    const [isConnected, setIsConnected] = useState(false);
    const [processes, setProcesses] = useState<CsrvProcessInstance[]>([]);
    const [messages, setMessages] = useState<CsrvProcessInstance[]>([]);
    const [deletedProcesses, setDeleteProcesses] = useState<string[]>([]);
    const [getServerById] = useLazyServerByIdQuery();
    const user = useAppSelector(selectCurrentUser);

    const prevTarget = useRef<string | undefined>(undefined);
    const csrvProcessRef = useRef<CsrvProcess | null>(null);

    const [userServicesIds, setUserServicesIds] = useState<string[] | null>(null);

    useEffect(() => {
        if (user) {
            const newServicesIds = user.services.map((s) => s.service_id);
            if (!equals(newServicesIds, userServicesIds)) {
                setUserServicesIds(newServicesIds);
            }
        }
    }, [user, userServicesIds]);

    const addMessage = useCallback(
        (message: CsrvProcessInstance) => {
            setMessages((prev) => [...prev, message]);
        },
        [setMessages]
    );

    const addSimpleMessage = useCallback(
        (type: CsrvProcessType, success: boolean) => {
            addMessage({
                id: messages.length.toString(),
                data: {},
                type,
                label: type,
                resourceId: "",
                state: success ? CsrvProcessState.DONE : CsrvProcessState.FAILED,
            });
        },
        [addMessage, messages]
    );

    const removeMessage = useCallback(
        (messageId: string) => {
            setMessages((prev) => prev.filter((message) => message.id !== messageId));
        },
        [setMessages]
    );

    const onProcessesChange = useCallback(
        (processes: CsrvProcessInstance[]) => {
            const filteredProcesses = processes.filter((process) => !deletedProcesses.includes(process.id));
            setProcesses(filteredProcesses);
        },
        [deletedProcesses]
    );

    const deleteProcess = useCallback(
        (processId: string) => {
            setDeleteProcesses((prev) => [...prev, processId]);

            const index = processes.findIndex((p) => p.id === processId);
            if (index !== -1) {
                setProcesses((prev) => {
                    const newProcesses = [...prev];
                    newProcesses.splice(index, 1);
                    return newProcesses;
                });
            }
        },
        [processes]
    );

    const createProcessEvent = useCallback(
        (process: CsrvProcessMessage) => {
            const event = new CustomEvent(`process-${process.type}-${process.state}`, {
                detail: {
                    serverId: process.resourceid,
                },
            });

            // console.debug(`REFRESHING process-${process.type}-${process.state}`);

            window.dispatchEvent(event);

            // notify that some process has finished
            if (process.state !== CsrvProcessState.IN_PROGRESS) {
                const event = new CustomEvent(ProcessEventType.PROCESS_FINISHED);
                window.dispatchEvent(event);
            }
            // notify about server package create process state
            let eventType = null;
            if (process.type === CsrvProcessType.SERVER_PACKAGE_CREATE) {
                if (process.state === CsrvProcessState.DONE) {
                    eventType = ProcessEventType.PROCESS_PACKAGE_SUCCESS;
                } else if (process.state === CsrvProcessState.IN_PROGRESS) {
                    eventType = ProcessEventType.PROCESS_PACKAGE_IN_PROGRESS;
                } else if (
                    process.state === CsrvProcessState.FAILED ||
                    process.state === CsrvProcessState.TIMEOUT ||
                    process.state === CsrvProcessState.ABORTED
                ) {
                    eventType = ProcessEventType.PROCESS_PACKAGE_FAILED;
                }

                if (eventType) {
                    const event = new CustomEvent(eventType, {
                        detail: {
                            id: process.processid,
                        },
                    });
                    window.dispatchEvent(event);
                }
            }

            // handle stopping and starting server
            switch (process.type) {
                case CsrvProcessType.STOPPING_GAME:
                    if (process.state === CsrvProcessState.DONE) {
                        getServerById(process.resourceid);
                    }

                    break;

                case CsrvProcessType.STARTING_GAME:
                    if (process.state === CsrvProcessState.DONE) {
                        getServerById(process.resourceid);
                    }

                    break;
                default:
                    break;
            }
        },
        [getServerById]
    );

    const connectListener = useCallback(
        (process: CsrvProcessMessage) => {
            debug("[GRPC Process] process received: ", process);
            setUILocalOption("processesExpanded", true); // show processes container
            createProcessEvent(process);
        },
        [createProcessEvent]
    );

    const subscribe = useCallback(
        async (serverId?: string) => {
            if (userServicesIds === null) {
                return;
            }

            const csrvProcess = new CsrvProcess(HostUrl);
            csrvProcessRef.current = csrvProcess;

            debug("[CSRV Process] INIT SUBSCRIBE CONNECTION ", {
                serverId,
                user,
            });

            const servicesIdsToConnect = userServicesIds;
            // it means that user tries to connect to server that is not his own (e.x. admin or user with capability)
            if (serverId && !userServicesIds.some((sid: string) => sid === serverId)) {
                debug("[CSRV Process] Connecting to process for not own server ", serverId);
                servicesIdsToConnect.push(serverId);
            }

            if (servicesIdsToConnect.length) {
                debug("[CSRV Process] Connecting to processes for services  ", servicesIdsToConnect.join(", "));
            } else {
                return;
            }

            try {
                setIsConnected(true);
                csrvProcess.connectToProcesses(servicesIdsToConnect, connectListener);
                csrvProcess.subscribeProcesses(onProcessesChange);
            } catch (err) {
                debug("[CSRV Process] Disconnecting: ", err);
                // setIsConnected(false);
            }
        },
        [userServicesIds, user, connectListener, onProcessesChange]
    );

    const unsubscribe = useCallback(async () => {
        if (csrvProcessRef && csrvProcessRef.current) {
            debug("[CSRV Process] Unsubscribe");
            await csrvProcessRef.current.disconnectFromProcesses();
            await csrvProcessRef.current.unsubscribeProcesses();
        }
    }, [csrvProcessRef]);

    const resubscribe = useCallback(
        async (targetServer: string | undefined) => {
            if (prevTarget.current !== targetServer) {
                if (
                    prevTarget.current !== undefined ||
                    (targetServer !== undefined && !userServicesIds?.includes(targetServer))
                ) {
                    debug("[CSRV Process] Resubscribe");
                    debug("[CSRV Process] Unsubscribe");
                    await unsubscribe();
                    debug("[CSRV Process] Subscribe");
                    await subscribe(targetServer);
                    prevTarget.current = targetServer;
                }
            }
        },
        [subscribe, unsubscribe, userServicesIds]
    );

    const value = useMemo(
        () => ({
            isConnected,
            processes,
            messages,
            addSimpleMessage,
            addMessage,
            removeMessage,
            deleteProcess,
            unsubscribe,
            resubscribe,
        }),
        [
            addMessage,
            addSimpleMessage,
            deleteProcess,
            isConnected,
            messages,
            processes,
            removeMessage,
            resubscribe,
            unsubscribe,
        ]
    );

    useEffect(() => {
        if (!isConnected) {
            subscribe();
        }
    }, [subscribe, isConnected]);

    return <ProcessesContext.Provider value={value}>{children}</ProcessesContext.Provider>;
};
