import { CsrvProcessState, CsrvProcessType } from "csrvprocess";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ProcessesContext } from "../../../../../shared/hooks/ProcessesContext";

export const useProcessNavigation = (serverId: string | null, CsrvProcessType: CsrvProcessType) => {
    const navigate = useNavigate();
    const { processes } = useContext(ProcessesContext);

    useEffect(() => {
        if (!serverId) {
            return;
        }

        // Check if the process with this serverId (resourceId) has reached DONE
        const process = processes.find((p) => p.resourceId === serverId && p.type === CsrvProcessType);
        if (process) {
            if (process.state === CsrvProcessState.DONE) {
                navigate(`/s/${serverId}/m`);
            }
        }
    }, [serverId, CsrvProcessType, processes, navigate]);
};
