import { createSlice } from "@reduxjs/toolkit";
import { TaxInfoResponse } from "../types";

const slice = createSlice({
    name: "vat_rate",
    initialState: {} as TaxInfoResponse,
    reducers: {
        setTaxInfo: (_, { payload }: { payload: TaxInfoResponse }) => ({
            country_code: payload.country_code,
            vat_rate: payload.vat_rate * 100,
        }),
    },
});

export const { setTaxInfo } = slice.actions;

export default slice.reducer;
