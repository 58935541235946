import { IoGift } from "@react-icons/all-files/io5/IoGift";
import { IoPlayOutline } from "@react-icons/all-files/io5/IoPlayOutline";
import { Button, Paragraph, Space } from "csrvui";
import { ButtonProps } from "csrvui/dist/esm/components/Button";
import { CSSProperties, useMemo } from "react";
import stars from "../../../../assets/images/stars.svg";
import useTheme from "../../../../shared/hooks/useCustomTheme";
import standarizeUrl from "../../helpers/standarizeUrl";

interface Props extends Omit<ButtonProps, "$variant"> {
    variant?: "default" | "free";
}

export const PlayButton = ({ variant, ...props }: Props) => {
    const theme = useTheme();
    const isMobile = window.innerWidth < 770;
    const isFreeVariant = variant === "free";
    const freeVariantStyles: CSSProperties = {
        background: "linear-gradient(10deg, #FAC203, #FBFB00)",
    };

    const freeVariantTextStyles: CSSProperties = {
        fontSize: "29px",
        fontWeight: 700,
    };

    const starsUrl = useMemo(() => standarizeUrl(stars), []);

    return (
        <Button
            {...props}
            $variant="success"
            style={{
                boxShadow: `0px 2px 10px ${theme.colors.success}`,
                ...(isFreeVariant ? freeVariantStyles : {}),
                ...props.style,
                zIndex: 0,
                position: "relative",
                maxHeight: isMobile ? "fit-content" : "86px",
                // height: "fit-content",
            }}
        >
            {isFreeVariant && (
                <img
                    style={{ position: "absolute", left: 0, top: 10, zIndex: -1 }}
                    src={starsUrl}
                    alt=""
                    aria-disabled
                    width="100%"
                    height="80"
                />
            )}
            <Space
                style={{
                    position: "absolute",
                    left: isFreeVariant ? 20 : 15,
                    top: isFreeVariant ? 37 : 36,
                }}
                $padding={0}
            >
                {isFreeVariant ? (
                    <IoGift
                        style={{
                            color: theme.colors.primaryText,
                        }}
                        size={29}
                    />
                ) : (
                    <div
                        style={{
                            marginTop: "2px",
                        }}
                    >
                        <IoPlayOutline
                            style={{
                                color: theme.colors.secondaryText,
                                strokeWidth: 2,
                            }}
                            size={34}
                        />
                    </div>
                )}
            </Space>
            <Space
                style={{
                    backgroundSize: "cover",
                }}
                $fullWidth
                $direction="horizontal"
                $align="center"
                $oppositeAlign="center"
                $gap={25}
                $padding="0 0 0 15px"
                $wrap={false}
            >
                <Paragraph
                    $level={3}
                    style={{
                        fontWeight: 550,
                        color: variant === "free" ? theme.colors.primaryText : theme.colors.secondaryText,
                        textWrap: "wrap",
                        textTransform: "uppercase",
                        ...(variant === "free" ? freeVariantTextStyles : {}),
                    }}
                >
                    {props.children}
                </Paragraph>
            </Space>
        </Button>
    );
};
