import { Button, Important, Label, Space } from "csrvui";
import { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { CurrentServerContext } from "../../../../shared/components/CurrentServerProvider";
import { HiddenField } from "../../../../shared/components/GlobalStyles";
import { CheckableWrapper } from "../../../../shared/styles";
import getPaymentImage from "../../../Payments/helpers/getPaymentImage";
import { TransactionAction } from "../../../Payments/types";
import useUserData from "../../../User/hooks/useUserData";
import useCreateServer from "../../hooks/useCreateServer";
import { PaymentType } from "../../types";

interface Props {
    data: Record<string, string>;
    action: TransactionAction;
}

const CreateTransactionForm = ({ action, data }: Props) => {
    const { payForServer } = useCreateServer();
    const { availablePaymentMethods } = useUserData();
    const { service } = useContext(CurrentServerContext);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState<PaymentType>(
        availablePaymentMethods.length > 0 ? availablePaymentMethods[0] : PaymentType.PayByWallet
    );

    const handleCreateTransaction = useCallback(async () => {
        setLoading(true);
        await payForServer(service.service_id, paymentMethod, service.wallet_id, action, data);
        setLoading(false);
    }, [action, data, paymentMethod, service, payForServer]);

    return (
        <Space>
            <Space $padding={0} $gap={10}>
                {[...availablePaymentMethods, PaymentType.PayLater].map((option) => {
                    const icon = getPaymentImage(option);
                    return (
                        <CheckableWrapper key={option} $checked={paymentMethod === option} $sizeVariant="small">
                            <Label htmlFor={option}>
                                {icon ? (
                                    <img height={40} src={icon} alt={option} />
                                ) : (
                                    <Important style={{ lineHeight: 3 }} $level={4}>
                                        {option}
                                    </Important>
                                )}
                            </Label>
                            <HiddenField
                                id={option}
                                type="radio"
                                data-ph-capture-attribute-id="create_transaction_form:payment_method"
                                data-ph-capture-attribute-payment_method={option}
                                name="paymentMethod"
                                value={option}
                                onChange={() => {
                                    setPaymentMethod(option);
                                }}
                            />
                        </CheckableWrapper>
                    );
                })}
            </Space>
            <Button $loading={loading} $fullWidth onClick={handleCreateTransaction}>
                {t("common.pay")}
            </Button>
        </Space>
    );
};

export default CreateTransactionForm;
