import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../hooks";
import {
    emitCreateServerEvent,
    emitRedeemTestServerEvent,
    emitSignInEvent,
    emitSignUpEvent,
} from "../../../shared/helpers/ga";
import showToast, { showApiError } from "../../../shared/helpers/showToast";
import { trackTwitterCreateServer, trackTwitterRedeemTestServer, trackTwitterRegister } from "../../../shared/twitter";
import { UserPermissions } from "../../../shared/types";
import {
    useFastEmailAuthBeginMutation,
    useFastEmailAuthCompleteMutation,
    useLoginMutation,
    useRegisterMutation,
} from "../api";
import { hasUserCapability } from "../helpers/hasCapability";
import { setAuth } from "../slices/authSlice";
import { FastAuthBeginPayload, FastAuthCompletePayload, LocalLoginPayload, RegisterPayload } from "../types";

export default function () {
    const { t } = useTranslation();
    const [loginRequest] = useLoginMutation();
    const [registerRequest] = useRegisterMutation();
    const dispatch = useAppDispatch();
    const [fastEmailAuthBeginRequest] = useFastEmailAuthBeginMutation();
    const [fastEmailAuthCompleteRequest] = useFastEmailAuthCompleteMutation();
    const lastLoginMethod = localStorage.getItem("last-auth-method");
    const [isCodePending, setIsCodePending] = useState(false);

    const setLastAuthMethod = useCallback((method: "code" | "password") => {
        localStorage.setItem("last-auth-method", method);
    }, []);

    const login = useCallback(
        async (data: LocalLoginPayload, onError?: () => void) => {
            try {
                const result = await loginRequest(data).unwrap();
                dispatch(
                    setAuth({
                        user: result,
                        verified: true,
                        isLoading: false,
                        error: null,
                        isAdmin: hasUserCapability(result, UserPermissions.SERVER),
                    })
                );

                emitSignInEvent("password");

                setLastAuthMethod("password");
            } catch (e) {
                showApiError(e);

                if (onError) {
                    onError();
                }
            }
        },
        [dispatch, loginRequest, setLastAuthMethod]
    );

    const register = useCallback(
        async (data: RegisterPayload) => {
            try {
                const result = await registerRequest(data).unwrap();
                dispatch(
                    setAuth({
                        user: result,
                        verified: true,
                        isLoading: false,
                        error: null,
                        isAdmin: hasUserCapability(result, UserPermissions.SERVER),
                    })
                );

                emitSignUpEvent("basic");
                trackTwitterRegister(data.email);

                setLastAuthMethod("password");
            } catch (e) {
                showApiError(e);
            }
        },
        [dispatch, registerRequest, setLastAuthMethod]
    );

    const fastEmailAuthBegin = useCallback(
        async (payload: FastAuthBeginPayload) => {
            setIsCodePending(true);
            try {
                await fastEmailAuthBeginRequest(payload).unwrap();
                trackTwitterRegister(payload.email);

                setLastAuthMethod("code");

                showToast({
                    variant: "success",
                    text: t("auth.fastRegisterBeginSuccess"),
                });
            } catch (e: unknown) {
                showApiError(e);
                throw e;
            }
            setIsCodePending(false);
        },

        [fastEmailAuthBeginRequest, setLastAuthMethod, t]
    );

    const fastEmailAuthComplete = useCallback(
        async (payload: FastAuthCompletePayload) => {
            const result = await fastEmailAuthCompleteRequest(payload).unwrap();

            setLastAuthMethod("code");

            if (result.already_exists) {
                emitSignInEvent("code");
            } else if (!result.server) {
                emitSignUpEvent("from-code-login-form");
            } else {
                emitSignUpEvent("from_redeem_test_server_form");
                emitCreateServerEvent({
                    product_id: result.server.requested_billing_services[0].name,
                    billing_period: result.server.billing_period,
                    currency: result.server.currency,
                });
                emitRedeemTestServerEvent({
                    product_id: result.server.requested_billing_services[0].name,
                    package: result.server.requested_billing_services[0].user_parameters?.engine || "",
                });

                trackTwitterRegister(result.user.email);
                trackTwitterCreateServer({
                    contents: [
                        {
                            content_id: result.server.requested_billing_services[0].user_parameters?.engine || "",
                            content_type: result.server.billing_period,
                            content_name: result.server.requested_billing_services[0].name,
                            content_price: 0,
                            num_items: 1,
                            content_group_id: result.server.requested_billing_services[0].name,
                        },
                    ],
                    email_address: result.user.email,
                });

                trackTwitterRedeemTestServer([
                    {
                        content_id: result.server.requested_billing_services[0].user_parameters?.engine || "",
                        content_type: result.server.billing_period,
                        content_name: result.server.requested_billing_services[0].name,
                        content_price: 0,
                        num_items: 1,
                        content_group_id: result.server.requested_billing_services[0].name,
                    },
                ]);
            }

            dispatch(
                setAuth({
                    user: result.user,
                    verified: true,
                    isLoading: false,
                    error: null,
                    isAdmin: hasUserCapability(result.user, UserPermissions.SERVER),
                })
            );

            return result;
        },
        [dispatch, fastEmailAuthCompleteRequest, setLastAuthMethod]
    );

    return { login, register, fastEmailAuthBegin, fastEmailAuthComplete, lastLoginMethod, isCodePending };
}
