import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../hooks";
import CustomError from "../../../shared/CustomError";
import { showApiError } from "../../../shared/helpers/showToast";
import { useGetUserTaxInfoMutation, useRefreshMutation, useVerifyMutation } from "../api";
import { setAuth } from "../slices/authSlice";
import { getTaxInfo, isUserAdmin, isUserLoggedIn, wasUserVerified } from "../slices/selectors";
import { setTaxInfo } from "../slices/taxInfoSlice";

export default function () {
    const [verify] = useVerifyMutation();
    const [refreshRequest] = useRefreshMutation();
    const [taxInfoRequest] = useGetUserTaxInfoMutation();
    const dispatch = useDispatch();
    const isLoggedIn = useAppSelector(isUserLoggedIn);
    const taxInfo = useAppSelector(getTaxInfo);
    const verified = useAppSelector(wasUserVerified);
    const isAdmin = useAppSelector(isUserAdmin);

    const verifyUser = useCallback(async () => {
        try {
            const response = await verify().unwrap();
            dispatch(
                setAuth({
                    user: response,
                    verified: true,
                    isLoading: false,
                    error: null,
                    isAdmin,
                })
            );
        } catch (e) {
            try {
                const response = await refreshRequest().unwrap();
                dispatch(
                    setAuth({
                        user: response,
                        verified: true,
                        isLoading: false,
                        error: null,
                        isAdmin,
                    })
                );
            } catch (e) {
                dispatch(
                    setAuth({
                        user: null,
                        verified: true,
                        isLoading: false,
                        error: (e as CustomError).message,
                        isAdmin: false,
                    })
                );
            }
        }
    }, [dispatch, isAdmin, refreshRequest, verify]);

    const refresh = verifyUser;
    const getUserTaxInfo = useCallback(async () => {
        if (taxInfo.vat_rate) return;

        try {
            const response = await taxInfoRequest().unwrap();
            dispatch(setTaxInfo(response));
        } catch (e) {
            showApiError(e);
        }
    }, [dispatch, taxInfoRequest, taxInfo.vat_rate]);

    return {
        verifyUser,
        isLoggedIn,
        isLoading: !verified,
        verified,
        refresh,
        getTaxInfo: getUserTaxInfo,
    };
}
