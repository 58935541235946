import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getCurrentScope } from "@sentry/browser";
import { array } from "superstruct";
import { HttpStatus } from "../../constants";
import { HostUrl } from "../../rootTypes";
import { transformToStruct } from "../../shared/helpers/customQuery";
import { transformError } from "../../shared/helpers/errors";
import { GetEventLogsResponse, UserID } from "../../shared/types";
import { BillingService } from "../Billings/types";
import * as ServerTypes from "../Server/types";
import { UserTicketsResponse } from "../Server/types";
import * as UserTypes from "./types";
import { FastAuthBeginPayload, FastAuthCompletePayload, FastAuthCompleteResponse, Notification } from "./types";

export const api = createApi({
    reducerPath: "user",
    baseQuery: fetchBaseQuery({
        baseUrl: `${HostUrl}/api/users`,
        prepareHeaders: (headers) => {
            const transactionId = Math.random().toString(36).substr(2, 9);
            const scope = getCurrentScope();
            if (scope) {
                scope.setTag("transaction_id", transactionId);
            }
            headers.set("X-transation-ID", transactionId);

            return headers;
        },
    }),
    endpoints: (builder) => ({
        login: builder.mutation<UserTypes.User, UserTypes.LocalLoginPayload>({
            query: (credentials: UserTypes.LocalLoginPayload) => ({
                url: "/login/local",
                method: "POST",
                body: credentials,
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
            transformResponse: (response: UserTypes.User) => {
                return transformToStruct(response, UserTypes.User, "/login/local");
            },
            transformErrorResponse: transformError,
        }),
        register: builder.mutation<UserTypes.User, UserTypes.RegisterPayload>({
            query: (credentials: UserTypes.RegisterPayload) => ({
                url: "",
                method: "POST",
                body: credentials,
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
            transformResponse: (response: UserTypes.User) => {
                return transformToStruct(response, UserTypes.User, "/");
            },
            transformErrorResponse: transformError,
        }),
        verify: builder.mutation<UserTypes.User, void>({
            query: () => ({
                url: "/verify",
                method: "GET",
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
            transformResponse: (response: UserTypes.User) => {
                return transformToStruct(response, UserTypes.User, "/verify");
            },
            transformErrorResponse: transformError,
        }),
        getUserTaxInfo: builder.mutation<UserTypes.TaxInfoResponse, void>({
            query: () => ({
                url: "/taxinfo",
                method: "GET",
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
            transformResponse: (response: UserTypes.TaxInfoResponse) => {
                return transformToStruct(response, UserTypes.TaxInfoResponse, "/taxinfo");
            },
            transformErrorResponse: transformError,
        }),
        refresh: builder.mutation<UserTypes.User, void>({
            query: () => ({
                url: "/refresh",
                method: "GET",
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
            transformResponse: (response: UserTypes.User) => {
                return transformToStruct(response, UserTypes.User, "/verify/refresh");
            },
            transformErrorResponse: transformError,
        }),
        logout: builder.mutation<void, void>({
            query: () => ({
                url: "/logout",
                method: "POST",
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
        }),
        servers: builder.query<ServerTypes.ServerWithParameters[], UserID | undefined>({
            query: (userId?: UserID) => ({
                url: `/${userId}/servers`,
                method: "GET",
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
            transformResponse: (response: ServerTypes.ServerWithParameters[]) => {
                return transformToStruct(response, array(ServerTypes.ServerWithParameters), "/:userId/servers");
            },
            transformErrorResponse: transformError,
        }),
        wallets: builder.query<UserTypes.WalletWithServers[], UserID>({
            query: (userId: UserID) => ({
                url: `/${userId}/wallets`,
                method: "GET",
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
            transformResponse: (response: UserTypes.WalletWithServers[]) => {
                return transformToStruct(response, array(UserTypes.WalletWithServers), "wallets /:userId/wallets");
            },
            transformErrorResponse: transformError,
        }),
        markAllNotificationsAsRead: builder.mutation<void, string>({
            query: (userId) => ({
                url: `/${userId}/notifications/mark_as_read`,
                method: "PUT",
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
            transformErrorResponse: transformError,
        }),
        notifications: builder.query<UserTypes.GetUserNotificationsResponse, UserTypes.UserNotificationsQueryData>({
            query: ({ id, after }) => ({
                url: `/${id}/notifications`,
                method: "GET",
                params: { after },
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
            transformResponse: (response: UserTypes.GetUserNotificationsResponse) => {
                return transformToStruct(response, UserTypes.GetUserNotificationsResponse, "/:id/notifications");
            },
            transformErrorResponse: transformError,
        }),
        openNotification: builder.query<Notification, { id: string; source: string }>({
            query: ({ id, source }) => ({
                url: `/${id}/notifications/${id}`,
                method: "POST",
                params: { source },
                validateStatus: (response) => response.status === HttpStatus.Created,
            }),
            transformErrorResponse: transformError,
        }),
        events: builder.query<GetEventLogsResponse, UserTypes.EventsQueryData>({
            query: ({ id, after, action, fromDate, toDate }) => ({
                url: `/${id}/events`,
                method: "GET",
                params: { after, action, fromDate, toDate },
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
            transformResponse: (response: GetEventLogsResponse) => {
                return transformToStruct(response, GetEventLogsResponse, "/:id/events");
            },
            transformErrorResponse: transformError,
        }),
        createWallet: builder.mutation<UserTypes.Wallet, { userId: UserID; currency: string }>({
            query: ({ userId, currency }) => ({
                url: `/${userId}/wallets`,
                method: "POST",
                body: { currency },
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
            transformResponse: (response: UserTypes.Wallet) => {
                return transformToStruct(response, UserTypes.Wallet, "createWallet /:userId/wallets");
            },
            transformErrorResponse: transformError,
        }),
        changeEmail: builder.mutation<void, { new_email: string; password: string }>({
            query: ({ new_email, password }) => ({
                url: "/email/change",
                method: "PUT",
                body: { new_email, password },
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
            transformErrorResponse: transformError,
        }),
        changePassword: builder.mutation<void, { old_password: string; new_password: string }>({
            query: ({ old_password, new_password }) => ({
                url: "/password/change",
                method: "PUT",
                body: { old_password, new_password },
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
            transformErrorResponse: transformError,
        }),
        updateInvoiceData: builder.mutation<void, { userId: UserID; payload: UserTypes.InvoiceFormPayload }>({
            query: ({ userId, payload }) => ({
                url: `/${userId}/taxdata`,
                method: "PUT",
                body: payload,
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
            transformErrorResponse: transformError,
        }),
        beginResetPassword: builder.mutation<void, { email: string }>({
            query: ({ email }) => ({
                url: "/password/reset",
                method: "POST",
                body: { email },
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
            transformErrorResponse: transformError,
        }),
        completeResetPassword: builder.mutation<void, { code: string; password: string }>({
            query: ({ code, password }) => ({
                url: "/password/reset/confirm",
                method: "PUT",
                body: { code, password },
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
            transformErrorResponse: transformError,
        }),
        confirmEmail: builder.mutation<void, { code: string }>({
            query: ({ code }) => ({
                url: "/email/confirm",
                method: "POST",
                body: { code },
                validateStatus: (response) => response.status === HttpStatus.Created,
            }),
            transformErrorResponse: transformError,
        }),
        getUserTickets: builder.query<UserTicketsResponse, string>({
            query: (userId) => ({
                url: `/${userId}/tickets`,
                method: "GET",
                credentials: "include",
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
            transformErrorResponse: transformError,
        }),
        getUser: builder.query<UserTypes.User, UserID>({
            query: (userId) => ({
                url: `/${userId}`,
                method: "GET",
                validateStatus: (response, result) => response.status === HttpStatus.OK && result !== undefined,
            }),
            transformErrorResponse: transformError,
        }),
        getUserCraftums: builder.query<BillingService[], UserID | undefined>({
            query: (userId) => ({
                url: `/${userId}/craftums`,
                method: "GET",
                credentials: "include",
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
            transformErrorResponse: transformError,
        }),
        checkUsernameAvailability: builder.mutation<
            void,
            {
                payload: string;
            }
        >({
            query: ({ payload }) => ({
                url: `/username/${payload}`,
                method: "GET",
                credentials: "include",
                validateStatus: (response) => response.status === HttpStatus.NoContent,
            }),
        }),
        updateUsername: builder.mutation<void, { username: string }>({
            query: ({ username }) => ({
                url: "/username",
                method: "POST",
                body: { username },
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
            transformErrorResponse: transformError,
        }),
        fastEmailAuthBegin: builder.mutation<void, FastAuthBeginPayload>({
            query: (payload) => ({
                url: "/auth/email/begin",
                method: "POST",
                body: payload,
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
            transformErrorResponse: transformError,
        }),
        fastEmailAuthComplete: builder.mutation<FastAuthCompleteResponse, FastAuthCompletePayload>({
            query: (payload) => ({
                url: "/auth/email/complete",
                method: "POST",
                body: payload,
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
            transformErrorResponse: transformError,
        }),
    }),
});

export const supportApi = createApi({
    reducerPath: "support",
    baseQuery: fetchBaseQuery({
        baseUrl: `${HostUrl}/api/support`,
        prepareHeaders: (headers) => {
            const transactionId = Math.random().toString(36).substr(2, 9);
            const scope = getCurrentScope();
            if (scope) {
                scope.setTag("transaction_id", transactionId);
            }
            headers.set("X-transation-ID", transactionId);

            return headers;
        },
    }),
    endpoints: (builder) => ({
        createTicket: builder.mutation<void, FormData>({
            query: (payload) => ({
                url: "/ticket",
                method: "POST",
                body: payload,
                credentials: "include",
                validateStatus: (response) => response.status === HttpStatus.Created,
            }),
            transformErrorResponse: transformError,
        }),
        getTicketTopics: builder.mutation<string[], void>({
            query: () => ({
                url: "/ticket_field_options",
                method: "GET",
                credentials: "include",
                validateStatus: (response) => response.status === HttpStatus.OK,
            }),
            transformErrorResponse: transformError,
        }),
    }),
});

export const {
    useLoginMutation,
    useRegisterMutation,
    useVerifyMutation,
    useRefreshMutation,
    useLogoutMutation,
    useServersQuery,
    useNotificationsQuery,
    useLazyNotificationsQuery,
    useWalletsQuery,
    useEventsQuery,
    useLazyEventsQuery,
    useCreateWalletMutation,
    useChangeEmailMutation,
    useChangePasswordMutation,
    useBeginResetPasswordMutation,
    useCompleteResetPasswordMutation,
    useConfirmEmailMutation,
    useOpenNotificationQuery,
    useGetUserTaxInfoMutation,
    useGetUserCraftumsQuery,
    useLazyGetUserCraftumsQuery,
    useGetUserQuery,
    useCheckUsernameAvailabilityMutation,
    useUpdateInvoiceDataMutation,
    useUpdateUsernameMutation,
    useFastEmailAuthBeginMutation,
    useFastEmailAuthCompleteMutation,
    useMarkAllNotificationsAsReadMutation,
} = api;

export const { useCreateTicketMutation, useGetTicketTopicsMutation } = supportApi;
