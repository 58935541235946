import { isAfter } from "date-fns";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../../hooks";
import useCurrentServer from "../../../modules/Server/hooks/useCurrentServer";
import { getServerStatus } from "../../../modules/Server/slices/selectors";
import { Grid } from "../../../styles";
import showToast from "../../helpers/showToast";
import useSidebarContext from "../../hooks/SidebarContext";
import usePermissions from "../../hooks/usePermissions";
import useWindowSize from "../../hooks/useWindowSize";
import { VisualLinkMode } from "../../types";
import AdminPanel from "../AdminPanel";
import { CurrentServerContext } from "../CurrentServerProvider";
import { MaxWidthContainer } from "../MaxWidthContainer/style";
import NPSModal from "../NPSModal";
import PanelHeader from "../PanelHeader";
import PanelWrapper from "../PanelWrapper";
import Sidebar from "../Sidebar";
import { Wrapper } from "./style";
import { Props } from "./types";

const PanelLayout: React.FC<Props> = ({ children, forPaidServerOnly }) => {
    const { t } = useTranslation();
    const { server, service } = useContext(CurrentServerContext);
    const { sidebarMode, toggleSidebar } = useSidebarContext();
    const { pod_name, game_status } = useAppSelector(getServerStatus);
    const { isRunning, isAutostartEnabled, enableAutostart, disableAutostartAndStop, isServerChangingState } =
        useCurrentServer();
    const [isGRPCConnected, setIsGRPConnected] = useState(false);
    const { width } = useWindowSize();
    const { serverIdentity } = useParams();
    const { canAdminMenu } = usePermissions();
    const disableMaxWidth = window.location.pathname.includes(`${serverIdentity}/m`);

    useEffect(() => {
        if (
            !isGRPCConnected &&
            pod_name &&
            ((service.expires && !isAfter(new Date(), new Date(service.expires))) || service.never_expiring) &&
            game_status
        ) {
            setIsGRPConnected(true);
        }
    }, [isGRPCConnected, game_status, pod_name, service.expires, service.never_expiring]);

    useEffect(() => {
        if (forPaidServerOnly) {
            if (!service.expires || isAfter(new Date(), new Date(service.expires))) {
                showToast({
                    variant: "info",
                    text: t("errors.for_paid_only"),
                });
            }
        }
    }, [forPaidServerOnly, service, t]);

    if (server && forPaidServerOnly && (!service.expires || isAfter(new Date(), new Date(service.expires)))) {
        return <Navigate to={`/s/${serverIdentity}/payments`} />;
    }

    if (!server) {
        return null;
    }

    return (
        <Grid>
            {width < 1000 && <PanelHeader />}
            {(sidebarMode === VisualLinkMode.SHOW || width > 992) && (
                <Sidebar
                    $mode={sidebarMode}
                    autostartLoading={isServerChangingState}
                    toggleSidebar={toggleSidebar}
                    $disabled={!server || !pod_name}
                    isServerRunning={isRunning}
                    isAutostartEnabled={isAutostartEnabled}
                    enableAutostart={enableAutostart}
                    disableAutostartAndStop={disableAutostartAndStop}
                />
            )}
            <Wrapper>
                <PanelWrapper id="panel-wrapper">
                    <MaxWidthContainer maxWidth={disableMaxWidth ? "auto" : "1920px"}>
                        {server && children}
                    </MaxWidthContainer>
                </PanelWrapper>
                {canAdminMenu && <AdminPanel />}
                <NPSModal />
            </Wrapper>
        </Grid>
    );
};

export default PanelLayout;
