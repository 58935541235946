const url = import.meta.env.VITE_TARGET_API || window.location.origin;
export const HostUrl = url;
export const ModpileUrl = `https://kloceg.${new URL(HostUrl).hostname}`;
export const GRPCUrl = url;
export const HostUrlSuffix = url.split("://")[1];
export const CraftumDomainSuffix = ".csrv.gg";
export const ZoneName = HostUrl === "https://craftserve.ovh" ? "craftserve.ovh" : "csrv.gg";

export enum ErrorCode {
    USER_WITH_EMAIL_ALREADY_EXISTS = "user_with_email_already_exists",
    INVALID_USER_PASSWORD = "invalid_user_password",
    WRONG_PASSWORD_OR_USERNAME = "wrong_password_or_username",
    USER_NOT_FOUND = "user_not_found",
    NOT_RECOGNIZED = "not_recognized",
    WRONG_PASSWORD = "wrong password",
    NOT_AUTHORIZED = "not authorized",
    NOTIFICATION_CODE_NOT_FOUND = "notification_code_not_found",
    NOT_PROJECT_MEMBER = "not_project_member",
    PROJECT_ALREADY_EXISTS = "project_already_exists",
    MARKET_PROJECT_BAD_SLUG = "market_project_bad_slug",
    UNKNOWN_FILE_NAME = "unknown_file_name",
    INVALID_VOUCHER_FOR_PRODUCT = "invalid_voucher_for_product",
    INVALID_VOUCHER_FOR_BILLING_PERIOD = "invalid_voucher_for_billing_period",
    INVALID_FILE = "invalid_file",
    SERVICE_CANNOT_BE_UPGRADED = "service_cannot_be_upgraded",
    USER_DOES_NOT_EXIST = "user_does_not_exist",
    PLAYER_NOT_FOUND = "player_not_foound",
    TRANSACTION_IS_NOT_NEW = "transaction_is_not_new",
    UNAUTHORIZED = "unauthorized",
    USER_CODE_EXPIRED = "user_code_expired",
    HAS_NOT_POD = "server_has_not_pod",
    DOMAIN_RESERVED = "domain_reserved",
    INSUFFICIENT_PERMISSIONS = "insufficient_permissions",
    INSUFFICIENT_CAPABILITIES = "insufficient_capabilities",
    SERVER_IS_NOT_RUNNING = "server_is_not_running",
    TEMPLATE_NOT_FOUND = "template_not_found",
    SERVER_IS_EXPIRED = "server_is_expired",
    SERVER_HAS_RUNNING_BACKUP = "server_has_running_backup",
    SERVER_IS_NOT_STOPPED = "server_is_not_stopped",
    TOO_MANY_MANUAL_BACKUPS = "too_many_manual_backups",
    BACKUP_DELETED = "backup_deleted",
    SERVER_HAS_NOT_GAME_PROCESS = "server_has_not_game_process",
    SERVER_IS_RUNNING = "server_is_running",
    NO_BACKUP_REPOSITORY_BACKUP = "no_backup_repository_backup",
    NO_SERVER_BACKUP_REPO_FOUND = "no_server_backup_repo_found",
    NO_SERVER_BACKUP_FOUND = "no_server_backup_found",
    ONLY_MANUAL_BACKUPS_CAN_BE_DELETED = "only_manual_backups_can_be_deleted",
    NO_BACKUP_REPOSITORY_UPDATED = "no_backup_repository_updated",
    NO_SERVER_REPO_FOUND = "no_server_repo_found",
    CLUSTER_IS_DISABLED = "cluster_is_disabled",
    CLUSTER_DOES_NOT_SUPPORT_MODULE = "cluster_does_not_support_module",
    CLUSTER_NOT_FOUND = "cluster_not_found",
    DOMAIN_INVALID_SUBDOMAIN = "domain_invalid_subdomain",
    TOO_MANY_FREE_DOMAINS = "too_many_free_domains",
    DOMAIN_NOT_FOUND = "domain_not_found",
    PDNS_RECORD_NOT_FOUND = "pdns_record_not_found",
    ZONE_ID_NOT_FOUND = "zone_id_not_found",
    PROCESS_CANT_BE_CANCELLED = "process_cant_be_cancelled",
    PROCESS_NOT_FOUND = "process_not_found",
    PRODUCT_HAS_NO_PRICE_FOR_SELECTED_CURRENCY = "product_has_no_price_for_selected_currency",
    MODULE_HAS_NO_PRICE = "module_has_no_price",
    SERVER_IS_DISABLED = "server_is_disabled",
    BILLING_SERVICE_ALREADY_HAS_COUPON = "billing_service_already_has_coupon",
    VOUCHER_NOT_FOR_SERVICE = "voucher_not_for_service",
    MODULE_IS_NOT_SUPPORTED = "module_is_not_supported",
    MODULES_NOT_IMPLEMENTING_GIVEN_INTERFACE = "modules_not_implementing_given_interface",
    GAME_PROCESS_ALREADY_STARTED = "game_process_already_started",
    GAME_SETUP_IS_INVALID = "game_setup_is_invalid",
    SERVER_NOT_FOUND = "server_not_found",
    FILE_HAS_BEEN_MODIFIED_SINCE = "file_has_been_modified_since",
    UNSUPPORTED_PAYMENT_METHOD = "unsupported_payment_method",
    TRANSACTION_NOT_FOUND = "transaction_not_found",
    VOUCHER_ALREADY_USED = "voucher_already_used",
    VOUCHER_EXPIRED = "voucher_expired",
    VOUCHER_NOT_FOUND = "voucher_not_found",
    VOUCHER_HAS_NOT_SUPPORTING_CURRENCY = "voucher_has_not_supporting_currency",
    WALLET_HAS_OTHER_CURRENCY = "wallet_has_other_currency",
    USER_HAS_TOO_MANY_WALLETS = "user_has_too_many_wallets",
    WALLET_HAS_NOT_FUNDS = "wallet_has_not_funds",
    WALLET_NOT_FOUND = "wallet_not_found",
    PRODUCT_NOT_AVAILABLE = "product_not_available",
    MODULE_HAS_DEPENDENCIES = "module_has_dependencies",
    PRODUCT_NOT_FOUND = "product_not_found",
    MODULE_PARAMETER_NOT_ALLOWED = "module_parameter_not_allowed",
    INSTANCE_NOT_FOUND = "instance_not_found",
    FILE_DOES_NOT_EXISTS = "file_does_not_exist",
    VOUCHER_NOT_FOR_BILLING = "voucher_not_for_billing",
    VOUCHER_NOT_FOR_CLUSTER = "voucher_not_for_cluster",
    VOUCHER_NOT_FOR_PRODUCT = "voucher_not_for_product",
    VOUCHER_NOT_FOR_CURRENT_CONFIG = "voucher_not_for_current_config",
    VAILED_TO_ACQUIRE_LOCK = "failed_to_acquire_lock",
    PROJECT_NOT_FOUND = "project_not_found",
    DISC_QUOTA_EXCEEDING = "disc_quota_exceeding",
    EMAIL_ALREADY_VERIFIED = "email_already_verified",
}

export enum ScreenTitle {
    CRAFTUM = "craftum",
    PANEL = "panel",
    FILES = "files",
    ITEMSHOP = "itemshop",
    LOGS = "logs",
    LOGS_DETAILS = "logs_details",
    MAP_PREVIEW = "map_preview",
    VOUCHERS = "vouchers",
    PLUGINS = "plugins",
    SETTINGS = "settings",
    DOMAIN_SETTINGS = "domain_settings",
    MODULE_SETTINGS = "module_settings",
    NOT_FOUND = "not_found",
    HOME = "home",
    PROFILE = "profile",
    NOTIFICATIONS = "notifications",
    NOTIFICATION_DETAILS = "notification_details",
    PAYMENTS_SUCCESS = "payments_success",
    LOGIN = "login",
    REGISTER = "register",
    FORGOT_PASSWORD = "forgot_password",
    UNAUTHORIZED = "unauthorized",
    MESSAGES = "messages",
    OFFERS = "offers",
    CREATE_OFFER = "create_offer",
    RECHARGE_WALLET = "recharge_wallet",
    RENEW_SERVER = "renew_server",
    CREATE_SERVER = "create_server",
    SERVERS_LIST = "servers_list",
    ACCOUNT = "account",
    ACCOUNT_SETTINGS = "account_settings",
    ACCOUNT_EVENTS = "account_events",
    CHANGE_EMAIL = "change_email",
    PAYMENTS = "payments",
    PARENTS = "parents",
    CONTACT = "contact",
    PLAYERS_MANAGEMENT = "players_management",
    CONFIRM_CHANGE_PASSWORD = "confirm_change_password",
    CONFIRM_EMAIL = "confirm_email",
    SERVER_EVENTS = "server_events",
    EVENT_DETAILS = "event_details",
    BACKUPS = "backups",
    PAYU_SUCCESS = "payu_success",
    PAYPAL_SUCCESS = "paypal_success",
    PAYPAL_CANCEL = "paypal_cancel",
    BASIC_SETTINGS = "basic_settings",
    MC_WORLD_MANAGER = "mc_world_manager",
    MARKETPLACE = "marketplace",
    CREATE_PROJECT = "create_project",
    USER_PROJECTS = "user_projects",
    USER_ADMIN_VIEW = "user_admin_view",
    PROEJCT_RELEASE_UPLOADER = "project_release_uploader",
    PROJECT_DETAILS = "project_details",
    COUPON_USE = "coupon_use",
    UNAUTHORIZED_SERVER = "unauthorized_server",
    PRIVACY_POLICY = "privacy_policy",
    CREATE_CRAFTUM = "create_craftum",
    CRAFTUM_PAYMENT = "craftum_payment",
    EDIT_PROJECT = "edit_project",
}

export enum InstanceStatus {
    IRL_NEW = "IRL_NEW",
    IRL_RUNNING = "IRL_RUNNING",
    IRL_IDLE = "IRL_IDLE", // it's IRL_RUNNING with low CPU
    IRL_LIGHT_SLEEP = "IRL_LIGHT_SLEEP",
    IRL_DEEP_SLEEP = "IRL_DEEP_SLEEP",
    IRL_HIBERNATED = "IRL_HIBERNATED",
    IRL_TERMINATED = "IRL_TERMINATED",
}
