// twq('event', 'tw-oyuhz-oyui0', {
//     value: null, // use this to pass the value of the conversion (e.g. 5.00)
//     contents: [ // use this to pass an array of products or content
//         // add all items to the array
//         // use this for the first item
//         {
//           content_type: null,
//           content_id: null,
//           content_name: null,
//           content_price: null,
//           num_items: null,
//           content_group_id: null
//         },
//         // use this for the second item
//         {
//           content_type: null,
//           content_id: null,
//           content_name: null,
//           content_price: null,
//           num_items: null,
//           content_group_id: null
//         }],
//     email_address: null // use this to pass a user’s email address
//   });

export interface TwitterContent {
    content_type: string | null;
    content_id: string | null;
    content_name: string | null;
    content_price: number | null;
    num_items: number | null;
    content_group_id: string | null;
}

export interface TwitterPurchaseParams {
    value: number;
    contents: TwitterContent[];
    email_address: string | null;
}

export interface TwitterCreateServerParams {
    contents: TwitterContent[];
    email_address: string | null;
}

export const trackTwitterPurchase = ({ params }: { params: TwitterPurchaseParams }) => {
    if (typeof window.twq === "function") {
        window.twq("event", "tw-oyuhz-oyui0", params);
    }
};

export const trackTwitterRegister = (email: string) => {
    if (typeof window.twq === "function") {
        window.twq("event", "tw-oyuhz-p0xem", {
            email_address: email, // use this to pass a user’s email address
        });
    }
};

export const trackTwitterCreateServer = (params: TwitterCreateServerParams) => {
    if (typeof window.twq === "function") {
        window.twq("event", "tw-oyuhz-p0xfc", params);
    }
};

export const trackTwitterRedeemTestServer = (items: TwitterContent[]) => {
    if (typeof window.twq === "function") {
        window.twq("event", "tw-oyuhz-p0xeu", {
            contents: items,
        });
    }
};
