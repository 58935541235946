import { useTranslation } from "react-i18next";
import { BillingPeriods } from "../../../constants";
import SwitchTab from "../../../modules/Marketplace/components/SwitchTab";
import { useTrackEvent } from "../../../tracking/useTrackEvent";
import useTheme from "../../hooks/useCustomTheme";

interface Props {
    value: BillingPeriods;
    onChange: (value: BillingPeriods) => void;
    periods?: BillingPeriods[];
}

const BillingPeriodSwitch = ({ onChange, value, periods }: Props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { trackEvent } = useTrackEvent();

    const hoursToPackage = {
        [BillingPeriods.MONTH]: t("offers.billing_periods.month"),
        [BillingPeriods.THREE_MONTHS]: t("offers.billing_periods.3-month"),
        [BillingPeriods.YEAR]: t("offers.billing_periods.year"),
    };

    const data = periods || Object.values(BillingPeriods);

    return (
        <SwitchTab
            data-ph-capture-attribute-id="billing-period-switch"
            variant="small"
            selectedColor={theme.colors.white}
            switchProps={{
                style: {
                    backgroundColor: theme.colors.dark,
                },
            }}
            tab={value}
            style={{
                backgroundColor: theme.colors.primaryBackground,
                border: `4px solid ${theme.colors.dark}`,
                color: theme.colors.white,
                width: "100%",
            }}
            tabs={data.map((period) => ({
                id: period,
                label: hoursToPackage[period],
            }))}
            onChange={(tab) => {
                onChange(tab.id as BillingPeriods);
                trackEvent("server_settings", "billing_period", "change", {
                    period: tab.id,
                });
            }}
        />
    );
};

export default BillingPeriodSwitch;
